import React, { useRef } from 'react';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import cn from 'classnames';

import { CloseCircleFilled } from '@ant-design/icons';
import { Icon } from '../icon';

import './styles.scss';

export const CustomDatePicker = ({
  value,
  onChange,
  className = undefined,
  withIcon = true,
  ...restProps
}) => {
  const pickerContainerRef = useRef(null);

  const parseValue = () => {
    if (dayjs.isDayjs(value)) return value;
    if (value) return dayjs(value);
    return undefined;
  };

  return (
    <>
      <DatePicker
        style={{ width: '100%' }}
        placeholder=""
        value={parseValue()}
        onSelect={onChange}
        getPopupContainer={(triggerNode) =>
          pickerContainerRef?.current || triggerNode
        }
        format="DD/MM/YYYY"
        clearIcon={
          <CloseCircleFilled color="91a2ab" className="date-input-clear-icon" />
        }
        suffixIcon={
          withIcon && (
            <Icon
              variant="calendar"
              width={24}
              height={24}
              fill="#91a2ab"
              className="date-input-suffix-icon"
            />
          )
        }
        className={cn(className, 'custom-date-time-picker')}
        {...restProps}
      />
      <div ref={pickerContainerRef} />
    </>
  );
};
