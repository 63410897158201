import { useState, useMemo } from 'react';
import { Table, Avatar } from 'antd';
import dayjs from 'dayjs';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { usePayments } from '../../hooks';
import { Icon, InfiniteScrollWrapper, getSearchFilterProps } from '../../components';
import { ViewInvoiceModal } from './ViewInvoicesModal';

export function OverduePayments() {
  const location = useLocation();
  const history = useHistory();

  const queryParams = useMemo(() => {
    const params = qs.parse(location.search);
    delete params?.category;
    delete params?.payment_id;
    return params;
  }, [location.search]);

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } =
    usePayments({ category: 'overdue', queryParams });

  const [isVisible, setVisible] = useState(false);

  const getSortOrder = (parameter) => {
    if (queryParams[parameter] === 'desc') return 'descend';
    if (queryParams[parameter] === 'asc') return 'ascend';
    return null;
  };

  const columns = [
    {
      title: "Driver's Name",
      dataIndex: 'driver_name',
      key: 'driver_name',
      ...getSearchFilterProps("Driver's Name"),
      render: (_, record) => (
        <>
          <Avatar
            size="large"
            shape="square"
            style={{ backgroundColor: '#cee5fb', marginRight: '16px' }}
            src={record?.driver_car.driver.profile_image}
          />
          <span>{`${record?.driver_car.driver.first_name} ${record?.driver_car.driver.last_name}`}</span>
        </>
      ),
    },
    {
      title: 'Car',
      dataIndex: 'rg_number',
      key: 'rg_number',
      ...getSearchFilterProps("Car's Registration Number"),
      render: (_, record) => (
        <>
          {`${record?.driver_car.car?.make} ${record?.driver_car.car?.model}`}
          <br />
          ({record?.driver_car.car?.registrationnumber})
        </>
      ),
    },
    {
      title: 'Payment date',
      render: (_, record) => dayjs(record?.payment_date).format('DD/MM/YYYY'),
      sorter: true,
      key: 'payment_date',
      sortOrder: getSortOrder('payment_date'),
    },
    {
      title: 'Payment amount',
      render: (_, record) => `£ ${record?.total_cost}`,
      sorter: true,
      key: 'amount',
      sortOrder: getSortOrder('amount'),
    },
    {
      width: 54,
      render: () => (
        <Icon
          variant="chevron-right"
          width={24}
          height={24}
          className="open-row-info-icon"
          fill="#000"
          stroke="#000"
        />
      ),
    },
  ];

  const handelChangeTable = (pagination, filters, sorter) => {
    const { current: page, pageSize: size } = pagination;
    const params = { page, size };
    if (sorter.column) {
      Object.assign(params, {
        [`${sorter.columnKey}`]:
          sorter.order === 'descend' ? 'desc' : 'asc',
      });
    }
    Object.entries(filters).forEach(([key, value]) => {
      if (value?.length > 0) {
        let paramKey;
        switch (key) {
          case 'rg_number':
            paramKey = 'rg_number';
            break;
          case 'driver_name':
            paramKey = 'driver_name';
            break;
          case 'payment_date':
            paramKey = 'payment_date';
            break;
          case 'amount':
            paramKey = 'amount';
            break;
          default:
            paramKey = key;
            break;
        }
        Object.assign(params, { [paramKey]: value[0] });
      }
    });
    history.replace({
      search: qs.stringify({
        ...qs.parse(history.location.search),
        driver_name: params.driver_name,
        rg_number: params.rg_number,
        payment_date: params.payment_date,
        amount: params.amount,
      }),
    });
  };

  return (
    <>
      <InfiniteScrollWrapper
        lengthData={data?.pages?.[0].result?.length}
        hasMore={hasNextPage}
        functionNext={fetchNextPage}
      >
        <Table
          columns={columns}
          dataSource={data?.pages.reduce((res, cur) => {
            if (cur?.result) {
              res.push(...cur?.result);
            } else res.push(...cur);
            return res;
          }, [])}
          rowKey={(record) => record.id}
          loading={isLoading || isFetching}
          pagination={false}
          onRow={(payment) => ({
            onClick: () => {
              setVisible(true);
              history.push({
                search: qs.stringify({
                  ...qs.parse(history.location.search),
                  payment_id: payment.id,
                }),
              });
            },
            className: 'clickable-table-row',
          })}
          onChange={handelChangeTable}
        />
      </InfiniteScrollWrapper>
      <ViewInvoiceModal
        isVisible={isVisible}
        setVisible={setVisible}
        invoiceType="overdue"
      />
    </>
  );
}
