import { Table } from 'antd';
import qs from 'query-string';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { usetVehicleProfitExpenses } from '../../../hooks/report';

export default function Expenses() {
  const location = useLocation();
  const query = useMemo(() => qs.parse(location.search), [location.search]);
  delete query.category;
  const { data, loading } = usetVehicleProfitExpenses({
    start:
      query?.start_date || dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    end: query?.end_date || dayjs().format('YYYY-MM-DD'),
    registration_number: query?.registration_number,
  });
  const columns = [
    {
      title: 'Expence type',
      dataIndex: 'expense_type',
    },
    {
      title: 'Total',
      dataIndex: 'total',
      render: (value) => `£ ${value}`,
    },
    {
      title: 'Net',
      dataIndex: 'net',
      render: (value) => `£ ${value}`,
    },
    {
      title: 'VAT',
      dataIndex: 'vat',
      render: (value) => `£ ${value}`,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (value) => dayjs(value).format('YYYY-MM-DD'),
    },
    {
      title: 'Service provider',
      dataIndex: 'service_providor',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
  ];
  return (
    <Table
      loading={loading}
      columns={columns}
      dataSource={data?.result}
      pagination={false}
    />
  );
}
