import { Divider, Form, Modal, Upload } from 'antd';
import { useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { axios, getBase64 } from '../../utils';
import CarInfo from './CarInfo';
import CustomersTestimonials from './CustomersTestimonials';
import Faq from './Faq';

export default function ShowOnSite({ carData, form }) {
  const location = useLocation();

  const carId = useMemo(
    () => qs.parse(location.search).car_id,
    [location.search],
  );

  const [preview, setPreview] = useState({
    image: null,
    visible: false,
  });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj);
    }
    setPreview({
      image: file.url || file.preview,
      visible: true,
    });
  };

  const editImages = () => {
    const fd = new FormData();
    const images = carData?.ablum || form.getFieldValue('album').fileList;

    images.forEach((image, index) => {
      fd.append(
        `image_ids[${index}]`,
        image.response ? image.response.data.id : image.id,
      );
    });
    fd.append(`make`, carData.make);
    fd.append(`model`, carData.model);
    fd.append(`body_shape`, carData.body_shape);

    axios.put(`/cars/car/${carId}/`, fd);
  };

  const uploadImage = ({ onSuccess, onError, onProgress, file }) => {
    const fd = new FormData();
    fd.append('image', file);
    fd.append('car', carId);
    return new Promise((resolve, reject) => {
      axios
        .post('/cars/album/add-image/', fd, {
          onUploadProgress: (e) => {
            const percent = (e.loaded / e.total) * 100;
            onProgress({ ...e, percent });
          },
        })
        .then((data) => {
          onSuccess(data);
          resolve(data);
        })
        .catch((err) => {
          onError(err);
          reject(err);
        });
    });
  };

  const removePicHandler = (image) => {
    const id = image.response ? image.response.data.id : image.id;
    return axios.delete(`/cars/album/add-image/${id}/`).then(() => true);
  };

  return (
    <>
      <Divider className="color-text" orientation="left">
        Car picture gallery
      </Divider>
      <div
        className="inline-container"
        style={{ justifyContent: 'space-between' }}
      >
        <Form.Item
          getValueProps={(values) => {
            if (Array.isArray(values)) {
              return {
                fileList: values?.map(({ image, id }) => ({
                  url: image,
                  id,
                })),
              };
            }
            return values;
          }}
          valuePropName="fileList"
          name="album"
          className="accident_images"
        >
          <Upload
            accept="image/png, image/jpeg, image/jpg"
            customRequest={(e) =>
              uploadImage({ ...e }).then(({ data }) => {
                editImages(data);
              })
            }
            onPreview={handlePreview}
            listType="picture-card"
            onRemove={removePicHandler}
          >
            Click here to upload
          </Upload>
        </Form.Item>

        <Modal
          visible={preview.visible}
          title={null}
          footer={null}
          closable={false}
          onCancel={() => setPreview({ visible: false, image: null })}
          className="preview-pic-modal"
        >
          <img style={{ width: '100%' }} src={preview.image} />
        </Modal>
      </div>
      <CarInfo data={carData?.carinformation} />
      <CustomersTestimonials data={carData?.customerstestimonial} />
      <Faq data={carData?.FAQ} />
    </>
  );
}
