import { string } from 'prop-types';

import sprite from './sprite.svg';

/**
 * @param {string} variant - id of icon you want to use that specified in the sprite.svg
 */
export function Icon(props) {
  const { variant, ...restProps } = props;
  return (
    <svg {...restProps}>
      <use xlinkHref={`${sprite}#${variant}`} />
    </svg>
  );
}
Icon.propTypes = {
  variant: string.isRequired,
};

export default Icon;
