import { Button } from 'antd';

import './styles.scss';

export function InfiniteScrollWrapper({ hasMore, functionNext, children }) {
  return (
    <>
      {children}
      {hasMore ? (
        <Button
          type="primary"
          onClick={functionNext}
          className="load-more-btn"
          size="middle"
        >
          Load more
        </Button>
      ) : null}
    </>
  );
}
