import { useInfiniteQuery, useQuery } from 'react-query';
import * as api from '../api';

export const useAccident = (paramsQuery) =>
  useInfiniteQuery(
    ['getAccident', paramsQuery],
    ({ pageParam }) => api.getAccident({ pageParam, paramsQuery }),
    {
      getNextPageParam: (lastPage) => lastPage?.next ?? false,
    },
  );

export const useAccidentId = ({ id, category }) =>
  useQuery(
    ['getAccidentId', { id, category }],
    () => api.getAccidentId({ id, category }),
    {
      enabled: !!id,
    },
  );
