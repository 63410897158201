// import qs from 'query-string';
import axios from '../utils/axios';

export const getDamageSketch = async (id) => {
  const { data } = await axios.get(`/cars/damage/${id}/`);
  return data;
};

export const updateDamageSketch = (damageSketchID, payload) => {
  const fd = new FormData();
  Object.entries(payload).forEach(([key, value]) => fd.append(key, value));

  return axios.put(`/cars/damage/${damageSketchID}/`, fd);
};
