import { Avatar, Table } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { useMemo } from 'react';
import Select from 'rc-select';
import { useDrivers, useUserStates } from '../../hooks';
import {
  getSearchFilterProps,
  getSelectFilterProps,
  InfiniteScrollWrapper,
} from '../../components';

export function NewRegisteredDrivers() {
  const history = useHistory();
  const location = useLocation();

  const queryParams = useMemo(() => {
    const params = qs.parse(location.search);
    delete params?.category;
    delete params?.driver_id;
    return params;
  }, [location.search]);

  const getSortOrder = (parameter) => {
    if (queryParams[parameter]) {
      if (queryParams[parameter]?.startsWith('-')) return 'descend';
      return 'ascend';
    }
    return null;
  };

  const { data: userStates } = useUserStates();

  const columns = [
    {
      title: 'Reference Number',
      dataIndex: 'reference_number',
      key: 'reference_number',
      width: 1,
    },
    {
      title: 'Name',
      dataIndex: 'first_name',
      ...getSearchFilterProps('New Registered Drivers'),
      render: (_, record) => (
        <>
          <Avatar
            size="large"
            shape="square"
            src={record?.profileimage}
            style={{ backgroundColor: '#cee5fb', marginRight: '16px' }}
          />
          <span>
            {`${record?.first_name} ${record?.last_name}` || record.username}
          </span>
        </>
      ),
    },
    {
      title: 'Phone number',
      dataIndex: 'phone_number',
      align: 'center',
      key: 'phone',
      ...getSearchFilterProps('Phone'),
    },
    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      sortOrder: getSortOrder('ordering'),
      ...getSelectFilterProps('state', userStates, (record) => (
        <Select.Option value={record?.[0]}>{record?.[1]}</Select.Option>
      )),
      render: (state) => state && Object.values(state)?.[0],
    },
    {
      title: 'Date of birth',
      dataIndex: 'birth_date',
      key: 'birth_date',
      ...getSearchFilterProps('Date of birth'),
      render: (value) => value || '-',
    },
    {
      title: 'Postcode',
      dataIndex: 'postal_number',
      key: 'postal_number',
      ...getSearchFilterProps('Postcode'),
      render: (value) => value || '-',
    },
  ];

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } =
    useDrivers({ category: 'newRegistered', queryParams });

  const handelChangeTable = (pagination, filters, sorter) => {
    const { current: page, pageSize: size } = pagination;
    const params = { page, size };
    if (sorter.column) {
      if (sorter.columnKey === 'state') {
        Object.assign(params, {
          ordering: sorter.order === 'descend' ? '-state' : 'state',
        });
      }
    }

    Object.entries(filters).forEach(([key, value]) => {
      if (value?.length > 0) {
        let paramKey;
        switch (key) {
          case 'phone':
            paramKey = 'phone';
            break;
          case 'first_name':
            paramKey = 'search';
            break;
          case 'postal_number':
            paramKey = 'postal_number';
            break;
          case 'birth_date':
            paramKey = 'birth_date';
            break;
          default:
            paramKey = key;
            break;
        }
        Object.assign(params, { [paramKey]: value[0] });
      }
    });
    history.replace({
      search: qs.stringify({
        ...qs.parse(history.location.search),
        search: params.search,
        state: params.state,
        sort_state: params.sort_state,
        phone_number: params.phone,
        ordering: params.ordering,
        postal_number: params.postal_number,
        birth_date: params.birth_date,
      }),
    });
  };

  return (
    <InfiniteScrollWrapper
      lengthData={data?.results?.[0].count}
      hasMore={hasNextPage}
      functionNext={fetchNextPage}
    >
      <Table
        columns={columns}
        dataSource={data?.pages?.reduce((res, cur) => {
          if (cur?.results) {
            res.push(...cur?.results);
          } else res.push(...cur);
          return res;
        }, [])}
        rowKey={(record) => record.id}
        loading={isLoading || isFetching}
        pagination={false}
        onRow={(driver) => ({
          onClick: () => {
            history.push({
              search: qs.stringify({
                ...qs.parse(history.location.search),
                driver_id: driver.id,
              }),
            });
          },
          className: 'clickable-table-row',
        })}
        onChange={handelChangeTable}
      />
    </InfiniteScrollWrapper>
  );
}
