import './utils/firebase';

import React from 'react';
import ReactDOM from 'react-dom';

import './antd.less';
import './global.scss';
import App from './App';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);
