import { useInfiniteQuery, useQuery } from 'react-query';
import qs from 'query-string';
import * as api from '../api';

const useInvoice = (id) =>
  useQuery(['invoice', id], () => api.getPaymentInvoiceByID(id), {
    enabled: id !== undefined && typeof id === 'string',
  });

const usePayments = ({ category, queryParams }) => {
  let fetchEndpoint = '';
  switch (category) {
    case 'pending':
      fetchEndpoint += '/payment/pending-payments?';
      break;
    case 'history':
      fetchEndpoint += '/payment/invoice-history?';
      break;
    case 'overdue':
      fetchEndpoint += '/payment/overdue-payments?';
      break;
    case 'outstanding':
      fetchEndpoint += '/payment/daily-outstanding?';
      break;
    case 'upcoming':
    default:
      fetchEndpoint += '/payment/upcoming-payments?';
      break;
  }

  return useInfiniteQuery(
    ['payments', qs.stringify({ category, ...queryParams })],
    ({ pageParam }) =>
      api.getPayments({ fetchEndpoint, pageParam, queryParams, category }),
    {
      getNextPageParam: (lastPage) => lastPage?.next ?? false,
    },
  );
};

export { usePayments, useInvoice };
