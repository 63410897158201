import { Form, Input } from 'antd';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';

import { SinglePhotoUploader } from '../single-photo-uploader';
import { CustomDatePicker } from '../custom-date-picker';
import { FloatLabel } from '../floating-label';

import './styles.scss';

const isRequiredRule = {
  required: true,
  message: 'This field is required',
};

export function DrivingAndPCOLicenseSection() {
  const location = useLocation();
  const isDriverProfileModal = !!qs.parse(location.search)?.driver_id;

  return (
    <div className="inline-container">
      <Form.Item
        name={
          isDriverProfileModal
            ? ['personaldocuments', 'driving_license_front_image']
            : 'driving_license_front_image'
        }
        label="Driving license - Front"
        className="flex-column"
        rules={[isRequiredRule]}
      >
        <SinglePhotoUploader />
      </Form.Item>
      <Form.Item
        name={
          isDriverProfileModal
            ? ['personaldocuments', 'driving_license_back_image']
            : 'driving_license_back_image'
        }
        label="Driving license - Back"
        className="flex-column"
        rules={[isRequiredRule]}
      >
        <SinglePhotoUploader />
      </Form.Item>
      <Form.Item name="driving_license_number" rules={[isRequiredRule]}>
        <FloatLabel label="Driving license number">
          <Input className="full-width" />
        </FloatLabel>
      </Form.Item>
      <Form.Item name="issuedate" rules={[isRequiredRule]}>
        <FloatLabel label="Driving license issue date">
          <CustomDatePicker />
        </FloatLabel>
      </Form.Item>
      <Form.Item />
      <Form.Item
        name="driving_license_expiration_date"
        rules={[isRequiredRule]}
      >
        <FloatLabel label="Driving license expiration date">
          <CustomDatePicker />
        </FloatLabel>
      </Form.Item>

      <div className="full-width">
        <Form.Item
          name={
            isDriverProfileModal
              ? ['personaldocuments', 'pco_license_image']
              : 'pco_license_image'
          }
          label="PCO license"
          className="flex-column"
          style={{ flexBasis: '48%', width: '48%' }}
          rules={[isRequiredRule]}
        >
          <SinglePhotoUploader />
        </Form.Item>
      </div>
      <Form.Item name="pco_license_number" rules={[isRequiredRule]}>
        <FloatLabel label="PCO license number">
          <Input className="full-width" />
        </FloatLabel>
      </Form.Item>
      <Form.Item name="pco_license_expiration_date" rules={[isRequiredRule]}>
        <FloatLabel label="PCO license expiration date">
          <CustomDatePicker />
        </FloatLabel>
      </Form.Item>
    </div>
  );
}
