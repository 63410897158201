import React, { useEffect, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Popconfirm,
  Select,
  Spin,
} from 'antd';
import { PlusOutlined, RightOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from 'react-query';
import cn from 'classnames';
import { useCarTypes, useCarModels, useDriver } from '../../hooks';
import { SinglePhotoUploader } from '../../components/single-photo-uploader';
import { CustomizedModal, FloatLabel, UploadPDF } from '../../components';
import { Expense } from './Expense';
import { axios } from '../../utils';
import { DamageSketchTemplate } from '../../components/driver-profile-modal/damage-sketch-template';
import { updateDamageSketch } from '../../api';
import { CustomDatePicker } from '../../components/custom-date-picker';
import ShowOnSite from './ShowOnSite';

const { Option } = Select;

export function FormItems({
  mode,
  car,
  carID,
  setAddExp,
  saveDamageSketchData,
  form,
  showOnSite,
  setShowOnSite,
  setIsModalVisible,
}) {
  const [formAddType] = Form.useForm();
  const [formAddCompany] = Form.useForm();
  const qc = useQueryClient();

  const [loadingAddType, setLoadingAddType] = useState(false);
  const [disableLastMot, setDisableLastMot] = useState(false);
  const [disableFinance, setDisableFinance] = useState(false);
  const [showOnSiteModal, setShowOnSiteModal] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const [isVisibleModalExpenses, setIsVisibleModalExpenses] = useState(false);
  const [visiblePopConfirm, setVisiblePopConfirm] = useState(false);

  const {
    data: carType,
    isLoading: loadingType,
    refetch: refetchType,
  } = useCarTypes();
  const {
    data: carModel,
    isLoading: loadingModel,
    refetch: refetchModel,
  } = useCarModels();

  useEffect(() => {
    if (car?.flag) {
      setShowOnSite(true);
    }
  }, [car]);

  const driverID = useMemo(
    () => (mode === 'update' && car?.assing_to ? car.assing_to.id : null),
    [car, mode],
  );

  const { data: driver } = useDriver(driverID);

  const rules = [{ required: true, message: 'This field is required' }];

  const priceBase = (value) =>
    value && `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const addCompany = () => {
    setLoadingAddType(true);
    const fd = new FormData();
    fd.append('company', formAddCompany.getFieldValue('company'));
    axios
      .post('/cars/carcompany/', fd)
      .then(() => {
        refetchModel();
        formAddCompany.resetFields();
      })
      .finally(() => {
        setLoadingAddType(false);
      });
  };

  const { isLoading: isLoadingArchiveCar, mutate } = useMutation(() => {
    const { data } = axios
      .delete(`/cars/car/${carID}/`)
      .then(({ data: { message: messageResponse } }) => {
        setIsModalVisible(false);
        setVisiblePopConfirm(false);
        message.info(messageResponse);
        qc.invalidateQueries('cars');
      });
    return data;
  });

  const archiveCar = () => {
    mutate();
  };

  const unArchiveCar = () => {
    axios.patch(`/cars/car/${carID}/`, { status: 2 }).then(() => {
      setIsModalVisible(false);
      qc.invalidateQueries('cars');
    });
  };

  const addType = () => {
    setLoadingAddType(true);
    const fd = new FormData();
    fd.append('type', formAddType.getFieldValue('body_shape'));
    axios
      .post('/cars/carbodyshape/', fd)
      .then(() => refetchType())
      .finally(() => {
        formAddType.resetFields();
        setLoadingAddType(false);
      });
  };

  const submitDamageSketchUpdate = (payload) => {
    if (mode === 'update' && car?.damagessketch) {
      return updateDamageSketch(car?.damagessketch, payload);
    }
    saveDamageSketchData(payload);
    return Promise.resolve();
  };

  return (
    <>
      <Divider orientation="left">Car legal info</Divider>
      <Form.Item className="form-item space-bottom" name="make" rules={rules}>
        <FloatLabel label="Car manufacturer">
          <Select
            loading={loadingModel}
            notFoundContent={loadingModel ? <Spin size="small" /> : undefined}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
              0
            }
            disabled={loadingModel}
            dropdownRender={(menu) => (
              <div>
                <Spin spinning={loadingAddType}>
                  {menu}
                  <Divider style={{ margin: '4px 0' }} />
                  <Form form={formAddCompany} onFinish={addCompany}>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        padding: 8,
                      }}
                    >
                      <Form.Item
                        name="company"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ height: '25px' }} />
                      </Form.Item>
                      <Form.Item shouldUpdate>
                        {() => (
                          <Button
                            type="link"
                            size="small"
                            onClick={() => formAddCompany.submit()}
                            disabled={
                              !formAddCompany.isFieldsTouched(true) ||
                              !!formAddCompany
                                .getFieldsError()
                                .filter(({ errors }) => errors.length).length
                            }
                          >
                            <PlusOutlined /> Add item
                          </Button>
                        )}
                      </Form.Item>
                    </div>
                  </Form>
                </Spin>
              </div>
            )}
          >
            {carModel?.map((item) => (
              <Option key={item.id} value={item.company}>
                {item.company}
              </Option>
            ))}
          </Select>
        </FloatLabel>
      </Form.Item>
      <Form.Item className="form-item space-bottom" name="model" rules={rules}>
        <FloatLabel label="Model">
          <Input autoComplete="off" />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        className="form-item space-bottom"
        name="registrationnumber"
        rules={rules}
      >
        <FloatLabel label="Registration Number">
          <Input className="full-width" />
        </FloatLabel>
      </Form.Item>
      <Divider orientation="left">Technical specifications</Divider>
      <Form.Item className="form-item space-bottom" name="year" rules={rules}>
        <FloatLabel label="Date of manufacturer">
          <CustomDatePicker />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        className="form-item space-bottom"
        name="transmission"
        rules={rules}
      >
        <FloatLabel label="Transmission">
          <Select>
            <Option value="Automatic">Automatic</Option>
            <Option value="Manual">Manual</Option>
          </Select>
        </FloatLabel>
      </Form.Item>
      <Form.Item
        className="form-item space-bottom"
        name="fueltype"
        rules={rules}
      >
        <FloatLabel label="Fuel type">
          <Select>
            <Option value="fullElectric">Full electric</Option>
            <Option value="hybrid">Hybrid</Option>
            <Option value="diesel">Diesel</Option>
            <Option value="Plugin">Plug-in</Option>
          </Select>
        </FloatLabel>
      </Form.Item>
      <Form.Item
        className="form-item space-bottom"
        name="enginesize"
        rules={rules}
      >
        <FloatLabel label="Engine size">
          <Input
            onWheel={(e) => e.target.blur()}
            className="full-width"
            type="number"
          />
        </FloatLabel>
      </Form.Item>
      <Divider orientation="left">Appearance</Divider>
      <Form.Item
        className="form-item space-bottom"
        name="body_shape"
        rules={rules}
      >
        <FloatLabel label="Type">
          <Select
            loading={loadingType}
            notFoundContent={loadingType ? <Spin size="small" /> : undefined}
            allowClear
            showSearch
            filterOption={(input, option) =>
              option?.children?.toLowerCase()?.indexOf(input?.toLowerCase()) >=
              0
            }
            disabled={loadingType}
            dropdownRender={(menu) => (
              <div>
                <Spin spinning={loadingAddType}>
                  {menu}
                  <Divider style={{ margin: '4px 0' }} />
                  <Form form={formAddType} onFinish={addType}>
                    <div
                      style={{
                        display: 'flex',
                        flexWrap: 'nowrap',
                        padding: 8,
                      }}
                    >
                      <Form.Item
                        name="body_shape"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input style={{ height: '25px' }} />
                      </Form.Item>
                      <Form.Item shouldUpdate>
                        {() => (
                          <Button
                            type="link"
                            size="small"
                            onClick={() => formAddType.submit()}
                            disabled={
                              !formAddType.isFieldsTouched(true) ||
                              !!formAddType
                                .getFieldsError()
                                .filter(({ errors }) => errors.length).length
                            }
                          >
                            <PlusOutlined /> Add item
                          </Button>
                        )}
                      </Form.Item>
                    </div>
                  </Form>
                </Spin>
              </div>
            )}
          >
            {carType?.map((item) => (
              <Option key={item.id} value={item.type}>
                {item?.type}
              </Option>
            ))}
          </Select>
        </FloatLabel>
      </Form.Item>
      <Form.Item className="form-item space-bottom" name="color" rules={rules}>
        <FloatLabel label="Color">
          <Input autoComplete="off" />
        </FloatLabel>
      </Form.Item>
      <Form.Item className="form-item space-bottom" name="seats" rules={rules}>
        <FloatLabel label="Seats">
          <Select>
            <Option value="4">4</Option>
            <Option value="5">5</Option>
            <Option value="6">6</Option>
            <Option value="7">7</Option>
          </Select>
        </FloatLabel>
      </Form.Item>
      <Divider orientation="left">Renting detail</Divider>
      <Form.Item
        className="form-item space-bottom"
        name="deposit_amount"
        rules={rules}
      >
        <FloatLabel label="Car deposit fee">
          <InputNumber
            onWheel={(e) => e.target.blur()}
            className="full-width"
            formatter={priceBase}
            maxLength={9}
          />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        className="form-item space-bottom"
        name="weekly_rent_amount"
        rules={rules}
      >
        <FloatLabel label="Car weekly rent fee">
          <InputNumber
            onWheel={(e) => e.target.blur()}
            formatter={priceBase}
            className="full-width"
            maxLength={9}
          />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        className="form-item space-bottom"
        name="carvalue"
        rules={rules}
      >
        <FloatLabel label="Car Value">
          <InputNumber
            onWheel={(e) => e.target.blur()}
            formatter={priceBase}
            className="full-width"
            maxLength={9}
          />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        className="form-item space-bottom"
        name="availableFrom"
        // rules={rules}
      >
        <FloatLabel label="Available from">
          <CustomDatePicker />
        </FloatLabel>
      </Form.Item>
      <Divider orientation="left">Vehicle finance details</Divider>
      <Form.Item
        className="wrapper-last-mot"
        name="financeFlag"
        valuePropName="checked"
      >
        <Checkbox
          value={disableFinance}
          onChange={(e) => {
            form.validateFields([
              'carfinance.purchase_date',
              'carfinance.direct_debit_day',
              'carfinance.term',
              'carfinance.total_amount',
              'carfinance.monthly_amount',
              'carfinance.paid_amount',
            ]);
            setDisableFinance(e.target.checked);
          }}
        >
          Include this vehicle in the finance report
        </Checkbox>
      </Form.Item>
      <Form.Item
        className="form-item space-bottom"
        name="carfinance.purchase_date"
        rules={!disableFinance ? [] : rules}
      >
        <FloatLabel label="Purchase date">
          <CustomDatePicker disabled={!disableFinance} />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        className="form-item space-bottom"
        name="carfinance.direct_debit_day"
        rules={!disableFinance ? [] : rules}
      >
        <FloatLabel label="Date of direct debit">
          <CustomDatePicker format="DD" disabled={!disableFinance} />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        className="form-item space-bottom"
        name="carfinance.term"
        rules={!disableFinance ? [] : rules}
      >
        <FloatLabel label="Term">
          <InputNumber
            onWheel={(e) => e.target.blur()}
            className="full-width"
            maxLength={9}
            disabled={!disableFinance}
          />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        className="form-item space-bottom"
        name="carfinance.total_amount"
        rules={!disableFinance ? [] : rules}
      >
        <FloatLabel label="Amount due to be paid">
          <InputNumber
            onWheel={(e) => e.target.blur()}
            formatter={priceBase}
            className="full-width"
            maxLength={9}
            disabled={!disableFinance}
          />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        className="form-item space-bottom"
        name="carfinance.monthly_amount"
        rules={!disableFinance ? [] : rules}
      >
        <FloatLabel label="Amount paid up to today">
          <InputNumber
            onWheel={(e) => e.target.blur()}
            formatter={priceBase}
            className="full-width"
            maxLength={9}
            disabled={!disableFinance}
          />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        className="form-item space-bottom"
        name="carfinance.paid_amount"
        rules={!disableFinance ? [] : rules}
      >
        <FloatLabel label="Remaining balance">
          <InputNumber
            onWheel={(e) => e.target.blur()}
            formatter={priceBase}
            className="full-width"
            maxLength={9}
            disabled={!disableFinance}
          />
        </FloatLabel>
      </Form.Item>
      <Divider orientation="left">Car pictures</Divider>
      <Form.Item
        className="form-item space-bottom flex-column"
        name="carimage"
        rules={rules}
      >
        <SinglePhotoUploader title="Car Image" />
      </Form.Item>
      <Divider orientation="left">Car service record</Divider>
      <Form.Item
        className="form-item space-bottom"
        name="currentOdometer"
        rules={rules}
      >
        <FloatLabel label="Current Odometer">
          <InputNumber
            type="number"
            onWheel={(e) => e.target.blur()}
            className="full-width"
          />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        className="form-item space-bottom"
        name="mileage_interval"
        rules={rules}
      >
        <FloatLabel label="Mileage interval">
          <InputNumber
            onWheel={(e) => e.target.blur()}
            className="full-width"
          />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        className="form-item space-bottom"
        name="last_service_date"
        rules={rules}
      >
        <FloatLabel label="Last car service">
          <CustomDatePicker />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        className="form-item space-bottom"
        name="last_service_milage"
        rules={rules}
      >
        <FloatLabel label="Last car service mileage">
          <InputNumber
            type="number"
            onWheel={(e) => e.target.blur()}
            className="full-width"
          />
        </FloatLabel>
      </Form.Item>
      <Divider orientation="left">MOT test record</Divider>
      {mode === 'add' && (
        <Form.Item
          className="wrapper-last-mot"
          name="first_hand"
          valuePropName="checked"
        >
          <Checkbox
            value={disableLastMot}
            onChange={(e) => {
              form.validateFields([
                ['mot', 'mot_test'],
                ['mot', 'last_mot'],
                ['mot', 'mot_expire'],
              ]);
              setDisableLastMot(e.target.checked);
            }}
          >
            Register this car as a first hand car
          </Checkbox>
        </Form.Item>
      )}
      <UploadPDF
        mode={mode}
        title="MOT certificate"
        name={['mot', 'mot_test']}
        linkdocs={mode === 'update' && car?.mot?.mot_test}
        border={false}
        disabled={disableLastMot}
        rules={mode === 'update' || disableLastMot ? [] : rules}
      />
      <Form.Item
        className="form-item"
        name={['mot', 'last_mot']}
        rules={disableLastMot ? [] : rules}
      >
        <FloatLabel label="Last MOT test date">
          <CustomDatePicker disabled={disableLastMot} />
        </FloatLabel>
      </Form.Item>{' '}
      <Form.Item
        className="form-item"
        name={['mot', 'mot_expire']}
        rules={disableLastMot ? [] : rules}
      >
        <FloatLabel label="MOT expiration date">
          <CustomDatePicker disabled={disableLastMot} />
        </FloatLabel>
      </Form.Item>
      <Divider orientation="left">PCO certificate</Divider>
      <UploadPDF
        mode={mode}
        title="PCO certificate"
        name="pcolicense"
        linkdocs={mode === 'update' && car?.pcolicense}
        border={false}
        rules={rules}
      />
      <Form.Item
        className="form-item"
        name="pco_expire"
        rules={rules}
        style={{ flexBasis: '100%' }}
      >
        <FloatLabel label="PCO expiration date">
          <CustomDatePicker />
        </FloatLabel>
      </Form.Item>
      <Divider orientation="left">Vehicle documents</Divider>
      <UploadPDF
        mode={mode}
        linkdocs={mode === 'update' && car?.car_insurance_certificate}
        title="Car insurance certificate"
        name="car_insurance_certificate"
        rules={rules}
      />
      <UploadPDF
        mode={mode}
        title="Damage sketch"
        name="damagessketch"
        onClickCTA={() => setVisible(true)}
      />
      <UploadPDF
        mode={mode}
        linkdocs={mode === 'update' && car?.logbook}
        title="Car logbook"
        name="logbook"
        border={false}
        rules={rules}
      />
      <Button
        onClick={() => setIsVisibleModalExpenses(true)}
        className="open-expenses"
      >
        <span>Vehicle expenses</span>
        <RightOutlined />
      </Button>
      <CustomizedModal
        visible={isVisibleModalExpenses}
        onCancel={() => setIsVisibleModalExpenses(false)}
        footer={<> </>}
        width="80%"
      >
        <Expense
          setAddExp={setAddExp}
          mode={mode}
          expenses={car?.carexpense}
          carID={carID}
        />
      </CustomizedModal>
      <DamageSketchTemplate
        user={driver || { assigncar: car }}
        isVisible={isVisible}
        close={() => setVisible(false)}
        onSubmit={submitDamageSketchUpdate}
      />
      {mode === 'update' && (
        <>
          {car?.status?.[4] === 'Archived' && (
            <Button
              type="primary"
              onClick={unArchiveCar}
              style={{ flexBasis: '100%', marginTop: 16 }}
            >
              unarchive this car
            </Button>
          )}
          {car?.status?.[2] === 'Available' && (
            <Popconfirm
              title="Are you sure you want to archive this car?"
              visible={visiblePopConfirm}
              onConfirm={archiveCar}
              okButtonProps={{ loading: isLoadingArchiveCar }}
              onCancel={() => setVisiblePopConfirm(false)}
            >
              <Button
                type="text"
                disabled={car?.status?.[2] !== 'Available'}
                onClick={() => setVisiblePopConfirm(true)}
                className={cn({
                  'btn-denger': car?.status?.[2] === 'Available',
                })}
                style={{ flexBasis: '100%', marginTop: 16 }}
              >
                Archive this car
              </Button>
            </Popconfirm>
          )}
          <Divider orientation="left">Website apperance</Divider>
          <Form.Item
            className="wrapper-last-mot"
            name="flag"
            valuePropName="checked"
          >
            <Checkbox
              value={showOnSite}
              onChange={(e) => {
                setShowOnSite(e.target.checked);
              }}
            >
              Display this car in the website
            </Checkbox>
          </Form.Item>
          {showOnSite && (
            <>
              {car?.slug && (
                <Form.Item
                  className="form-item"
                  name="slug"
                  rules={[
                    { required: true, message: 'Please input the slug!' },
                  ]}
                >
                  <FloatLabel label="website car index">
                    <Input className="full-width" />
                  </FloatLabel>
                </Form.Item>
              )}
              <Button
                onClick={() => setShowOnSiteModal(true)}
                className="open-expenses"
                style={{ marginTop: 0 }}
              >
                <span>Car website information</span>
                <RightOutlined />
              </Button>
              <CustomizedModal
                visible={showOnSiteModal}
                onCancel={() => setShowOnSiteModal(false)}
                footer={<> </>}
                width={1100}
                destroyOnClose
              >
                <ShowOnSite form={form} carData={car} />
              </CustomizedModal>
            </>
          )}
        </>
      )}
    </>
  );
}
