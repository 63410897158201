import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';

import { useHistory } from 'react-router-dom';
import { AuthLayout } from '../../layout';
import axios from '../../utils/axios';
import { GoBackBtn, Heading } from '../../components';

export function ForgotPassword() {
  const [form] = Form.useForm();
  const history = useHistory();

  const [isSubmitting, setSubmitting] = useState(false);

  const handleForgotPassRequest = () => {
    form.validateFields().then((formData) => {
      setSubmitting(true);
      axios
        .post('account/reset-password/', { ...formData, role: 'panel' })
        .then(({ data }) => {
          message.success(data.message);
          history.push('/auth/login', formData);
        })
        .finally(() => setSubmitting(false));
    });
  };

  return (
    <AuthLayout>
      <Form form={form} onFinish={handleForgotPassRequest}>
        <GoBackBtn
          onClick={() => history.replace('/auth/login')}
          style={{ marginBottom: '16px' }}
        />
        <Heading
          title="Forgot password"
          subTitle="Please enter the email that you were signed up with"
          className="auth-layout-heading"
        />
        <Form.Item
          name="email"
          rules={[
            { required: true, message: 'Please enter your email' },
            { type: 'email', message: 'Please enter a valid email' },
          ]}
        >
          <Input type="email" placeholder="Email" />
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          block
          loading={isSubmitting}
          className="cta-btn"
        >
          Confirm
        </Button>
      </Form>
    </AuthLayout>
  );
}
