import qs from 'query-string';
import { axios } from '../utils';

export const getAccident = async ({ pageParam = 1, paramsQuery }) => {
  const query = { ...paramsQuery, page: pageParam };
  if (Object.keys(paramsQuery).length === 0) {
    Object.assign(query, { category: 'new' });
  }
  if (paramsQuery.ordering === 'ascend') {
    query.ordering = '-date';
  }
  if (paramsQuery.ordering === 'descend') {
    query.ordering = 'date';
  }
  const { data } = await axios.get(
    qs.stringifyUrl({ url: '/accident/panel/report/', query }),
  );
  return data;
};

export const getAccidentId = async ({ id, category }) => {
  const { data } = await axios.get(
    category === 'history'
      ? `/accident/panel/report/${id}/?category=${category}`
      : `/accident/panel/report/${id}/`,
  );
  return data;
};
