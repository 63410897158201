import { Table } from 'antd';
import dayjs from 'dayjs';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import qs from 'query-string';
import { InfiniteScrollWrapper } from '../Infinite-scroll-wrapper';
import { getSearchFilterProps } from '../antd-filter-props';
import { usePCNs } from '../../hooks';

export function PcnTable({ category }) {
  const history = useHistory();

  const queryParams = useMemo(
    () => qs.parse(history.location.search),
    [history.location.search],
  );
  const getSortOrder = (parameter) => {
    if (queryParams[parameter] === 'descend') return 'descend';
    if (queryParams[parameter] === 'ascend') return 'ascend';
    return null;
  };
  const columns = [
    {
      title: "Driver's name",
      dataIndex: 'driver',
      ...getSearchFilterProps('New Tickets'),
      render: (val) => `${val?.first_name} ${val?.last_name}`,
    },
    {
      title: 'Date of contravention',
      dataIndex: 'date',
      render: (val) => dayjs(val).format('DD/MM/YYYY hh:mm:ss'),
      key: 'date',
      sorter: true,
      sortOrder: getSortOrder('sort_date'),
    },
    {
      title: 'Added date',
      dataIndex: 'created_at',
      key: 'added',
      sorter: true,
      sortOrder: getSortOrder('sort_added'),
      render: (val) => dayjs(val).format('DD/MM/YYYY hh:mm:ss'),
    },
    {
      title: 'Payment status',
      dataIndex: 'status',
    },
  ];

  const qurey = useMemo(() => qs.parse(history.location.search));

  const deleteParamsInQuery = useMemo(() =>
    qs.parse(
      qs.exclude(history.location.search, [
        'sort_date',
        'sort_added',
        'category',
        'id',
      ]),
    ),
  );
  const getOrdering = () => {
    if (qurey.sort_added === 'ascend') return 'created_at';
    if (qurey.sort_added === 'descend') return '-created_at';
    if (qurey.sort_date === 'ascend') return 'date';
    if (qurey.sort_date === 'descend') return '-date';
    return '';
  };
  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } = usePCNs({
    category,
    queryParams: { ...deleteParamsInQuery, ordering: getOrdering() },
  });

  const handelChangeTable = (pagination, filters, sorter) => {
    const { current: page, pageSize: size } = pagination;
    const params = { page, size };
    if (sorter.column) {
      Object.assign(params, {
        [`sort_${sorter.columnKey}`]:
          sorter.order === 'descend' ? 'descend' : 'ascend',
      });
    }
    // eslint-disable-next-line no-unused-vars
    Object.entries(filters).forEach(([_key, value]) => {
      if (value?.length > 0) Object.assign(params, { search: value[0] });
    });
    history.replace({
      search: qs.stringify({
        ...qs.parse(history.location.search),
        search: params.search,
        sort_date: params.sort_date,
        sort_added: params.sort_added,
      }),
    });
  };

  return (
    <InfiniteScrollWrapper
      lengthData={data?.pages?.[0].count}
      hasMore={hasNextPage}
      functionNext={fetchNextPage}
      scrollableTarget="modal-with-infinite-scroll-table"
    >
      <Table
        dataSource={data?.pages.reduce((res, cur) => {
          if (cur?.results) {
            res.push(...cur?.results);
          } else res.push(...cur);
          return res;
        }, [])}
        columns={columns}
        loading={isFetching || isLoading}
        pagination={false}
        scroll={{
          x: 1024,
        }}
        rowKey="id"
        onRow={(record) => ({
          onClick: () => {
            history.push({
              search: qs.stringify({
                id: record.id,
                ...qs.parse(history.location.search),
              }),
            });
          },
          className: 'clickable-table-row',
        })}
        onChange={handelChangeTable}
      />
    </InfiniteScrollWrapper>
  );
}
