import { useInfiniteQuery, useQuery } from 'react-query';
import * as api from '../api';

export const useMotList = ({ type, search }) =>
  useInfiniteQuery(
    ['getMotList', { type, search }],
    ({ pageParam }) => api.getMotList({ type, search, pageParam }),
    {
      getNextPageParam: (lastPage) => lastPage?.next ?? false,
      enabled: typeof search === 'object',
    },
  );

export const useMot = ({ type, id }) =>
  useQuery(['getMot', { type, id }], () => api.getMot({ type, id }), {
    enabled: !!id,
  });

export const useCheckTime = ({ url, time }) =>
  useQuery(
    ['getCheckTime', { url, time }],
    () => api.getCheckTime({ url, time }),
    {
      enabled: !!time,
    },
  );
export const useCarSearch = (CarName, disable) =>
  useQuery(['getCarSearch', CarName], () => api.getCarSearch(CarName), {
    enabled: !!CarName && disable,
  });
export const useDriverMOt = (driverName, disable) =>
  useQuery(['getDriverMOt', driverName], () => api.getDriverMOt(driverName), {
    enabled: !!driverName && disable,
  });
