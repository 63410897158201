import { useInfiniteQuery, useQuery } from 'react-query';
import * as api from '../api';

export const useCarReturnList = ({ category, ordering }) =>
  useInfiniteQuery(
    ['getCarReturnList', { category, ordering }],
    ({ pageParam }) => api.getCarReturnList({ pageParam, category, ordering }),
    {
      getNextPageParam: (lastPage) => lastPage?.next ?? false,
    },
  );

export const useCarReturn = ({ id, category }) =>
  useQuery(
    ['getCarReturn', { id, category }],
    () => api.getCarReturn({ id, category }),
    {
      enabled: !!id,
    },
  );
