import { Form, Table } from 'antd';
import { useEffect, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import qs from 'query-string';
import { usetVehicleProfitSales } from '../../../hooks/report';

export default function Sales() {
  const [form] = Form.useForm();
  const location = useLocation();

  const query = useMemo(() => qs.parse(location.search), [location.search]);
  delete query.category;

  const { data, loading, isFetching } = usetVehicleProfitSales({
    start_date:
      query?.start_date || dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    end_date: query?.end_date || dayjs().format('YYYY-MM-DD'),
    car: query?.id,
  });

  useEffect(() => {
    if (query.start_date) {
      form.setFieldsValue({
        date: [dayjs(query.start_date), dayjs(query.end_date)],
      });
    } else {
      form.setFieldsValue({
        date: [dayjs(new Date()).subtract(1, 'month'), dayjs(new Date())],
      });
    }
  }, [location]);

  const columns = [
    {
      title: 'Invoice number',
      dataIndex: 'id',
      width: 150,
    },
    {
      title: 'Date',
      dataIndex: 'created_at',
      render: (date) => dayjs.unix(date).format('DD/MM/YYYY'),
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Credit note',
      dataIndex: 'title',
      render: (title, record) =>
        title === 'Credit note' ? `£ ${record.amount}` : '-',
    },
    {
      title: 'Invoice amount',
      dataIndex: 'amount',
      render: (amount, record) =>
        record.title !== 'Credit note' ? `£ ${amount}` : '-',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      render: (balance) => `£ ${balance}`,
    },
  ];
  return (
    <>
      <Table
        loading={loading || isFetching}
        columns={columns}
        dataSource={data?.invoices}
        pagination={false}
      />
    </>
  );
}
