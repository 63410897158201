import { Button, message, Table } from 'antd';
import dayjs from 'dayjs';
import printJS from 'print-js';
import { useQuery } from 'react-query';
import { axios, getBase64 } from '../../utils';

export function MiscellaneousTable({
  url,
  setVisableMiscellaneous,
  setInvoice,
  form,
  setIsPayed,
}) {
  const {
    data: miscellaneous,
    isLoading,
    isFetching,
  } = useQuery(url, async () => {
    const { data } = await axios.get(url);
    return data;
  });

  const showFile = (file) => {
    const blob = new Blob([file], { type: 'application/pdf' });

    getBase64(blob).then((base64) => {
      printJS({
        printable: base64.replace('data:application/pdf;base64,', ''),
        base64: true,
        documentTitle: `Receipt.pdf`,
      });
    });
  };

  const handelResipt = (source, id) => {
    message.loading({
      key: 'downloading-pdf',
      content: 'Generating recipt...\nPlease wait',
    });
    axios
      .get(
        `/payment/print-receipt?source=${
          source === 'Direct debit' ? 'Direct Debit' : source
        }&id=${id}`,
        {
          responseType: 'arraybuffer',
        },
      )
      .then(({ data }) => showFile(data))
      .finally(() => message.destroy('downloading-pdf'));
  };

  const coluumns = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (date) => dayjs(date).format('DD/MM/YYYY'),
    },
    {
      title: 'Source',
      dataIndex: 'source',
    },
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Description',
      dataIndex: 'description',
    },
    {
      title: 'Invoice amount',
      dataIndex: 'amount',
      render: (amount, { source }) =>
        source === 'Invoice' ? `£ ${amount}` : '',
    },
    {
      title: 'Payment amount',
      dataIndex: 'amount',
      render: (amount, { source }) =>
        source !== 'Invoice' ? `£ ${amount}` : '',
    },
    {
      title: 'Balance',
      dataIndex: 'balance',
      render: (balance, record) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          {`£${balance}`}{' '}
          <Button
            type="text"
            onClick={() => handelResipt(record.source, record.id)}
          >
            Receipt
          </Button>
        </div>
      ),
    },
  ];

  return (
    <Table
      columns={coluumns}
      dataSource={miscellaneous?.history}
      pagination={false}
      loading={isLoading || isFetching}
      onRow={(record) => ({
        onClick: () => {
          if (form && record.source === 'Invoice' && record.is_paid === false) {
            setInvoice(record);
            setVisableMiscellaneous(true);
            form.setFieldsValue(record);
            setIsPayed(record.is_paid);
          }
        },
        className:
          form &&
          record.source === 'Invoice' &&
          record.is_paid === false &&
          'invoice-row-active',
      })}
      footer={() => (
        <div style={{ display: 'flex' }}>
          <p style={{ marginRight: 30 }}>
            Total to be Collected : £ {miscellaneous?.total_to_be_collected}
          </p>
          <p style={{ flexShrink: 2 }}>
            Total Collected : £ {miscellaneous?.total_collected}
          </p>
        </div>
      )}
    />
  );
}
