import { useEffect, useState } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import { UserLayout } from '../../layout';
import {
  CarBank,
  DriversList,
  Notifications,
  Pcn,
  NotFound,
  Payments,
  Dashboard,
  ReportsAdmin,
  MOT,
  Service,
  Settings,
  Pco,
  Mileage,
  CarReturn,
  Accidents,
  TaskBoard,
} from '../../pages';

import { useUser } from '../../hooks';

export default function UserRouter() {
  const [isSupervisor, setIsSupervisor] = useState(false);

  const { data } = useUser();

  useEffect(() => {
    if (data?.[0].is_supervisor) {
      setIsSupervisor(true);
    }
  }, [data]);

  return (
    <UserLayout>
      <Switch>
        <Route exact path="/">
          <Redirect to="/dashboard" />
        </Route>
        <Route path="/drivers" exact>
          <DriversList />
        </Route>
        <Route path="/dashboard" exact>
          <Dashboard />
        </Route>
        <Route path="/car-bank" exact>
          <CarBank />
        </Route>
        <Route path="/pcn">
          <Pcn />
        </Route>
        <Route path="/payments">
          <Payments />
        </Route>
        {isSupervisor && (
          <Route path="/reports">
            <ReportsAdmin />
          </Route>
        )}
        <Route path="/notifications" exact>
          <Notifications />
        </Route>
        <Route path="/mot-test" exact>
          <MOT />
        </Route>
        <Route path="/car-service" exact>
          <Service />
        </Route>
        <Route path="/settings" exact>
          <Settings />
        </Route>
        <Route path="/pco-test" exact>
          <Pco />
        </Route>
        <Route path="/mileage" exact>
          <Mileage />
        </Route>
        <Route path="/car-return" exact>
          <CarReturn />
        </Route>
        <Route path="/accidents" exact>
          <Accidents />
        </Route>
        <Route path="/task-board" exact>
          <TaskBoard />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </UserLayout>
  );
}
