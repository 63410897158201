/* eslint-disable camelcase */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Button, Form, message, Spin } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import cn from 'classnames';
import qs from 'query-string';
import dayjs from 'dayjs';
import isEqual from 'lodash.isequal';

import { useQueryClient } from 'react-query';
import { useDriver, usePersonalInfoRejectReasons } from '../../hooks';
import { GoBackBtn } from '../go-back-btn';
import { Heading } from '../heading';
import { CustomizedModal } from '../modal';
import { CustomizedStepper } from '../stepper';

import { SignupDocumentsStep } from './SignupDocuments';
import { AssignCarStep } from './AssignCar';
import { DepositAndInvoiceStep } from './DepositAndInvoice';
import { LegalDocumentsStep } from './LegalDocuments';
import { DeliverCarStep } from './DeliverCar';

import './styles.scss';
import { axios, confirmExit } from '../../utils';
import { assignCarToDriver } from '../../api';
import { RejectDocsModal } from './RejectDocsModal';
import { InsuranceRequestFormModal } from './InsuranceRequestFormModal';
import { useWindowWidth } from '../../hooks/useWindowWidth';

const fieldsToValidate = [
  [
    'profileimage',
    'first_name',
    'last_name',
    'birth_date',
    'phone_number',
    'address',
    'postal_number',
    'email',
    'years_pco_license',
    'years_driving_license',
    'accident_number',
    'negative_drivinglicense_point',
    ['personaldocuments', 'driving_license_front_image'],
    ['personaldocuments', 'driving_license_back_image'],
    ['personaldocuments', 'pco_license_image'],
    ['personaldocuments', 'national_insurance_number'],
    'driving_license_number',
    'issuedate',
    'driving_license_expiration_date',
    'pco_license_number',
    'pco_license_expiration_date',
    'note',
  ], // Step 1: Sign up documents
  ['car'], // Step 2: Assign car
  [], // Step 3: Deposit & invoice
  [], // Step 4: Legal documents\
  [
    ['payment', 'hire_end'],
    ['driver_car', 'issue_date_permission'],
    'damagessketch',
    'payment_start_date',
    'issuedate',
  ], // Step 7: Deliver car
];

export function DriverProfileModal({ visible, onCancel, ...restProps }) {
  const location = useLocation();
  const history = useHistory();

  const queryClient = useQueryClient();
  const windowWidth = useWindowWidth();

  const queryParams =
    useMemo(() => qs.parse(location.search), [location.search]) || {};

  const { driver_id: driverID } = queryParams;
  const {
    data: driver,
    refetch,
    isFetching: isFetchingDriver,
  } = useDriver(driverID);

  const currentDriverStep = useMemo(
    () => (driver?.state ? Number(Object.keys(driver.state)[0]) : null),
    [driver?.state],
  );

  const [insuranceFormVisible, setInsuranceFormVisible] = useState(false);

  const onChangeStep = (cur) => {
    if (cur === 3 && !driver.delivery_date) {
      setInsuranceFormVisible(true);
    }
  };

  const initStep = useCallback(() => {
    switch (currentDriverStep) {
      case 1:
      case 2:
      case 10:
        return 0;
      case 3:
        return driver?.assigncar === null ? 1 : 2;
      case 4:
      case 5:
      case 6:
        onChangeStep(3);
        return 3;
      case 7:
      case 8:
      case 9:
        return 4;
      default:
        return 0;
    }
  }, [currentDriverStep, driver?.assigncar]);

  const [currentStep, setCurrentStep] = useState(initStep);
  useEffect(() => {
    setCurrentStep(initStep);
  }, [currentDriverStep, initStep, setCurrentStep]);

  const [formInstance] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);
  const [isRejecting, setIsRejecting] = useState(false);

  const [isVisible, setVisible] = useState(visible || driverID);
  const [formHasChanged, setFormHasChanged] = useState(false);

  const checkFormChanged = useCallback(() => {
    setFormHasChanged(!isEqual(formInstance.getFieldsValue(), driver));
  }, [formInstance, driver]);

  const onConfirmClose = () => {
    history.push({
      search: qs.exclude(location.search, ['driver_id', 'car_id', 'msg']),
    });
    onCancel?.();
    setCurrentStep(0);
  };

  const handleCloseModal = () => {
    confirmExit(onConfirmClose, formHasChanged);
  };

  useEffect(() => {
    if (visible === undefined) {
      if (driverID) {
        setVisible(true);
      } else {
        setVisible(false);
      }
    }
  }, [driverID]);

  useEffect(() => formInstance.setFieldsValue(driver), [driver]);

  const isActiveDriver = useMemo(
    () => currentDriverStep === 10,
    [currentDriverStep],
  );

  const steps = useMemo(() => {
    const stepsArray = [
      {
        title: isActiveDriver ? (
          <>
            Personal
            <br />
            information
          </>
        ) : (
          <>
            Sign up
            <br />
            documents
          </>
        ),
        content: <SignupDocumentsStep />,
        hasRejectBtnInFooter: currentDriverStep <= 2,
        confirmTitle: currentDriverStep <= 2 ? 'Confirm' : 'Update',
        hasNotConfirmBtn: currentDriverStep > 2 && !formHasChanged,
      },
      {
        title: (
          <>
            {isActiveDriver ? 'Assigned' : 'Assign'}
            <br />
            car
          </>
        ),
        content: (
          <AssignCarStep
            form={formInstance}
            driverChosenCar={driver?.favcar}
            driverAssignedCar={driver?.assigncar}
            isActiveDriver={isActiveDriver}
            driver={driver}
          />
        ),
        disabled: currentDriverStep < 3,
        confirmTitle: driver?.assigncar ? 'Reassign' : 'Confirm and assign',
        // hasNotConfirmBdtn: currentDriverStep >= 3 && driver.assigncar,
      },
      {
        title: (
          <>
            Deposit
            <br />& invoice
          </>
        ),
        content: <DepositAndInvoiceStep form={formInstance} user={driver} />,
        disabled: driver?.assigncar === null,
        hasNotConfirmBtn: true,
      },
      {
        title: (
          <>
            Legal
            <br />
            documents
          </>
        ),
        content: (
          <LegalDocumentsStep
            driver={driver}
            refetchDriver={refetch}
            isActiveDriver={isActiveDriver}
            form={formInstance}
          />
        ),
        disabled: currentDriverStep < 4,
        hasNotConfirmBtn: true,
      },
      {
        title: (
          <>
            {isActiveDriver ? 'Return' : 'Deliver'}
            <br />
            car
          </>
        ),
        content: <DeliverCarStep user={driver} />,
        disabled: currentDriverStep < 9,
        confirmTitle: isActiveDriver
          ? 'Save changes'
          : 'Confirm and activate the driver',
        hasNotConfirmBtn: isActiveDriver && !formHasChanged,
      },
    ];
    return isActiveDriver
      ? stepsArray.map((el, index) => ({
          ...el,
          status: currentStep === index ? 'process' : 'wait',
        }))
      : stepsArray;
  }, [driver, formInstance, formHasChanged, isActiveDriver, currentStep]);

  const validateCurrentStep = () =>
    formInstance.validateFields(fieldsToValidate[currentStep]);

  const submitCurrentStep = (values) =>
    new Promise((resolve, reject) => {
      switch (currentStep) {
        case 0: {
          // Sign up documents
          const fd = new FormData();
          Object.entries(values).forEach(([key, value]) => {
            if (
              [
                'driving_license_expiration_date',
                'pco_license_expiration_date',
                'birth_date',
                'issuedate',
              ].includes(key)
            ) {
              const formattedDate = dayjs(value).format('YYYY-MM-DD');
              const isDifferent = !isEqual(driver[key], formattedDate);
              if (isDifferent) fd.append(key, formattedDate);
            } else if (key === 'profileimage') {
              if (value?.fileList?.length)
                fd.append(key, value?.file?.originFileObj);
              // else fd.append(key, null);
            } else if (key === 'personaldocuments') {
              Object.entries(value).forEach(([docKey, docValue]) => {
                if (
                  docKey === 'national_insurance_number' &&
                  !isEqual(driver?.personaldocuments?.[docKey], docValue)
                ) {
                  fd.append(`personaldocuments.${docKey}`, docValue);
                } else if (typeof docValue === 'string') {
                  // do nothing!
                } else {
                  fd.append(
                    `personaldocuments.${docKey}`,
                    docValue?.file?.originFileObj,
                  );
                }
              });
            } else if (!isEqual(driver[key], value)) {
              fd.append(key, value);
            }
          });

          if (!fd.keys().next().done) {
            setSubmitting(true);
            axios
              .put(`/account/users/${driver.id}/`, fd)
              .then(({ data }) => {
                // means driver is waiting for their personal info review
                if (driver?.state && Object.keys(driver.state)?.[0] < 3) {
                  axios
                    .get(`/account/acceptdoc/${driver.id}/`)
                    .then(() => resolve(data))
                    .catch(() =>
                      reject(
                        new Error('Failed to accept driver personal info'),
                      ),
                    );
                } else {
                  resolve(data);
                }
              })
              .catch(reject);
          }
          break;
        }
        case 1: {
          // Assign car
          const selectedCar = values?.car;
          if (selectedCar) {
            setSubmitting(true);
            assignCarToDriver({ user: driver.id, car: selectedCar })
              .then(({ data }) => {
                resolve(data);
              })
              .catch(reject);
          } else reject(new Error('No car selected'));
          break;
        }
        case 2: {
          // Deposit & invoice
          setSubmitting(true);
          axios
            .get(`account/deposit/${driverID}/`)
            .then(({ data }) => resolve(data))
            .catch(reject);
          break;
        }
        case 3: {
          // Legal documents
          break;
        }
        // case 4: {
        //   // DVLA
        //   const fd = new FormData();
        //   fd.append('user', driverID);
        //   fd.append('dvla', values.dvla?.file?.originFileObj);

        //   setSubmitting(true);
        //   axios
        //     .put('/account/driverform/', fd, {
        //       headers: {
        //         'Content-Type': 'multipart/form-data',
        //       },
        //     })
        //     .then(({ data }) => resolve(data))
        //     .catch(reject);

        //   break;
        // }
        // case 5: {
        //   // Insurance certificate
        //   const fd = new FormData();
        //   fd.append(
        //     'insurancepolicy',
        //     values.insurancepolicy?.file?.originFileObj,
        //   );
        //   fd.append(
        //     'vehicleschedule',
        //     values.vehicleschedule?.file?.originFileObj,
        //   );
        //   fd.append(
        //     'driverschedule',
        //     values.driverschedule?.file?.originFileObj,
        //   );

        //   setSubmitting(true);
        //   axios
        //     .put(`cars/drivercars/${driver.assingcar_id}/`, fd, {
        //       headers: {
        //         'Content-Type': 'multipart/form-data',
        //       },
        //     })
        //     .then(({ data }) => resolve(data))
        //     .catch(reject);

        //   break;
        // }
        case 4: {
          // Deliver car (update damage sketch and delivery date)
          const {
            payment: { hire_end },
            driver_car: { issue_date_permission },
            payment_start_date,
            issuedate,
          } = values;
          if (dayjs.isDayjs(hire_end) || dayjs.isDayjs(payment_start_date)) {
            setSubmitting(true);
            const payload = {
              check_in: dayjs(hire_end).unix(),
              issue_date_permission: dayjs(issue_date_permission).format(
                'YYYY-MM-DD',
              ),
            };
            axios
              .put(`cars/drivercars/${driver.assingcar_id}/`, payload)
              .then(({ data }) => {
                if (currentDriverStep !== 9) {
                  resolve(data);
                  setVisible(false);
                }
              })
              .catch(reject);
          }

          if (currentDriverStep === 9) {
            setSubmitting(true);
            axios
              .get(
                `account/activate-driver/${
                  driver.id
                }/?payment_start_date=${dayjs(payment_start_date).format(
                  'YYYY-MM-DD',
                )}&issue_date=${dayjs(issuedate).format('YYYY-MM-DD')}`,
              )
              .then(() => {
                queryClient.invalidateQueries('drivers');
                onConfirmClose();
                resolve();
              })
              .catch(reject);
          }
          break;
        }
        default:
          resolve();
          break;
      }
    });

  const goToPrevStep = () => {
    setCurrentStep((curStep) => curStep - 1);
  };

  const handleConfirmStep = () => {
    validateCurrentStep().then((values) => {
      submitCurrentStep(values)
        .then(() => {
          message.success('Updated successfully');
          queryClient.invalidateQueries('driver');
          history.push({
            search: qs.exclude(location.search, ['driver_id']),
          });
        })
        .catch((err) => message.error(err?.message))
        .finally(() => setSubmitting(false));
    });
  };

  const [rejectModal, setRejectModal] = useState({
    visible: false,
  });

  const {
    data: personalInfoRejectReasons,
    isFetching: isFetchingRejectReasons,
  } = usePersonalInfoRejectReasons();

  const handleOpenRejectModal = () => {
    switch (currentStep) {
      case 0:
        setRejectModal({
          visible: true,
          title: 'Reject documents',
          subTitle:
            'Please select the reasons why the documents are being rejected',
          rejectOptions: personalInfoRejectReasons,
        });
        break;
      default:
        break;
    }
  };

  const handleRejectStep = (description) => {
    // setIsRejecting(true);
    switch (currentStep) {
      case 0: {
        axios
          .post(`account/rejectdoc/${driver.id}/`, { description })
          .then(async () => {
            await refetch();
            queryClient.invalidateQueries('drivers');
            message.success("Driver's sign up documents rejected");
            setRejectModal({
              visible: false,
            });
          })
          .catch(() =>
            message.error("Failed to reject driver's sign up documents"),
          )
          .finally(() => setIsRejecting(false));
        break;
      }
      default:
        break;
    }
  };

  const calculateEachInstallmentAmount = (
    numberOfInstallments,
    firstDeposit,
  ) => {
    let eachInstallmentAmount;
    if (!numberOfInstallments || !firstDeposit) {
      eachInstallmentAmount = undefined;
    } else {
      const depositAmount = formInstance.getFieldValue([
        'assigncar',
        'deposit_amount',
      ]);
      eachInstallmentAmount =
        (depositAmount - firstDeposit) / numberOfInstallments;
    }

    formInstance.setFieldsValue({
      each_installment_amount: eachInstallmentAmount,
    });
  };

  const handleFormValuesChange = (changedValues, allValues) => {
    const { number_of_installments, first_deposit } = allValues;

    if (number_of_installments || first_deposit) {
      calculateEachInstallmentAmount(number_of_installments, first_deposit);
    }
  };
  const archiveDriver = () => {
    axios.delete(`/account/users/${driver?.id}/`).then(({ data }) => {
      message.success(data?.message);
      queryClient.invalidateQueries('drivers');
      history.push({
        search: qs.exclude(location.search, ['driver_id', 'car_id', 'msg']),
      });
      setCurrentStep(0);
    });
  };

  const duplicateDriver = () => {
    axios.post(`/account/duplicate/${driver?.id}`).then(() => {
      queryClient.invalidateQueries('drivers');
      message.success('Driver duplicated successfully');
      history.push({
        search: qs.exclude(location.search, ['driver_id', 'car_id', 'msg']),
      });
      setCurrentStep(0);
    });
  };

  return (
    <>
      <CustomizedModal
        visible={isVisible}
        onCancel={handleCloseModal}
        confirmLoading={isSubmitting}
        afterClose={() => {
          setFormHasChanged(false);
          setIsRejecting(false);
          setSubmitting(false);
          formInstance.resetFields();
        }}
        wrapProps={{
          id: 'modal-with-infinite-scroll-table',
        }}
        {...restProps}
        title={
          isActiveDriver ? null : (
            <GoBackBtn
              onClick={goToPrevStep}
              className={cn({ hidden: currentStep < 1 })}
            />
          )
        }
        footer={
          <>
            {driver?.archive && (
              <Button type="primary" onClick={duplicateDriver} size="large">
                Duplicate this file
              </Button>
            )}
            {steps[currentStep]?.hasRejectBtnInFooter ||
            !steps[currentStep]?.hasNotConfirmBtn ? (
              <div className="footer-cta-btns-container">
                {steps[currentStep]?.hasRejectBtnInFooter && (
                  <>
                    <Button
                      type="ghost"
                      onClick={handleOpenRejectModal}
                      className="footer-cta-btn half-width ghost"
                      size="large"
                      loading={isRejecting}
                    >
                      Reject
                    </Button>
                  </>
                )}
                {!steps[currentStep]?.hasNotConfirmBtn && (
                  <Button
                    type="primary"
                    htmlType="submit"
                    onClick={handleConfirmStep}
                    size="large"
                    loading={isSubmitting}
                    block
                  >
                    {steps[currentStep]?.confirmTitle}
                  </Button>
                )}
              </div>
            ) : null}
            {currentStep === 0 && (
              <Button
                type="text"
                onClick={archiveDriver}
                className="btn-denger"
                size="large"
                loading={isRejecting}
                block
                style={{ marginTop: 12 }}
              >
                Archive this driver
              </Button>
            )}
          </>
        }
      >
        <Spin spinning={isFetchingDriver}>
          <Heading
            title="Driver's profile"
            subTitle={
              driver?.state?.[10] === 'Finish'
                ? 'Driver details'
                : 'New registered driver'
            }
            element="h3"
          />
          <CustomizedStepper
            current={currentStep}
            steps={steps}
            setCurrent={setCurrentStep}
            onChange={onChangeStep}
            direction={windowWidth > 948 ? 'horizontal' : 'vertical'}
          />
          <div className="steps-content">
            <Form
              form={formInstance}
              size="large"
              colon={false}
              onFinish={handleConfirmStep}
              name="driver-profile-form"
              requiredMark={false}
              onValuesChange={handleFormValuesChange}
              onFieldsChange={checkFormChanged}
            >
              {steps[currentStep].content}
            </Form>
          </div>
        </Spin>
        <RejectDocsModal
          isVisible={rejectModal.visible}
          isLoadingData={isFetchingRejectReasons}
          onCancel={() => setRejectModal({ visible: false })}
          onConfirm={handleRejectStep}
          isSubmitting={isRejecting}
          title={rejectModal.title}
          subTitle={rejectModal.subTitle}
          rejectOptions={rejectModal.rejectOptions}
        />
      </CustomizedModal>
      <InsuranceRequestFormModal
        setCurrentStep={setCurrentStep}
        isVisible={insuranceFormVisible}
        setVisible={setInsuranceFormVisible}
        assignCarID={driver?.assingcar_id}
        onSuccess={refetch}
      />
    </>
  );
}
