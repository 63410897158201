import { SearchOutlined } from '@ant-design/icons';
import { Button, Input, Space } from 'antd';
import './style.scss';

export function getSearchFilterProps(label) {
  let searchInput;
  return {
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    filterDropdown: ({ setSelectedKeys, confirm, clearFilters }) => (
      <div className="wrapper-filter">
        <Input
          placeholder={`Search in ${label}`}
          onChange={({ target: { value } }) =>
            setSelectedKeys(value ? [value] : [])
          }
          onPressEnter={() => confirm()}
          ref={(node) => {
            searchInput = node;
          }}
        />
        <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button
            type="primary"
            onClick={confirm}
            icon={<SearchOutlined />}
            size="small"
          >
            Search
          </Button>
          <Button type="ghost" onClick={clearFilters} size="small">
            Reset
          </Button>
        </Space>
      </div>
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput?.select?.(), 100);
      }
    },
  };
}
