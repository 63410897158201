/* eslint-disable camelcase */
import { useMemo } from 'react';
import { Table } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { usePayments } from '../../hooks';
import { InfiniteScrollWrapper, getSearchFilterProps } from '../../components';

export function Outstanding() {
  const history = useHistory();
  const location = useLocation();

  const queryParams = useMemo(() => {
    const params = qs.parse(location.search);
    delete params?.category;
    delete params?.payment_id;
    return params;
  }, [location.search]);

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } =
    usePayments({ category: 'outstanding', queryParams });

  const getSortOrder = (parameter) => {
    if (queryParams[parameter] === 'desc') return 'descend';
    if (queryParams[parameter] === 'asc') return 'ascend';
    return null;
  };

  const columns = [
    {
      title: 'Refrence',
      ...getSearchFilterProps("Car's Registration Number"),
      dataIndex: 'reference_number',
      key: 'driver__reference_number',
    },
    {
      title: 'Driver',
      key: 'search',
      ...getSearchFilterProps('Driver'),
      render: (text, { driver_last_name, driver_first_name }) =>
        `${driver_first_name} ${driver_last_name}`,
    },
    {
      title: 'Reg. number',
      ...getSearchFilterProps("Car's Registration Number"),
      dataIndex: 'registration_number',
      key: 'driver__car__registrationnumber',
    },
    {
      title: 'Balance',
      dataIndex: 'amount',
      key: 'amount',
      sorter: true,
      sortOrder: getSortOrder('amount'),
      render: (text, { weekly_rent_amount }) => {
        const amount = parseFloat(text).toFixed(2);
        let color = 'black';
        if (text < 0 && text > -weekly_rent_amount) {
          color = '#ff9a00';
        } else if (text < -weekly_rent_amount) {
          color = 'red';
        }
        return <spam style={{ color }}>${amount}</spam>;
      },
    },
    {
      title: 'Phone number',
      dataIndex: 'phone_number',
      ...getSearchFilterProps('Phone number'),
      key: 'driver__user__phone_number',
    },
  ];

  const handelChangeTable = (pagination, filters, sorter) => {
    const { current: page, pageSize: size } = pagination;
    const params = { page, size };
    if (sorter.column) {
      Object.assign(params, {
        [`${sorter.columnKey}`]: sorter.order === 'descend' ? 'desc' : 'asc',
      });
    }
    Object.entries(filters).forEach(([key, value]) => {
      if (value?.length > 0) {
        let paramKey;
        switch (key) {
          case 'driver__reference_number':
            paramKey = 'driver__reference_number';
            break;
          case 'driver__car__registrationnumber':
            paramKey = 'driver__car__registrationnumber';
            break;
          case 'driver__user__phone_number':
            paramKey = 'driver__user__phone_number';
            break;
          case 'search':
            paramKey = 'search';
            break;
          default:
            paramKey = key;
            break;
        }
        Object.assign(params, { [paramKey]: value[0] });
      }
    });
    history.replace({
      search: qs.stringify({
        ...qs.parse(history.location.search),
        driver__reference_number: params.driver__reference_number,
        driver__car__registrationnumber: params.driver__car__registrationnumber,
        driver__user__phone_number: params.driver__user__phone_number,
        search: params.search,
      }),
    });
  };

  return (
    <>
      <InfiniteScrollWrapper
        lengthData={data?.pages?.[0].count}
        hasMore={hasNextPage}
        functionNext={fetchNextPage}
      >
        <Table
          columns={columns}
          dataSource={data?.pages.reduce((res, cur) => {
            if (cur?.results) {
              res.push(...cur?.results);
            } else res.push(...cur);
            return res;
          }, [])}
          rowKey="id"
          loading={isLoading || isFetching}
          pagination={false}
          onRow={({ user }) => ({
            onClick: () => {
              history.push({
                pathname: '/drivers',
                search: `?driver_id=${user}`,
              });
            },
            className: 'clickable-table-row',
          })}
          onChange={handelChangeTable}
        />
      </InfiniteScrollWrapper>
    </>
  );
}
