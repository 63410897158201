import React, { useEffect, useMemo, useState } from 'react';
import {
  Table,
  Modal,
  Form,
  Button,
  message,
  Spin,
  Avatar,
  Checkbox,
} from 'antd';
import { Link, useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import dayjs from 'dayjs';
import isEqual from 'lodash.isequal';
import { axios } from '../../utils';
import { useCar, useCars } from '../../hooks';
import {
  InfiniteScrollWrapper,
  Heading,
  CustomizedModal,
  getSearchFilterProps,
} from '../../components';
import { FormItems } from './CarFormItems';

// carsCategory options: all, free, assigned
export default function ShowTableCarBank({ carsCategory }) {
  const [form] = Form.useForm();
  const history = useHistory();
  const [showOnSite, setShowOnSite] = useState(false);
  const location = useLocation();

  const carID =
    useMemo(() => qs.parse(location.search)?.car_id, [location.search]) || '';

  const [isModalVisible, setIsModalVisible] = useState(false || carID);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [ischanging, setIschanging] = useState(false);

  const {
    data: car,
    isLoading: carLoading,
    refetch: refetchCar,
  } = useCar(carID);

  const qurey = useMemo(() => qs.parse(history.location.search));
  const deleteParamsInQuery = useMemo(() =>
    qs.parse(
      qs.exclude(history.location.search, [
        'sort_deposit',
        'sort_weekly',
        'category',
        'car_id',
      ]),
    ),
  );
  const queryParams = useMemo(
    () => qs.parse(history.location.search),
    [history.location.search],
  );

  const getOrdering = () => {
    if (qurey.sort_weekly === 'ascend') return 'weekly_rent_amount';
    if (qurey.sort_weekly === 'descend') return '-weekly_rent_amount';
    if (qurey.sort_deposit === 'ascend') return 'deposit_amount';
    if (qurey.sort_deposit === 'descend') return '-deposit_amount';
    return undefined;
  };

  const { data, isLoading, isFetching, refetch, hasNextPage, fetchNextPage } =
    useCars({
      category: carsCategory,
      queryParams: { ...deleteParamsInQuery, ordering: getOrdering() },
    });

  const onConfirmExit = () => {
    history.push({
      search: qs.exclude(location.search, ['car_id']),
    });
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    Modal.confirm({
      title: 'Exit Confirmation',
      content: 'Do you cancel the add car process?',
      onOk: onConfirmExit,
      okText: 'Yes, exit',
      okType: 'danger',
      closable: true,
      maskClosable: true,
    });
  };

  const updateCar = () => {
    if (
      +form.getFieldValue('last_service_milage') >=
      +form.getFieldValue('currentOdometer')
    ) {
      message.error(
        'Last service mileage must be less than the current mileage',
      );
      return;
    }
    setUpdateLoading(true);
    const dataForm = new FormData();
    dataForm.append('model', form.getFieldValue('model'));
    dataForm.append('make', form.getFieldValue('make'));
    dataForm.append('body_shape', form.getFieldValue('body_shape'));
    dataForm.append('flag', form.getFieldValue('flag'));
    if (+form.getFieldValue('flag')) {
      dataForm.append('slug', form.getFieldValue('slug'));
    }
    dataForm.append(
      'last_service_milage',
      form.getFieldValue('last_service_milage'),
    );
    if (form.getFieldValue(['mot', 'mot_test'])?.file?.originFileObj) {
      dataForm.append(
        'mot_test',
        form.getFieldValue(['mot', 'mot_test'])?.file?.originFileObj,
      );
    }
    Object.entries(car).forEach(([key]) => {
      if (Object.keys(form.getFieldsValue()).includes(key)) {
        const isDifferent = !isEqual(car[key], form.getFieldValue(key));
        if (isDifferent) {
          if (key === 'year') {
            dataForm.append(
              'year',
              dayjs(form.getFieldValue('year')).format('YYYY-MM-DD'),
            );
          } else if (key === 'pcolicense') {
            if (form.getFieldValue(key)?.fileList?.length)
              dataForm.append(
                'pcolicense',
                form.getFieldValue(key)?.file?.originFileObj,
              );
          } else if (key === 'damagessketch') {
            if (form.getFieldValue(key)?.fileList?.length)
              dataForm.append(
                'damagessketch',
                form.getFieldValue(key)?.file?.originFileObj,
              );
          } else if (key === 'carimage') {
            if (form.getFieldValue(key)?.fileList?.length)
              dataForm.append(
                'carimage',
                form.getFieldValue(key)?.file?.originFileObj,
              );
          } else if (key === 'hh') {
            if (form.getFieldValue(key)?.fileList?.length)
              dataForm.append(
                'carimage',
                form.getFieldValue(key)?.file?.originFileObj,
              );
          } else if (key === 'car_insurance_certificate') {
            if (form.getFieldValue(key)?.fileList?.length)
              dataForm.append(
                'car_insurance_certificate',
                form.getFieldValue(key)?.file?.originFileObj,
              );
          } else if (key === 'logbook') {
            if (form.getFieldValue(key)?.fileList?.length)
              dataForm.append(
                'logbook',
                form.getFieldValue(key)?.file?.originFileObj,
              );
          } else if (key === 'mot') {
            dataForm.append(
              'last_mot',
              dayjs(form.getFieldValue(['mot', 'last_mot'])).format(
                'YYYY-MM-DD',
              ),
            );
            dataForm.append(
              'mot_expire',
              dayjs(form.getFieldValue(['mot', 'mot_expire'])).format(
                'YYYY-MM-DD',
              ),
            );
          } else if (key === 'pco_expire') {
            dataForm.append(
              'pco_expire',
              dayjs(form.getFieldValue('pco_expire')).format('YYYY-MM-DD'),
            );
          } else if (key === 'last_service_date') {
            dataForm.append(
              'last_service_date',
              dayjs(form.getFieldValue('last_service_date')).format(
                'YYYY-MM-DD',
              ),
            );
          } else {
            dataForm.append(key, form.getFieldValue(key));
          }
        }
      }
    });
    if (!form.getFieldValue('financeFlag')) {
      dataForm.append('carfinance', null);
    } else {
      dataForm.append(
        'carfinance.purchase_date',
        dayjs(form.getFieldValue('carfinance.purchase_date')).format(
          'YYYY-MM-DD',
        ),
      );
      dataForm.append(
        'carfinance.direct_debit_day',
        dayjs(form.getFieldValue('carfinance.direct_debit_day')).format('DD'),
      );
      dataForm.append('carfinance.term', form.getFieldValue('carfinance.term'));
      dataForm.append(
        'carfinance.total_amount',
        form.getFieldValue('carfinance.total_amount'),
      );
      dataForm.append(
        'carfinance.monthly_amount',
        form.getFieldValue('carfinance.monthly_amount'),
      );
      dataForm.append(
        'carfinance.paid_amount',
        form.getFieldValue('carfinance.paid_amount'),
      );
    }
    axios
      .put(`/cars/car/${carID}/`, dataForm)
      .then(() =>
        refetch().then(() => {
          refetchCar().then(
            () => message.success('Car details updated successfully'),
            history.push({
              search: qs.exclude(location.search, ['car_id']),
            }),
          );
        }),
      )
      .finally(() => {
        setIschanging(false);
        setUpdateLoading(false);
      });
  };

  const handelChangeTable = (pagination, filters, sorter) => {
    const { current: page, pageSize: size } = pagination;
    const params = { page, size };
    if (sorter.column) {
      Object.assign(params, {
        [`sort_${sorter.columnKey}`]:
          sorter.order === 'descend' ? 'descend' : 'ascend',
      });
    }
    // eslint-disable-next-line no-unused-vars
    Object.entries(filters).forEach(([_, value]) => {
      if (value?.length > 0) Object.assign(params, { search: value[0] });
    });
    history.replace({
      search: qs.stringify({
        ...qs.parse(history.location.search),
        search: params.search,
        sort_deposit: params.sort_deposit,
        sort_model: params.sort_model,
        sort_weekly: params.sort_weekly,
      }),
    });
  };

  const getSortOrder = (parameter) => {
    if (queryParams[parameter] === 'descend') return 'descend';
    if (queryParams[parameter] === 'ascend') return 'ascend';
    return null;
  };

  const handelDataSource = (dataSource) =>
    dataSource?.pages.reduce((res, cur) => {
      if (cur?.results) {
        res.push(...cur?.results);
      } else res.push(...cur);
      return res;
    }, []);

  useEffect(() => {
    if (car) {
      form.setFieldsValue({
        ...car,
        'carfinance.purchase_date': car?.carfinance?.purchase_date,
        'carfinance.direct_debit_day':
          car?.carfinance?.direct_debit_day &&
          dayjs().format(`YYYY/MM/${car?.carfinance?.direct_debit_day}`),
        'carfinance.term': car?.carfinance?.term,
        'carfinance.total_amount': car?.carfinance?.total_amount,
        'carfinance.monthly_amount': car?.carfinance?.monthly_amount,
        'carfinance.paid_amount': car?.carfinance?.paid_amount,
        financeFlag: car?.carfinance,
      });
    }
  }, [car]);

  useEffect(() => {
    if (
      carsCategory === qurey.category ||
      (!qurey.category && carsCategory === 'all')
    ) {
      if (!carID) setIsModalVisible(false);
      if (carID) setIsModalVisible(true);
    }
  }, [location]);

  const columns = [
    {
      title: 'Car manufacturer and model',
      dataIndex: 'model',
      ...getSearchFilterProps('Model'),
      render: (_model, record) => (
        <>
          <Avatar
            shape="square"
            size="large"
            style={{ marginRight: '10px', width: '80px' }}
            src={record?.carimage}
          />{' '}
          {record.make} - {record.model}
        </>
      ),
    },
    {
      title: 'Registration number',
      dataIndex: 'registrationnumber',
      ...getSearchFilterProps('Registration number'),
    },
    {
      title: 'Deposit amount',
      dataIndex: 'deposit_amount',
      render: (value) => (value ? `£${value}` : '-'),
      key: 'deposit',
      sorter: true,
      sortOrder: getSortOrder('sort_deposit'),
    },
    {
      title: 'Weekly rent',
      dataIndex: 'weekly_rent_amount',
      render: (value) => (value ? `£${value}` : '-'),
      key: 'weekly',
      sorter: true,
      sortOrder: getSortOrder('sort_weekly'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      render: (value) => Object.values(value)?.[0],
    },
    {
      title: 'Assigned to',
      dataIndex: 'assing_to',
      render: (value) =>
        (value && (
          <Link to={`drivers?driver_id=${value?.id}`}>
            {value?.name} {value?.last_name}
          </Link>
        )) ||
        '-',
    },
    {
      title: 'Show on website',
      dataIndex: 'flag',
      render: (value) => <Checkbox checked={value} />,
    },
  ];

  return (
    <>
      <InfiniteScrollWrapper
        lengthData={data?.pages?.[0].count}
        hasMore={hasNextPage}
        functionNext={fetchNextPage}
      >
        <Table
          columns={columns}
          dataSource={handelDataSource(data)}
          rowKey="id"
          loading={isLoading || isFetching}
          pagination={false}
          scroll={{
            x: 1024,
          }}
          onRow={(row) => ({
            onClick: () => {
              history.push({
                search: qs.stringify({
                  ...qs.parse(history.location.search), // keep other query parameters
                  car_id: row.id,
                }),
              });
            },
            className: 'clickable-table-row',
          })}
          onChange={handelChangeTable}
        />
      </InfiniteScrollWrapper>
      <CustomizedModal
        width={800}
        visible={isModalVisible}
        onCancel={ischanging ? handleCancel : onConfirmExit}
        afterClose={() => {
          form.resetFields();
          setIschanging(false);
          setShowOnSite(false);
        }}
        footer={false}
      >
        <Heading title="Car profile" subTitle="View or edit car information" />
        <Spin spinning={carLoading || updateLoading}>
          <Form
            form={form}
            size="large"
            className="inline-container reset-error-form"
            style={{ justifyContent: 'space-between' }}
            onFinish={updateCar}
            onValuesChange={(val) => {
              setIschanging(!!val);
            }}
          >
            <FormItems
              mode="update"
              showOnSite={showOnSite}
              setShowOnSite={setShowOnSite}
              carID={carID}
              car={car}
              form={form}
              setIsModalVisible={setIsModalVisible}
            />
            <Form.Item
              shouldUpdate
              style={{
                marginTop: '40px',
              }}
              className="full-width form-item space-bottom"
            >
              {() => (
                <div className="btn-off-on">
                  <Button
                    type="primary"
                    className="footer-cta-btn"
                    size="large"
                    onClick={form.submit}
                    loading={carLoading || updateLoading}
                    disabled={
                      !ischanging ||
                      !!form
                        .getFieldsError()
                        .filter(({ errors }) => errors.length).length
                    }
                  >
                    Update car information
                  </Button>
                </div>
              )}
            </Form.Item>
          </Form>
        </Spin>
      </CustomizedModal>
    </>
  );
}
