import { Table, DatePicker, Form, Button, message } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import qs from 'query-string';
import printJS from 'print-js';
import { useReceiptBreakdown } from '../../hooks/report';
import { axios, getBase64 } from '../../utils';

const { RangePicker } = DatePicker;

export default function ReceiptBreakdown() {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => qs.parse(location.search), [location.search]);
  delete query.category;

  const { data, loading, isFetching } = useReceiptBreakdown({
    ...query,
    start_date:
      query?.start_date || dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    end_date: query?.end_date || dayjs().format('YYYY-MM-DD'),
  });
  const showFile = (file) => {
    const blob = new Blob([file], { type: 'application/pdf' });

    getBase64(blob).then((base64) => {
      printJS({
        printable: base64.replace('data:application/pdf;base64,', ''),
        base64: true,
        documentTitle: `Receipt.pdf`,
      });
    });
  };

  const handelResipt = (source, id) => {
    message.loading({
      key: 'downloading-pdf',
      content: 'Generating recipt...\nPlease wait',
    });
    axios
      .get(`/payment/print-receipt?source=${source}&id=${id}`, {
        responseType: 'arraybuffer',
      })
      .then(({ data: dataPrint }) => showFile(dataPrint))
      .finally(() => message.destroy('downloading-pdf'));
  };
  const changeDate = (date) => {
    history.push(
      `reports?category=receipt-breakdown&start_date=${dayjs(date?.[0]).format(
        'YYYY-MM-DD',
      )}&end_date=${dayjs(date?.[1]).format('YYYY-MM-DD')}`,
    );
  };

  useEffect(() => {
    if (query.start_date) {
      form.setFieldsValue({
        date: [dayjs(query.start_date), dayjs(query.end_date)],
      });
    } else {
      form.setFieldsValue({
        date: [dayjs(new Date()).subtract(1, 'month'), dayjs(new Date())],
      });
    }
  }, [location]);

  const columns = [
    {
      title: 'Client name',
      dataIndex: 'client_name',
    },
    {
      title: 'Unique code',
      dataIndex: 'uniqueCode',
    },
    {
      title: 'Latest update',
      dataIndex: 'date',
      render: (date) => dayjs(date).format('DD/MM/YYYY'),
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      render: (amount) => `£ ${amount}`,
    },
    {
      title: 'Source',
      dataIndex: 'type',
    },
    {
      title: '',
      dataIndex: 'type',
      render: (title, record) => (
        <Button type="text" onClick={() => handelResipt(title, record.id)}>
          Receipt
        </Button>
      ),
    },
  ];

  return (
    <>
      <Form form={form}>
        <Form.Item name="date">
          <RangePicker
            style={{ width: 'auto', marginBottom: 30 }}
            onChange={changeDate}
          />
        </Form.Item>
      </Form>
      <Table
        loading={loading || isFetching}
        columns={columns}
        dataSource={data?.payments}
        pagination={false}
      />
    </>
  );
}
