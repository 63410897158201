import { Form, Input } from 'antd';
import { useLocation } from 'react-router-dom';
import qs from 'query-string';
import { FloatLabel } from '../floating-label';

export function InsuranceNumberSection() {
  const location = useLocation();
  const isDriverProfileModal = !!qs.parse(location.search)?.driver_id;

  return (
    <div className="inline-container">
      <Form.Item
        name={
          isDriverProfileModal
            ? ['personaldocuments', 'national_insurance_number']
            : 'national_insurance_number'
        }
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <FloatLabel label="National insurance number">
          <Input />
        </FloatLabel>
      </Form.Item>
    </div>
  );
}
