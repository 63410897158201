import { useEffect, useState } from 'react';
import { useUser } from '../../hooks';
import AddNewAdmin from './AddNewAdmin';
import SetLocation from './SetLocation';

export function Settings() {
  const [isSupervisor, setIsSupervisor] = useState(false);

  const { data } = useUser();

  useEffect(() => {
    if (data?.[0].is_supervisor) {
      setIsSupervisor(true);
    }
  }, [data]);

  return (
    <>
      {isSupervisor && <AddNewAdmin />}
      <SetLocation isStaff={!isSupervisor} />
    </>
  );
}
