import { Form, Input, InputNumber, Select } from 'antd';
import { FloatLabel } from '../../components';
import { CustomDatePicker } from '../../components/custom-date-picker';

const { Option } = Select;

export default function ExpenseModal({ form, onFinish }) {
  const rules = [{ required: true, message: '' }];

  const priceBase = (value) =>
    value && `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  if (form.getFieldValue('description') === 'undefined') {
    form.setFieldsValue({ description: '' });
  }
  if (form.getFieldValue('service_providor') === 'undefined') {
    form.setFieldsValue({ service_providor: '' });
  }
  if (form.getFieldValue('vat_amount') === 'undefined') {
    form.setFieldsValue({ vat_amount: 0 });
  }
  if (form.getFieldValue('total_excluded_vat') === 'undefined') {
    form.setFieldsValue({ total_excluded_vat: 0 });
  }

  return (
    <Form
      form={form}
      name="horizontal_login"
      layout="inline"
      onFinish={onFinish}
      className="reset-error-form"
      style={{ justifyContent: 'space-between' }}
    >
      <Form.Item
        className="full-width"
        name="expense_type"
        rules={rules}
        style={{ marginBottom: '16px' }}
      >
        <FloatLabel label="Expense type">
          <Select>
            <Option value={1}>MOT</Option>
            <Option value={2}>Parts</Option>
            <Option value={3}>PCO</Option>
            <Option value={4}>Road TAX</Option>
            <Option value={5}>Bodywork expenses</Option>
            <Option value={6}>Accident expenses</Option>
            <Option value={10}>Service</Option>
            <Option value={11}>Tyer</Option>
            <Option value={12}>Seat Cover</Option>
            <Option value={13}>Trackers and Camera</Option>
            <Option value={7}>Other expenses</Option>
          </Select>
        </FloatLabel>
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '16px' }}
        className="form-item"
        name="cost"
        rules={rules}
      >
        <FloatLabel label="Cost">
          <InputNumber
            onWheel={(e) => e.target.blur()}
            formatter={(value) =>
              value && `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
            }
            className="full-width"
          />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '16px' }}
        className="form-item"
        name="date"
        rules={rules}
      >
        <FloatLabel label="Date">
          <CustomDatePicker className="full-width" />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '16px' }}
        className="full-width"
        name="service_providor"
      >
        <FloatLabel label="Service Providor">
          <Input autoComplete="off" />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '16px' }}
        className="full-width"
        name="description"
      >
        <FloatLabel label="Description">
          <Input autoComplete="off" />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '16px' }}
        className="form-item"
        name="total_excluded_vat"
        rules={rules}
      >
        <FloatLabel label="Net">
          <InputNumber
            onWheel={(e) => e.target.blur()}
            className="full-width"
            formatter={priceBase}
            maxLength={9}
          />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '16px' }}
        className="form-item"
        name="vat_amount"
        rules={rules}
      >
        <FloatLabel label="VAT">
          <InputNumber
            onWheel={(e) => e.target.blur()}
            className="full-width"
            formatter={priceBase}
            maxLength={9}
          />
        </FloatLabel>
      </Form.Item>
    </Form>
  );
}
