import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from 'antd';

const { confirm } = Modal;

export const confirmExit = (onConfirm, getConfirmCondition = true, title, content, okText) => {
  if (getConfirmCondition) {
    confirm({
      title: title || 'Exit Confirmation',
      content: content || 'Are you sure you want to exit? any entered data will be lost',
      icon: <ExclamationCircleOutlined />,
      onOk: onConfirm,
      okText: okText || 'Yes, exit',
      okType: 'danger',
      closable: true,
      maskClosable: true,
    });
  } else {
    onConfirm();
  }
};
