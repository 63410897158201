/* eslint-disable no-console */
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage,
} from 'firebase/messaging';

import * as firebase from 'firebase/app';

// import { userSlice } from '~/reducers/user'
// import { store } from '~/store'

// const setUserState = userSlice.actions.setState

// const firebaseConfig = {
//   apiKey: 'AIzaSyAZfexOnXIoxiZK1Txc9zJVUx1vvLugDIY',
//   authDomain: 'gm-app-d44af.firebaseapp.com',
//   projectId: 'gm-app-d44af',
//   storageBucket: 'gm-app-d44af.appspot.com',
//   messagingSenderId: '701230325633',
//   appId: '1:701230325633:web:edcc7cbeb95025b6545280',
//   // databaseURL: 'https://hengam-ee2b3.firebaseio.com',
//   measurementId: 'G-9LNYTXX07K',
// };
const firebaseConfig = {
  apiKey: 'AIzaSyD8xIjr3lDAZAWwulXc9gEo1TWTLyfLP-8',
  authDomain: 'gm-direct-hire.firebaseapp.com',
  projectId: 'gm-direct-hire',
  storageBucket: 'gm-direct-hire.appspot.com',
  messagingSenderId: '345901662865',
  appId: '1:345901662865:web:857dff73d2c51b7041b530',
  measurementId: 'G-S22WV3J2K0',
};

firebase.initializeApp(firebaseConfig);

// TODO: remove the extra file and use this config
// if ('serviceWorker' in navigator) {
//   navigator.serviceWorker
//     .register('firebase-messaging-sw.js')
//     .then(
//       (registration) =>
//         // console.log('[SW]: SCOPE: ', registration.scope)
//         registration.scope,
//     )
//     .catch(
//       (err) =>
//         // console.log(err)
//         err,
//     );
// }
isSupported().then((isSupport) => {
  if (isSupport) {
    const messaging = getMessaging();

    getToken(messaging, {
      vapidKey:
        'BD7FR10h45d2FRd8oSl6_qP-4zu_m-A8W57fCNh3z5YOVBGBQhlx7L0_Y4EI4yunxLcU_fq_WpVImTq1_yqTfwc',
    })
      .then((currentToken) => {
        if (currentToken) {
          // Send the token to your server and update the UI if necessary
          localStorage.setItem('fcm_token', currentToken);
          // ...
        } else {
          // Show permission request UI
          console.log(
            'No registration token available. Request permission to generate one.',
          );
          // ...
        }
      })
      .catch((err) => {
        console.error('An error occurred while retrieving token.', err);
        // ...
      });

    onMessage(messaging, (e) => {
      console.log(e);
    });
  }
});

export default firebase;
