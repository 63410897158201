import React from 'react';
import './index.scss';
import cn from 'classnames';
import { string } from 'prop-types';

/**
 * @param {string=} [title='']
 * @param {string=} [subTitle='']
 * @param {string=} [element='h1'] - which html tag to use for main heading element, like h1, h2, etc...
 * @param {string=} [className='']
 */
export const Heading = ({
  title,
  subTitle,
  element,
  className,
  ...restProps
}) => (
  <div className={cn('heading-container', className)} {...restProps}>
    {React.createElement(element, { className: 'title' }, title)}
    <p className="sub-title">{subTitle}</p>
  </div>
);
Heading.propTypes = {
  title: string,
  subTitle: string,
  element: string,
  className: string,
};
Heading.defaultProps = {
  title: '',
  subTitle: '',
  element: 'h1',
  className: '',
};
