import { SearchOutlined } from '@ant-design/icons';
import { Button, Select, Space } from 'antd';
import './style.scss';

export function getSelectFilterProps(label, selectData, selectItemRenderer) {
  let searchInput;
  return {
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
    }) => (
      <div className="select-filter">
        <Select
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys([e])}
          placeholder={`Select ${label}`}
          showSearch
          ref={(node) => {
            searchInput = node;
          }}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {selectData?.map((record) => selectItemRenderer(record))}
        </Select>
        <Space className="select-filter__row">
          <Button
            type="primary"
            icon={<SearchOutlined />}
            size="small"
            onClick={() => {
              confirm();
            }}
          >
            Search
          </Button>
          <Button onClick={clearFilters} size="small">
            Reset
          </Button>
        </Space>
      </div>
    ),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => {
          if (searchInput) searchInput?.focus?.();
        }, 100);
      }
    },
  };
}
