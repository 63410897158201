import { Tabs, DatePicker, Form, Table, Button } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import qs from 'query-string';
import Sales from './Sales';
import Expenses from './Expenses';
import { useVehicleProfit } from '../../../hooks/report';
import { CustomizedModal } from '../../../components';

const { TabPane } = Tabs;

export default function VehicleProfit() {
  const [isVisible, setIsVisible] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => qs.parse(location.search), [location.search]);
  delete query.category;
  delete query.registration_number;
  delete query.id;

  const rgNumber = useMemo(
    () => qs.parse(location.search)?.registration_number,
    [location.search],
  );

  useEffect(() => {
    if (rgNumber) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [window.location]);

  const { data, loading, isFetching } = useVehicleProfit({
    ...query,
    start_date:
      query?.start_date || dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    end_date: query?.end_date || dayjs().format('YYYY-MM-DD'),
  });

  const changeDate = (date) => {
    history.push(
      `reports?category=vehicle-profit&start_date=${dayjs(date?.[0]).format(
        'YYYY-MM-DD',
      )}&end_date=${dayjs(date?.[1]).format('YYYY-MM-DD')}`,
    );
  };

  useEffect(() => {
    if (query.start_date) {
      form.setFieldsValue({
        date: [dayjs(query.start_date), dayjs(query.end_date)],
      });
    } else {
      form.setFieldsValue({
        date: [dayjs(new Date()).subtract(1, 'month'), dayjs(new Date())],
      });
    }
  }, [location]);

  const columns = [
    {
      title: 'Registration number',
      dataIndex: 'registrationnumber',
    },
    {
      title: 'Sales hire NET',
      dataIndex: 'net_sales',
      render: (value) => `£ ${value}`,
    },
    {
      title: 'Sales with VAT',
      dataIndex: 'sales_with_vat',
      render: (value) => `£ ${value}`,
    },
    {
      title: 'Expenses',
      dataIndex: 'expenses',
      render: (value) => `£ ${value}`,
    },
    {
      title: 'Expenses + Insurance',
      dataIndex: 'expenses_insurance',
      render: (value) => `£ ${value}`,
    },
    {
      title: 'Expenses + Insurance + VAT',
      dataIndex: 'expenses_insurance_vat',
      render: (value) => `£ ${value}`,
    },
    {
      title: 'Net profit',
      dataIndex: 'net_profit',
      render: (value) => `£ ${value}`,
    },
    {
      title: '',
      dataIndex: 'registrationnumber',
      render: (rgn, record) => (
        <Button
          onClick={() => {
            history.push({
              search: qs.stringify({
                ...query,
                category: 'vehicle-profit',
                registration_number: rgn,
                id: record.id,
              }),
            });
            setIsVisible(true);
          }}
        >
          Breakdown
        </Button>
      ),
    },
  ];
  return (
    <>
      <Form form={form}>
        <Form.Item name="date">
          <DatePicker.RangePicker
            style={{ width: 'auto', marginBottom: 30 }}
            onChange={changeDate}
          />
        </Form.Item>
      </Form>
      <Table
        loading={loading || isFetching}
        columns={columns}
        dataSource={data}
        pagination={false}
      />
      <CustomizedModal
        visible={isVisible}
        onCancel={() => {
          setIsVisible(false);
          history.push({
            search: qs.exclude(location.search, ['registration_number', 'id']),
          });
        }}
        width={1000}
        footer={<></>}
      >
        <Tabs type="card" animated destroyInactiveTabPane>
          <TabPane tab="Sales" key="sales">
            <Sales />
          </TabPane>
          <TabPane tab="Expenses" key="expenses">
            <Expenses />
          </TabPane>
        </Tabs>
      </CustomizedModal>
    </>
  );
}
