/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Button, Form } from 'antd';

import { Heading } from '../heading';
import { CustomizedModal } from '../modal';
import { FloatLabel } from '../floating-label';

import './styles.scss';
import { CustomDatePicker } from '../custom-date-picker';
import { axios } from '../../utils';
import { GoBackBtn } from '../go-back-btn';

export function InsuranceRequestFormModal({
  isVisible,
  setVisible,
  assignCarID,
  setCurrentStep,
  onSuccess,
  ...restProps
}) {
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);

  const handleSubmitForm = () => {
    form.validateFields().then((values) => {
      if (assignCarID) {
        setSubmitting(true);
        const { delivery_date, ...rest } = values;
        const payload = { delivery_date: delivery_date.unix(), ...rest };
        axios
          .put(`cars/drivercars/${assignCarID}/`, payload)
          .then(() => {
            setVisible(false);
            onSuccess?.();
          })
          .finally(() => setSubmitting(false));
      }
    });
  };

  return (
    <CustomizedModal
      visible={isVisible}
      closable={false}
      // afterClose={() => formInstance.resetFields()}
      {...restProps}
      title={
        <>
          <GoBackBtn
            onClick={() => {
              setVisible(false);
              setCurrentStep(2);
            }}
          />
        </>
      }
      footer={
        <div className="footer-cta-btns-container">
          <Button
            type="primary"
            htmlType="submit"
            onClick={handleSubmitForm}
            className="footer-cta-btn"
            loading={isSubmitting}
            size="large"
          >
            Confirm
          </Button>
        </div>
      }
    >
      <Heading
        title="Car checkout date and time"
        subTitle="Please enter the date and time that the driver wishes to receive their car"
        element="h3"
      />
      <Form
        // form={formInstance}
        size="large"
        colon={false}
        className="inline-container"
        onFinish={handleSubmitForm}
        requiredMark={false}
        style={{ marginTop: '24px' }}
        form={form}
      >
        <Form.Item
          name="delivery_date"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <FloatLabel label="Checkout date">
            <CustomDatePicker format="DD/MM/YYYY" />
          </FloatLabel>
        </Form.Item>
        <Form.Item
          name="delivery_date"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <FloatLabel label="Checkout time">
            <CustomDatePicker format="HH:mm" picker="time" suffixIcon={null} />
          </FloatLabel>
        </Form.Item>
      </Form>
    </CustomizedModal>
  );
}
