import React, { useEffect, useRef, useState } from 'react';

import { Menu, Popover, Modal } from 'antd';
import cn from 'classnames';
import { LogoutOutlined } from '@ant-design/icons';

import { Icon } from '../icon';
import axios from '../../utils/axios';
import { version } from '../../../package.json';

import './index.scss';

/**
 *
 * @param {string=} [className='']
 */
export const UserProfileBtn = ({ className }) => {
  const [userData, setUserData] = useState();

  const fetchUserProfile = () => {
    axios.get('/account/user/').then(({ data: res }) => {
      setUserData(res[0]);
    });
  };

  const Logout = () => {
    const diviceID = localStorage.getItem('fcm_token');
    localStorage.removeItem('type');
    if (diviceID) {
      const fd = new FormData();
      fd.append('device_id', diviceID);
      axios.post('/account/logout/', fd).then(() => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('fcm_token');
        localStorage.removeItem('type');
        window.location.reload();
      });
    } else {
      localStorage.removeItem('access_token');
      localStorage.removeItem('refresh_token');
      localStorage.removeItem('fcm_token');
      localStorage.removeItem('type');
      window.location.reload();
    }
  };

  const handleLogout = () => {
    Modal.confirm({
      title: 'Logout Confirmation',
      content:
        'Are you sure about logging out? You will have to log in again before using the panel again.',
      onOk: Logout,
      okText: 'Yes, Logout',
      cancelText: 'Cancel',
      okType: 'danger',
      closable: true,
      closeIcon: true,
      maskClosable: false,
      centered: true,
    });
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  const userOptionsPopupContainerRef = useRef(null);
  return (
    <Popover
      trigger="click"
      placement="leftBottom"
      getPopupContainer={(defaultTriggerNode) =>
        userOptionsPopupContainerRef.current || defaultTriggerNode
      }
      content={
        <Menu mode="vertical" className="profile-menu">
          <Menu.Item
            key="version"
            disabled
            icon={
              <Icon
                variant="version"
                width={14}
                height={14}
                fill="currentColor"
              />
            }
            className="profile-menu-item readonly"
          >
            Version {version}
          </Menu.Item>
          <Menu.Item
            icon={<LogoutOutlined />}
            danger
            key="logout"
            className="profile-menu-item danger"
            onClick={handleLogout}
          >
            Logout
          </Menu.Item>
        </Menu>
      }
    >
      <div className={cn('user-profile-btn', className)}>
        {/* <Avatar
          size={42}
          shape="square"
          src={userData?.profileimage}
          className="user-avatar"
        /> */}
        <div className="user-info-container">
          <span className="user-name">
            {userData?.first_name || userData?.last_name
              ? `${userData?.first_name} ${userData?.last_name}`
              : '-'}
          </span>
          <span className="user-email">{userData?.email || '-'}</span>
        </div>
        <Icon variant="chevron-down" className="chevron-icon" />
        <div
          ref={userOptionsPopupContainerRef}
          className="user-options-menu-container"
        />
      </div>
    </Popover>
  );
};
