import { Form, InputNumber } from 'antd';
import './styles.scss';
import { FloatLabel } from '../floating-label';

const rules = [
  {
    required: true,
    message: 'This field is required',
  },
  {
    pattern: /^\d*$/,
    message: 'Only positive integers',
  },
];

const items = [
  {
    name: 'years_pco_license',
    placeholder: 'How many years does the driver have their PCO licence?',
    rules: [],
  },
  {
    name: 'years_driving_license',
    placeholder: 'How many years does the driver have their driving licence?',
    rules: [
      {
        type: 'number',
        min: 3,
        message: 'The driver should be having their licence at least 3 years',
      },
    ],
  },
  {
    name: 'accident_number',
    placeholder: 'In the past 3 years, how many accidents did the driver have?',
    rules: [
      {
        type: 'number',
        max: 3,
        message:
          'Driver should not have more than 3 accidents in the past 3 years',
      },
    ],
  },
  {
    name: 'negative_drivinglicense_point',
    placeholder:
      'How many negative points does the driver have on their driving licence?',
    rules: [
      {
        type: 'number',
        max: 6,
        message: 'Driver should not have more than 6 negative points',
      },
    ],
  },
];

export function LicenseInfoSection() {
  return items.map((item) => (
    <Form.Item
      name={item.name}
      rules={[...rules, ...item.rules]}
      key={item.name}
    >
      <FloatLabel label={item.placeholder}>
        <InputNumber
          onWheel={(e) => e.target.blur()}
          controls={false}
          type="number"
          className="full-width"
          id={item.name}
          visibilityToggle={false}
        />
      </FloatLabel>
    </Form.Item>
  ));
}
