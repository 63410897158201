import qs from 'query-string';
import { axios } from '../utils';

export const getFinanceReport = async ({ pageParam = 1, querys }) => {
  const endpoint = qs.stringifyUrl({
    url: '/cars/car-finance-detail/',
    query: { ...querys, page: pageParam },
  });
  const { data } = await axios.get(endpoint);
  return data;
};

export const getSummaryReport = async ({ querys }) => {
  const endpoint = qs.stringifyUrl({
    url: '/payment/summary-report',
    query: querys,
  });
  const { data } = await axios.get(endpoint);
  return data;
};

export const getSalesBreakdown = async ({ querys }) => {
  const endpoint = qs.stringifyUrl({
    url: '/payment/sales-breakdown',
    query: querys,
  });
  const { data } = await axios.get(endpoint);
  return data;
};
export const getReceiptBreakdown = async ({ querys }) => {
  const endpoint = qs.stringifyUrl({
    url: '/payment/receipt-breakdown',
    query: querys,
  });
  const { data } = await axios.get(endpoint);
  return data;
};

export const getVehicleProfit = async ({ querys }) => {
  const endpoint = qs.stringifyUrl({
    url: '/payment/vehicle-sales-breakdown',
    query: querys,
  });
  const { data } = await axios.get(endpoint);
  return data;
};
export const getVehicleProfitExpenses = async ({ querys }) => {
  const endpoint = qs.stringifyUrl({
    url: '/report/expense/',
    query: querys,
  });
  const { data } = await axios.get(endpoint);
  return data;
};
export const getVehicleProfitSales = async ({ querys }) => {
  const endpoint = qs.stringifyUrl({
    url: '/payment/sales-breakdown',
    query: querys,
  });
  const { data } = await axios.get(endpoint);
  return data;
};
