import { Modal } from 'antd';
import cn from 'classnames';

import { Icon } from '../icon';

import './styles.scss';

export const CustomizedModal = ({
  children,
  visible,
  onCancel,
  title,
  className,
  ...restProps
}) => (
  <Modal
    title={title || ' '}
    visible={visible}
    onCancel={onCancel}
    width={800}
    className={cn('styled-modal', className)}
    closeIcon={
      <Icon variant="cross" width={24} height={24} fill="currentColor" />
    }
    {...restProps}
  >
    {children}
  </Modal>
);
