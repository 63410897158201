import { useInfiniteQuery, useQuery } from 'react-query';
import * as api from '../api';

export const useFinanceReport = (querys) =>
  useInfiniteQuery(
    ['getFinanceReport', querys],
    ({ pageParam }) => api.getFinanceReport({ querys, pageParam }),
    {
      getNextPageParam: (lastPage) => lastPage?.next ?? false,
    },
  );

export const useSummaryReport = (querys) =>
  useQuery(['getSummaryReport', querys], () =>
    api.getSummaryReport({ querys }),
  );

export const useSalesBreakdown = (querys) =>
  useQuery(['getSalesBreakdown', querys], () =>
    api.getSalesBreakdown({ querys }),
  );

export const useReceiptBreakdown = (querys) =>
  useQuery(['getReceiptBreakdown', querys], () =>
    api.getReceiptBreakdown({ querys }),
  );

export const useVehicleProfit = (querys) =>
  useQuery(['getVehicleProfit', querys], () =>
    api.getVehicleProfit({ querys }),
  );

export const usetVehicleProfitExpenses = (querys) =>
  useQuery(['getVehicleProfitExpenses', querys], () =>
    api.getVehicleProfitExpenses({ querys }),
  );

export const usetVehicleProfitSales = (querys) =>
  useQuery(['getVehicleProfitSales', querys], () =>
    api.getVehicleProfitSales({ querys }),
  );
