/* eslint-disable camelcase */
import React, { useState, useMemo, useEffect } from 'react';
import './pcn.scss';
import {
  Button,
  Divider,
  Tabs,
  Form,
  Input,
  DatePicker,
  Spin,
  InputNumber,
  message,
  Modal,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { CloseCircleFilled } from '@ant-design/icons';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useQueryClient } from 'react-query';
import {
  CustomizedModal,
  FloatLabel,
  PcnTable,
  SinglePhotoUploader,
} from '../../components';
import { CustomDatePicker } from '../../components/custom-date-picker';
import { axios } from '../../utils';

const { TabPane } = Tabs;
dayjs.extend(utc);

export function Pcn() {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [formSubmit] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const [isModalVisible, setModalVisible] = useState(false);
  const [searching, setSearching] = useState(false);
  const [searched, setSearched] = useState(false);
  const [tiket, setTiket] = useState();
  const [hidenBtnSubnit, setHidenBtnSubnit] = useState(false);
  const [disabledFilead, setDisabled] = useState(true);
  const [isChangeing, setIsChangeing] = useState();

  const category =
    useMemo(() => qs.parse(location.search)?.category, [location.search]) ||
    'new';

  const isRequiredRule = [
    {
      required: true,
      message: 'This field is required',
    },
  ];
  const disabledDate = (current) => current && current > dayjs().endOf('day');

  const ticketID = useMemo(
    () => qs.parse(location.search)?.id,
    [location.search],
  );

  useEffect(() => setModalVisible(false), [location]);

  const tabClick = (key) => history.push(`pcn?category=${key}`);

  useEffect(() => {
    if (category === 'history') {
      if (ticketID) {
        setSearched(true);
        setSearching(true);
        setHidenBtnSubnit(true);
        axios
          .get(`/pcn/history-pcn-panel/${ticketID}/`)
          .then((res) => {
            setModalVisible(true);
            const dateTicket = dayjs(res?.data?.date);
            const {
              driver: { first_name, last_name, phone_number },
            } = res.data;
            formSubmit.setFieldsValue({
              ...res.data,
              name: `${first_name} ${last_name}`,
              phone_number,
            });
            form.setFieldsValue({
              ...res.data,
              registrationnumber: res?.data?.registration,
              ticketDate: dateTicket,
              time: dateTicket,
            });
          })
          .finally(() => setSearching(false));
      }
    }
    if (category === 'new') {
      if (ticketID) {
        setSearched(true);
        setSearching(true);
        setHidenBtnSubnit(false);
        axios
          .get(`pcn/pcn-panel/${ticketID}/`)
          .then((res) => {
            setModalVisible(true);
            const dateTicket = dayjs(res?.data?.date);
            const {
              driver: { first_name, last_name, phone_number },
            } = res.data;
            formSubmit.setFieldsValue({
              ...res.data,
              name: `${first_name} ${last_name}`,
              phone_number,
            });
            form.setFieldsValue({
              ...res.data,
              registrationnumber: res?.data?.registration,
              ticketDate: dateTicket,
              time: dateTicket,
            });
          })
          .finally(() => setSearching(false));
      }
    }
  }, [ticketID]);

  useEffect(() => {
    if (!ticketID) {
      setModalVisible(false);
    }
    if (ticketID) {
      setModalVisible(true);
    }
  }, [location]);

  const onConfirmExit = () => setModalVisible(false);

  const onCancel = () => {
    Modal.confirm({
      title: 'Exit Confirmation',
      content: 'Do you cancel the add New PCN ticket process?',
      onOk: onConfirmExit,
      okText: 'Yes, exit',
      okType: 'danger',
      closable: true,
      maskClosable: true,
    });
  };

  const AddTiket = () => {
    setSearching(true);
    const date = dayjs(form.getFieldValue('ticketDate')).format('YYYY-MM-DD');
    const time = dayjs(form.getFieldValue('time')).format('THH:mm:ss[Z]');
    const fd = new FormData();
    fd.append('date', date + time);
    fd.append('ticket_title', form.getFieldValue('ticket_title'));
    fd.append('location', form.getFieldValue('location'));
    fd.append('registration', form.getFieldValue('registrationnumber'));
    axios
      .post('pcn/pcn-panel/', fd)
      .then(({ data: ticketData }) => {
        setSearched(true);
        setHidenBtnSubnit(false);
        const {
          driver: { first_name, last_name, phone_number },
        } = ticketData;
        formSubmit.setFieldsValue({
          ...ticketData,
          name: `${first_name} ${last_name}`,
          phone_number: `${phone_number}`,
        });
        formSubmit.setFieldsValue(ticketData);
        setTiket(ticketData);
        queryClient.invalidateQueries('PCNs');
      })
      .finally(() => setSearching(false));
  };

  const onSubmit = () => {
    setSearching(true);
    const fileTiket = formSubmit.getFieldValue('ticket')?.file?.originFileObj;
    const fd = new FormData();
    fd.append('registration', form.getFieldValue('registrationnumber'));
    fd.append('ticket', fileTiket);
    axios
      .put(`/pcn/pcn-panel/${ticketID || tiket.id}/`, fd)
      .then(async () => {
        queryClient.invalidateQueries('PCNs');
        message.success('Reissued PCN ticket submitted');
      })
      .finally(() => {
        setSearched(false);
        setModalVisible(false);
        setSearching(false);
      });
  };

  return (
    <>
      <Tabs
        tabBarExtraContent={
          <Button
            onClick={() => {
              setModalVisible(true);
              setDisabled(false);
            }}
            className="secondary-btn"
          >
            New ticket
          </Button>
        }
        activeKey={category}
        onTabClick={tabClick}
        type="card"
        className="tables-tab-bar"
        animated
        destroyInactiveTabPane
      >
        <TabPane tab="New tickets" key="new">
          <PcnTable category="all" />
        </TabPane>
        <TabPane tab="Tickets history" key="history">
          <PcnTable category="history" />
        </TabPane>
      </Tabs>
      <CustomizedModal
        visible={isModalVisible}
        onCancel={isChangeing ? onCancel : onConfirmExit}
        afterClose={() => {
          form.resetFields();
          formSubmit.resetFields();
          history.push({
            search: qs.exclude(location.search, ['id']),
          });
          setSearched(false);
          setIsChangeing(false);
          setDisabled(true);
        }}
        footer={
          <>
            {!searched && (
              <Button
                type="primary"
                className="footer-cta-btn"
                onClick={() => form.submit()}
              >
                Search for driver
              </Button>
            )}
            {searched && !hidenBtnSubnit && (
              <Button
                type="primary"
                onClick={() => formSubmit.submit()}
                className="footer-cta-btn"
                disabled={hidenBtnSubnit}
              >
                Submit ticket
              </Button>
            )}
          </>
        }
      >
        <Title level={4}>PCN ticket</Title>
        <Divider orientation="left" style={{ marginTop: '30px' }}>
          Ticket details
        </Divider>
        <Spin spinning={searching}>
          <Form
            form={form}
            className="inline-container reset-error-form"
            style={{ justifyContent: 'space-between' }}
            onFinish={AddTiket}
            onValuesChange={(val) => {
              setIsChangeing(!!val);
            }}
          >
            <Form.Item name="ticketDate" rules={isRequiredRule}>
              <FloatLabel label="Ticket date">
                <CustomDatePicker
                  disabled={category === 'history' && disabledFilead}
                  disabledDate={disabledDate}
                />
              </FloatLabel>
            </Form.Item>
            <Form.Item name="time" rules={isRequiredRule}>
              <FloatLabel label="Ticket time">
                <DatePicker.TimePicker
                  style={{ width: '100%' }}
                  placeholder=""
                  showSecond={false}
                  format="HH:mm"
                  showNow={false}
                  suffixIcon={null}
                  disabled={category === 'history' && disabledFilead}
                  clearIcon={
                    <CloseCircleFilled
                      color="91a2ab"
                      className="date-input-clear-icon"
                    />
                  }
                />
              </FloatLabel>
            </Form.Item>
            <Form.Item
              className="form-item"
              name="registrationnumber"
              rules={isRequiredRule}
            >
              <FloatLabel label="Registration Number">
                <Input
                  className="full-width"
                  disabled={category === 'history' && disabledFilead}
                />
              </FloatLabel>
            </Form.Item>
            <Form.Item
              className="form-item full-width"
              name="ticket_title"
              rules={isRequiredRule}
            >
              <FloatLabel label="Title">
                <Input
                  className="full-width"
                  disabled={category === 'history' && disabledFilead}
                />
              </FloatLabel>
            </Form.Item>
            <Form.Item
              className="form-item full-width"
              name="location"
              rules={isRequiredRule}
            >
              <FloatLabel label="Location">
                <Input
                  className="full-width"
                  disabled={category === 'history' && disabledFilead}
                />
              </FloatLabel>
            </Form.Item>
          </Form>
          {searched && (
            <>
              <Divider orientation="left" style={{ marginTop: '30px' }}>
                Drivers details
              </Divider>
              <Form
                form={formSubmit}
                className="inline-container reset-error-form"
                style={{ justifyContent: 'space-between' }}
                onFinish={onSubmit}
                onValuesChange={(val) => {
                  setIsChangeing(!!val);
                }}
              >
                <Form.Item name="name" rules={isRequiredRule}>
                  <FloatLabel label="Driver's name">
                    <Input disabled />
                  </FloatLabel>
                </Form.Item>
                <Form.Item name="phone_number" rules={isRequiredRule}>
                  <FloatLabel label="Phone number">
                    <InputNumber
                      disabled
                      maxLength="11"
                      className="full-width"
                      formatter={(e) =>
                        e.replace(/(\d{3})(\d{4})(\d{4})/, '$1-$2-$3')
                      }
                      parser={(e) => e.replace(/\D+/g, '')}
                    />
                  </FloatLabel>
                </Form.Item>
                <Form.Item name="ticket" rules={isRequiredRule}>
                  <SinglePhotoUploader
                    disabled={category === 'history' && disabledFilead}
                  />
                </Form.Item>
              </Form>
            </>
          )}
        </Spin>
      </CustomizedModal>
    </>
  );
}
