import { useMemo } from 'react';
import { Table } from 'antd';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';

import { useDrivers } from '../../hooks';
import { InfiniteScrollWrapper } from '../../components';
import { columns } from './columnDriver';

export function ActiveDrivers() {
  const history = useHistory();
  const location = useLocation();

  const queryParams = useMemo(() => {
    const params = qs.parse(location.search);
    delete params?.category;
    delete params?.driver_id;
    return params;
  }, [location.search]);

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } =
    useDrivers({ category: 'active', queryParams });

  const handelChangeTable = (pagination, filters) => {
    const { current: page, pageSize: size } = pagination;
    const params = { page, size };
    Object.entries(filters).forEach(([key, value]) => {
      if (value?.length > 0) {
        let paramKey;
        switch (key) {
          case 'first_name':
            paramKey = 'search';
            break;
          default:
            paramKey = key;
            break;
        }
        Object.assign(params, { [paramKey]: value[0] });
      }
    });
    history.replace({
      search: qs.stringify({
        ...qs.parse(history.location.search),
        search: params.search,
        phone_number: params.phone,
        postal_number: params.postal_number,
        birth_date: params.birth_date,
        reference_number: params.reference_number,
        vehicle: params.vehicle,
      }),
    });
  };

  return (
    <InfiniteScrollWrapper
      lengthData={data?.pages?.[0].count}
      hasMore={hasNextPage}
      functionNext={fetchNextPage}
    >
      <Table
        columns={columns}
        dataSource={data?.pages.reduce((res, cur) => {
          if (cur?.results) {
            res.push(...cur?.results);
          } else res.push(...cur);
          return res;
        }, [])}
        rowKey={(record) => record.id}
        loading={isLoading || isFetching}
        pagination={false}
        scroll={{
          x: 1024,
        }}
        onRow={(driver) => ({
          onClick: () => {
            history.push({
              search: qs.stringify({
                ...qs.parse(history.location.search),
                driver_id: driver.id,
              }),
            });
          },
          className: 'clickable-table-row',
        })}
        onChange={handelChangeTable}
      />
    </InfiniteScrollWrapper>
  );
}
