import { useInfiniteQuery, useQuery } from 'react-query';
import * as api from '../api';

export const useNotificationsCount = ({ type }) =>
  useQuery(
    ['notificationsCount', { type }],
    () => api.getNotificationsCount({ type }),
    {
      enabled: !!type,
    },
  );

export const useLicensesNotifications = ({ isReed, url }) =>
  useInfiniteQuery(
    ['licensesNotifications', { isReed, url }],
    ({ pageParam }) => api.getLicensesNotifications({ isReed, url, pageParam }),
    {
      getNextPageParam: (lastPage) => lastPage?.next ?? false,
    },
  );
