/* eslint-disable camelcase */
import React, { useEffect, useMemo, useState } from 'react';
import {
  Form,
  Input,
  Row,
  Col,
  InputNumber,
  Divider,
  Button,
  Spin,
} from 'antd';
import { Icon } from '../../icon';
import { useDamageSketch } from '../../../hooks';
import { allowOnlyNumbers, axios } from '../../../utils';

import CarSketch from '../../../assets/CarSketch@3x.png';
import Logo from '../../../assets/logo.png';
import { CustomizedModal } from '../../modal';
import { FloatLabel } from '../../floating-label';
import { SinglePhotoUploader } from '../../single-photo-uploader';

import './styles.scss';

import { SignatureSection } from './SignatureSection';
import { CustomDatePicker } from '../../custom-date-picker';

function AddNewDamageModal({ isVisible, close, onAdd }) {
  const [form] = Form.useForm();
  const handleSubmit = () =>
    form.validateFields().then(({ number, image }) => {
      onAdd({ number, image: image.file.originFileObj });
      form.resetFields();
    });

  return (
    <CustomizedModal
      visible={isVisible}
      onCancel={close}
      className="damage-sketch-template-modal"
      footer={
        <div className="footer-cta-btns-container">
          <Button
            type="primary"
            className="footer-cta-btn"
            size="large"
            onClick={form.submit}
          >
            Add to the list
          </Button>
        </div>
      }
    >
      <h2 className="add-damage-modal-heading">Add new damage record</h2>
      <Row style={{ marginTop: '40px' }}>
        <Col
          sm={24}
          md={{
            span: 10,
            // push: 4,
          }}
        >
          <Form form={form} onFinish={handleSubmit}>
            <Form.Item
              name="number"
              rules={[
                { required: true, message: 'This field is required' },
                {
                  type: 'number',
                  min: 0,
                  max: 32,
                  message: 'Between 0 to 32',
                },
              ]}
              className="full-width"
            >
              <FloatLabel label="Damage number">
                <InputNumber
                  className="full-width"
                  onKeyPress={allowOnlyNumbers}
                />
              </FloatLabel>
            </Form.Item>
            <Form.Item
              name="image"
              label="Damage picture"
              className="flex-column"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <SinglePhotoUploader />
            </Form.Item>
          </Form>
        </Col>
        <Col
          sm={24}
          md={{
            span: 10,
            push: 2,
          }}
        >
          <img src={CarSketch} className="car-sketch-img" />
        </Col>
      </Row>
    </CustomizedModal>
  );
}

const CarDamagesTable = ({ form }) => {
  const [modalIsVisible, setModalVisible] = useState(false);
  const handleAddNewDamage = (newRecord, add) => {
    const damagesList = form.getFieldValue('damages_list');
    const elementIndex =
      damagesList?.findIndex((el) => el.number === +newRecord.number) || -1;

    if (elementIndex === -1) add(newRecord);
    else {
      damagesList[elementIndex].image = newRecord.image;
      form.setFieldsValue({ damages_list: damagesList });
    }
    setModalVisible(false);
  };
  const onDelete = ({ id }, remove) => {
    if (id) {
      axios.delete(`cars/damage-image/${id}`).then(remove);
    } else {
      remove();
    }
  };

  return (
    <Form.List name="damages_list">
      {(fields, { add, remove }) => (
        <>
          <div className="car-damages-table">
            <header className="table-header">
              <h2>CAR DAMAGES</h2>
              <Button
                type="primary"
                className="add-new-damage-btn"
                onClick={() => setModalVisible(true)}
              >
                Add a new record
              </Button>
            </header>
            <Row className="damages-row">
              {fields.map(({ key, name, fieldKey, ...restField }) => (
                <Form.Item required={false} key={key} noStyle>
                  <Col sm={24} md={12} className="damage-record">
                    <div className="damage-number-cell">
                      <Form.Item
                        {...restField}
                        name={[name, 'number']}
                        fieldKey={[fieldKey, 'number']}
                        noStyle
                        valuePropName="children"
                      >
                        <span />
                      </Form.Item>
                    </div>
                    <div className="damage-image-wrapper">
                      <Form.Item
                        {...restField}
                        name={[name, 'image']}
                        fieldKey={[fieldKey, 'image']}
                        noStyle
                        getValueProps={(file) => ({
                          // eslint-disable-next-line no-nested-ternary
                          src: file
                            ? typeof file === 'string'
                              ? file
                              : URL?.createObjectURL(file)
                            : undefined,
                        })}
                      >
                        <img className="damage-image" />
                      </Form.Item>
                      <Button
                        block
                        onClick={() =>
                          onDelete(
                            form.getFieldValue(['damages_list', name]),
                            () => remove(['damages_list', name]),
                          )
                        }
                      >
                        delete
                      </Button>
                    </div>
                  </Col>
                </Form.Item>
              ))}
            </Row>
          </div>
          <AddNewDamageModal
            isVisible={modalIsVisible}
            close={() => setModalVisible(false)}
            onAdd={(newRecord) =>
              handleAddNewDamage(newRecord, add, fields.length)
            }
          />
        </>
      )}
    </Form.List>
  );
};

export const DamageSketchTemplate = ({
  user,
  isVisible,
  close,
  isReceivingCar = false,
  onSubmit,
}) => {
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);

  const damageSketchID = useMemo(
    () => user?.assigncar?.damagessketch,
    [user?.assigncar],
  );

  const {
    data: damageSketch,
    refetch,
    isFetching,
  } = useDamageSketch(damageSketchID);

  useEffect(() => isVisible && damageSketchID && refetch(), [isVisible]);

  useEffect(() => {
    if (damageSketch) {
      form.setFieldsValue({
        ...damageSketch,
        signature_agreement: damageSketch.signature_agreement || null,
        signature_damages: damageSketch.signature_damages || null,
      });
    }
  }, [damageSketch]);

  useEffect(() => form.setFieldsValue(user), [user]);

  const isFile = (input) => 'File' in window && input instanceof File;

  const handleUpdateDamageSketch = () => {
    form.validateFields().then((values) => {
      setSubmitting(true);
      const {
        fuel_tank,
        signature_agreement,
        signature_damages,
        damages_list,
      } = values;
      const damages = damages_list?.reduce((res, cur) => {
        Object.assign(res, {
          [`damage_${cur.number}`]: isFile(cur.image) ? cur.image : null,
        });
        return res;
      }, {});
      const payload = {
        car: user.assigncar.id,
        receive: isReceivingCar,
        fuel_tank,
        signature_agreement,
        signature_damages,
        ...damages,
      };
      if (typeof signature_agreement === 'string') {
        delete payload.signature_agreement;
      }
      if (typeof signature_damages === 'string') {
        delete payload.signature_damages;
      }
      if (user?.assigncar?.assing_to)
        Object.assign(payload, { user: user.assigncar.assing_to.id });

      onSubmit(payload)
        ?.then(close)
        ?.finally(() => setSubmitting(false));
    });
  };

  const saveSignature = (signatureImg, name) => {
    form.setFieldsValue({ [name]: signatureImg?.file });
  };

  return (
    <CustomizedModal
      visible={isVisible}
      onCancel={close}
      className="damage-sketch-template-modal"
      footer={
        <div className="footer-cta-btns-container">
          <Button
            type="primary"
            htmlType="submit"
            onClick={form.submit}
            className="footer-cta-btn"
            size="large"
            loading={isSubmitting}
          >
            Update
          </Button>
        </div>
      }
    >
      <Spin spinning={isFetching}>
        <div className="damage-sketch-template">
          <Form
            size="small"
            colon={false}
            onFinish={handleUpdateDamageSketch}
            form={form}
            preserve={false}
          >
            <Row>
              <header className="header">
                <img src={Logo} className="logo" />
                <h1 className="template-title">Damage sketch</h1>
              </header>
              <Col sm={24} md={10}>
                <div className="section">
                  <h2 className="heading">Hirer&apos;s Details:</h2>
                  <Form.Item label="First name">
                    <Input value={user?.first_name || '-'} readOnly disabled />
                  </Form.Item>
                  <Form.Item label="Surname">
                    <Input value={user?.last_name || '-'} readOnly disabled />
                  </Form.Item>
                  <Form.Item label="Full address">
                    <Input.TextArea
                      rows={3}
                      value={user?.address || '-'}
                      readOnly
                      disabled
                    />
                  </Form.Item>
                  <Form.Item label="Postcode">
                    <Input
                      value={user?.postal_number || '-'}
                      readOnly
                      disabled
                    />
                  </Form.Item>
                </div>
                <h2 className="heading">Vehicle Details:</h2>
                <Form.Item label="Make">
                  <Input value={user?.assigncar?.make} readOnly disabled />
                </Form.Item>
                <Form.Item label="model">
                  <Input value={user?.assigncar?.model} readOnly disabled />
                </Form.Item>
                <Form.Item label="Reg number">
                  <Input
                    value={user?.assigncar?.registrationnumber}
                    readOnly
                    disabled
                  />
                </Form.Item>
                <Form.Item label="Start Date" name={['payment', 'hire_start']}>
                  <CustomDatePicker
                    format="DD/MM/YYYY"
                    disabled
                    withIcon={false}
                  />
                </Form.Item>
                <Form.Item label="Damages out" name="damage_out">
                  <Input readOnly disabled />
                </Form.Item>
                <Form.Item label="Damages in" name="damage_in">
                  <Input readOnly disabled />
                </Form.Item>
                <Form.Item label="Hire end date" name={['payment', 'hire_end']}>
                  <CustomDatePicker
                    format="DD/MM/YYYY"
                    disabled
                    withIcon={false}
                  />
                </Form.Item>
                <Form.Item label="Millage" name="delivered_at_miles">
                  <Input readOnly disabled />
                </Form.Item>
                <div className="section description">
                  <p>Damages</p>
                  <p>
                    The hirer must accept responsibility for the non-waivable
                    excess of the amount printed below for all losses caused to
                    the aforementioned vehicle
                  </p>
                  <p>Non-Waivable excess: £500.00</p>
                  <p>Returning the Hire Vehicle</p>
                  <p>Return Date:</p>
                  <p>
                    I confirm that I have see Report and I understand my
                    responsibilities and the basis upon which I’m accepting this
                    vehicle, as detailed above.I understand that my one
                    signature will be captured Electronically and I authorise
                    G&M Direct Hire to transferit to the credit Hire Agreement.
                    And the vehicle condition Report.
                  </p>
                </div>
                <SignatureSection
                  onSave={saveSignature}
                  name="signature_agreement"
                  user={user}
                />
              </Col>

              <Col
                sm={24}
                md={{
                  span: 10,
                  push: 4,
                }}
              >
                <div className="section fuel-and-address-section">
                  <div className="flex-col fuel-capacity-column">
                    <h4 className="title">
                      <Icon
                        variant="fuel-pump"
                        className="fuel-pump-icon"
                        width={24}
                        height={24}
                      />
                      Fuel tank
                    </h4>
                    <Form.Item
                      name="fuel_tank"
                      rules={[
                        { required: true, message: 'This field is required' },
                      ]}
                    >
                      <InputNumber
                        formatter={(value) => `% ${value}`}
                        parser={(value) => value.replace('% ', '')}
                        className="full-width full-capacity-input"
                        min={0}
                        max={100}
                      />
                    </Form.Item>
                  </div>
                  <div className="flex-col">
                    <p className="title">
                      6-9 The Square, Stockley Park, Uxbridge, UB11 1 FW
                      <br />
                      Tel: 02089009574
                      <br />
                      Fax: 02089009574
                      <br />
                      Email: Info@gmdirecthire.co.uk
                    </p>
                  </div>
                </div>
                <img src={CarSketch} className="car-sketch-img" />
              </Col>
            </Row>
            <Divider />
            <CarDamagesTable form={form} />
            <SignatureSection
              onSave={saveSignature}
              name="signature_damages"
              user={user}
            />
          </Form>
        </div>
      </Spin>
    </CustomizedModal>
  );
};
