/* eslint-disable camelcase */
import dayjs from 'dayjs';

export const MileageColumn = [
  {
    title: 'Vehicle reg.',
    dataIndex: ['car', 'registrationnumber'],
  },
  { title: 'Extra mileage', dataIndex: ['driver', 'extra_miles'] },
  { title: 'Extra charge', dataIndex: ['driver', 'extra_charges'] },
];

export const ServiceColumn = [
  {
    title: 'Vehicle reg.',
    dataIndex: ['car_details', 'registrationnumber'],
  },
  { title: 'Status', dataIndex: 'state' },
];
export const MOTColumn = [
  {
    title: 'Vehicle reg.',
    dataIndex: ['car_details', 'registrationnumber'],
  },
  { title: 'Status', dataIndex: 'state' },
];

export const outstandingColumn = [
  {
    title: 'Reference',
    dataIndex: 'reference_number',
  },
  {
    title: "Driver's name",
    dataIndex: 'driver_name',
    render: (_, { driver_last_name, driver_first_name }) =>
      `${driver_first_name} ${driver_last_name}`,
  },
  {
    title: 'Balance',
    dataIndex: 'amount',
    render: (text, { weekly_rent_amount }) => {
      const amount = parseFloat(text).toFixed(2);
      let color = 'black';
      if (text < 0 && text > -weekly_rent_amount) {
        color = '#ff9a00';
      } else if (text < -weekly_rent_amount) {
        color = 'red';
      }
      return <spam style={{ color }}>${amount}</spam>;
    },
  },
];

export const AvalebleCarsColumn = [
  {
    title: 'Car manufacturer and model',
    dataIndex: 'model',
    render: (model, { make }) => `${make} ${model}`,
  },
  {
    title: 'Registeration number',
    dataIndex: 'registrationnumber',
  },
  { title: 'Weekly rent', dataIndex: 'weekly_rent_amount' },
  {
    title: 'Status',
    dataIndex: 'available_from',
    render: (date) =>
      date ? (
        <>Available at {dayjs(date).format('DD/MM')}</>
      ) : (
        <span style={{ color: '#36930F' }}>Available</span>
      ),
  },
];
