/* eslint-disable no-nested-ternary */
/* eslint-disable react/destructuring-assignment */
import { Avatar, Button, Table } from 'antd';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import axios from '../../utils/axios';
import { useLicensesNotifications, useNotificationsCount } from '../../hooks';
import { Icon, InfiniteScrollWrapper } from '../../components';

export default function Notif({
  url,
  unSeenReqURL,
  seenReqURL,
  notifCountURL,
}) {
  const history = useHistory();
  const [loadingSeen, setloadingSeen] = useState();

  const { data, loading, isFetching, hasNextPage, refetch, fetchNextPage } =
    useLicensesNotifications({ isReed: false, url });

  const { refetch: refetchNotificationsCount } = useNotificationsCount({
    type: notifCountURL,
  });

  const {
    data: dataReed,
    loading: loadingReed,
    isFetching: isFetchingReed,
    hasNextPage: hasNextPageReed,
    refetch: refetchReed,
    fetchNextPage: fetchNextPageReed,
  } = useLicensesNotifications({ isReed: true, url });

  const columns = [
    {
      title: 'Title',
      dataIndex: 'message',
      key: 'title',
      width: '35%',
    },
    {
      title: 'Driver name',
      dataIndex: 'driver',
      key: 'driver',
      render: (driver) => (
        <>
          <Avatar
            src={driver?.profileimage}
            shape="square"
            style={{ marginRight: '16px' }}
          />
          {driver?.first_name
            ? `${driver.first_name} ${driver.last_name}`
            : 'GM Admin'}
        </>
      ),
    },
    {
      title: 'Car',
      dataIndex: 'Car',
      key: 'Car',
      render: (_, val) => (
        <>
          {val?.car?.model}
          <br />
          {val?.car && `(${val?.car?.registrationnumber})`}
        </>
      ),
    },
    {
      title: 'Notification date',
      dataIndex: 'created_at',
      key: 'created_at',
      render: (val) => dayjs(val).format('DD/MM/YYYY - HH:mm'),
    },
    {
      title: '',
      dataIndex: 'id',
      key: 'id',
      className: 'border-cell',
      render: (id, row) => (
        <Button
          className="seen"
          onClick={(e) => {
            e.stopPropagation();
            setloadingSeen(true);
            axios
              .get(!row.read ? `${seenReqURL + id}/` : `${unSeenReqURL + id}/`)
              .then(() => {
                refetch();
                refetchReed();
                refetchNotificationsCount();
              })
              .finally(() => setloadingSeen(false));
          }}
          type="primary"
        >
          <span id="text-seen">
            {!row.read ? 'Mark as seen' : 'Mark as unseen'}
          </span>
          &nbsp;
          <Icon
            variant={row.read ? 'icon-Seen' : 'icon-unSeen'}
            width={24}
            height={24}
          />
        </Button>
      ),
    },
  ];

  return (
    <div>
      <InfiniteScrollWrapper
        lengthData={data?.pages?.[0].count}
        hasMore={hasNextPage}
        functionNext={fetchNextPage}
      >
        <Table
          dataSource={data?.pages
            .reduce((res, cur) => {
              if (cur?.results) {
                res.push(...cur?.results);
              } else res.push(...cur);
              return res;
            }, [])
            .filter((i) => i.read === false)}
          columns={columns}
          pagination={false}
          onRow={(record) => ({
            onClick: () => {
              history.push(
                localStorage.getItem('type') === 'admin'
                  ? record?.driver
                    ? `/drivers?driver_id=${record?.driver?.id}`
                    : `/car-bank?car_id=${record?.car?.id}`
                  : undefined,
              );
            },
            className: 'clickable-table-row',
          })}
          loading={isFetching || loading || loadingSeen}
          scroll={{
            x: 1024,
          }}
        />
      </InfiniteScrollWrapper>
      <InfiniteScrollWrapper
        lengthData={dataReed?.pages?.[0].count}
        hasMore={hasNextPageReed}
        functionNext={fetchNextPageReed}
      >
        <Table
          dataSource={dataReed?.pages
            .reduce((res, cur) => {
              if (cur?.results) {
                res.push(...cur?.results);
              } else res.push(...cur);
              return res;
            }, [])
            .filter((item) => item.read === true)}
          columns={columns}
          pagination={false}
          onRow={(record) => ({
            onClick: () => {
              history.push(
                localStorage.getItem('type') === 'admin'
                  ? record?.driver
                    ? `/drivers?driver_id=${record?.driver?.id}`
                    : `/car-bank?car_id=${record?.car?.id}`
                  : undefined,
              );
            },
            className: 'clickable-table-row',
          })}
          loading={isFetchingReed || loadingReed || loadingSeen}
          scroll={{
            x: 1024,
          }}
          style={{ marginTop: '80px' }}
        />
      </InfiniteScrollWrapper>
    </div>
  );
}
