/* eslint-disable no-unused-vars */
import { Button, Modal, Tabs, message, Form } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useQueryClient } from 'react-query';
import { CustomizedModal, Heading } from '../../components';
import CarsTable from './CarsTable';
import { axios } from '../../utils';
import { FormItems } from './CarFormItems';
import { updateDamageSketch } from '../../api';

const { confirm } = Modal;
const { TabPane } = Tabs;
dayjs.extend(utc);

export function CarBank() {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [, forceUpdate] = useState({});
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addExp, setAddExp] = useState([]);
  const [damageSketchData, setDamageSketchData] = useState();
  const [car, setCar] = useState();

  const page =
    useMemo(() => qs.parse(location.search)?.category, [location.search]) ||
    'all';

  const showModal = () => setIsModalVisible(true);
  const onConfirm = () => setIsModalVisible(false);

  const handleCancel = () => {
    confirm({
      title: 'Exit Confirmation',
      content: 'Do you cancel the add car process?',
      onOk: onConfirm,
      okText: 'Yes, exit',
      okType: 'danger',
      closable: true,
      maskClosable: true,
    });
  };

  const AddNewCar = () => {
    form.validateFields().then(() => {
      if (
        +form.getFieldValue('last_service_milage') >=
        +form.getFieldValue('currentOdometer')
      ) {
        message.error(
          'The last service mileage must be less than the current mileage',
        );
        return;
      }
      setLoading(true);
      const dataForm = new FormData();
      dataForm.append('make', form.getFieldValue('make'));
      dataForm.append('model', form.getFieldValue('model'));
      dataForm.append('body_shape', form.getFieldValue('body_shape'));
      dataForm.append(
        'registrationnumber',
        form.getFieldValue('registrationnumber'),
      );
      dataForm.append('currentOdometer', form.getFieldValue('currentOdometer'));
      dataForm.append(
        'year',
        dayjs(form.getFieldValue('year')).format('YYYY-MM-DD'),
      );
      dataForm.append('transmission', form.getFieldValue('transmission'));
      dataForm.append('enginesize', form.getFieldValue('enginesize'));
      dataForm.append('color', form.getFieldValue('color'));
      dataForm.append('seats', form.getFieldValue('seats'));
      dataForm.append('fueltype', form.getFieldValue('fueltype'));
      dataForm.append('tankSize', '34');
      dataForm.append('deposit_amount', form.getFieldValue('deposit_amount'));
      dataForm.append('carvalue', form.getFieldValue('carvalue'));
      dataForm.append(
        'last_service_milage',
        form.getFieldValue('last_service_milage'),
      );
      dataForm.append(
        'carimage',
        form.getFieldValue('carimage')?.file?.originFileObj,
      );
      dataForm.append(
        'pcolicense',
        form.getFieldValue('pcolicense')?.file?.originFileObj,
      );
      dataForm.append(
        'logbook',
        form.getFieldValue('logbook')?.file?.originFileObj,
      );
      dataForm.append(
        'car_insurance_certificate',
        form.getFieldValue('car_insurance_certificate')?.file?.originFileObj,
      );
      dataForm.append(
        'weekly_rent_amount',
        form.getFieldValue('weekly_rent_amount'),
      );
      if (form.getFieldValue(['mot', 'last_mot'])) {
        dataForm.append(
          'last_mot',
          dayjs(form.getFieldValue(['mot', 'last_mot'])).format('YYYY-MM-DD'),
        );
      } else {
        dataForm.append('last_mot', dayjs().format('YYYY-MM-DD'));
      }
      if (form.getFieldValue(['mot', 'mot_expire'])) {
        dataForm.append(
          'mot_expire',
          dayjs(form.getFieldValue(['mot', 'mot_expire'])).format('YYYY-MM-DD'),
        );
      } else {
        dataForm.append(
          'mot_expire',
          dayjs().add(1, 'year').format('YYYY-MM-DD'),
        );
      }
      dataForm.append(
        'pco_expire',
        dayjs(form.getFieldValue('pco_expire')).format('YYYY-MM-DD'),
      );
      dataForm.append(
        'last_service_date',
        dayjs(form.getFieldValue('last_service_date')).format('YYYY-MM-DD'),
      );
      if (form.getFieldValue(['mot', 'mot_test'])?.file?.originFileObj) {
        dataForm.append(
          'mot_test',
          form.getFieldValue(['mot', 'mot_test'])?.file?.originFileObj,
        );
      }
      if (addExp) {
        addExp.forEach((el, index) => {
          dataForm.append(`carexpense[${index}]cost`, el.cost);
          dataForm.append(
            `carexpense[${index}]date`,
            dayjs.utc(el.date).format(),
          );
          dataForm.append(`carexpense[${index}]description`, el.description);
          dataForm.append(`carexpense[${index}]expense_type`, el.expense_type);
          dataForm.append(
            `carexpense[${index}]service_providor`,
            el.service_providor,
          );
          dataForm.append(`carexpense[${index}]vat_amount`, el.vat_amount);
          dataForm.append(
            `carexpense[${index}]total_excluded_vat`,
            el.total_excluded_vat,
          );
        });
      }
      if (!form.getFieldValue('financeFlag')) {
        dataForm.append('carfinance', null);
      } else {
        dataForm.append(
          'carfinance.purchase_date',
          dayjs(form.getFieldValue('carfinance.purchase_date')).format(
            'YYYY-MM-DD',
          ),
        );
        dataForm.append(
          'carfinance.direct_debit_day',
          dayjs(form.getFieldValue('carfinance.direct_debit_day')).format('DD'),
        );
        dataForm.append(
          'carfinance.term',
          form.getFieldValue('carfinance.term'),
        );
        dataForm.append(
          'carfinance.total_amount',
          form.getFieldValue('carfinance.total_amount'),
        );
        dataForm.append(
          'carfinance.monthly_amount',
          form.getFieldValue('carfinance.monthly_amount'),
        );
        dataForm.append(
          'carfinance.paid_amount',
          form.getFieldValue('carfinance.paid_amount'),
        );
      }
      axios
        .post(`/cars/car/`, dataForm)
        .then(async ({ data: newCarData }) => {
          if (damageSketchData)
            await updateDamageSketch(newCarData.damagessketch, {
              ...damageSketchData,
              car: newCarData.id,
            });

          queryClient.invalidateQueries('cars');
          setLoading(false);
          message.success('New vehicle added to car bank');
          setIsModalVisible(false);
          form.resetFields();
        })
        .catch(() => setLoading(false));
    });
  };

  const tabClick = (key) => {
    history.push(`car-bank?category=${key}`);
  };

  useEffect(() => {
    forceUpdate({});
  }, []);

  useEffect(() => {
    setIsModalVisible(false);
  }, [location]);

  return (
    <>
      <Tabs
        tabBarExtraContent={
          <Button onClick={showModal} className="secondary-btn">
            Add a new car
          </Button>
        }
        type="card"
        className="tables-tab-bar"
        activeKey={page}
        onTabClick={tabClick}
        animated
      >
        <TabPane tab="All" key="all">
          <CarsTable carsCategory="all" />
        </TabPane>
        <TabPane tab="Assigned" key="assigned">
          <CarsTable carsCategory="assigned" />
        </TabPane>
        <TabPane tab="Available" key="free">
          <CarsTable carsCategory="free" />
        </TabPane>
        <TabPane tab="Archived" key="archive">
          <CarsTable carsCategory="archive" />
        </TabPane>
      </Tabs>
      <CustomizedModal
        width={800}
        visible={isModalVisible}
        onCancel={car ? handleCancel : onConfirm}
        afterClose={() => {
          setCar(false);
          form.resetFields();
        }}
        footer={false}
      >
        <Heading
          title="Add a new car"
          subTitle="Please fill all the fields below to add the car to the car bank"
        />
        <Form
          className="inline-container reset-error-form"
          form={form}
          size="large"
          style={{ justifyContent: 'space-between' }}
          onFinish={AddNewCar}
          onValuesChange={(_, values) => setCar(values)}
        >
          <FormItems
            setAddExp={setAddExp}
            mode="add"
            car={car}
            saveDamageSketchData={(payload) => setDamageSketchData(payload)}
            form={form}
          />
          <Form.Item
            shouldUpdate
            style={{
              marginTop: '40px',
            }}
            className="full-width form-item space-bottom"
          >
            <div className="btn-off-on">
              <Button
                type="primary"
                className="footer-cta-btn"
                size="large"
                onClick={form.submit}
                loading={loading}
              >
                Add to car bank
              </Button>
            </div>
          </Form.Item>
        </Form>
      </CustomizedModal>
    </>
  );
}
