import { Button, Tabs, Form, Modal } from 'antd';
import { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import TableCarReturn from './Table';
import { CustomizedModal } from '../../components';
import ItemsModalCarReturn from './ItemsModal';
import { axios } from '../../utils';

const { TabPane } = Tabs;

export function CarReturn() {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [isVisible, setVisible] = useState(false);
  const [isChangeing, setIsChangeing] = useState();
  const [isLoadingAddCarReturn, setIsLoadingAddCarReturn] = useState(false);

  const tabClick = (key) => history.push(`/car-return?category=${key}`);

  const category =
    useMemo(() => qs.parse(location.search)?.category, [location.search]) ||
    'new';

  const onConfirmExit = () => setVisible(false);

  const onCancel = () => {
    Modal.confirm({
      title: 'Exit Confirmation',
      content:
        'You are leaving this process. All your unsaved data will be lost. Do you wish to proceed?',
      onOk: onConfirmExit,
      okText: 'Leave',
      cancelText: 'Stay',
      okType: 'danger',
      closable: true,
      maskClosable: true,
      closeIcon: true,
    });
  };

  const registerAppointment = () => {
    setIsLoadingAddCarReturn(false);
    const fd = new FormData();
    fd.append(
      'appointment_date',
      dayjs(form.getFieldValue('appointment_date')).format(
        'YYYY-MM-DD HH:mm:00',
      ),
    );
    fd.append('reason', form.getFieldValue('reason'));
    fd.append(
      'driver',
      form.getFieldValue(['car_details', 'registrationnumber']).split('#')[2],
    );
    fd.append('account_number', form.getFieldValue('account_number'));
    fd.append('sort_code', form.getFieldValue('sort_code'));
    fd.append(
      'name_of_the_account_holder',
      form.getFieldValue('name_of_the_account_holder'),
    );
    fd.append('bank_name', form.getFieldValue('bank_name'));
    if (form.getFieldValue('bank_statement_picture')?.file?.originFileObj) {
      fd.append(
        'bank_statement_picture',
        form.getFieldValue('bank_statement_picture')?.file?.originFileObj,
      );
    }
    axios
      .post('/returncar/panel/returncar-list/', fd)
      .then(() => {
        setVisible(false);
        queryClient.invalidateQueries('getCarReturnList');
      })
      .finally(() => setIsLoadingAddCarReturn(false));
  };

  return (
    <>
      <Tabs
        tabBarExtraContent={
          <Button
            className="secondary-btn"
            onClick={() => {
              setVisible(true);
            }}
          >
            Register a new car return appointment
          </Button>
        }
        activeKey={category}
        onTabClick={tabClick}
        type="card"
        className="tables-tab-bar"
        animated
        destroyInactiveTabPane
      >
        <TabPane tab="New requests" key="new">
          <TableCarReturn />
        </TabPane>
        <TabPane tab="Request history" key="history">
          <TableCarReturn isHistory />
        </TabPane>
      </Tabs>
      <CustomizedModal
        visible={isVisible}
        className="book-appointment"
        onCancel={isChangeing ? onCancel : onConfirmExit}
        afterClose={() => {
          setIsChangeing(false);
          form.resetFields();
        }}
        footer={
          <>
            <Button
              type="primary"
              onClick={form.submit}
              className="footer-cta-btn"
              loading={isLoadingAddCarReturn}
            >
              Register the appointment
            </Button>
          </>
        }
      >
        <Form
          form={form}
          className="inline-container"
          style={{ justifyContent: 'space-between' }}
          onFinish={registerAppointment}
          onValuesChange={(val) => {
            setIsChangeing(!!val);
          }}
        >
          <ItemsModalCarReturn
            titleModal="New return appointment"
            modeModal="add"
            form={form}
          />
        </Form>
      </CustomizedModal>
    </>
  );
}
