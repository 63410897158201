import {
  Button,
  Col,
  Form,
  Input,
  message,
  Row,
  Select,
  Table,
  Tabs,
} from 'antd';
import { useEffect, useMemo, useState } from 'react';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useMutation, useQueryClient } from 'react-query';
import cn from 'classnames';
import {
  CustomizedModal,
  FloatLabel,
  getSearchFilterProps,
  getSelectFilterProps,
} from '../../components';
import { useAdminList, useTasks } from '../../hooks';
import { axios } from '../../utils';

const { TabPane } = Tabs;

export function TaskBoard() {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [isModalVisible, setModalVisible] = useState(false);

  const page =
    useMemo(() => qs.parse(location.search)?.type, [location.search]) || 'open';

  const taskID = useMemo(
    () => qs.parse(location.search)?.id,
    [location.search],
  );

  const tabClick = (key) => history.push(`/task-board?type=${key}`);

  const { data: adminList, isLoading } = useAdminList();

  const filterAdminList = adminList?.filter(
    (admin) => admin?.is_staff || admin?.is_supervisor,
  );

  const priorityOptions = [
    { label: 'Low', value: 1 },
    { label: 'Medium', value: 2 },
    { label: 'High', value: 3 },
  ];

  const rules = [{ required: true }];

  const { mutate, isLoading: addLoading } = useMutation((data) =>
    axios.post('/tasks/', data).then(() => {
      setModalVisible(false);
      queryClient.invalidateQueries('getTasks');
      message.success('Task added successfully');
    }),
  );

  const addTask = () => {
    form.validateFields().then((dataValid) => {
      mutate({ ...dataValid, assignees: [dataValid.assignees] });
    });
  };

  useEffect(() => {
    if (taskID) {
      axios(`/tasks/${taskID}/`).then(({ data }) => {
        form.setFieldsValue({
          ...data,
          assignees: `${data.assignees[0]?.assignees || ''} ${
            data.assignees[0]?.last_name || ''
          }`,
        });
      });
    }
  }, []);

  const closeTask = async (id, e) => {
    e.stopPropagation();
    await axios.patch(`/tasks/${id}/`, { closed: true });
    queryClient.invalidateQueries('getTasks');
    message.success('Task closed');
  };
  const priority = [
    { label: 'Low', value: 1 },
    { label: 'Medium', value: 2 },
    { label: 'High', value: 3 },
  ];

  const openTask = async (id, e) => {
    e.stopPropagation();
    await axios.patch(`/tasks/${id}/`, { closed: false });
    queryClient.invalidateQueries('getTasks');
    message.success('Task opened');
  };

  const columns = [
    {
      title: 'Task description',
      dataIndex: 'description',
      key: 'description',
      ...getSearchFilterProps('description'),
    },
    {
      title: 'Assigned to',
      dataIndex: ['assignees', '0', 'email'],
      key: 'email',
      ...getSearchFilterProps('email'),
    },
    {
      title: 'Priority',
      dataIndex: 'priority',
      ...getSelectFilterProps('state', priority, ({ label, value }) => (
        <Select.Option value={value}>{label}</Select.Option>
      )),
      render: (item) => (
        <span
          className={cn({
            danger: item === 'High',
            warning: item === 'Normal',
          })}
        >
          {item}
        </span>
      ),
    },
    {
      render: ({ id }) =>
        page === 'open' ? (
          <Button onClick={(e) => closeTask(id, e)}>Close</Button>
        ) : (
          <Button onClick={(e) => openTask(id, e)}>Open</Button>
        ),
      width: 1,
    },
  ];

  const handelTableChange = (pagination, filters, sorter) => {
    const { current: pageCu, pageSize: size } = pagination;
    const params = { pageCu, size };
    if (sorter.column) {
      Object.assign(params, {
        [`${sorter.columnKey}`]: sorter.order === 'descend' ? 'desc' : 'asc',
      });
    }
    Object.entries(filters).forEach(([key, value]) => {
      if (value?.length > 0) {
        let paramKey;
        switch (key) {
          case 'description':
            paramKey = 'description';
            break;
          case 'email':
            paramKey = 'email';
            break;
          case 'priority':
            paramKey = 'priority';
            break;
          default:
            paramKey = key;
            break;
        }
        Object.assign(params, { [paramKey]: value[0] });
      }
    });
    history.replace({
      search: qs.stringify({
        ...qs.parse(history.location.search),
        description: params.description,
        email: params.email,
        priority: params.priority,
      }),
    });
  };
  const query = qs.parse(location.search);
  delete query.type;
  delete query.id;

  const {
    data: tasksData,
    isLoading: tasksIsLoading,
    isFetching: taskIsFetching,
  } = useTasks(
    qs.stringifyUrl({ url: '/tasks/', query: { ...query, closed: 'false' } }),
  );

  const {
    data: tasksCloseData,
    isLoading: closeTasksIsLoading,
    isFetching: closeTaskIsFetching,
  } = useTasks(
    qs.stringifyUrl({ url: '/tasks/', query: { ...query, closed: 'true' } }),
  );

  useEffect(() => {
    if (qs.parse(location.search)?.id) {
      setModalVisible(true);
    } else {
      setModalVisible(false);
    }
  }, [location]);

  const deleteTask = async () => {
    await axios.delete(`/tasks/${taskID}/`);
    setModalVisible(false);
    queryClient.invalidateQueries('getTasks');
    message.success('Task deleted');
  };

  const editTask = async () => {
    const data = await form.validateFields();
    if (typeof data.priority !== 'number') delete data.priority;
    if (typeof data.assignees !== 'number') delete data.assignees;
    else data.assignees = [data.assignees];
    try {
      await axios.patch(`/tasks/${taskID}/`, data);
      queryClient.invalidateQueries('getTasks');
      message.success('Task updated');
    } catch (error) {
      message.error(error?.message);
    }
  };

  return (
    <>
      <Tabs
        tabBarExtraContent={
          <Button
            onClick={() => setModalVisible(true)}
            className="secondary-btn"
          >
            Create new task
          </Button>
        }
        activeKey={page}
        onTabClick={tabClick}
        type="card"
        className="tables-tab-bar"
        animated
        destroyInactiveTabPane
      >
        <TabPane tab="Open" key="open">
          <Table
            loading={tasksIsLoading || taskIsFetching}
            dataSource={tasksData}
            columns={columns}
            pagination={false}
            rowKey="id"
            onChange={handelTableChange}
            onRow={(record) => ({
              onClick: () => {
                history.push(
                  qs.stringifyUrl({
                    url: `/task-board/`,
                    query: { ...qs.parse(location.search), id: record.id },
                  }),
                );
                form.setFieldsValue({
                  ...record,
                  assignees: `${record.assignees[0]?.assignees || ''} ${
                    record.assignees[0]?.last_name || ''
                  }`,
                });
              },
            })}
          />
        </TabPane>
        <TabPane tab="Closed" key="close">
          <Table
            loading={closeTasksIsLoading || closeTaskIsFetching}
            dataSource={tasksCloseData}
            columns={columns}
            pagination={false}
            onChange={handelTableChange}
            onRow={(record) => ({
              onClick: () => {
                history.push(
                  qs.stringifyUrl({
                    url: `/task-board/`,
                    query: { ...qs.parse(location.search), id: record.id },
                  }),
                );
                form.setFieldsValue({
                  ...record,
                  assignees: `${record.assignees[0]?.assignees || ''} ${
                    record.assignees[0]?.last_name || ''
                  }`,
                });
              },
            })}
          />
        </TabPane>
      </Tabs>
      <CustomizedModal
        visible={isModalVisible}
        onCancel={() => setModalVisible(false)}
        afterClose={() => {
          form.resetFields();
          history.push({
            search: qs.exclude(location.search, ['id']),
          });
        }}
        centered
        title={taskID ? 'Task edit' : 'Create new task'}
        footer={
          <>
            {taskID ? (
              <Row justify="space-between">
                <Col span={12}>
                  <Button
                    type="text"
                    onClick={deleteTask}
                    className="footer-cta-btn btn-denger"
                    size="large"
                    // loading={addLoading}
                  >
                    Delete this task
                  </Button>
                </Col>
                <Col span={12}>
                  <Button
                    type="primary"
                    onClick={editTask}
                    className="footer-cta-btn"
                    size="large"
                    // loading={addLoading}
                  >
                    Apply changes
                  </Button>
                </Col>
              </Row>
            ) : (
              <Button
                type="primary"
                onClick={addTask}
                className="footer-cta-btn"
                size="large"
                loading={addLoading}
              >
                Add to task list
              </Button>
            )}
          </>
        }
      >
        <Form form={form}>
          <Form.Item rules={rules} name="description">
            <FloatLabel label="Task description">
              <Input />
            </FloatLabel>
          </Form.Item>
          <Row gutter={24}>
            <Col span={12}>
              <Form.Item rules={rules} name="assignees">
                <FloatLabel label="Assign to">
                  <Select
                    loading={isLoading}
                    options={filterAdminList?.map((admin) => ({
                      value: admin.id,
                      label: `${admin.first_name} ${admin.last_name}`,
                    }))}
                  />
                </FloatLabel>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item rules={rules} name="priority">
                <FloatLabel label="Task priority">
                  <Select
                    options={priorityOptions.map(({ label, value }) => ({
                      value,
                      label,
                    }))}
                  />
                </FloatLabel>
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </CustomizedModal>
    </>
  );
}
