import { Button, Table, Tabs } from 'antd';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';
import { useMemo } from 'react';
import dayjs from 'dayjs';
import { useMutation } from 'react-query';
import { getSearchFilterProps, InfiniteScrollWrapper } from '../../components';
import { useMileageList } from '../../hooks/mileage';
import DistanceReport from './DistanceReport';
import { axios } from '../../utils';

const { TabPane } = Tabs;
export function Mileage() {
  const history = useHistory();
  const location = useLocation();

  const category =
    useMemo(() => qs.parse(location.search)?.type, [location.search]) || 'all';

  const queryParams = useMemo(() => {
    const params = qs.parse(location.search);
    return params;
  }, [location.search]);

  const tabClick = (key) => history.push(`mileage?type=${key}`);

  const { data, hasNextPage, fetchNextPage, isLoading, isFetching } =
    useMileageList({ paramers: qs.parse(location.search) });

  const getSortOrder = (parameter) => {
    if (queryParams[parameter] === 'des') return 'descend';
    if (queryParams[parameter] === 'asc') return 'ascend';
    return null;
  };

  const handelChangeTable = (pagination, filters, sorter) => {
    const { current: page, pageSize: size } = pagination;
    const params = { page, size };
    if (sorter.column) {
      Object.assign(params, {
        [`sort_${sorter.columnKey}`]:
          sorter.order === 'descend' ? 'des' : 'asc',
      });
    }
    Object.entries(filters).forEach(([key, value]) => {
      if (value?.length > 0) {
        let paramKey;
        switch (key) {
          case 'rg_number':
            paramKey = 'rg_number';
            break;
          case 'driver_name':
            paramKey = 'driver';
            break;
          default:
            paramKey = key;
            break;
        }
        Object.assign(params, { [paramKey]: value[0] });
      }
    });
    history.replace({
      search: qs.stringify({
        ...qs.parse(history.location.search),
        driver_name: params.driver,
        rg_number: params.rg_number,
        current_mileage: params.sort_current_mileage,
        monthly_usage: params.sort_monthly_usage,
        extra_miles: params.sort_extra_miles,
        extra_charges: params.sort_extra_charges,
        odometer: params.sort_odometer,
      }),
    });
  };

  const columns = [
    {
      title: "Driver's name",
      dataIndex: 'driver',
      key: 'driver_name',
      ...getSearchFilterProps('Driver Name'),
      render: (val) => `${val?.first_name} ${val?.last_name}`,
    },
    {
      title: 'Car',
      dataIndex: 'car',
      key: 'rg_number',
      ...getSearchFilterProps('Car'),
      render: ({ company, model, registrationnumber }) => (
        <>
          {company} {model}
          <br />
          {`(${registrationnumber})`}
        </>
      ),
    },
    {
      title: 'Current Odometer',
      dataIndex: ['car', 'currentOdometer'],
      key: 'odometer',
      sorter: true,
      sortOrder: getSortOrder('odometer'),
      render: (val) => <>{`${val} Miles`}</>,
    },
    {
      title: "Driver's total travel",
      dataIndex: ['driver', 'drived_miles'],
      key: 'current_mileage',
      sorter: true,
      sortOrder: getSortOrder('current_mileage'),
      render: (val) => <>{`${val} Miles`}</>,
    },
    {
      title: 'Miles traveled during last period',
      dataIndex: ['driver', 'last_month_drived_miles'],
      key: 'last_month_drived_miles',
      render: (val) => <>{`${val} Miles`}</>,
    },
    {
      title: 'Miles traveled during current period',
      dataIndex: ['driver', 'monthly_usage'],
      key: 'monthly_usage',
      sorter: true,
      sortOrder: getSortOrder('monthly_usage'),
      render: (val, dataMileage) => (
        <>
          <p
            style={{
              color:
                (val >= 1600 && '#da4242') ||
                (val >= 800 && val <= 1600 && '#fdbd39'),
            }}
          >{`${parseFloat(val).toFixed(2)} Miles`}</p>
          <p style={{ color: '#91a2ab', fontSize: 12 }}>
            Resets at{' '}
            {dayjs
              .unix(dataMileage.driver.next_monthly_mileage_check)
              .format('DD/MM')}
          </p>
        </>
      ),
    },
    {
      title: 'Extra Miles',
      dataIndex: ['driver', 'extra_miles'],
      key: 'extra_miles',
      sorter: true,
      sortOrder: getSortOrder('extra_miles'),
      render: (val) => <>{`${val} Miles`}</>,
    },
    {
      title: 'Extra charge',
      dataIndex: ['driver', 'extra_charges'],
      key: 'extra_charges',
      sorter: true,
      sortOrder: getSortOrder('extra_charges'),
      render: (val) => <>{`£ ${val}`}</>,
    },
  ];

  const { isLoading: isLoadingUpdateCars, mutate: updateCars } = useMutation(
    () => axios.get('/mileage/vehicle-id/'),
  );

  return (
    <>
      <Tabs
        type="card"
        className="tables-tab-bar"
        activeKey={category}
        onTabClick={tabClick}
        animated
        destroyInactiveTabPane
        tabBarExtraContent={
          <Button
            loading={isLoadingUpdateCars}
            onClick={updateCars}
            className="secondary-btn"
          >
            Sync Vehicle ID
          </Button>
        }
      >
        <TabPane tab="Current two weeks period" key="all">
          <InfiniteScrollWrapper
            lengthData={data?.pages[0].count}
            hasMore={hasNextPage}
            functionNext={fetchNextPage}
            scrollableTarget="modal-with-infinite-scroll-table"
          >
            <Table
              loading={isLoading || isFetching}
              dataSource={data?.pages.reduce((res, cur) => {
                if (cur?.result) {
                  res.push(...cur?.result);
                } else res.push(...cur);
                return res;
              }, [])}
              columns={columns}
              pagination={false}
              onChange={handelChangeTable}
              scroll={{
                x: 1024,
              }}
            />
          </InfiniteScrollWrapper>
        </TabPane>
        <TabPane tab="Distance report" key="distance">
          <DistanceReport />
        </TabPane>
      </Tabs>
    </>
  );
}
