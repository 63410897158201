import { BrowserRouter as Router, Switch } from 'react-router-dom';
import GuestRouter from '../guest-router';
import UserRouter from '../admin-router';
import MOTRouter from '../mot-router';
import ServiceRouter from '../service-router';
import {
  MOTRoute,
  PrivateRoute,
  PublicRoute,
  ServiceRoute,
} from '../ProtectedRoutes';

export default function MainRouter() {
  return (
    <Router>
      <Switch>
        <PublicRoute path="/auth">
          <GuestRouter />
        </PublicRoute>
        <MOTRoute path="/mot">
          <MOTRouter />
        </MOTRoute>
        <ServiceRoute path="/service">
          <ServiceRouter />
        </ServiceRoute>
        <PrivateRoute path="/">
          <UserRouter />
        </PrivateRoute>
      </Switch>
    </Router>
  );
}
