/* eslint-disable camelcase */
import { Button, Form, message, Spin } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { useHistory } from 'react-router';
import { isEqual } from 'lodash';
import { useQueryClient } from 'react-query';
import {
  Heading,
  GoBackBtn,
  CustomizedModal,
  CustomizedStepper,
  PersonalInfoSection,
  LicenseInfoSection,
  DrivingAndPCOLicenseSection,
  InsuranceNumberSection,
  AssignCarTable,
} from '../../components';
import axios from '../../utils/axios';

import { assignCarToDriver } from '../../api';

import './styles.scss';
import { useCar } from '../../hooks';
import { confirmExit } from '../../utils';
import { useWindowWidth } from '../../hooks/useWindowWidth';

const fields = [
  [
    'profileimage',
    'first_name',
    'last_name',
    'birth_date',
    'phone_number',
    'address',
    'postal_number',
    'email',
  ],
  [
    'years_pco_license',
    'years_driving_license',
    'accident_number',
    'negative_drivinglicense_point',
  ],
  [
    'driving_license_front_image',
    'driving_license_number',
    'driving_license_back_image',
    'driving_license_expiration_date',
    'pco_license_image',
    'pco_license_number',
    'pco_license_expiration_date',
  ],
  ['national_insurance_number'],
  ['car'],
];

export function RegisterNewDriverModal({ isVisible, setVisible }) {
  const [form] = Form.useForm();
  const windowWidth = useWindowWidth();
  const queryClient = useQueryClient();

  const [current, setCurrent] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedCarID, setSelectedCarID] = useState(undefined);
  const [formHasChanged, setFormHasChanged] = useState(false);

  const { data: selectedCar, isLoading: isLoadingCarData } =
    useCar(selectedCarID);

  useEffect(() => {
    form.setFieldsValue({
      assigncar: selectedCar,
    });
  }, [selectedCar]);

  const onConfirmClose = () => {
    setVisible(false);
    setCurrent(0);
    form.resetFields();
  };

  const validateCurrentStep = (successCallback) => {
    form
      .validateFields(fields[current])
      .then(successCallback)
      .catch(() =>
        message.error(
          'Please fill all the fields in order to continue to the next step',
        ),
      );
  };

  const next = () => validateCurrentStep(() => setCurrent(current + 1));

  const prev = () => setCurrent(current - 1);

  const history = useHistory();

  const finish = () => {
    if (current === 4) setSelectedCarID(form.getFieldValue('car'));
    setIsSubmitting(true);
    const fd = new FormData();
    const data = form.getFieldsValue(true);
    // Step 1, 2
    const profileImage = data.profileimage?.file?.originFileObj || null;
    if (profileImage) fd.append('user.profileimage', profileImage);
    fd.append('user.first_name', data.first_name);
    fd.append('user.last_name', data.last_name);
    fd.append('user.birth_date', data.birth_date.format('YYYY-MM-DD'));
    fd.append('user.phone_number', data.phone_number);
    fd.append('user.address', data.address);
    fd.append('user.postal_number', data.postal_number);
    fd.append('user.email', data.email);
    fd.append('user.years_pco_license', data.years_pco_license);
    fd.append('user.years_driving_license', data.years_driving_license);
    fd.append('user.accident_number', data.accident_number);
    fd.append(
      'user.negative_drivinglicense_point',
      data.negative_drivinglicense_point,
    );
    // Step 3
    fd.append(
      'driving_license_front_image',
      data.driving_license_front_image?.file?.originFileObj,
    );
    fd.append(
      'driving_license_back_image',
      data.driving_license_back_image?.file?.originFileObj,
    );
    fd.append('pco_license_image', data.pco_license_image?.file?.originFileObj);
    fd.append('user.driving_license_number', data.driving_license_number);
    fd.append(
      'user.driving_license_expiration_date',
      data.driving_license_expiration_date.format('YYYY-MM-DD'),
    );
    fd.append('user.issuedate', data.issuedate.format('YYYY-MM-DD'));
    fd.append('user.pco_license_number', data.pco_license_number);
    fd.append(
      'user.pco_license_expiration_date',
      data.pco_license_expiration_date.format('YYYY-MM-DD'),
    );
    // Step 4
    fd.append('national_insurance_number', data.national_insurance_number);
    axios
      .post('registers/registeringpanel/', fd)
      .then(({ data: newDriverData }) => {
        // Step 5
        assignCarToDriver({ user: newDriverData.user.id, car: data.car })
          .then(() => {
            queryClient.invalidateQueries('drivers');
            setVisible(false);
            setCurrent(0);
            form.resetFields();
            history.push(
              `/drivers?category=new&driver_id=${newDriverData.user.id}`,
            );
          })
          .catch(() =>
            axios.delete(`/account/users/${newDriverData.user.id}/`),
          );
      })
      .finally(() => setIsSubmitting(false));
  };

  const steps = [
    {
      title: 'Personal info',
      content: <PersonalInfoSection form={form} />,
    },
    {
      title: 'License info',
      content: <LicenseInfoSection />,
    },
    {
      title: 'Driving and PCO license',
      content: <DrivingAndPCOLicenseSection />,
    },
    {
      title: 'Insurance number',
      content: <InsuranceNumberSection />,
    },
    {
      title: 'Assign car',
      content: <AssignCarTable form={form} />,
    },
  ];

  const checkFormChanged = useCallback(() => {
    setFormHasChanged(!isEqual(form.getFieldsValue(), undefined));
  }, [form]);

  const handleCloseModal = () => {
    confirmExit(onConfirmClose, formHasChanged);
  };

  return (
    <CustomizedModal
      visible={isVisible}
      onCancel={handleCloseModal}
      afterClose={() => {
        setFormHasChanged(false);
        setIsSubmitting(false);
        setSelectedCarID(null);
      }}
      wrapProps={{
        id: 'modal-with-infinite-scroll-table',
      }}
      title={
        <GoBackBtn onClick={prev} className={cn({ hidden: current < 1 })} />
      }
      footer={
        <>
          <Button
            type="primary"
            onClick={current === steps.length - 1 ? finish : next}
            className="footer-cta-btn"
            size="large"
            loading={isSubmitting}
          >
            {current === steps.length - 1
              ? 'Finish sign up process'
              : 'Next step'}
          </Button>
        </>
      }
    >
      <Heading
        title="Sign up new driver"
        subTitle={
          current === 4
            ? 'Select the car that you want to assign to the driver'
            : 'Please fill all the fields below to go to the next step'
        }
        element="h3"
      />
      <CustomizedStepper
        current={current}
        steps={steps}
        direction={windowWidth > 700 ? 'horizontal' : 'vertical'}
      />
      <Spin spinning={current === 5 && isLoadingCarData}>
        <div className="steps-content">
          <Form
            className="form"
            form={form}
            size="large"
            colon={false}
            requiredMark={false}
            name="register-new-driver-form"
            onFieldsChange={checkFormChanged}
          >
            {steps[current].content}
          </Form>
        </div>
      </Spin>
    </CustomizedModal>
  );
}
