import React, { useMemo, useState } from 'react';
import {
  Table,
  Input,
  Popconfirm,
  Form,
  Typography,
  Button,
  Divider,
  Modal,
} from 'antd';
import qs from 'query-string';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { axios } from '../../utils';
import { FloatLabel, Icon } from '../../components';

const isRequiredRule = [
  {
    required: true,
    message: 'This field is required',
  },
];

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please enter ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default function Faq({ data }) {
  const [form] = Form.useForm();
  const [formAdd] = Form.useForm();
  const location = useLocation();
  const queryClient = useQueryClient();

  const carId = useMemo(
    () => qs.parse(location.search).car_id,
    [location.search],
  );

  const [editingKey, setEditingKey] = useState('');
  const [loadingAdd, setLoadingAdd] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingUpdate, setLoadingUpdate] = useState(false);

  const isEditing = (record) => record.id === editingKey;

  const edit = (record) => {
    form.setFieldsValue({
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => setEditingKey('');

  const saveForm = async (id) => {
    form.validateFields().then(() => {
      setLoadingUpdate(true);
      axios
        .put(`/cars/faqs/${id}/`, form.getFieldsValue())
        .then(() => {
          queryClient.invalidateQueries('car');
          setEditingKey('');
        })
        .finally(() => setLoadingUpdate(false));
    });
  };

  const deleteAdmin = (id) => {
    setLoadingDelete(true);
    axios
      .delete(`/cars/faqs/${id}/`)
      .then(() => {
        queryClient.invalidateQueries('car');
        setEditingKey('');
      })
      .finally(() => setLoadingDelete(false));
  };

  const handleDeleteAdmin = (id) => {
    Modal.confirm({
      title: 'Delete account',
      content: 'Are you sure you want to delete this record?',
      onOk: () => deleteAdmin(id),
      okText: 'Yes',
      cancelText: 'Cancel',
      okType: 'danger',
      closable: true,
      closeIcon: true,
      maskClosable: false,
      centered: true,
    });
  };

  const columns = [
    {
      title: 'Category',
      dataIndex: 'category',
      editable: true,
    },
    {
      title: 'Question',
      dataIndex: 'questin',
      editable: true,
    },
    {
      title: 'Message',
      dataIndex: 'message',
      editable: true,
    },
    {
      title: 'Tags',
      dataIndex: 'tags',
      editable: true,
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => saveForm(record.id)}
              style={{
                marginRight: 16,
              }}
            >
              <CheckOutlined style={{ fontSize: '20px' }} />
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <CloseOutlined style={{ fontSize: '20px', color: '#da4242' }} />
            </Popconfirm>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
              style={{
                marginRight: 8,
              }}
            >
              <Icon variant="editAdmin" width={24} height={24} />
            </Typography.Link>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => handleDeleteAdmin(record.id)}
            >
              <Icon variant="trash" width={24} height={24} />
            </Typography.Link>
          </>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex,
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const addAdmin = () => {
    formAdd.validateFields().then(() => {
      setLoadingAdd(true);
      axios
        .post('/cars/faqs/', {
          ...formAdd.getFieldsValue(),
          car: carId,
        })
        .then(() => {
          formAdd.resetFields();
          queryClient.invalidateQueries('car');
        })
        .finally(() => setLoadingAdd(false));
    });
  };

  return (
    <div className="box">
      <Divider className="color-text" orientation="left">
        FAQs
      </Divider>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          scroll={{ x: 900 }}
          dataSource={data}
          columns={mergedColumns}
          className="admin-table"
          pagination={false}
          loading={loadingAdd || loadingDelete || loadingUpdate}
        />
      </Form>
      <Form form={formAdd}>
        <div
          style={{
            marginTop: 40,
          }}
        >
          <Form.Item rules={isRequiredRule} name="category">
            <FloatLabel label="Category">
              <Input />
            </FloatLabel>
          </Form.Item>
          <Form.Item rules={isRequiredRule} name="questin">
            <FloatLabel label="Question">
              <Input />
            </FloatLabel>
          </Form.Item>
          <Form.Item rules={isRequiredRule} name="message">
            <FloatLabel label="Message">
              <Input />
            </FloatLabel>
          </Form.Item>
          <Form.Item rules={isRequiredRule} name="tags">
            <FloatLabel label="Tags">
              <Input />
            </FloatLabel>
          </Form.Item>
        </div>
        <Button
          type="dashed"
          block
          className="full-width"
          style={{ margin: '0 0 20px 0' }}
          icon={<PlusOutlined />}
          onClick={addAdmin}
          loading={loadingAdd}
        >
          Add a new record
        </Button>
      </Form>
    </div>
  );
}
