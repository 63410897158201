import React, { useMemo, useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { AuthLayout } from '../../layout';
import axios from '../../utils/axios';
import { Heading } from '../../components';

export function ConfirmPassword() {
  const [form] = Form.useForm();
  const history = useHistory();
  const { uid, token } = useParams();

  const [isSubmitting, setSubmitting] = useState(false);
  const [passIsVisible, setPassVisible] = useState(false);

  const togglePassVisibility = () => setPassVisible((prevState) => !prevState);

  const handleResetPassword = () => {
    form.validateFields().then(({ password }) => {
      const payload = {
        password,
        uid,
        token,
      };
      setSubmitting(true);
      axios
        .post('account/confirm-password/', payload)
        .then(({ data }) => {
          message.success(data.message);
          history.push('/auth/login');
        })
        .finally(() => setSubmitting(false));
    });
  };

  const passVisibilityIconRenderer = useMemo(
    () => (
      <button
        type="button"
        className="password-visibility-btn"
        onClick={togglePassVisibility}
      >
        {passIsVisible ? <EyeOutlined /> : <EyeInvisibleOutlined />}
      </button>
    ),
    [passIsVisible],
  );

  return (
    <AuthLayout>
      <Form form={form} onFinish={handleResetPassword}>
        <Heading
          title="Reset password"
          subTitle="Please enter and confirm your new password"
          className="auth-layout-heading"
        />
        <Form.Item
          name="password"
          rules={[
            { required: true, message: 'Please enter your new password' },
          ]}
          hasFeedback
        >
          <Input
            placeholder="New Password"
            type={passIsVisible ? 'text' : 'password'}
            suffix={passVisibilityIconRenderer}
          />
        </Form.Item>
        <Form.Item
          name="confirm"
          dependencies={['password']}
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Please confirm your password',
            },
            ({ getFieldValue }) => ({
              validator(_, value) {
                if (!value || getFieldValue('password') === value) {
                  return Promise.resolve();
                }
                return Promise.reject(
                  new Error('Password does not match the confirm password'),
                );
              },
            }),
          ]}
        >
          <Input
            placeholder="New Password Confirm"
            type={passIsVisible ? 'text' : 'password'}
            suffix={passVisibilityIconRenderer}
          />
        </Form.Item>
        <Button
          type="primary"
          htmlType="submit"
          block
          className="cta-btn"
          loading={isSubmitting}
        >
          Confirm
        </Button>
      </Form>
    </AuthLayout>
  );
}
