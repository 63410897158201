import { Route, Switch } from 'react-router';
import { ScheduleTable } from '../../components';
import { UserLayout } from '../../layout';
import { NotFound, Reports } from '../../pages';
import Notif from '../../pages/notifications/Notif';

export default function MOTRouter() {
  return (
    <UserLayout>
      <Switch>
        <Route path="/mot/mot-test">
          <ScheduleTable url="/mot/check-time/" routeOpenMpdal="/mot/reports" />
        </Route>
        <Route path="/mot/reports">
          <Reports />
        </Route>
        <Route path="/mot/notifications">
          <Notif
            url="/mot/motpanel-notification/"
            unSeenReqURL="/mot/unseen-notification-mot/"
            seenReqURL="/mot/seen-notification-mot/"
            notifCountURL="/mot/count-motnotification/"
          />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </UserLayout>
  );
}
