/* eslint-disable no-param-reassign */
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  InputNumber,
  message,
  Row,
  Spin,
  Table,
} from 'antd';
import { useEffect, useMemo, useState } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import dayjs from 'dayjs';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { FloatLabel, Icon, CustomizedModal } from '../../components';
import { CustomDatePicker } from '../../components/custom-date-picker';
import { axios } from '../../utils';
import { useComplex, useInformation, useComplexId } from '../../hooks';

const isRequiredRule = [
  {
    required: true,
    message: 'This field is required',
  },
];

export default function SetLocation({ isStaff }) {
  const [form] = Form.useForm();
  const [formComplex] = Form.useForm();
  const history = useHistory();
  const locationReactDom = useLocation();
  const [loading, setLoading] = useState(false);
  const [location, setLocation] = useState({
    lat: 51.53187190831481,
    lng: -0.298037836048268,
  });
  const [isVisible, setIsVisible] = useState(false);

  const [viewport, setViewport] = useState({
    height: 224,
    latitude: 51.53187190831481,
    longitude: -0.298037836048268,
    zoom: 9,
  });

  const idComplex = useMemo(
    () => qs.parse(locationReactDom.search).id_complex,
    [locationReactDom.search],
  );
  const params = qs.parse(history.location.search);

  const { data: dataComplexId } = useComplexId(idComplex);
  const {
    data: complexData,
    isFetching: isFetchingComplex,
    isLoading: isLoadingComplex,
  } = useComplex(true);

  useEffect(() => {
    if (idComplex) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [params]);

  useEffect(() => {
    if (dataComplexId) {
      formComplex.setFieldsValue(dataComplexId);
      setLocation({
        lat: +dataComplexId.location.split(',')[0],
        lng: +dataComplexId.location.split(',')[1],
      });
      setViewport({
        ...viewport,
        latitude: +dataComplexId.location.split(',')[0],
        longitude: +dataComplexId.location.split(',')[1],
      });
    }
  }, [dataComplexId]);

  const columns = [
    {
      title: 'Complex name',
      dataIndex: 'name',
    },
    {
      title: 'Type',
      dataIndex: 'type',
    },
    {
      title: 'Address',
      dataIndex: 'address',
    },
    {
      width: 54,
      render: () => (
        <Icon
          variant="chevron-right"
          width={24}
          height={24}
          className="open-row-info-icon"
          fill="#000"
          stroke="#000"
        />
      ),
    },
  ];

  const { data, isLoading } = useInformation();

  const priceBase = (value) => value && `£ ${value}`;

  useEffect(() => {
    if (data) {
      form.setFieldsValue(data?.[0]);
    }
  }, [data]);

  function handleChangeLocation({ lngLat }) {
    setLocation({ ...viewport, lat: lngLat[1], lng: lngLat[0] });
  }

  const updateSettings = () => {
    form.validateFields().then((dataForm) => {
      setLoading(true);
      const dataFormUpdate = {
        ...dataForm,
        expiration_date: dayjs(dataForm.expiration_date).format('YYYY-MM-DD'),
      };
      axios
        .put(`/settings/insurances/${data?.[0].id}/`, dataFormUpdate)
        .then(() => {
          message.success('Changes saved');
        })
        .finally(() => setLoading(false));
    });
  };

  const updateComplex = () => {
    formComplex.validateFields().then((complexDataValidate) => {
      delete complexDataValidate.type;
      axios
        .patch(`/settings/garages/${idComplex}/`, {
          ...complexDataValidate,
          location: `${location.lat},${location.lng}`,
        })
        .then(() => {
          message.success('Service complex details updated');
        });
    });
  };

  return (
    <>
      <Form form={form}>
        <Spin spinning={isLoading}>
          <div className="box">
            <Divider className="color-text" orientation="left">
              Company insurance policy
            </Divider>
            <Row gutter={20}>
              <Col flex={1}>
                <Form.Item rules={isRequiredRule} name="certificate_number">
                  <FloatLabel label="Policy number">
                    <Input disabled={isStaff} />
                  </FloatLabel>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item rules={isRequiredRule} name="expiration_date">
                  <FloatLabel label="Insurance expiration date">
                    <CustomDatePicker disabled={isStaff} />
                  </FloatLabel>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <Form.Item rules={isRequiredRule} name="daily_cost">
                  <FloatLabel label="Daily insurance cost">
                    <InputNumber
                      disabled={isStaff}
                      onWheel={(e) => e.target.blur()}
                      formatter={priceBase}
                      className="full-width"
                    />
                  </FloatLabel>
                </Form.Item>
              </Col>
              <Col>
                {!isStaff && (
                  <Button
                    type="primary"
                    onClick={updateSettings}
                    loading={loading}
                  >
                    Save changes
                  </Button>
                )}
              </Col>
            </Row>
          </div>
        </Spin>
        <div className="box">
          <Divider className="color-text" orientation="left">
            Test & Service centre locations
          </Divider>
          <Table
            columns={columns}
            dataSource={complexData}
            pagination={false}
            loading={isFetchingComplex || isLoadingComplex}
            className="table-complex"
            onRow={(record) => ({
              onClick: () => {
                history.push({
                  search: qs.stringify({
                    id_complex: record.id,
                    ...qs.parse(history.location.search),
                  }),
                });
              },
              className: 'clickable-table-row',
            })}
          />
          <CustomizedModal
            visible={isVisible}
            onCancel={() => {
              history.push({
                search: qs.exclude(locationReactDom.search, ['id_complex']),
              });
              setIsVisible(false);
            }}
            width={864}
            footer={
              <Row justify="end">
                <Col span={12}>
                  <Button
                    onClick={updateComplex}
                    type="primary"
                    style={{ width: '100%' }}
                  >
                    Save changes
                  </Button>
                </Col>
              </Row>
            }
            title={
              <h4 style={{ marginBottom: 0 }}>
                Test & Service centre location
              </h4>
            }
          >
            <Form form={formComplex}>
              <Row gutter={20}>
                <Col span={12}>
                  <Form.Item name="type" rules={isRequiredRule}>
                    <FloatLabel label="Complex service type">
                      <Input disabled />
                    </FloatLabel>
                  </Form.Item>
                  <Form.Item name="name" rules={isRequiredRule}>
                    <FloatLabel label="Complex name">
                      <Input />
                    </FloatLabel>
                  </Form.Item>
                  <Form.Item name="email" rules={isRequiredRule}>
                    <FloatLabel label="Complex email">
                      <Input />
                    </FloatLabel>
                  </Form.Item>
                  <Form.Item name="address" rules={isRequiredRule}>
                    <FloatLabel label="Address">
                      <Input.TextArea rows={6} />
                    </FloatLabel>
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <ReactMapGL
                    {...viewport}
                    asyncRender
                    onViewportChange={setViewport}
                    mapboxApiAccessToken="pk.eyJ1IjoibW9naGFuaTgxIiwiYSI6ImNrdzByY21xYzBpZTEycXBxaDd4aWhlM28ifQ.imTrkjM7tx4ztuEa1Clgbg"
                    mapStyle="mapbox://styles/moghani81/ckx1ide8437z014o3gv51wtvf"
                    width="100%"
                    height="100%"
                    className="map"
                    onClick={handleChangeLocation}
                  >
                    <Marker
                      latitude={location.lat}
                      longitude={location.lng}
                      offsetLeft={-20}
                      offsetTop={-10}
                    >
                      <Icon variant="marker" width={24} height={24} />
                    </Marker>
                  </ReactMapGL>
                </Col>
              </Row>
            </Form>
          </CustomizedModal>
        </div>
      </Form>
    </>
  );
}
