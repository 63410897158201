import React, { useEffect, useState } from 'react';
import { Modal, Upload } from 'antd';
import cn from 'classnames';

import { Icon } from '../icon';
import { getBase64 } from '../../utils';

import './styles.scss';

const UploadBtn = ({ size, title }) => {
  if (size === 'big')
    return (
      <div className="upload-photo-inner-content soft-transition">
        <Icon
          variant="upload-photo"
          width={48}
          height={48}
          fill="currentColor"
        />
        <span>{title}</span>
      </div>
    );
  return (
    <Icon
      variant="upload-photo"
      width={48}
      height={48}
      fill="currentColor"
      className="soft-transition"
    />
  );
};

/**
 * @param {'small'|'big'} size
 * @param title
 */
export function SinglePhotoUploader({
  size = 'big',
  title = 'Click here to upload',
  titleTextUp,
  ...restProps
}) {
  const [fileList, setFileList] = useState([]);
  const [preview, setPreview] = useState({
    image: null,
    visible: false,
  });

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj);
    }
    setPreview({
      image: file.url || file.preview,
      visible: true,
    });
  };

  useEffect(() => {
    const { value } = restProps || {};
    if (value) {
      if (value?.fileList) {
        setFileList(value.fileList);
      } else if (typeof value === 'string') {
        // this is probably for loading
        setFileList([
          {
            uid: '1',
            url: value,
          },
        ]);
      }
    }
  }, [restProps?.value]);

  return (
    <>
      <p className="title-upload-pic-pco">{titleTextUp}</p>
      <Upload
        style={{ marginBottom: '0' }}
        className={cn('image-uploader', { 'big-uploader': size === 'big' })}
        listType="picture-card"
        fileList={fileList}
        showUploadList={{ showRemoveIcon: true }}
        maxCount={1}
        accept="image/png, image/jpeg, image/jpg"
        multiple={false}
        customRequest={({ onSuccess }) => onSuccess()}
        onPreview={
          restProps?.accept === 'application/pdf' ? undefined : handlePreview
        }
        {...restProps}
        onChange={(info) => {
          const { fileList: fl } = info;
          setFileList(fl);
          restProps?.onChange(info);
        }}
      >
        {fileList.length >= 1 ? null : <UploadBtn size={size} title={title} />}
      </Upload>
      <Modal
        visible={preview.visible}
        title={null}
        footer={null}
        closable={false}
        onCancel={() => setPreview({ visible: false, image: null })}
        className="preview-pic-modal"
      >
        <img style={{ width: '100%' }} src={preview.image} />
      </Modal>
    </>
  );
}
