import { Route, Redirect } from 'react-router-dom';

const pathname = '/auth/login';
let redirectRoute;
switch (localStorage.getItem('type')) {
  case 'admin':
    redirectRoute = '/dashboard';
    break;
  case 'mot':
    redirectRoute = '/mot/mot-test';
    break;
  case 'service':
    redirectRoute = '/service/service-test';
    break;
  default:
    redirectRoute = '/';
    break;
}
export const PublicRoute = ({ children, ...rest }) => {
  const type = localStorage.getItem('type');
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !type ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: redirectRoute,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export const MOTRoute = ({ children, ...rest }) => {
  const isAuth =
    localStorage.getItem('access_token') &&
    localStorage.getItem('type') === 'mot';
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export const ServiceRoute = ({ children, ...rest }) => {
  const isAuth =
    localStorage.getItem('access_token') &&
    localStorage.getItem('type') === 'service';
  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

export const PrivateRoute = ({ children, ...rest }) => {
  const isAuth =
    localStorage.getItem('access_token') &&
    localStorage.getItem('type') === 'admin';

  return (
    <Route
      {...rest}
      render={({ location }) =>
        isAuth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname,
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
