import { useQuery } from 'react-query';
import * as api from '../api';

export const useAdminList = () => useQuery(['getAdminList'], api.getAdminList);
export const useInformation = (didsable) =>
  useQuery(['getInformation'], api.getInformation, { enabled: didsable });

export const useUser = () => useQuery(['getUser'], api.getUser);

export const useComplex = () => useQuery(['getComplex'], api.getComplex);

export const useComplexId = (id) =>
  useQuery(['getComplexId', id], () => api.getComplexId(id), { enabled: !!id });
