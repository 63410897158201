import qs from 'query-string';
import axios from '../utils/axios';

export const getLicensesNotifications = async ({
  pageParam = 1,
  isReed,
  url,
}) => {
  const endpoint = qs.stringifyUrl({
    url,
    query: { read: isReed, page: pageParam },
  });
  const { data } = await axios.get(endpoint);
  return data;
};

export const getNotificationsCount = async ({ type }) => {
  const { data } = await axios.get(type);
  return data;
};
