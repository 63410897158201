import { Row, Button, Form, Checkbox, Spin } from 'antd';
import { CustomizedModal } from '../modal';

import './styles.scss';
import { Heading } from '../heading';

export function RejectDocsModal({
  title,
  subTitle,
  isVisible,
  isLoadingData,
  onCancel,
  onConfirm,
  isSubmitting,
  rejectOptions,
  ...restProps
}) {
  const [formInstance] = Form.useForm();

  const onConfirmRejection = () => {
    formInstance
      .validateFields()
      .then((values) => onConfirm(values?.description));
  };

  return (
    <CustomizedModal
      visible={isVisible}
      onCancel={onCancel}
      confirmLoading={isSubmitting}
      afterClose={() => formInstance.resetFields()}
      closable={false}
      maskClosable
      wrapProps={{
        id: 'modal-with-infinite-scroll-table',
      }}
      {...restProps}
      footer={
        <div className="footer-cta-btns-container">
          <Button
            type="ghost"
            htmlType="submit"
            onClick={onCancel}
            className="footer-cta-btn half-width ghost"
            size="large"
          >
            Cancel
          </Button>
          <Button
            type="primary"
            htmlType="submit"
            onClick={() =>
              formInstance.validateFields().then(onConfirmRejection)
            }
            className="footer-cta-btn half-width"
            size="large"
            loading={isSubmitting}
          >
            Confirm
          </Button>
        </div>
      }
    >
      <Heading title={title} subTitle={subTitle} element="h4" />
      <Form form={formInstance} size="large" onFinish={onConfirmRejection}>
        <Form.Item
          name="description"
          rules={[
            {
              required: true,
              message: 'Please select at least one reason for rejection',
            },
          ]}
        >
          <Checkbox.Group className="checkbox-group">
            <Spin spinning={isLoadingData}>
              {rejectOptions?.map((option) => (
                <Row key={option?.id} className="checkbox-option-row">
                  <Checkbox value={option?.id} className="checkbox-option">
                    {option?.title}
                  </Checkbox>
                </Row>
              ))}
            </Spin>
          </Checkbox.Group>
        </Form.Item>
      </Form>
    </CustomizedModal>
  );
}
