import React, { useState } from 'react';
import { Button, Divider, Form, message } from 'antd';
import dayjs from 'dayjs';
import { DamageSketchTemplate } from './damage-sketch-template';
import { FloatLabel } from '../floating-label';
import { CustomDatePicker } from '../custom-date-picker';
import { DocRow } from '../document-row';
import { updateDamageSketch } from '../../api';
import { axios } from '../../utils';

export function DeliverCarStep({ user }) {
  const [isVisible, setVisible] = useState(false);
  const [permissionletterIsLoading, setPermissionletterIsLoading] =
    useState(false);

  const submitDamageSketchUpdate = (payload) =>
    updateDamageSketch(user?.assigncar?.damagessketch, payload);

  return (
    <div className="inline-container">
      <Divider orientation="left">Driver&apos;s return agreement</Divider>
      <Form.Item name="delivery_date">
        <FloatLabel label="Checkout date">
          <CustomDatePicker format="DD/MM/YYYY" disabled />
        </FloatLabel>
      </Form.Item>
      <Form.Item name="delivery_date">
        <FloatLabel label="Checkout time">
          <CustomDatePicker format="HH:mm" disabled picker="time" />
        </FloatLabel>
      </Form.Item>
      <Form.Item name={['payment', 'hire_end']}>
        <FloatLabel label="Checkin date">
          <CustomDatePicker format="DD/MM/YYYY" />
        </FloatLabel>
      </Form.Item>
      <Form.Item name={['payment', 'hire_end']}>
        <FloatLabel label="Checkin time">
          <CustomDatePicker format="HH:mm" picker="time" />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        name="payment_start_date"
        initialValue={dayjs(user?.payment?.hire_start).add(1, 'week')}
        rules={[
          {
            required: true,
            message: 'Please select a payment start date',
          },
        ]}
      >
        <FloatLabel label="Direct debit start date">
          <CustomDatePicker />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        name={['driver_car', 'issue_date_permission']}
        rules={[
          {
            required: true,
            message: 'Please select a date',
          },
        ]}
      >
        <FloatLabel label="Permission letter issue date">
          <CustomDatePicker />
        </FloatLabel>
      </Form.Item>
      <Divider orientation="left">Damage sketch and Permission letter</Divider>
      <Form.Item
        name={['assigncar', 'damagessketch']}
        className="flex-column full-width with-y-divider without-margin"
        rules={[
          {
            required: true,
            message: 'Please update the Damage Sketch file',
          },
        ]}
      >
        <DocRow
          name="damagessketch"
          title="Damage sketch"
          uploadable={false}
          printable={false}
          viewable={false}
          suffix={
            <Button className="upload-doc-btn" onClick={() => setVisible(true)}>
              Update
            </Button>
          }
        />
      </Form.Item>
      <Form.Item
        name="permissionletter"
        className="flex-column full-width with-y-divider without-margin"
        rules={[
          {
            required: true,
            message: 'Please upload the Permission Letter file',
          },
        ]}
      >
        <DocRow
          name="permissionletter"
          title="Permission letter"
          driverID={user?.id}
          requestUrl={
            user?.assingcar_id
              ? `/cars/drivercars/${user.assingcar_id}/`
              : undefined
          }
          printable={false}
          uploadable={false}
          suffix={
            <Button
              loading={permissionletterIsLoading}
              onClick={() => {
                setPermissionletterIsLoading(true);
                axios
                  .post(`/account/update-permission-letter/${user?.id}/`)
                  .then(({ data }) => {
                    message.success(data.message);
                  })
                  .finally(() => {
                    setPermissionletterIsLoading(false);
                  });
              }}
            >
              Update
            </Button>
          }
        />
      </Form.Item>
      <DamageSketchTemplate
        user={user}
        isVisible={isVisible}
        close={() => setVisible(false)}
        onSubmit={submitDamageSketchUpdate}
      />
    </div>
  );
}
