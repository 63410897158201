import qs from 'query-string';
import axios from '../utils/axios';

/**
 * @param {('cars/car'|'cars/freecar'|'cars/assignedcar')} [fetchEndpoint='cars/car']
 */
export const getCars = async ({
  pageParam = 1,
  fetchEndpoint = '/cars/car/',
  queryParams,
}) => {
  const endpoint =
    fetchEndpoint +
    qs.stringify(
      { page: pageParam || null, ...queryParams },
      { skipNull: true, skipEmptyString: true },
    );

  const { data } = await axios.get(endpoint);
  return data;
};

export const getCarByID = async (id) => {
  const { data } = await axios.get(`/cars/car/${id}/`);
  return data;
};
export const getSearchCarRegestratinNumber = async ({ regestratinNumber }) => {
  const { data } = await axios.get(`/cars/car/?search=${regestratinNumber}`);
  return data;
};

export const getCarTypes = async () => {
  const { data } = await axios.get('/cars/carbodyshapelsit/');
  return data;
};

export const getCarModels = async () => {
  const { data } = await axios.get('/cars/carcompany/');
  return data;
};

export const updateCar = async ({ id, ...car }) => {
  const { data } = await axios.put(`/cars/car/${id}/`, car);
  return data;
};

/**
 * @param {number} user - Driver ID
 * @param {number} car - Car ID
 * @param {string=} end - End date of contract in format of YYYY-MM-DD hh:mm (optional, one year later by default)
 */
export const assignCarToDriver = async ({ user, car }) => {
  const payload = { user, car };
  return new Promise((resolve, reject) => {
    axios
      .post('/cars/driverassingcar/', payload)
      .then(({ data }) => resolve(data))
      .catch(reject);
  });
};
