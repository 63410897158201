import { Tabs } from 'antd';
import React, { useMemo } from 'react';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { UpcomingPayments } from './Upcoming';
import { PendingPayments } from './Pending';
import { OverduePayments } from './Overdue';
import { InvoicesHistory } from './History';
import { CreateNewInvoiceModal } from './CreateNewInvoiceModal';

import './styles.scss';
import { Outstanding } from './Outstanding';

const { TabPane } = Tabs;

export function Payments() {
  const history = useHistory();
  const location = useLocation();

  const { category, action } =
    useMemo(() => qs.parse(location.search), [location.search]) || {};

  const changeRoute = (key) => history.push(`/payments?category=${key}`);

  const addModalIsVisible = useMemo(
    () =>
      location.pathname.startsWith('/payments') && action === 'add_new_invoice',
    [location.pathname, action],
  );

  return (
    <>
      <Tabs
        activeKey={category}
        onTabClick={changeRoute}
        type="card"
        className="tables-tab-bar"
        animated
        destroyInactiveTabPane
      >
        <TabPane tab="Upcoming" key="upcoming">
          <UpcomingPayments />
        </TabPane>
        <TabPane tab="Overdue" key="overdue">
          <OverduePayments />
        </TabPane>
        <TabPane tab="Pending confirmation" key="pending">
          <PendingPayments />
        </TabPane>
        <TabPane tab="Invoices history" key="history">
          <InvoicesHistory />
        </TabPane>
        <TabPane tab="Outstanding balance" key="outstanding">
          <Outstanding />
        </TabPane>
      </Tabs>
      <CreateNewInvoiceModal
        isVisible={addModalIsVisible}
        close={() => {
          history.push({
            search: qs.exclude(location.search, ['action']),
          });
        }}
      />
    </>
  );
}
