import { Icon } from '../icon';

const items = [
  {
    title: 'Main menu',
    items: [
      {
        Icon: <Icon variant="list" className="icon-24 icon-fill-inherit" />,
        pathname: '/drivers',
        Title: 'Drivers',
        TitleHeader: 'Drivers list',
        id: 1,
      },
      {
        Icon: <Icon variant="car" className="icon-24 icon-fill-inherit" />,
        pathname: '/car-bank',
        Title: 'Car bank',
        TitleHeader: 'Car bank',
        id: 2,
      },
      {
        Icon: <Icon variant="payment" className="icon-24 icon-fill-inherit" />,
        pathname: '/payments',
        Title: 'Payments',
        TitleHeader: 'Payments',
        id: 3,
      },
      {
        Icon: <Icon variant="reports" className="icon-24 icon-fill-inherit" />,
        pathname: '/reports',
        Title: 'Reports',
        TitleHeader: 'reports',
        id: 3,
      },
      {
        Icon: (
          <Icon variant="car-accident" className="icon-24 icon-fill-inherit" />
        ),
        pathname: '/accidents',
        Title: 'Accidents',
        TitleHeader: 'Accidents',
        id: 4,
      },
      {
        Icon: <Icon variant="PCN" className="icon-24 icon-fill-inherit" />,
        pathname: '/pcn',
        Title: 'PCN',
        TitleHeader: 'PCN',
        id: 5,
      },
      {
        Icon: <Icon variant="mileage" className="icon-24 icon-fill-inherit" />,
        pathname: '/mileage',
        Title: 'Mileage',
        TitleHeader: 'Mileage',
        id: 6,
      },
      {
        Icon: (
          <Icon variant="car-retrunal" className="icon-24 icon-fill-inherit" />
        ),
        pathname: '/car-return',
        Title: 'Car return',
        TitleHeader: 'Car return',
        id: 7,
      },
    ],
  },
  {
    title: 'Service centres',
    items: [
      {
        Icon: <Icon variant="service" className="icon-24 icon-fill-inherit" />,
        pathname: '/car-service',
        Title: 'Car service',
        TitleHeader: 'Car service',
        id: 8,
      },
      {
        Icon: <Icon variant="MOT" className="icon-24 icon-fill-inherit" />,
        pathname: '/mot-test',
        Title: 'MOT test',
        TitleHeader: 'MOT test',
        id: 9,
      },
      {
        Icon: <Icon variant="PCO" className="icon-24 icon-fill-inherit" />,
        pathname: '/pco-test',
        Title: 'PCO test',
        TitleHeader: 'PCO test',
        id: 10,
      },
    ],
  },
  {
    title: '',
    items: [
      {
        Icon: <Icon variant="service" className="icon-24 icon-fill-inherit" />,
        pathname: '/settings',
        Title: 'Settings',
        TitleHeader: 'Settings',
        id: 9,
      },
    ],
  },
];
export default items;
