import React, { useEffect, useState } from 'react';
import { Table, Avatar, Form } from 'antd';
import cn from 'classnames';
import { useCars } from '../../hooks';
import { InfiniteScrollWrapper } from '../Infinite-scroll-wrapper';
import { getSearchFilterProps } from '../antd-filter-props';

const Columns = [
  {
    title: 'Car manufacturer and model',
    dataIndex: 'model',
    render: (_model, record) => (
      <>
        <Avatar
          shape="square"
          size="large"
          className="car-image"
          style={{ marginRight: '10px', width: 80 }}
          src={record?.carimage}
        />
        <span>{`${record.model} (${record.transmission})`}</span>
      </>
    ),
    key: 'model',
    ...getSearchFilterProps('Car manufacturer and model'),
  },
  {
    title: 'Registration number',
    dataIndex: 'registrationnumber',
  },
  {
    title: 'Deposit fee',
    dataIndex: 'deposit_amount',
    render: (value) => `£${value}`,
  },
  {
    title: 'Weekly rent',
    dataIndex: 'weekly_rent_amount',
    render: (value) => `£${value}`,
  },
];

export function AssignCarTable({ form }) {
  const [searchCar, setSearchCar] = useState('');
  const { data, isLoading, hasNextPage, fetchNextPage } = useCars({
    category: 'free',
    queryParams: { search: searchCar },
  });
  const [selectedCar, setSelectedCar] = useState(form?.getFieldValue('car'));

  useEffect(() => {
    form.setFieldsValue({ car: selectedCar });
  }, [selectedCar]);

  const handelChangeTable = (pagination, filters) => {
    setSearchCar(filters?.model?.[0]);
  };

  return (
    <>
      <InfiniteScrollWrapper
        lengthData={data?.pages?.[0].count}
        hasMore={hasNextPage}
        functionNext={fetchNextPage}
        scrollableTarget="modal-with-infinite-scroll-table"
      >
        <Table
          columns={Columns}
          dataSource={data?.pages.reduce((res, cur) => {
            if (cur.results) res.push(...cur.results);
            else res.push(...cur);
            return res;
          }, [])}
          loading={isLoading}
          rowKey={(record) => record.id}
          className="bordered-table"
          onRow={(record) => ({
            onClick: () => {
              setSelectedCar(record?.id);
            },
            className: cn('clickable-table-row', {
              'selected-table-row': selectedCar === record?.id,
            }),
          })}
          pagination={false}
          onChange={handelChangeTable}
        />
      </InfiniteScrollWrapper>
      <Form.Item
        name="car"
        className="only-feedback-form-item"
        rules={[
          {
            required: true,
            message: 'Please select a car to assign to the driver',
          },
        ]}
      />
    </>
  );
}
