import { Form, Upload } from 'antd';
import { Button } from 'antd/lib/radio';
import { Icon } from '../icon';

export function UploadPDF({
  title,
  name,
  mode,
  linkdocs,
  onClickCTA,
  border = true,
  disabled,
  rules,
}) {
  return (
    <div className={`upload-doc-row full-width ${border && 'with-y-divider'}`}>
      <div
        style={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}
      >
        {linkdocs ? (
          <a
            href={linkdocs || undefined}
            target="_blank"
            rel="noopener noreferrer"
            className="view-doc-link-btn"
          >
            <Icon
              variant="document"
              width={36}
              height={36}
              fill="currentColor"
              color="inherit"
            />
          </a>
        ) : (
          <span className="view-doc-link-btn disabled">
            <Icon
              variant="document"
              width={36}
              height={36}
              fill="currentColor"
              color="inherit"
            />
          </span>
        )}
        <div className="doc-received-status-box">
          <span className="doc-title">{title}</span>
        </div>
      </div>
      {mode === 'update'
        ? linkdocs && (
            <a
              href={linkdocs}
              target="_blank"
              rel="noopener noreferrer"
              className="print-doc-btn"
              style={{ paddingBottom: '20px' }}
            >
              Print this document
            </a>
          )
        : null}

      <div
        style={{
          flexBasis: '156px',
          maxWidth: '156px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Form.Item name={name} rules={rules}>
          {name === 'damagessketch' ? (
            <Button
              className="upload-doc-btn vehicle-documents"
              onClick={onClickCTA}
              disabled={disabled}
            >
              Update
            </Button>
          ) : (
            <Upload
              className="upload-doc-btn vehicle-documents"
              accept="application/pdf"
              maxCount={1}
              customRequest={({ onSuccess }) => onSuccess()}
              disabled={disabled}
            >
              {linkdocs ? 'Change' : 'Upload'}
            </Upload>
          )}
        </Form.Item>
      </div>
    </div>
  );
}
