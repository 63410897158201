import { useInfiniteQuery, useQuery } from 'react-query';
import qs from 'query-string';
import * as api from '../api';

/**
 * @param {('all'|'assigned'|'free')} [carCategory="all"]
 */
export const useCars = ({ category, queryParams }) => {
  let fetchEndpoint;
  switch (category) {
    case 'assigned':
      fetchEndpoint = `/cars/assignedcar/?`;
      break;
    case 'archive':
      fetchEndpoint = `/cars/car/?archived=true&`;
      break;
    case 'free':
      fetchEndpoint = `/cars/freecar/?`;
      break;
    case 'all':
    default:
      fetchEndpoint = `/cars/car/?archived=false&`;
      break;
  }
  return useInfiniteQuery(
    ['cars', qs.stringify({ category, ...queryParams })],
    ({ pageParam }) => api.getCars({ fetchEndpoint, pageParam, queryParams }),
    {
      getNextPageParam: (lastPage) => lastPage?.next ?? false,
    },
  );
};

export const useCar = (id) =>
  useQuery(['car', id], () => api.getCarByID(id), {
    enabled: !!id,
  });
export const useSearchCarRegestratinNumber = (regestratinNumber) =>
  useQuery(
    ['getSearchCarRegestratinNumber', regestratinNumber],
    () => api.getSearchCarRegestratinNumber({ regestratinNumber }),
    {
      enabled: !!regestratinNumber,
    },
  );

export const useCarTypes = () => useQuery(['carTypes'], api.getCarTypes);
export const useCarModels = () => useQuery(['carModels'], api.getCarModels);
