import { axios } from '../utils';

export const getAdminList = async () => {
  const { data } = await axios.get('/settings/admins/');
  return data;
};
export const getInformation = async () => {
  const { data } = await axios.get('/settings/insurances/');
  return data;
};
export const getUser = async () => {
  const { data } = await axios.get('/account/user/');
  return data;
};

export const getComplex = async () => {
  const { data } = await axios.get('/settings/garages/');
  return data;
};

export const getComplexId = async (id) => {
  const { data } = await axios.get(`/settings/garages/${id}/`);
  return data;
};
