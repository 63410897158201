import './styles.scss';
import Logo from '../../assets/logo.png';
import { version } from '../../../package.json';
import { Icon } from '../../components';

export function AuthLayout({ children }) {
  return (
    <div className="register">
      <img src={Logo} alt="logo" className="logo" />
      <div className="register__wrapper">{children}</div>
      <div className="version">
        <Icon variant="version" width={13} height={13} />
        version {version}
      </div>
    </div>
  );
}
