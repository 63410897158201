/* eslint-disable react/destructuring-assignment */
import { Button, Divider, message, Table } from 'antd';
import Avatar from 'antd/lib/avatar/avatar';
import dayjs from 'dayjs';
import { useQueryClient, useMutation } from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { InfiniteScrollWrapper } from '../Infinite-scroll-wrapper';
import { useCarReturnList } from '../../hooks';
import { AssignCarTable } from './AssignCarTable';
import { axios, confirmExit } from '../../utils';

function AssignCarStep({
  form,
  driverChosenCar,
  driverAssignedCar,
  isActiveDriver,
}) {
  const location = useLocation();
  const history = useHistory();

  const queryClient = useQueryClient();
  const { data, hasNextPage, isLoading, isFetching, fetchNextPage } =
    useCarReturnList({
      category: 'new',
    });
  const Columns = [
    {
      title: 'Car manufacturer and model',
      dataIndex: 'car_details',
      render: (car) => (
        <>
          <Avatar
            shape="square"
            size="large"
            className="car-image"
            style={{ marginRight: '10px', width: 80 }}
            src={car?.img}
          />
          <span>{`${car?.company} ${car?.model} (${car?.fueltype})`}</span>
        </>
      ),
    },
    {
      title: 'Registration number',
      dataIndex: ['car_details', 'registrationnumber'],
    },
    {
      title: 'Weekly rent',
      dataIndex: ['car_details', 'weekly_rent_amount'],
      render: (value) => `£${value}`,
    },
    {
      title: 'Available at',
      dataIndex: 'appointment_date',
      render: (date) => dayjs(date).format('DD/MM/YYYY'),
    },
  ];
  const columnsAssigned = [
    {
      title: 'Car manufacturer and model',
      dataIndex: 'model',
      render: (model, carInfo) => (
        <>
          <Avatar
            shape="square"
            size="large"
            className="car-image"
            style={{ marginRight: '10px', width: 80 }}
            src={carInfo?.carimage}
          />
          <span>{`${carInfo?.make} ${model} (${carInfo?.fueltype})`}</span>
        </>
      ),
    },
    {
      title: 'Registration number',
      dataIndex: 'registrationnumber',
    },
    {
      title: 'Weekly rent',
      dataIndex: 'weekly_rent_amount',
      render: (value) => `£${value}`,
    },
  ];

  const { mutate, isLoading: unassignLoading } = useMutation(() =>
    axios.patch(`/cars/unassign/${driverAssignedCar.id}/`).then(() => {
      message.success('Car unassigned successfully');
      queryClient.invalidateQueries('driver');
      queryClient.invalidateQueries('cars');
    }),
  );

  return (
    <>
      <Divider orientation="left">Available cars</Divider>
      <AssignCarTable form={form} />

      {driverChosenCar && (
        <>
          <Divider orientation="left">Driver&apos;s chosen car</Divider>
          <div className="driver-chosen-car-container">
            <Avatar
              shape="square"
              size="large"
              className="car-image"
              src={driverChosenCar?.carimage}
            />
            {`${driverChosenCar.make} ${driverChosenCar.model} (${driverChosenCar.transmission})`}
          </div>
        </>
      )}

      {driverAssignedCar && (
        <>
          <Divider orientation="left">Driver&apos;s assigned car</Divider>
          <Table
            scroll={{
              x: 700,
            }}
            className="bordered-table"
            dataSource={[driverAssignedCar]}
            columns={columnsAssigned}
            pagination={false}
          />
        </>
      )}

      {!isActiveDriver && (
        <>
          <Divider orientation="left">Soon to be available</Divider>
          <InfiniteScrollWrapper
            lengthData={data?.results?.[0]?.count}
            hasMore={hasNextPage}
            functionNext={fetchNextPage}
            scrollableTarget="modal-with-infinite-scroll-table"
          >
            <Table
              scroll={{
                x: 700,
              }}
              loading={isLoading || isFetching}
              className="bordered-table"
              dataSource={data?.pages.reduce((res, cur) => {
                if (cur?.results) {
                  res.push(...cur?.results);
                } else res.push(...cur);
                return res;
              }, [])}
              columns={Columns}
              pagination={false}
            />
          </InfiniteScrollWrapper>
        </>
      )}
      {
        // driver?.first_deposit_balance?.total_collected === 0 &&
        driverAssignedCar && (
          <Button
            block
            loading={unassignLoading}
            onClick={() =>
              confirmExit(
                () => {
                  mutate();
                  history.push({
                    search: qs.exclude(location.search, ['driver_id']),
                  });
                },
                true,
                'Vehicle unassigning',
                `If you are wishing to change the driver’s vehicle, we highly recommend to first, select the new vehicle you are willing to assign, and then select the “Re-assign” button to complete the process If you unassign the driver’s vehicle, they will be treated as they have a new file opened for them. You will also lose all of the payment records registered so far with this driver. This action is irreversible. Are you willing to continue?`,
                "Unassign driver's vehicle",
              )
            }
            danger
            style={{ marginTop: 16 }}
          >
            Unassign the driver’s vehicle
          </Button>
        )
      }
    </>
  );
}

export { AssignCarStep, AssignCarTable };
