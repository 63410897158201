import { Tabs } from 'antd';
import { useHistory, useLocation } from 'react-router';
import qs from 'query-string';
import { useMemo } from 'react';
import { TableListMOT } from '../../../components';

const { TabPane } = Tabs;

export function ReportsService() {
  const history = useHistory();
  const location = useLocation();

  const category =
    useMemo(() => qs.parse(location.search)?.category, [location.search]) ||
    'progress';

  const tabClick = (key) => history.push(`reports?category=${key}`);

  return (
    <>
      <Tabs
        type="card"
        className="tables-tab-bar"
        activeKey={category}
        onTabClick={tabClick}
        animated
        destroyInactiveTabPane
      >
        <TabPane tab="In-progress reports" key="progress">
          <TableListMOT
            mode="in-progres-report"
            noLinkCar
            noLinkDriver
            url="/service/garage/servicelist/"
            isCarService
            isGarage
          />
        </TabPane>
        <TabPane tab="Tests history" key="history">
          <TableListMOT
            mode="historymotpanel"
            noLinkCar
            noLinkDriver
            isHistory
            url="/service/garage/servicehistory/"
            isCarService
            isGarage
          />
        </TabPane>
      </Tabs>
    </>
  );
}
