import { Route, Switch } from 'react-router';
import { ScheduleTable } from '../../components';
import { UserLayout } from '../../layout';
import { NotFound, ReportsService } from '../../pages';
import Notif from '../../pages/notifications/Notif';

export default function MOTRouter() {
  return (
    <UserLayout>
      <Switch>
        <Route path="/service/service-test">
          <ScheduleTable
            url="/service/check-time/"
            routeOpenMpdal="/service/reports"
          />
        </Route>
        <Route path="/service/reports">
          <ReportsService />
        </Route>
        <Route path="/service/notifications">
          <Notif
            url="/service/garage/notifications/"
            unSeenReqURL="/service/garage/unseen-notification/"
            seenReqURL="/service/garage/seen-notification/"
            notifCountURL="/service/garage/count-notifications/"
          />
        </Route>
        <Route>
          <NotFound />
        </Route>
      </Switch>
    </UserLayout>
  );
}
