import { Table, DatePicker, Form } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import qs from 'query-string';
import { useFinanceReport } from '../../hooks/report';
import { InfiniteScrollWrapper } from '../../components/Infinite-scroll-wrapper';

const { RangePicker } = DatePicker;

export default function VehiclesFinance() {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => qs.parse(location.search), [location.search]);
  delete query.category;

  const { data, loading, hasNextPage, isFetching, fetchNextPage } =
    useFinanceReport(query);

  const changeDate = (date) => {
    history.push(
      `reports?category=vehicles-finance&next_payment_date_after=${dayjs(
        date?.[0],
      ).format('YYYY-MM-DD')}&next_payment_date_before=${dayjs(
        date?.[1],
      ).format('YYYY-MM-DD')}`,
    );
  };

  useEffect(() => {
    if (query.next_payment_date_after) {
      form.setFieldsValue({
        date: [
          dayjs(query.next_payment_date_after),
          dayjs(query.next_payment_date_before),
        ],
      });
    } else {
      form.setFieldsValue({
        date: [dayjs(new Date()).subtract(1, 'month'), dayjs(new Date())],
      });
    }
  }, [location]);

  const columns = [
    {
      title: 'Registration number',
      dataIndex: 'registeraion_number',
    },
    {
      title: 'Date of purchase',
      dataIndex: 'purchase_date',
    },
    {
      title: 'Price',
      dataIndex: 'total_amount',
      render: (value) => `£ ${value}`,
    },
    {
      title: 'Date of direct debit',
      dataIndex: 'next_payment_date',
    },
    {
      title: 'Amount due to be paid',
      dataIndex: 'monthly_amount',
      render: (value) => `£ ${value}`,
    },
    {
      title: 'Term',
      dataIndex: 'term',
    },
    {
      title: 'Amount paid up to today',
      dataIndex: 'paid_amount',
      render: (value) => `£ ${value}`,
    },
    {
      title: 'Remaining balance',
      dataIndex: 'remained_amount',
      render: (value) => `£ ${value}`,
    },
  ];

  return (
    <>
      <Form form={form}>
        <Form.Item name="date">
          <RangePicker
            style={{ width: 'auto', marginBottom: 30 }}
            onChange={changeDate}
          />
        </Form.Item>
      </Form>
      <InfiniteScrollWrapper
        lengthData={data?.results?.[0]?.count}
        hasMore={hasNextPage}
        functionNext={fetchNextPage}
        scrollableTarget="modal-with-infinite-scroll-table"
      >
        <Table
          loading={loading || isFetching}
          columns={columns}
          dataSource={data?.pages.reduce((res, cur) => {
            if (cur?.results) {
              res.push(...cur?.results);
            } else res.push(...cur);
            return res;
          }, [])}
          pagination={false}
        />
      </InfiniteScrollWrapper>
    </>
  );
}
