/* eslint-disable camelcase */
/* eslint-disable prefer-promise-reject-errors */
import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Modal,
  Row,
  Select,
} from 'antd';
import { useEffect, useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import dayjs from 'dayjs';
import { FloatLabel } from '../floating-label';
import { CustomDatePicker } from '../custom-date-picker';
import { axios, confirmExit } from '../../utils';

export function MiscellaneousModal({
  isVisable,
  setVisable,
  form,
  invoice,
  driver,
  url,
  isPayed,
  title,
  setTitle,
}) {
  const queryClient = useQueryClient();

  const [isChecked, setIsChecked] = useState(false);
  const [isGenerate, setIsGenerate] = useState(false);
  const [isVisableSagepay, setIsVisableSagepay] = useState(false);
  const [deleteIsLoading, setDeleteIsLoading] = useState(false);

  const { data: descriptions } = useQuery('descriptions', async () => {
    const { data } = await axios.get('/payment/invoice-descriptions/');
    return data;
  });

  useEffect(() => {
    if (form.getFieldValue()?.id) setIsGenerate(true);
  }, [isVisable]);

  const { isLoading: collectViaCashLoading, mutate: collectViaCash } =
    useMutation(() => {
      form.validateFields(['remaining_balance']).then((validData) =>
        axios
          .post('/payment/cash/', {
            invoice: form.getFieldValue('id'),
            driver_car: driver,
            amount: validData.remaining_balance,
          })
          .then(() => {
            if (url) queryClient.invalidateQueries(url);
            setVisable(false);
          }),
      );
    });

  const { isLoading: collectViaSagepayLoading, mutate: collectViaSagepay } =
    useMutation(() => {
      form
        .validateFields(['remaining_balance', 'reference_number'])
        .then(({ remaining_balance, reference_number }) =>
          axios
            .post('/payment/collect-invoice-payment', {
              invoice: form.getFieldValue('id'),
              reference_number,
              amount: remaining_balance,
            })
            .then(({ data }) => {
              window.location.href = data?.gateway;
              setVisable(false);
              setIsVisableSagepay(false);
            }),
        );
    });

  const { isLoading: generateInvoiceLoading, mutate: generateInvoice } =
    useMutation(() => {
      form
        .validateFields(['amount', 'description', 'title'])
        .then((validData) =>
          axios
            .post('/payment/general-invoices/', { ...validData, driver })
            .then(() => {
              if (url) queryClient.invalidateQueries(url);
              setVisable(false);
            }),
        );
    });

  const { isLoading: payWithInstallmentLoading, mutate: payWithInstallment } =
    useMutation(() => {
      form
        .validateFields(['first_charge_date', 'number_of_installments'])
        .then((validData) =>
          axios
            .post('/payment/collect-invoice-payment', {
              invoice: form.getFieldValue('id'),
              number_of_installments: validData.number_of_installments,
              first_charge_date: dayjs(validData.first_charge_date).format(
                'YYYY-MM-DD',
              ),
            })
            .then(() => {
              if (url) queryClient.invalidateQueries(url);
              setVisable(false);
            }),
        );
    });

  const deleteInvoice = () => {
    setDeleteIsLoading(true);
    axios
      .delete(`/payment/general-invoices/${invoice?.id}/`)
      .then(() => {
        message.info('Invoice deleted');
        if (url) queryClient.invalidateQueries(url);
        setVisable(false);
      })
      .finally(() => {
        setDeleteIsLoading(false);
      });
  };

  return (
    <Form form={form}>
      <Modal
        visible={isVisable}
        onCancel={() => {
          setVisable(false);
          form.resetFields(['weekly_rent', 'holiday_payment_amount']);
        }}
        maskClosable={false}
        footer={null}
        centered
        title={title ? 'New miscellaneous expense' : 'Collect Invoice'}
        className="driver-contact-info-modal"
        width={864}
        afterClose={() => {
          form.resetFields();
          setIsGenerate(false);
          setIsChecked(false);
          setTitle(false);
        }}
      >
        <Form.Item name="title" initialValue="Miscellaneous">
          <FloatLabel label="Title">
            <Input className="full-width" disabled />
          </FloatLabel>
        </Form.Item>
        <Form.Item name="description">
          <FloatLabel label="Description">
            <Select
              options={descriptions?.map(({ description }) => ({
                value: description,
                label: description,
              }))}
            />
          </FloatLabel>
        </Form.Item>
        {isGenerate ? (
          <>
            <Form.Item
              name="isInstallment"
              style={{ marginBottom: 0 }}
              valuePropName="checked"
            >
              <Checkbox
                onChange={({ target: { checked } }) => setIsChecked(checked)}
                disabled={isPayed}
              >
                Collect the payment via instalment plan
              </Checkbox>
            </Form.Item>
            {isChecked ? (
              <>
                <Form.Item
                  name="remaining_balance"
                  rules={[
                    { required: true, message: 'This field is required' },
                  ]}
                >
                  <FloatLabel label="Total to be collected">
                    <InputNumber
                      onWheel={(e) => e.target.blur()}
                      formatter={(value) =>
                        value &&
                        `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                      }
                      style={{ width: '100%' }}
                      maxLength={9}
                      onFocus={() => form.resetFields(['installments'])}
                      disabled
                    />
                  </FloatLabel>
                </Form.Item>
                <Row gutter={24}>
                  <Col span={12}>
                    <Form.Item
                      name="number_of_installments"
                      rules={[
                        { required: true, message: 'This field is required' },
                      ]}
                    >
                      <FloatLabel label="Number of installments">
                        <InputNumber
                          onWheel={(e) => e.target.blur()}
                          maxLength={3}
                          style={{ width: '100%' }}
                          min={1}
                        />
                      </FloatLabel>
                    </Form.Item>
                  </Col>
                  <Col span={12}>
                    <Form.Item
                      name="number_of_installments"
                      rules={[
                        { required: true, message: 'This field is required' },
                      ]}
                      getValueProps={(value) => ({
                        value:
                          value &&
                          (
                            form.getFieldValue('remaining_balance') / value
                          ).toFixed(2),
                      })}
                    >
                      <FloatLabel label="Amount of each installment">
                        <InputNumber
                          onWheel={(e) => e.target.blur()}
                          formatter={(value) =>
                            value &&
                            `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                          }
                          disabled
                          style={{ width: '100%' }}
                        />
                      </FloatLabel>
                    </Form.Item>
                  </Col>
                </Row>
                <Form.Item
                  name="first_charge_date"
                  rules={[
                    { required: true, message: 'This field is required' },
                  ]}
                  initialValue={new Date()}
                >
                  <FloatLabel label="Direct debit start date">
                    <CustomDatePicker />
                  </FloatLabel>
                </Form.Item>
                <Form.Item wrapperCol={{ span: 12, push: 12 }}>
                  <Button
                    type="primary"
                    style={{ width: '100%' }}
                    onClick={payWithInstallment}
                    loading={payWithInstallmentLoading}
                  >
                    Confirm and generate invoice
                  </Button>
                </Form.Item>
              </>
            ) : (
              <Row gutter={12}>
                <Col span={12}>
                  <Form.Item
                    name="remaining_balance"
                    rules={[
                      { required: true, message: 'This field is required' },
                    ]}
                  >
                    <FloatLabel label="amount">
                      <InputNumber
                        onWheel={(e) => e.target.blur()}
                        formatter={(value) =>
                          value &&
                          `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        }
                        style={{ width: '100%' }}
                        maxLength={9}
                      />
                    </FloatLabel>
                  </Form.Item>
                </Col>
                <Col span={6}>
                  <Button
                    style={{ width: '100%' }}
                    type="primary"
                    onClick={collectViaCash}
                    loading={collectViaCashLoading}
                    disabled={isPayed}
                  >
                    Collect via Cash
                  </Button>
                </Col>
                <Col span={6}>
                  <Button
                    type="primary"
                    style={{ width: '100%' }}
                    loading={collectViaSagepayLoading}
                    onClick={() => setIsVisableSagepay(true)}
                    disabled={isPayed}
                  >
                    Collect via Sagepay
                  </Button>
                </Col>
              </Row>
            )}
            {invoice.title === 'Miscellaneous' && (
              <Button
                type="secondary"
                onClick={() => {
                  confirmExit(
                    deleteInvoice,
                    true,
                    'Delete invoice',
                    `You are about to delete an invoice. You will lose the invoice and all of its payments after this action. This action is irreversible. Are you willing to continue?`,
                    'Yes, delete the invoice',
                  );
                }}
                danger
                style={{ width: '100%' }}
                loading={deleteIsLoading}
              >
                Delete invoice
              </Button>
            )}
          </>
        ) : (
          <>
            <Form.Item
              name="amount"
              rules={[{ required: true, message: 'This field is required' }]}
            >
              <FloatLabel label="Amount">
                <InputNumber
                  onWheel={(e) => e.target.blur()}
                  formatter={(value) =>
                    value && `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                  }
                  style={{ width: '100%' }}
                  maxLength={9}
                />
              </FloatLabel>
            </Form.Item>
            <Button
              loading={generateInvoiceLoading}
              type="primary"
              block
              onClick={generateInvoice}
            >
              Confirm and generate invoice
            </Button>
          </>
        )}
      </Modal>
      <Modal
        visible={isVisableSagepay}
        centered
        closable={false}
        footer={null}
        closeIcon={null}
        maskClosable={false}
        width={380}
        afterClose={() => {
          form.resetFields(['reference_number']);
        }}
      >
        <Form.Item
          name="reference_number"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <FloatLabel label="Reference Number">
            <Input />
          </FloatLabel>
        </Form.Item>
        <Button
          type="primary"
          style={{ width: '100%' }}
          loading={collectViaSagepayLoading}
          onClick={collectViaSagepay}
          block
        >
          Collect
        </Button>
        <Button
          style={{ marginTop: 12 }}
          onClick={() => setIsVisableSagepay(false)}
          block
        >
          Cancel
        </Button>
      </Modal>
    </Form>
  );
}
