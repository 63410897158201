import cn from 'classnames';
import { oneOf, bool, string } from 'prop-types';

import './styles.scss';

/**
 *
 * @param {('error'|'process'|'finish'|'wait')} status
 */
export const CheckMarkDot = ({ status, solid, className, ...restProps }) => (
  <span
    role="checkbox"
    className={cn('dot', { [status]: status, solid }, className)}
    {...restProps}
  >
    <span className="dot-inner-mark" />
  </span>
);

CheckMarkDot.propTypes = {
  status: oneOf(['error', 'process', 'finish', 'wait']).isRequired,
  solid: bool,
  className: string,
};
CheckMarkDot.defaultProps = {
  solid: false,
  className: '',
};
