import React from 'react';
import { Button, Alert } from 'antd';

import './styles.scss';

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: undefined,
      errorInfo: undefined,
      showDetails: false,
    };

    this.toggleShowErrorDetails = this.toggleShowErrorDetails.bind(this);
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
  }

  toggleShowErrorDetails() {
    const { showDetails } = this.state;
    this.setState({ showDetails: !showDetails });
  }

  render() {
    const { hasError, error, errorInfo, showDetails } = this.state;
    const { children } = this.props;

    if (hasError) {
      // You can render any custom fallback UI
      return (
        <div className="error-boundary-container">
          <Alert
            type="error"
            className="alert-message"
            showIcon={false}
            message={
              <>
                <p>We&apos;re sorry — something&apos;s gone wrong.</p>
                <p>Our team has been notified,</p>
                <div className="actions">
                  <Button onClick={this.toggleShowErrorDetails}>
                    {showDetails ? 'Hide' : 'Show'} details
                  </Button>
                  <Button
                    type="default"
                    onClick={() => {
                      window.history.pushState(
                        null,
                        'redirected from error boundary',
                        '/',
                      );
                      window.location.reload();
                    }}
                  >
                    Back to homepage
                  </Button>
                </div>
              </>
            }
          />
          {showDetails && (
            <Alert
              type="error"
              message={<h2>{error.message}</h2>}
              description={<pre>{errorInfo?.componentStack}</pre>}
            />
          )}
        </div>
      );
    }

    return children;
  }
}
