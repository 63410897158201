/* eslint-disable react/destructuring-assignment */
import {
  Button,
  Table,
  Form,
  Modal,
  Input,
  Spin,
  message,
  Typography,
} from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useState, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import dayjs from 'dayjs';
import { isNumber } from 'lodash';
import { useInformation, useMot, useMotList } from '../../hooks';
import { InfiniteScrollWrapper } from '../Infinite-scroll-wrapper';
import ItemsModal from './ItemsModal';
import { CustomizedModal } from '../modal';
import { Heading } from '../heading';
import { axios } from '../../utils';
import { getSearchFilterProps } from '../antd-filter-props';
import { SinglePhotoUploader } from '../single-photo-uploader';

export function TableListMOT({
  noLinkCar,
  noLinkDriver,
  isHistory,
  url,
  isCarService,
  isGarage,
  isMot,
  isPco,
}) {
  const [isChangeing, setIsChangeing] = useState();
  const [isVisible, setVisible] = useState(false);
  const [isVisibleRejectModal, setVisibleRejectModal] = useState(false);
  const [isVisiblePictureModal, setVisiblePictureModal] = useState(false);
  const [textRejectModal, setTextRejectModal] = useState();
  const [loadingAbset, setLoadingAbset] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [loadingConfirmAdmin, setLoadingconfirmAdmin] = useState(false);
  const [loadingApplyChanges, setLoadingApplyChanges] = useState(false);
  const [loadingBookApponment, setLoadingBookApponment] = useState(false);
  const [loadingConfirmCarPic, setLoadingConfirmCarPic] = useState(false);
  const [loadingUploadCarPic, setLoadingUploadCarPic] = useState(false);
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const [locationMap, setLocationMap] = useState({
    lat: 51.53187190831481,
    lng: -0.298037836048268,
  });
  const id = useMemo(() => qs.parse(location.search).id, [location.search]);
  const reserveIdFromPCO = useMemo(
    () =>
      qs.parse(location.search)?.reserveDriver ||
      qs.parse(location.search)?.reserveCar,
    [location.search],
  );

  const { Title } = Typography;

  const isRequiredRule = [
    {
      required: true,
      message: 'This field is required',
    },
  ];
  const {
    data: dataMot,
    isLoading: isLoadingMot,
    isFetching: isFetchingMot,
    refetch: refetchDataMot,
  } = useMot({ type: url, id });

  const { data: dataSettings } = useInformation(
    localStorage.getItem('type') === 'admin',
  );

  useEffect(() => {
    if (dataSettings?.mot_center_settings && isMot) {
      form.setFieldsValue({
        test_appointment_details: {
          testcenter_name: dataSettings?.mot_center_settings?.name,
          testcenter_address: dataSettings?.mot_center_settings?.address,
        },
      });
    }
    if (dataSettings?.car_service_settings && isCarService) {
      form.setFieldsValue({
        test_appointment_details: {
          testcenter_name: dataSettings?.mot_center_settings?.name,
          testcenter_address: dataSettings?.mot_center_settings?.address,
        },
      });
    }
  }, [isVisible, dataSettings]);

  const queryParams = useMemo(() => {
    const params = qs.parse(location.search);
    delete params?.category;
    delete params?.id;
    return params;
  }, [location.search]);

  const getOrdering = () => {
    if (queryParams.last_mot === 'ascend') return 'car__last_mot';
    if (queryParams.last_mot === 'descend') return '-car__last_mot';
    if (queryParams.expire_mot === 'ascend') return 'car__mot_expire';
    if (queryParams.expire_mot === 'descend') return '-car__mot_expire';
    if (queryParams.state === 'ascend') return 'state';
    if (queryParams.state === 'descend') return '-state';
    if (queryParams.odometer === 'ascend') return 'car__currentOdometer';
    if (queryParams.odometer === 'descend') return '-car__currentOdometer';
    if (queryParams.last === 'ascend') return 'car__last_service_date';
    if (queryParams.last === 'descend') return '-car__last_service_date';
    if (queryParams.car__pco_expire === 'ascend') return 'car__pco_expire';
    if (queryParams.car__pco_expire === 'descend') return '-car__pco_expire';
    if (queryParams.car__mot_expire === 'ascend') return 'car__mot_expire';
    if (queryParams.car__mot_expire === 'descend') return '-car__mot_expire';
    return undefined;
  };
  const { data, isLoading, isFetching, refetch, hasNextPage, fetchNextPage } =
    useMotList({
      type: url,
      search: {
        ordering: getOrdering(),
        driver: queryParams.driver,
        car: queryParams.car,
      },
    });

  const handelChangeTable = (pagination, filters, sorter) => {
    const { current: page, pageSize: size } = pagination;
    const params = { page, size };
    if (sorter.column) {
      Object.assign(params, {
        [`sort_${sorter.columnKey}`]:
          sorter.order === 'descend' ? 'descend' : 'ascend',
      });
    }
    Object.entries(filters).forEach(([key, value]) => {
      if (value?.length > 0) {
        let paramKey;
        switch (key) {
          case 'car_details':
            paramKey = 'car';
            break;
          case 'Driver_details':
            paramKey = 'driver';
            break;
          default:
            paramKey = key;
            break;
        }
        Object.assign(params, { [paramKey]: value[0] });
      }
    });
    history.replace({
      search: qs.stringify({
        ...qs.parse(history.location.search),
        driver: params.driver,
        car: params.car,
        last_mot: params.sort_last_mot,
        expire_mot: params.sort_expire_mot,
        state: params.sort_state,
        odometer: params.sort_odometer,
        last: params.sort_last,
        car__pco_expire: params.sort_car__pco_expire,
        car__mot_expire: params.sort_car__mot_expire,
      }),
    });
  };

  const onConfirmExit = () => {
    setVisible(false);
  };
  const onCancel = () => {
    Modal.confirm({
      closeIcon: true,
      centered: true,
      title: 'Exit Confirmation',
      content:
        'You are leaving this process. All your unsaved data will be lost. Do you wish to proceed?',
      onOk: onConfirmExit,
      okText: 'Leave',
      cancelText: 'Stay',
      okType: 'danger',
      closable: true,
      maskClosable: true,
      icon: false,
    });
  };

  const confirmAndSend = () => {
    form.validateFields().then(() => {
      setLoadingConfirm(true);
      const fd = new FormData();
      if (form.getFieldValue('invoice')?.file) {
        fd.append(
          'invoice',
          form.getFieldValue('invoice')?.file?.originFileObj,
        );
      }
      if (isCarService) {
        fd.append(
          'total_excluded_vat',
          form.getFieldValue('total_excluded_vat'),
        );
        fd.append('vat_amount', form.getFieldValue('vat_amount'));
        fd.append('current_milage', form.getFieldValue('current_milage'));
      }
      if (isCarService && isGarage) {
        axios
          .put(`/service/garage/servicelist/${id}/`, fd)
          .then(() => {
            message.success('Report submitted successfully');
            setVisible(false);
            refetch();
          })
          .finally(() => setLoadingConfirm(false));
      }
      if (isCarService && !isGarage) {
        axios
          .put(`/service/servicelist/${id}/`, fd)
          .then(() => {
            message.success('Report submitted successfully');
            setVisible(false);
            refetch();
          })
          .finally(() => setLoadingConfirm(false));
      } else if (isMot) {
        if (form.getFieldValue('test_certificate')?.file) {
          fd.append(
            'test_certificate',
            form.getFieldValue('test_certificate')?.file?.originFileObj,
          );
          fd.append(
            'total_excluded_vat',
            form.getFieldValue('total_excluded_vat'),
          );
          fd.append('vat_amount', form.getFieldValue('vat_amount'));
        }
        axios
          .put(`/mot/in-progres-report/${id}/`, fd)
          .then(() => {
            message.success('Report submitted successfully');
            setVisible(false);
            refetch();
          })
          .finally(() => setLoadingConfirm(false));
      } else if (isPco) {
        if (form.getFieldValue('test_certificate')?.file) {
          fd.append(
            'testcertificate',
            form.getFieldValue('test_certificate')?.file?.originFileObj,
          );
        }
        axios
          .put(`/pco/list/${id}/`, fd)
          .then(() => {
            message.success('Report submitted successfully');
            setVisible(false);
            refetch();
          })
          .finally(() => setLoadingConfirm(false));
      }
    });
  };
  const confirmInvoiceAdmin = () => {
    form.validateFields().then(() => {
      setLoadingconfirmAdmin(true);
      if (isCarService) {
        axios
          .get(`/service/confirm-invoice/${id}/`)
          .then(() => {
            message.success('Invoice confirmed successfully');
            setVisible(false);
            refetch();
          })
          .finally(() => setLoadingconfirmAdmin(false));
      } else {
        axios
          .get(`/mot/confirm-service-invoice/${id}/`)
          .then(() => {
            message.success('Invoice confirmed successfully');
            setVisible(false);
            refetch();
          })
          .finally(() => setLoadingconfirmAdmin(false));
      }
    });
  };

  useEffect(() => {
    if (!id) setVisible(false);
    if (id) setVisible(true);
  }, [location]);

  useEffect(() => {
    if (dataMot) {
      form.setFieldsValue({
        ...dataMot,
        Driver_details: {
          name: dataMot?.Driver_details
            ? `${dataMot?.Driver_details?.first_name} ${dataMot?.Driver_details?.last_name}`
            : 'GM Admin',
          phone_number: dataMot?.Driver_details?.phone_number,
        },
        fullname: dataMot?.car_details?.registrationnumber,
        is_mot: dataMot?.latest_mot_testdetails?.mot_state,
        test_center: dataMot?.test_appointment_details?.testcenter_name,
        test_appointment_details: {
          ...dataMot.test_appointment_details,
          test_time:
            !isPco &&
            dataMot?.test_appointment_details?.test_time &&
            dataMot?.test_appointment_details?.test_time.split(':')[0],
        },
      });

      if (dataMot?.test_appointment_details && isPco) {
        form.setFieldsValue({
          test_center: dataMot?.test_appointment_details?.testcenter_name,
          test_appointment_details: {
            test_time: dayjs(
              dataMot?.test_appointment_details.test_date +
                dataMot?.test_appointment_details.test_time,
            ),
          },
        });
      }
    }
  }, [dataMot]);

  const reject = () => {
    const fd = new FormData();
    fd.append('reason', textRejectModal);
    if (isCarService) {
      axios.post(`/service/reject-invoice/${id}/`, fd).then((res) => {
        message.success(res.data?.message);
        setVisibleRejectModal(false);
        setVisible(false);
        refetch();
      });
    } else if (isMot) {
      axios.post(`/mot/reject-service-invoice/${id}/`, fd).then((res) => {
        message.success(res.data?.message);
        setVisibleRejectModal(false);
        setVisible(false);
        refetch();
      });
    } else if (isPco) {
      axios.post(`/pco/carpicture-rejection/${id}/`, fd).then((res) => {
        message.success(res.data?.message);
        setVisibleRejectModal(false);
        setVisiblePictureModal(false);
        setVisible(false);
        refetch();
      });
    }
  };

  const onConfirmAbsent = () => {
    setLoadingAbset(true);
    if (isCarService) {
      axios
        .get(`/service/garage/driverabsent/${id}/`)
        .then((res) => {
          message.success(res.data?.message);
          setVisible(false);
          refetch();
        })
        .finally(() => setLoadingAbset(false));
    } else if (isMot) {
      axios
        .get(`/mot/driverabsent/${id}/`)
        .then((res) => {
          message.success(res.data?.message);
          setVisible(false);
          refetch();
        })
        .finally(() => setLoadingAbset(false));
    } else if (isPco) {
      axios
        .get(`/pco/driverabsent/${id}/`)
        .then((res) => {
          message.success(res.data?.message);
          setVisible(false);
          refetch();
        })
        .finally(() => setLoadingAbset(false));
    }
  };
  const DriverAbsent = () => {
    Modal.confirm({
      closeIcon: true,
      centered: true,
      title: 'Driver absence report',
      content: 'Do you want to report this driver as absent?',
      onOk: onConfirmAbsent,
      okText: 'Yes, the driver was absent',
      okType: 'danger',
      closable: true,
      maskClosable: true,
      icon: false,
    });
  };

  const cancelTime = () => {
    setLoadingCancel(true);
    const fd = new FormData();
    if (dataMot?.assign_to_admin) {
      fd.append('car', dataMot?.car_details.id);
    } else {
      fd.append('user', dataMot?.Driver_details?.id);
    }
    if (isCarService) {
      axios
        .delete(
          `/service/reserve-timepanel/${dataMot?.test_appointment_details?.id}/`,
        )
        .then(() => {
          message.success('Appointment cancelled');
          setVisible(false);
          refetch();
        })
        .finally(() => setLoadingCancel(false));
    } else {
      axios
        .post(`/mot/cancel-time/${dataMot?.test_appointment_details?.id}/`, fd)
        .then((res) => {
          message.success(res.data.message);
          setVisible(false);
          refetch();
        })
        .finally(() => setLoadingCancel(false));
    }
  };
  const cancelTimeConfirm = () => {
    Modal.confirm({
      closeIcon: true,
      centered: true,
      title: 'Canceling appointment',
      content: 'Do you want to cancel this appointment?',
      onOk: cancelTime,
      okText: 'Yes, cancel the appointment',
      cancelText: 'No',
      okType: 'danger',
      closable: true,
      maskClosable: true,
      icon: false,
    });
  };

  const updateReserveTime = () => {
    form.validateFields().then(() => {
      const {
        car_details: { is_electric: isElectric },
      } = form.getFieldsValue();
      setLoadingApplyChanges(true);
      const fd = new FormData();
      const time = form.getFieldValue([
        'test_appointment_details',
        'test_time',
      ]);
      const date = dayjs(
        form.getFieldValue(['test_appointment_details', 'test_date']),
      ).format('YYYY-MM-DD');
      fd.append(
        'reserve_time',
        `${date} ${isPco ? dayjs(time).format('HH:mm') : `${time}:00`}:00`,
      );
      if (isCarService) {
        if (isElectric) {
          fd.append(
            'testcenter_address',
            form.getFieldValue([
              'test_appointment_details',
              'testcenter_address',
            ]),
          );
          fd.append(
            'testcenter_name',
            form.getFieldValue(['test_appointment_details', 'testcenter_name']),
          );
          fd.append(
            'testcenter_location',
            `${locationMap.lat},${locationMap.lng}`,
          );
        } else if (Number.isInteger(form.getFieldValue('test_center')))
          fd.append('test_center', form.getFieldValue('test_center'));
        axios
          .put(
            `/service/reserve-timepanel/${dataMot?.test_appointment_details?.id}/`,
            fd,
          )
          .then(() => {
            message.success('Appointment details updated');
            setVisible(false);
            refetch();
          })
          .finally(() => setLoadingApplyChanges(false));
      } else if (isMot) {
        if (dataMot?.assign_to_admin) {
          fd.append('car', dataMot?.car_details.id);
        } else {
          fd.append(
            'user',
            dataMot?.Driver_details?.id || dataMot?.car_details.id,
          );
        }
        axios
          .post(
            `/mot/change-time/${dataMot?.test_appointment_details?.id}/`,
            fd,
          )
          .then(() => {
            message.success('Appointment details updated');
            setVisible(false);
            refetch();
          })
          .finally(() => setLoadingApplyChanges(false));
      } else if (isPco) {
        if (isNumber(form.getFieldValue('test_center')))
          fd.append('test_center', form.getFieldValue('test_center'));
        axios
          .put(
            `/pco/reserve-time/${dataMot?.test_appointment_details?.id}/`,
            fd,
          )
          .then(() => {
            message.success('Appointment details updated');
            setVisible(false);
            refetch();
          })
          .finally(() => setLoadingApplyChanges(false));
      }
    });
  };
  const UploadCarPictures = () => {
    form.validateFields().then(() => {
      const fd = new FormData();
      if (
        form.getFieldValue(['car_picture', 'frontview'])?.file?.originFileObj
      ) {
        fd.append(
          'frontview',
          form.getFieldValue(['car_picture', 'frontview'])?.file?.originFileObj,
        );
      } else {
        message.error('Please upload front view file');
      }
      if (
        form.getFieldValue(['car_picture', 'rearview'])?.file?.originFileObj
      ) {
        fd.append(
          'rearview',
          form.getFieldValue(['car_picture', 'rearview'])?.file?.originFileObj,
        );
      } else {
        message.error('Please upload rear view file');
      }
      if (
        form.getFieldValue(['car_picture', 'leftside'])?.file?.originFileObj
      ) {
        fd.append(
          'leftside',
          form.getFieldValue(['car_picture', 'leftside'])?.file?.originFileObj,
        );
      } else {
        message.error('Please upload left side view file');
      }
      if (
        form.getFieldValue(['car_picture', 'rightside'])?.file?.originFileObj
      ) {
        fd.append(
          'rightside',
          form.getFieldValue(['car_picture', 'rightside'])?.file?.originFileObj,
        );
      } else {
        message.error('Please upload right side view file');
      }
      if (
        form.getFieldValue(['car_picture', 'rightside'])?.file?.originFileObj &&
        form.getFieldValue(['car_picture', 'frontview'])?.file?.originFileObj &&
        form.getFieldValue(['car_picture', 'rearview'])?.file?.originFileObj &&
        form.getFieldValue(['car_picture', 'leftside'])?.file?.originFileObj
      ) {
        setLoadingUploadCarPic(true);
        axios
          .put(`/pco/list/${dataMot?.id}/`, fd)
          .then(() => {
            message.success('Apopintment details updated');
            setVisible(false);
            setVisiblePictureModal(false);
            refetch();
          })
          .finally(() => setLoadingUploadCarPic(false));
      }
    });
  };
  const ConfirmCarPictures = () => {
    axios
      .get(`/pco/carpicture-confirmation/${dataMot?.id}/`)
      .then(() => {
        message.success('Apopintment details updated');
        setVisible(false);
        refetch();
      })
      .finally(() => setLoadingConfirmCarPic(false));
  };

  const getSortOrder = (parameter) => {
    if (queryParams[parameter] === 'descend') return 'descend';
    if (queryParams[parameter] === 'ascend') return 'ascend';
    return null;
  };

  useEffect(() => {
    if (reserveIdFromPCO) {
      setVisible(true);
      axios
        .get(
          qs.parse(location.search).reserveDriver
            ? `/account/activeusers/${reserveIdFromPCO}/`
            : `/cars/car/${reserveIdFromPCO}/`,
        )
        .then(({ data: reserveData }) => {
          if (reserveData) {
            form.setFieldsValue({
              ...reserveData,
              fullname: qs.parse(location.search).reserveDriver
                ? `${reserveData?.first_name} ${reserveData?.last_name}`
                : 'GM admin',
              Driver_details: { phone_number: reserveData?.phone_number },
              car_details: {
                ...reserveData,
                model: qs.parse(location.search).reserveDriver
                  ? reserveData?.assigncar?.model
                  : reserveData?.model,
                registrationnumber: qs.parse(location.search).reserveDriver
                  ? reserveData?.assigncar?.registrationnumber
                  : reserveData?.registrationnumber,
              },
            });
          }
        });
    }
  }, [reserveIdFromPCO]);

  const checkState = (value) => {
    switch (value) {
      case 'Due Soon':
        return (
          <div className="status-align-service">
            <div>
              <span style={{ color: '#da9842' }}>Due soon</span>
              <p className="sub-state-mot">Test not booked yet</p>
            </div>
            <RightOutlined style={{ fontSize: '24px', color: '#91a2ab' }} />
          </div>
        );
      case 'Warning!':
        return (
          <>
            <div className="status-align-service">
              <div>
                <span style={{ color: '#da4242' }}>Warning!</span>
                <p className="sub-state-mot">Driver was absent</p>
              </div>
              <RightOutlined style={{ fontSize: '24px', color: '#91a2ab' }} />
            </div>
          </>
        );
      case 'Alert!':
        return (
          <div className="status-align-service">
            <div>
              <span style={{ color: '#da4242' }}>Alert!</span>
              <p className="sub-state-mot">Test not booked yet</p>
            </div>
            <RightOutlined style={{ fontSize: '24px', color: '#91a2ab' }} />
          </div>
        );
      case 'Scheduled':
        return (
          <div className="status-align-service">
            <span style={{ color: '#2c56b6' }}>Scheduled</span>
            <RightOutlined style={{ fontSize: '24px', color: '#91a2ab' }} />
          </div>
        );
      case 'Pending report':
        return (
          <div className="status-align-service">
            <span>Pending report</span>
            <RightOutlined style={{ fontSize: '24px', color: '#91a2ab' }} />
          </div>
        );
      case 'Pending report confirmation':
        return (
          <div className="status-align-service">
            <span>Pending report confirmation</span>
            <RightOutlined style={{ fontSize: '24px', color: '#91a2ab' }} />
          </div>
        );
      case 'Passed':
        return (
          <div className="status-align-service">
            <span style={{ color: '#46bc14' }}>Passed</span>
            <RightOutlined style={{ fontSize: '24px', color: '#91a2ab' }} />
          </div>
        );
      case 'Confirmed':
        return (
          <div className="status-align-service">
            <span style={{ color: '#46bc14' }}>
              {isGarage ? 'Accepted by admin' : 'Confirmed'}
            </span>
            <RightOutlined style={{ fontSize: '24px', color: '#91a2ab' }} />
          </div>
        );
      case 'Rejected':
        return (
          <div className="status-align-service">
            <span style={{ color: '#da4242' }}>Rejected</span>
            <RightOutlined style={{ fontSize: '24px', color: '#91a2ab' }} />
          </div>
        );
      case 'Driver was absent':
        return (
          <div className="status-align-service">
            <span style={{ color: '#da4242' }}>Driver was absent</span>
            <RightOutlined style={{ fontSize: '24px', color: '#91a2ab' }} />
          </div>
        );
      case 'Car Pictors Rejected':
        return (
          <div className="status-align-service">
            <div>
              <span style={{ color: '#da4242' }}>Warning!</span>
              <p className="sub-state-mot">Car pictures not validated yet</p>
            </div>
            <RightOutlined style={{ fontSize: '24px', color: '#91a2ab' }} />
          </div>
        );
      case 'Mot Test Not Scheduled':
        return (
          <div className="status-align-service">
            <div>
              <span style={{ color: '#da4242' }}>Warning!</span>
              <p className="sub-state-mot">Driver has not scheduled MOT</p>
            </div>
            <RightOutlined style={{ fontSize: '24px', color: '#91a2ab' }} />
          </div>
        );
      case 'Mot Test Not Passed!':
        return (
          <div className="status-align-service">
            <div>
              <span style={{ color: '#da4242' }}>Warning!</span>
              <p className="sub-state-mot">Driver has not passed MOT</p>
            </div>
            <RightOutlined style={{ fontSize: '24px', color: '#91a2ab' }} />
          </div>
        );
      case 'Test not booked yet':
        return (
          <div className="status-align-service">
            <div>
              <span style={{ color: '#da4242' }}>Warning!</span>
              <p className="sub-state-mot">{value}</p>
            </div>
            <RightOutlined style={{ fontSize: '24px', color: '#91a2ab' }} />
          </div>
        );
      case 'Pending Car Pictures':
        return (
          <div className="status-align-service">
            <div>
              <span style={{ color: '#da4242' }}>Warning!</span>
              <p className="sub-state-mot">
                Driver has not sent <br />
                the car pictures yet
              </p>
            </div>
            <RightOutlined style={{ fontSize: '24px', color: '#91a2ab' }} />
          </div>
        );
      case 'Pending Car Pictures Confirmation':
        return (
          <div className="status-align-service">
            <div>
              <span style={{ color: '#da4242' }}>Warning!</span>
              <p className="sub-state-mot">{value}</p>
            </div>
            <RightOutlined style={{ fontSize: '24px', color: '#91a2ab' }} />
          </div>
        );
      case 'Car Pictures Rejected':
        return (
          <div className="status-align-service">
            <div>
              <span style={{ color: '#da4242' }}>Warning!</span>
              <p className="sub-state-mot">{value}</p>
            </div>
            <RightOutlined style={{ fontSize: '24px', color: '#91a2ab' }} />
          </div>
        );
      default:
        return (
          <div className="status-align-service">
            <span>{value}</span>
            <RightOutlined style={{ fontSize: '24px', color: '#91a2ab' }} />
          </div>
        );
    }
  };

  const coloumnsServise = [
    {
      title: 'Current mileage',
      dataIndex: 'car_details',
      render: (value) =>
        isCarService ? value?.current_millage : value?.last_mot,
      key: 'odometer',
      sorter: true,
      sortOrder: getSortOrder('odometer'),
    },
    {
      title: 'Last service',
      dataIndex: 'car_details',
      render: (value) =>
        value?.last_service_date
          ? dayjs(value?.last_service_date).format('DD/MM/YYYY')
          : '-',
      key: 'last',
      sorter: true,
      sortOrder: getSortOrder('last'),
    },
  ];
  const coloumnsServisePanel = [
    {
      title: 'Appointment date',
      dataIndex: 'test_appointment_details',
      key: 'last_mot',
      render: (value) =>
        value?.test_date ? dayjs(value?.test_date).format('DD/MM/YYYY') : '-',
      sorter: true,
      sortOrder: getSortOrder('last_mot'),
    },
    {
      title: 'Appointment time',
      dataIndex: 'test_appointment_details',
      render: (value) => (value?.test_time ? value?.test_time : '-'),
      key: 'last_service_date',
      sorter: true,
      sortOrder: getSortOrder('last_service_date'),
    },
  ];
  const coloumnsMOt = [
    {
      title: 'Last MOT',
      dataIndex: 'car_details',
      render: (value) =>
        value?.last_mot ? dayjs(value?.last_mot).format('DD/MM/YYYY') : '-',
      key: 'last_mot',
      sorter: true,
      sortOrder: getSortOrder('last_mot'),
    },
    {
      title: 'MOT exp. date',
      dataIndex: 'car_details',
      render: (value) =>
        value?.mot_expire ? dayjs(value?.mot_expire).format('DD/MM/YYYY') : '-',
      key: 'expire_mot',
      sorter: true,
      sortOrder: getSortOrder('expire_mot'),
    },
  ];
  const coloumnsMOtPanel = [
    {
      title: 'Appointment date',
      dataIndex: 'test_appointment_details',
      key: 'last_mot',
      render: (value) => (value?.test_date ? value?.test_date : '-'),
      sorter: true,
      sortOrder: getSortOrder('last_mot'),
    },
    {
      title: 'Appointment time',
      dataIndex: 'test_appointment_details',
      render: (value) => (value?.test_time ? value?.test_time : '-'),
      key: 'expire_mot',
      sorter: true,
      sortOrder: getSortOrder('expire_mot'),
    },
  ];
  const coloumnsPco = [
    {
      title: 'PCO exp. date',
      dataIndex: 'pco_expire',
      render: (value) =>
        value ? (
          <>
            {dayjs(value).format('DD/MM/YYYY')}
            <p className="sub-state-mot">
              {dayjs(value).diff(dayjs(), 'days')} days left
            </p>
          </>
        ) : (
          '-'
        ),
      key: 'car__pco_expire',
      sorter: true,
      sortOrder: getSortOrder('car__pco_expire'),
    },
    {
      title: 'MOT exp. date',
      dataIndex: 'mot_expire',
      render: (value) =>
        value ? (
          <>
            {dayjs(value).format('DD/MM/YYYY')}
            <p className="sub-state-mot">
              {dayjs(value).diff(dayjs(), 'days')} days left
            </p>
          </>
        ) : (
          '-'
        ),
      key: 'car__mot_expire',
      sorter: true,
      sortOrder: getSortOrder('car__mot_expire'),
    },
  ];
  const columns = [
    {
      title: "Driver's name",
      dataIndex: 'first_name',
      key: 'Driver_details',
      ...getSearchFilterProps('Driver'),
      render: (_, fullName) =>
        fullName.Driver_details ? (
          `${fullName.Driver_details?.first_name} ${fullName.Driver_details?.last_name}`
        ) : (
          <span style={{ color: 'rgb(218, 152, 66)' }}>GM Admin</span>
        ),
    },
    {
      title: 'Car',
      dataIndex: 'car_details',
      key: 'car_details',
      ...getSearchFilterProps('Car'),
      render: (car) =>
        car ? (
          <>
            {car.company} {car.model}
            <br />
            {`(${car.registrationnumber})`}
          </>
        ) : (
          '-'
        ),
    },
    ...coloumnsServise.filter(() => isCarService && !isGarage),
    ...coloumnsServisePanel.filter(() => isCarService && isGarage),
    ...coloumnsMOt.filter(() => isMot && !isGarage),
    ...coloumnsMOtPanel.filter(() => isMot && isGarage),
    ...coloumnsPco.filter(() => isPco),
    {
      title: 'Status',
      dataIndex: 'state',
      key: 'state',
      sorter: true,
      sortOrder: getSortOrder('state'),
      render: (value) => checkState(value),
    },
  ];
  const BookAppointment = () => {
    form.validateFields().then(() => {
      setLoadingBookApponment(true);
      const time = form.getFieldValue([
        'test_appointment_details',
        'test_time',
      ]);
      const date = dayjs(
        form.getFieldValue(['test_appointment_details', 'test_date']),
      ).format('YYYY-MM-DD');
      const fd = new FormData();
      if (isMot || dataMot?.is_electric) {
        fd.append('reserve_time', `${date} ${time}:00:00`);
      } else if (isCarService) {
        fd.append('reserve_time', `${date} ${time}:00:00`);
      } else {
        fd.append('reserve_time', `${date} ${dayjs(time).format('HH:mm')}:25`);
      }
      if (dataMot?.assign_to_admin || qs.parse(location.search)?.reserveCar) {
        fd.append('car', dataMot?.car_details.id || reserveIdFromPCO);
      } else {
        fd.append('user', dataMot?.Driver_details?.id || reserveIdFromPCO);
      }
      if (isCarService) {
        if (dataMot.is_electric) {
          fd.append(
            'testcenter_address',
            form.getFieldValue([
              'test_appointment_details',
              'testcenter_address',
            ]),
          );
          fd.append(
            'testcenter_name',
            form.getFieldValue(['test_appointment_details', 'testcenter_name']),
          );
          fd.append(
            'testcenter_location',
            `${locationMap.lat},${locationMap.lng}`,
          );
        } else {
          fd.append('test_center', form.getFieldValue('test_center'));
        }
        axios
          .post('/service/reserve-timepanel/', fd)
          .then(() => {
            setVisible(false);
            message.success('Appointment scheduled');
            refetch();
          })
          .finally(() => setLoadingBookApponment(false));
      } else if (isMot) {
        axios
          .post('/mot/reserve-timepanel/', fd)
          .then(() => {
            setVisible(false);
            message.success('Appointment scheduled');
            refetch();
          })
          .finally(() => setLoadingBookApponment(false));
      } else if (isPco) {
        fd.append('test_center', form.getFieldValue('test_center'));
        axios
          .post('/pco/reserve-time/', fd)
          .then(() => {
            setVisible(false);
            message.success('Appointment scheduled');
            refetch();
          })
          .finally(() => setLoadingBookApponment(false));
      }
    });
  };
  return (
    <>
      <InfiniteScrollWrapper
        lengthData={data?.results?.[0]?.count}
        hasMore={hasNextPage}
        functionNext={fetchNextPage}
        scrollableTarget="modal-with-infinite-scroll-table"
      >
        <Table
          columns={columns}
          dataSource={data?.pages.reduce((res, cur) => {
            if (cur?.results) {
              res.push(...cur?.results);
            } else res.push(...cur);
            return res;
          }, [])}
          rowKey={(record) => record.id}
          className="bordered-table clickable-table-row"
          loading={isLoading || isFetching}
          scroll={{
            x: 1024,
          }}
          onChange={handelChangeTable}
          onRow={(record) => ({
            onClick: () => {
              history.push({
                search: qs.stringify({
                  id: record.id,
                  ...qs.parse(history.location.search),
                }),
              });
            },
          })}
          pagination={false}
        />
      </InfiniteScrollWrapper>
      <CustomizedModal
        visible={isVisible}
        className="book-appointment"
        onCancel={isChangeing ? onCancel : onConfirmExit}
        afterClose={() => {
          history.push({
            search: qs.exclude(location.search, [
              'id',
              'reserveCar',
              'reserveDriver',
            ]),
          });
          setIsChangeing(false);
          form.resetFields();
        }}
        footer={
          isGarage ? (
            <div className="wrapper-footer-mot">
              {!isHistory && dataMot?.state === 'Pending report' && (
                <>
                  <Button
                    type="primary"
                    onClick={confirmAndSend}
                    style={{ padding: '0 59px' }}
                    loading={loadingConfirm}
                  >
                    Confirm and send
                  </Button>
                  <Button
                    type="default"
                    style={{ padding: '0 59px' }}
                    className="btn-denger"
                    onClick={DriverAbsent}
                    loading={loadingAbset}
                  >
                    Driver was absent
                  </Button>
                </>
              )}
              {!isHistory && dataMot?.state === 'Rejected' && (
                <>
                  <Button
                    type="primary"
                    onClick={confirmAndSend}
                    style={{ padding: '0 59px' }}
                    loading={loadingConfirm}
                  >
                    Confirm and send
                  </Button>
                </>
              )}
            </div>
          ) : (
            <>
              {dataMot?.state === 'Warning!' && (
                <div className="wrapper-footer-mot">
                  <Button
                    style={{ padding: '0 59px' }}
                    type="primary"
                    loading={loadingApplyChanges}
                    onClick={updateReserveTime}
                    disabled={!isChangeing}
                  >
                    Apply changes
                  </Button>
                </div>
              )}
              {dataMot?.state === 'Alert!' && (
                <div className="wrapper-footer-mot">
                  <Button
                    style={{ padding: '0 59px' }}
                    type="primary"
                    loading={loadingBookApponment}
                    onClick={BookAppointment}
                  >
                    Confirm and book appointment
                  </Button>
                </div>
              )}
              {dataMot?.state === 'Pending Car Pictures' && (
                <div className="wrapper-footer-mot">
                  <Button
                    style={{ padding: '0 59px' }}
                    type="primary"
                    loading={loadingApplyChanges}
                    onClick={updateReserveTime}
                    disabled={!isChangeing}
                  >
                    Apply changes
                  </Button>
                </div>
              )}
              {dataMot?.state === 'Mot Test Not Passed!' && (
                <div className="wrapper-footer-mot">
                  <Button
                    style={{ padding: '0 59px' }}
                    type="primary"
                    loading={loadingApplyChanges}
                    onClick={updateReserveTime}
                    disabled={!isChangeing}
                  >
                    Apply changes
                  </Button>
                </div>
              )}
              {dataMot?.state === 'Pending Car Pictures Confirmation' && (
                <div className="wrapper-footer-mot">
                  <Button
                    style={{ padding: '0 59px' }}
                    type="primary"
                    loading={loadingApplyChanges}
                    onClick={updateReserveTime}
                    disabled={!isChangeing}
                  >
                    Apply changes
                  </Button>
                </div>
              )}
              {dataMot?.state === 'Mot Test Not Scheduled' && (
                <div className="wrapper-footer-mot">
                  <Button
                    style={{ padding: '0 59px' }}
                    type="primary"
                    loading={loadingApplyChanges}
                    onClick={updateReserveTime}
                    disabled={!isChangeing}
                  >
                    Apply changes
                  </Button>
                </div>
              )}
              {dataMot?.state === 'Scheduled' && !isPco && (
                <div className="wrapper-footer-mot">
                  <Button
                    style={{ padding: '0 59px' }}
                    type="primary"
                    loading={loadingApplyChanges}
                    onClick={updateReserveTime}
                    disabled={!isChangeing}
                  >
                    Apply changes
                  </Button>
                  <Button
                    type="default"
                    style={{ padding: '0 59px' }}
                    onClick={cancelTimeConfirm}
                    loading={loadingCancel}
                    className="btn-denger"
                  >
                    Cancel appointment
                  </Button>
                </div>
              )}
              {dataMot?.state === 'Due Soon' && (
                <div className="wrapper-footer-mot">
                  <Button
                    style={{ padding: '0 59px' }}
                    type="primary"
                    loading={loadingBookApponment}
                    onClick={BookAppointment}
                  >
                    Confirm and book appointment
                  </Button>
                </div>
              )}
              {dataMot?.state === 'Appointment Canceled' && (
                <div className="wrapper-footer-mot">
                  <Button
                    style={{ padding: '0 59px' }}
                    type="primary"
                    loading={loadingBookApponment}
                    onClick={BookAppointment}
                  >
                    Confirm and book appointment
                  </Button>
                </div>
              )}
              {dataMot?.state === 'Test not booked yet' && (
                <div className="wrapper-footer-mot">
                  <Button
                    style={{ padding: '0 59px' }}
                    type="primary"
                    loading={loadingBookApponment}
                    onClick={BookAppointment}
                  >
                    Confirm and book appointment
                  </Button>
                </div>
              )}
              {reserveIdFromPCO && (
                <div className="wrapper-footer-mot">
                  <Button
                    style={{ padding: '0 59px' }}
                    type="primary"
                    loading={loadingBookApponment}
                    onClick={BookAppointment}
                  >
                    Confirm and book appointment
                  </Button>
                </div>
              )}
              {dataMot?.state === 'PendingReport' && (
                <div className="wrapper-footer-mot">
                  <Button
                    type="primary"
                    onClick={confirmAndSend}
                    style={{ padding: '0 59px' }}
                    loading={loadingConfirm}
                  >
                    Confirm test certificate and invoice
                  </Button>
                  {dataMot?.assign_to_admin && (
                    <Button
                      type="default"
                      style={{ padding: '0 59px' }}
                      className="btn-denger"
                      onClick={DriverAbsent}
                      loading={loadingAbset}
                    >
                      Driver was absent
                    </Button>
                  )}
                </div>
              )}
              {dataMot?.state === 'Pending report' && isCarService && (
                <div className="wrapper-footer-mot">
                  <Button
                    type="primary"
                    onClick={confirmAndSend}
                    style={{ padding: '0 59px' }}
                    loading={loadingConfirm}
                  >
                    Confirm test certificate and invoice
                  </Button>
                </div>
              )}
              {dataMot?.state === 'Pending report confirmation' && (
                <div className="wrapper-footer-mot">
                  <Button
                    type="primary"
                    style={{ padding: '0 59px' }}
                    onClick={confirmInvoiceAdmin}
                    loading={loadingConfirmAdmin}
                  >
                    Confirm invoice and save
                  </Button>
                  <Button
                    type="default"
                    style={{ padding: '0 59px' }}
                    onClick={() => setVisibleRejectModal(true)}
                    className="btn-denger"
                  >
                    Reject report
                  </Button>
                </div>
              )}
            </>
          )
        }
      >
        <Spin spinning={isLoadingMot || isFetchingMot}>
          <Form
            form={form}
            className="inline-container"
            style={{ justifyContent: 'space-between' }}
            onValuesChange={(val) => {
              setIsChangeing(!!val);
            }}
          >
            <ItemsModal
              noLinkCar={noLinkCar}
              noLinkDriver={noLinkDriver}
              isGarage={isGarage}
              modeModal="update"
              dataMot={dataMot}
              setLocation={setLocationMap}
              location={locationMap}
              isCarService={isCarService}
              isHistory={isHistory}
              isMot={isMot}
              isPco={isPco}
              setVisiblePictureModal={setVisiblePictureModal}
              refetchDataMot={refetchDataMot}
            />
          </Form>
        </Spin>
      </CustomizedModal>
      <CustomizedModal
        visible={isVisibleRejectModal}
        onCancel={() => setVisibleRejectModal(false)}
        width={712}
        header={false}
        className="no-cancel"
        centered
        footer={
          <>
            <Button
              type="default"
              style={{ padding: '0 59px' }}
              onClick={() => setVisibleRejectModal(false)}
            >
              Cancel
            </Button>
            <Button
              type="primary"
              style={{ padding: '0 59px' }}
              onClick={reject}
              disabled={!textRejectModal}
            >
              Confirm
            </Button>
          </>
        }
      >
        <Heading
          title="Reject service centre report"
          subTitle="Please specify the reasons why the documents are being rejected"
          element="h3"
        />

        <Input.TextArea
          style={{ marginTop: '24px' }}
          cols={50}
          rows={5}
          placeholder="Rejection reasons"
          onChange={(e) => setTextRejectModal(e.target.value)}
        />
      </CustomizedModal>
      <CustomizedModal
        visible={isVisiblePictureModal}
        onCancel={() => setVisiblePictureModal(false)}
        width={792}
        header={false}
        centered
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            {dataMot?.state === 'Pending Car Pictures' && (
              <div className="wrapper-footer-mot">
                <Button
                  type="primary"
                  style={{ padding: '0 59px' }}
                  loading={loadingUploadCarPic}
                  onClick={UploadCarPictures}
                >
                  Upload car pictures
                </Button>
              </div>
            )}
            {dataMot?.state === 'Car Pictors Rejected' && (
              <div className="wrapper-footer-mot">
                <Button
                  type="primary"
                  style={{ padding: '0 59px' }}
                  loading={loadingUploadCarPic}
                  onClick={UploadCarPictures}
                >
                  Upload car pictures
                </Button>
              </div>
            )}
            {dataMot?.state === 'Pending Car Pictures Confirmation' && (
              <>
                <Button
                  type="default"
                  style={{ padding: '0 59px' }}
                  onClick={() => setVisibleRejectModal(true)}
                  className="btn-denger"
                >
                  Reject car pictures
                </Button>
                <Button
                  type="primary"
                  style={{ padding: '0 59px' }}
                  loading={loadingConfirmCarPic}
                  onClick={ConfirmCarPictures}
                >
                  Confirm car pictures
                </Button>
              </>
            )}
          </div>
        }
      >
        <Title level={3} style={{ marginBottom: '40px' }} orientation="left">
          Car pictures
        </Title>
        <Form className="inline-container" form={form}>
          <Form.Item rules={isRequiredRule} name={['car_picture', 'frontview']}>
            <SinglePhotoUploader
              titleTextUp="Front view (Front windshield and bumper)"
              disabled={
                dataMot?.state !== 'Pending Car Pictures' &&
                dataMot?.state !== 'Car Pictors Rejected'
              }
            />
          </Form.Item>
          <Form.Item rules={isRequiredRule} name={['car_picture', 'rearview']}>
            <SinglePhotoUploader
              titleTextUp="Rear view (Rear windshield and bumper)"
              disabled={
                dataMot?.state !== 'Pending Car Pictures' &&
                dataMot?.state !== 'Car Pictors Rejected'
              }
            />
          </Form.Item>
          <Form.Item rules={isRequiredRule} name={['car_picture', 'leftside']}>
            <SinglePhotoUploader
              titleTextUp="Left side"
              disabled={
                dataMot?.state !== 'Pending Car Pictures' &&
                dataMot?.state !== 'Car Pictors Rejected'
              }
            />
          </Form.Item>
          <Form.Item rules={isRequiredRule} name={['car_picture', 'rightside']}>
            <SinglePhotoUploader
              titleTextUp="Right side"
              disabled={
                dataMot?.state !== 'Pending Car Pictures' &&
                dataMot?.state !== 'Car Pictors Rejected'
              }
            />
          </Form.Item>
        </Form>
      </CustomizedModal>
    </>
  );
}
