import { useInfiniteQuery, useQuery } from 'react-query';
import qs from 'query-string';
import * as api from '../api';

const useUserStates = () => useQuery('userStates', api.getUserStates);
const usePersonalInfoRejectReasons = () =>
  useQuery('personalInfoRejectReasons', api.getPersonalInfoRejectReasons);

const useDriver = (id) =>
  useQuery(['driver', id], () => api.getDriverByID(id), {
    enabled: !!id,
  });

const useDrivers = ({ category, queryParams }) => {
  let fetchEndpoint = '';
  switch (category) {
    case 'archived':
      fetchEndpoint += '/account/archiveveusers/?';
      break;
    case 'newRegistered':
      fetchEndpoint += '/account/newregister/?';
      break;
    case 'active':
    default:
      fetchEndpoint += '/account/activeusers/?';
      break;
  }

  return useInfiniteQuery(
    ['drivers', qs.stringify({ category, ...queryParams })],
    ({ pageParam }) =>
      api.getDrivers({ fetchEndpoint, pageParam, queryParams }),
    {
      getNextPageParam: (lastPage) => lastPage?.next ?? false,
    },
  );
};

export { useUserStates, usePersonalInfoRejectReasons, useDriver, useDrivers };
