import { Button, Form, message, Modal, Tabs } from 'antd';
import { useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useQueryClient } from 'react-query';
import dayjs from 'dayjs';
import { CustomizedModal } from '../../components';
import TableAccident from './TableAccident';
import ItemsModal from './ItemsModal';
import { axios } from '../../utils';

const { TabPane } = Tabs;

export function Accidents() {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [isVisible, setVisible] = useState();
  const [isChangeing, setIsChangeing] = useState();
  const [isLoadingAddReport, setIsLoadingAddReport] = useState(false);
  const [valueStatus, setValueStatus] = useState();

  const category =
    useMemo(() => qs.parse(location.search)?.category, [location.search]) ||
    'new';

  const tabClick = (key) => {
    history.push(`/accidents?category=${key}`);
  };

  const onConfirmExit = () => setVisible(false);

  const onCancel = () => {
    Modal.confirm({
      title: 'Exit Confirmation',
      content:
        'You are leaving this process. All your unsaved data will be lost. Do you wish to proceed?',
      onOk: onConfirmExit,
      okText: 'Leave',
      cancelText: 'Stay',
      okType: 'danger',
      closable: true,
      maskClosable: true,
      closeIcon: true,
    });
  };

  const registerNewAccident = () => {
    form.validateFields().then(() => {
      setIsLoadingAddReport(true);
      const fd = new FormData();
      fd.append('driver', form.getFieldValue('fullName').split('#')[2]);
      fd.append(
        'otherdriver_first_name',
        form.getFieldValue('otherdriver_first_name'),
      );
      fd.append(
        'otherdriver_last_name',
        form.getFieldValue('otherdriver_last_name'),
      );
      fd.append(
        'otherdriver_phone_number',
        form.getFieldValue('otherdriver_phone_number'),
      );
      fd.append(
        'otherdriver_registrationnumber',
        form.getFieldValue('otherdriver_registrationnumber'),
      );
      fd.append(
        'otherdriver_address',
        form.getFieldValue('otherdriver_address'),
      );
      if (
        form.getFieldValue('otherdriver_driving_license')?.file?.originFileObj
      ) {
        fd.append(
          'otherdriver_driving_license',
          form.getFieldValue('otherdriver_driving_license')?.file
            ?.originFileObj,
        );
      }
      fd.append(
        'date',
        dayjs(form.getFieldValue('test_date')).format('YYYY-MM-DD HH:mm:00'),
      );
      fd.append('location', form.getFieldValue('location'));
      fd.append('description', form.getFieldValue('description'));
      fd.append('status', form.getFieldValue('status'));
      fd.append(
        'culprit_of_accident',
        form.getFieldValue('culprit_of_accident'),
      );
      const images = form.getFieldValue('accident_images')?.fileList || [];
      images.forEach((image, index) => {
        fd.append(`image_ids[${index}]`, image.response.data.id);
      });

      axios
        .post('accident/panel/report/', fd)
        .then(() => {
          message.success('Report added successfully');
          setVisible(false);
          queryClient.invalidateQueries('getAccident');
        })
        .finally(() => setIsLoadingAddReport(false));
    });
  };

  return (
    <>
      <Tabs
        tabBarExtraContent={
          <Button
            className="secondary-btn"
            onClick={() => {
              setVisible(true);
            }}
          >
            Register new accident
          </Button>
        }
        activeKey={category}
        onTabClick={tabClick}
        type="card"
        className="tables-tab-bar"
        animated
        destroyInactiveTabPane
      >
        <TabPane tab="On-going cases" key="new">
          <TableAccident />
        </TabPane>
        <TabPane tab="Accidents history" key="history">
          <TableAccident isHistory />
        </TabPane>
      </Tabs>
      <CustomizedModal
        visible={isVisible}
        className="book-appointment"
        onCancel={isChangeing ? onCancel : onConfirmExit}
        afterClose={() => {
          setIsChangeing(false);
          form.resetFields();
          setValueStatus();
        }}
        footer={
          <>
            {valueStatus === 3 ? (
              <Button
                type="primary"
                loading={isLoadingAddReport}
                onClick={form.submit}
              >
                Mark the case as closed
              </Button>
            ) : (
              <Button
                type="primary"
                onClick={form.submit}
                className="footer-cta-btn"
                loading={isLoadingAddReport}
              >
                Register the appointment
              </Button>
            )}
          </>
        }
      >
        <Form
          form={form}
          className="inline-container"
          style={{ justifyContent: 'space-between' }}
          onFinish={registerNewAccident}
          onValuesChange={(val) => {
            setIsChangeing(!!val);
          }}
        >
          <ItemsModal
            titleModal="add new accident"
            form={form}
            setValueStatus={setValueStatus}
          />
        </Form>
      </CustomizedModal>
    </>
  );
}
