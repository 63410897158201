import React, { useState } from 'react';
import {
  Table,
  Input,
  Popconfirm,
  Form,
  Typography,
  Select,
  Button,
  Divider,
  message,
  Modal,
} from 'antd';
import { CheckOutlined, CloseOutlined, PlusOutlined } from '@ant-design/icons';
import { useAdminList } from '../../hooks';
import { axios } from '../../utils';
import { FloatLabel, Icon } from '../../components';

const Option = Select;

const isRequiredRule = [
  {
    required: true,
    message: 'This field is required',
  },
];

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === 'select' ? (
      <Select>
        <Option value="Service Admin">Service Admin</Option>
        <Option value="Superuser">Superuser</Option>
        <Option value="Staff">Staff</Option>
        <Option value="MOT Admin">MOT Admin</Option>
      </Select>
    ) : (
      <Input />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0,
          }}
          rules={[
            {
              required: true,
              message: `Please enter ${title}!`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export default function AddNewAdmin() {
  const [form] = Form.useForm();
  const [formAddAdmin] = Form.useForm();

  const [editingKey, setEditingKey] = useState('');
  const [loadingAddAdmin, setLoadingAddAdmin] = useState(false);
  const [loadingDeleteAdmin, setLoadingDeleteAdmin] = useState(false);
  const [loadingUpdateAdmin, setLoadingUpdateAdmin] = useState(false);

  const isEditing = (record) => record.id === editingKey;

  const {
    data: adminList,
    isLoading,
    refetch: refetchAdminList,
  } = useAdminList();

  const edit = (record) => {
    form.setFieldsValue({
      first_name: '',
      last_name: '',
      age: '',
      address: '',
      ...record,
    });
    setEditingKey(record.id);
  };

  const cancel = () => setEditingKey('');

  const save = async (id) => {
    form.validateFields().then(() => {
      setLoadingUpdateAdmin(true);
      axios
        .put(`/settings/admins/${id}/`, form.getFieldsValue())
        .then(() => {
          refetchAdminList();
          setEditingKey('');
        })
        .finally(() => setLoadingUpdateAdmin(false));
    });
  };

  const deleteAdmin = (id) => {
    setLoadingDeleteAdmin(true);
    axios
      .delete(`/settings/admins/${id}/`)
      .then(() => {
        refetchAdminList();
        setEditingKey('');
      })
      .finally(() => setLoadingDeleteAdmin(false));
  };

  const handleDeleteAdmin = (id) => {
    Modal.confirm({
      title: 'Delete account',
      content: 'Are you sure you want to delete this account?',
      onOk: () => deleteAdmin(id),
      okText: 'Yes',
      cancelText: 'Cancel',
      okType: 'danger',
      closable: true,
      closeIcon: true,
      maskClosable: false,
      centered: true,
    });
  };

  const columns = [
    {
      title: 'First Name',
      dataIndex: 'first_name',
      width: '20%',
      editable: true,
    },
    {
      title: 'Last Name',
      dataIndex: 'last_name',
      width: '20%',
      editable: true,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      width: '20%',
      editable: true,
    },
    {
      title: 'Role',
      dataIndex: 'role',
      width: '20%',
      editable: true,
    },
    {
      title: 'Actions',
      dataIndex: 'operation',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.id)}
              style={{
                marginRight: 16,
              }}
            >
              <CheckOutlined style={{ fontSize: '20px' }} />
            </Typography.Link>
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <CloseOutlined style={{ fontSize: '20px', color: '#da4242' }} />
            </Popconfirm>
          </span>
        ) : (
          <>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
              style={{
                marginRight: 8,
              }}
            >
              <Icon variant="editAdmin" width={24} height={24} />
            </Typography.Link>
            <Typography.Link
              disabled={editingKey !== ''}
              onClick={() => handleDeleteAdmin(record.id)}
            >
              <Icon variant="trash" width={24} height={24} />
            </Typography.Link>
          </>
        );
      },
    },
  ];

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        inputType: col.dataIndex === 'role' ? 'select' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    };
  });

  const addAdmin = () => {
    formAddAdmin.validateFields().then(() => {
      setLoadingAddAdmin(true);
      axios
        .post('/settings/admins/', formAddAdmin.getFieldsValue())
        .then(() => {
          message.success('New admin added successfully');
          formAddAdmin.resetFields();
          refetchAdminList();
        })
        .finally(() => setLoadingAddAdmin(false));
    });
  };

  return (
    <div className="box">
      <Divider className="color-text" orientation="left">
        Admins list
      </Divider>
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell,
            },
          }}
          scroll={{ x: 1000 }}
          dataSource={adminList}
          columns={mergedColumns}
          className="admin-table"
          pagination={false}
          loading={
            isLoading ||
            loadingAddAdmin ||
            loadingDeleteAdmin ||
            loadingUpdateAdmin
          }
        />
      </Form>
      <Form form={formAddAdmin}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginTop: 40,
          }}
        >
          <Form.Item rules={isRequiredRule} name="first_name">
            <FloatLabel label="First name">
              <Input />
            </FloatLabel>
          </Form.Item>
          <Form.Item rules={isRequiredRule} name="last_name">
            <FloatLabel label="Last name">
              <Input />
            </FloatLabel>
          </Form.Item>
          <Form.Item rules={isRequiredRule} name="email">
            <FloatLabel label="Email">
              <Input />
            </FloatLabel>
          </Form.Item>
          <Form.Item
            rules={isRequiredRule}
            style={{ flexBasis: '25%' }}
            name="role"
          >
            <FloatLabel label="Role">
              <Select>
                <Option value="Service Admin">Service Admin</Option>
                <Option value="Superuser">Superuser</Option>
                <Option value="Staff">Staff</Option>
                <Option value="MOT Admin">MOT Admin</Option>
              </Select>
            </FloatLabel>
          </Form.Item>
        </div>
        <Button
          type="dashed"
          block
          className="full-width"
          style={{ margin: '20px 0' }}
          icon={<PlusOutlined />}
          onClick={addAdmin}
          loading={loadingAddAdmin}
        >
          Add a new admin
        </Button>
      </Form>
    </div>
  );
}
