import { Col, Divider, Row, Table } from 'antd';
import { useInfiniteQuery, useQuery } from 'react-query';
import { InfiniteScrollWrapper } from '../../components';
import { usePayments } from '../../hooks';
import { useMileageList } from '../../hooks/mileage';
import { axios } from '../../utils';
import {
  AvalebleCarsColumn,
  MileageColumn,
  MOTColumn,
  outstandingColumn,
  ServiceColumn,
} from './columns';

export function Dashboard() {
  const useServiceList = () =>
    useInfiniteQuery(
      ['servicelist'],
      async ({ pageParam = 1 }) => {
        const { data } = await axios.get(
          `/service/servicelist/?page=${pageParam}`,
        );
        return data;
      },
      {
        getNextPageParam: (lastPage) => lastPage.next,
        getPreviousPageParam: (firstPage) => firstPage.previous,
      },
    );

  const useMOTList = () =>
    useInfiniteQuery(
      ['MOTlist'],
      async ({ pageParam = 1 }) => {
        const { data } = await axios.get(`/mot/motlist/?page=${pageParam}`);
        return data;
      },
      {
        getNextPageParam: (lastPage) => lastPage.next,
        getPreviousPageParam: (firstPage) => firstPage.previous,
      },
    );

  const usePCOList = () =>
    useInfiniteQuery(
      ['PCOlist'],
      async ({ pageParam = 1 }) => {
        const { data } = await axios.get(`/pco/list/?page=${pageParam}`);
        return data;
      },
      {
        getNextPageParam: (lastPage) => lastPage.next,
        getPreviousPageParam: (firstPage) => firstPage.previous,
      },
    );

  const useAvailableCars = () =>
    useQuery(['AvailableCars'], async () => {
      const { data } = await axios.get(`/cars/available/`);
      return data;
    });

  const {
    data: ServiceData,
    hasNextPage: ServiceHasNextPage,
    fetchNextPage: ServiceFetchNextPage,
    isLoading: ServiceIsLoading,
    isFetching: ServiceIsFetching,
  } = useServiceList();

  const {
    data: MotData,
    hasNextPage: MotHasNextPage,
    fetchNextPage: MotFetchNextPage,
    isLoading: MotIsLoading,
    isFetching: MotIsFetching,
  } = useMOTList();
  const {
    data: PCOData,
    hasNextPage: PCOHasNextPage,
    fetchNextPage: PCOFetchNextPage,
    isLoading: PCOIsLoading,
    isFetching: PCOIsFetching,
  } = usePCOList();

  const { data: AvailableCarsData, isLoading: AvailableCarsIsLoading } =
    useAvailableCars();

  const {
    data: exceedingData,
    hasNextPage: exceedingHasNextPage,
    fetchNextPage: exceedingFetchNextPage,
    isLoading: exceedingIsLoading,
    isFetching: exceedingIsFetching,
  } = useMileageList({
    paramers: { type: 'exceeding' },
  });

  const {
    data: outstandingData,
    isLoading: outstandingIsLoading,
    isFetching: outstandingIsFetching,
    hasNextPage: outstandingHasNextPage,
    fetchNextPage: outstandingFetchNextPage,
  } = usePayments({ category: 'outstanding' });

  return (
    <>
      <Row className="dashboard" gutter={[24, 24]}>
        <Col span={12}>
          <div className="box_table">
            <Divider orientation="left">Mileage exceeding cars</Divider>
            <InfiniteScrollWrapper
              lengthData={exceedingData?.pages[0]?.count}
              hasMore={exceedingHasNextPage}
              functionNext={exceedingFetchNextPage}
              scrollableTarget="modal-with-infinite-scroll-table"
            >
              <Table
                bordered
                loading={exceedingIsLoading || exceedingIsFetching}
                dataSource={exceedingData?.pages?.reduce((res, cur) => {
                  if (cur?.result) {
                    res.push(...cur?.result);
                  } else res.push(...cur);
                  return res;
                }, [])}
                columns={MileageColumn}
                pagination={false}
                scroll={{ y: 300 }}
              />
            </InfiniteScrollWrapper>
          </div>
        </Col>
        <Col span={12}>
          <div className="box_table">
            <Divider orientation="left">Cars need to be serviced</Divider>
            <InfiniteScrollWrapper
              lengthData={ServiceData?.pages[0]?.count}
              hasMore={ServiceHasNextPage}
              functionNext={ServiceFetchNextPage}
              scrollableTarget="modal-with-infinite-scroll-table"
            >
              <Table
                bordered
                dataSource={ServiceData?.pages?.reduce((res, cur) => {
                  if (cur?.results) {
                    res.push(...cur?.results);
                  } else res.push(...cur);
                  return res;
                }, [])}
                columns={ServiceColumn}
                loading={ServiceIsLoading || ServiceIsFetching}
                scroll={{ y: 300 }}
                pagination={false}
              />
            </InfiniteScrollWrapper>
          </div>
        </Col>
        <Col span={12}>
          <div className="box_table">
            <Divider orientation="left">
              Drivers’ daily outstanding balance
            </Divider>
            <InfiniteScrollWrapper
              lengthData={outstandingData?.pages[0]?.count}
              hasMore={outstandingHasNextPage}
              functionNext={outstandingFetchNextPage}
              scrollableTarget="modal-with-infinite-scroll-table"
            >
              <Table
                bordered
                dataSource={outstandingData?.pages?.reduce((res, cur) => {
                  if (cur?.results) {
                    res.push(...cur?.results);
                  } else res.push(...cur);
                  return res;
                }, [])}
                columns={outstandingColumn}
                loading={outstandingIsLoading || outstandingIsFetching}
                scroll={{ y: 300 }}
                pagination={false}
              />
            </InfiniteScrollWrapper>
          </div>
        </Col>
        <Col span={12}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <div className="box_table">
                <Divider orientation="left">Cars need new MOT</Divider>
                <InfiniteScrollWrapper
                  lengthData={MotData?.pages[0]?.count}
                  hasMore={MotHasNextPage}
                  functionNext={MotFetchNextPage}
                  scrollableTarget="modal-with-infinite-scroll-table"
                >
                  <Table
                    bordered
                    loading={MotIsLoading || MotIsFetching}
                    dataSource={MotData?.pages?.reduce((res, cur) => {
                      if (cur?.results) {
                        res.push(...cur?.results);
                      } else res.push(...cur);
                      return res;
                    }, [])}
                    columns={MOTColumn}
                    pagination={false}
                    scroll={{ y: 300 }}
                  />
                </InfiniteScrollWrapper>
              </div>
            </Col>
            <Col span={24}>
              <div className="box_table">
                <Divider orientation="left">Cars need new PCO license</Divider>
                <InfiniteScrollWrapper
                  lengthData={PCOData?.pages[0]?.count}
                  hasMore={PCOHasNextPage}
                  functionNext={PCOFetchNextPage}
                  scrollableTarget="modal-with-infinite-scroll-table"
                  scroll={{ y: 300 }}
                >
                  <Table
                    bordered
                    loading={PCOIsLoading || PCOIsFetching}
                    dataSource={PCOData?.pages?.reduce((res, cur) => {
                      if (cur?.results) {
                        res.push(...cur?.results);
                      } else res.push(...cur);
                      return res;
                    }, [])}
                    columns={MOTColumn}
                    pagination={false}
                    scroll={{ y: 300 }}
                  />
                </InfiniteScrollWrapper>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <div className="box_table">
            <Divider orientation="left">Available cars</Divider>

            <Table
              bordered
              dataSource={AvailableCarsData}
              columns={AvalebleCarsColumn}
              pagination={false}
              scroll={{ y: 300 }}
              loading={AvailableCarsIsLoading}
            />
          </div>
        </Col>
      </Row>
    </>
  );
}
