import React, { useRef, useState } from 'react';
import SignatureCanvas from 'react-signature-canvas';
import { Button } from 'antd';

import './styles.scss';
import { CustomizedModal } from '../modal';

export function SignaturePad({ isVisible, close, onFinish, isRequired }) {
  const signCanvasRef = useRef(null);

  const urlToFile = (url, filename, mimeType) =>
    fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], filename, { type: mimeType }));

  const [error, setError] = useState('');

  const clear = () => signCanvasRef.current.clear();

  const captureSignature = () => {
    const isEmpty = signCanvasRef.current.isEmpty();
    if (isEmpty) {
      if (isRequired) {
        setError('Please add your signature');
      } else {
        onFinish({ file: null, base64: null });
        close();
        clear();
      }
    } else {
      setError('');
      const base64 = signCanvasRef.current
        .getTrimmedCanvas()
        .toDataURL('image/png');
      urlToFile(base64, 'test.png', 'image/png').then((file) => {
        onFinish({ file, base64 });
        close();
        clear();
      });
    }
  };

  return (
    <CustomizedModal
      visible={isVisible}
      onCancel={close}
      className="capture-signature-modal"
      footer={
        <div className="footer-cta-btns-container">
          <Button
            type="ghost"
            onClick={clear}
            className="footer-cta-btn half-width ghost"
            size="large"
          >
            Clear
          </Button>
          <Button
            type="primary"
            onClick={captureSignature}
            className="footer-cta-btn half-width"
            size="large"
          >
            Save
          </Button>
        </div>
      }
    >
      <SignatureCanvas
        ref={signCanvasRef}
        canvasProps={{ className: 'signature-canvas' }}
      />
      {error && <p className="error-line">{error}</p>}
    </CustomizedModal>
  );
}
