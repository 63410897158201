import { Button, DatePicker, Form, Select, Spin, Table } from 'antd';
import { useEffect, useState } from 'react';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useSearchCarRegestratinNumber } from '../../hooks';
import { axios } from '../../utils';

export default function DistanceReport() {
  const [searchForm] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const [searchCars, setSearchCars] = useState([]);
  const [distanceData, setDistanceData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const query = qs.parse(location.search);
  delete query.type;

  const { data: carsList, isLoading: CarisLoading } =
    useSearchCarRegestratinNumber(searchCars);

  const generateReport = () => {
    searchForm.validateFields().then(({ registrationnumber, date }) => {
      const searchQuery = qs.stringify({
        start_date: dayjs(date[0]).format('YYYY-MM-DD'),
        start_time: dayjs(date[0]).format('HH:mm:00'),
        end_date: dayjs(date[1]).format('YYYY-MM-DD'),
        end_time: dayjs(date[1]).format('HH:mm:00'),
        registration_number: registrationnumber,
      });
      history.push(`/mileage?type=distance&${searchQuery}`);
    });
  };
  useEffect(() => {
    setIsLoading(true);
    axios
      .get(qs.stringifyUrl({ url: '/mileage/distance/', query }))
      .then(({ data }) => {
        setDistanceData(data);
      })
      .finally(() => setIsLoading(false));
  }, [location]);

  const columns = [
    {
      title: 'Registration number',
      dataIndex: 'registration_number',
    },
    {
      title: 'Current oddometer',
      dataIndex: 'current_odometer',
      key: 'driver_name',
      sorter: true,
    },
    {
      title: 'Trip count',
      dataIndex: 'trip_count',
      key: 'trip_count',
    },
    {
      title: 'Mileage',
      dataIndex: 'distance',
      key: 'distance',
      sorter: (a, b) => a.distance - b.distance,
      render: (distance) => `${distance} Miles`,
    },
  ];

  return (
    <>
      <div className="serach-bar-mailage">
        <Form layout="inline" form={searchForm} style={{ marginBottom: 30 }}>
          <Form.Item
            name="date"
            style={{ flex: '2' }}
            label="Date and time range"
            rules={[
              {
                required: true,
                message: 'Please specify the date and time range of the report',
              },
            ]}
          >
            <DatePicker.RangePicker
              showTime
              format="YYYY-MM-DD HH:mm:00"
              disabledDate={(current) =>
                current && current > dayjs().subtract(0, 'day')
              }
            />
          </Form.Item>
          <Form.Item
            name="registrationnumber"
            style={{ flex: '3' }}
            label="Filter vehicles"
          >
            <Select
              mode="multiple"
              notFoundContent={CarisLoading ? <Spin size="small" /> : undefined}
              placeholder="Please select"
              showSearch
              allowClear
              onSearch={(value) => setSearchCars(value)}
              style={{ width: '100%' }}
              loading={CarisLoading}
              options={
                carsList &&
                carsList?.results?.map((car) => ({
                  value: car.registrationnumber,
                  label: car.registrationnumber,
                }))
              }
            />
          </Form.Item>
          <Button onClick={generateReport}>Generate report</Button>
        </Form>
      </div>
      <Table
        loading={isLoading}
        dataSource={distanceData}
        columns={columns}
        pagination={false}
      />
    </>
  );
}
