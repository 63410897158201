import { Button, Modal, Form, Divider, Input } from 'antd';
import React, { useState } from 'react';

import { CustomizedModal, FloatLabel } from '../../components';
import { CustomDatePicker } from '../../components/custom-date-picker';

import './styles.scss';

const { confirm } = Modal;

// function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
//   const [fetching, setFetching] = useState(false);
//   const [options, setOptions] = useState([]);
//   const fetchRef = useRef(0);
//   const debounceFetcher = useMemo(() => {
//     const loadOptions = (value) => {
//       fetchRef.current += 1;
//       const fetchId = fetchRef.current;
//       setOptions([]);
//       setFetching(true);
//       fetchOptions(value).then((newOptions) => {
//         if (fetchId !== fetchRef.current) {
//           // for fetch callback order
//           return;
//         }

//         setOptions(newOptions);
//         setFetching(false);
//       });
//     };

//     return debounce(loadOptions, debounceTimeout);
//   }, [fetchOptions, debounceTimeout]);
//   return (
//     <Select
//       labelInValue
//       filterOption={false}
//       onSearch={debounceFetcher}
//       notFoundContent={fetching ? <Spin size="small" /> : null}
//       {...props}
//       options={options}
//     />
//   );
// }

export function CreateNewInvoiceModal({ isVisible, close }) {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onConfirmClose = () => {
    close();
    form.resetFields();
  };

  const handleCancel = () => {
    confirm({
      title: 'Exit Confirmation',
      content: 'Are you sure about canceling the registration process?',
      onOk: onConfirmClose,
      okText: 'Yes, exit',
      cancelText: 'Nah, forget it!',
      okType: 'danger',
      closable: true,
      maskClosable: true,
      centered: true,
    });
  };

  const finish = () => {
    setIsSubmitting(true);
    setTimeout(() => setIsSubmitting(false), 3000);
    //
  };

  // eslint-disable-next-line no-unused-vars
  const [selectedDriver, setSelectedDriver] = useState();

  return (
    <CustomizedModal
      visible={isVisible}
      onCancel={handleCancel}
      footer={
        selectedDriver ? (
          <Button
            type="primary"
            onClick={finish}
            className="footer-cta-btn"
            size="large"
            loading={isSubmitting}
          >
            Confirm and generate invoice
          </Button>
        ) : null
      }
    >
      <h2>Create new invoice</h2>
      <Form className="inline-container">
        <Divider orientation="left">Target driver</Divider>
        {/* <FloatLabel label="Name" forceFocused value="Select the target driver">
          <DebounceSelect
            onChange={(newSelected) => setSelectedDriver(newSelected)}
            style={{ width: '100%' }}
          />
        </FloatLabel> */}
        <Form.Item>
          <FloatLabel label="Name" forceFocused value="Jensen Marshall">
            <Input readOnly disabled />
          </FloatLabel>
        </Form.Item>
        <Form.Item>
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'flex-end',
            }}
          >
            <Button type="default">Change driver</Button>
          </div>
        </Form.Item>
        <Form.Item>
          <FloatLabel label="Phone number" forceFocused value="07911-123456">
            <Input readOnly disabled />
          </FloatLabel>
        </Form.Item>
        <Divider orientation="left">Car details</Divider>
        <FloatLabel label="Car model" forceFocused value="Kia Niro">
          <Input readOnly disabled />
        </FloatLabel>
        <FloatLabel label="Registration number" forceFocused value="AA55 AAA">
          <Input readOnly disabled />
        </FloatLabel>
        <Divider orientation="left">Invoice details</Divider>
        <Form.Item>
          <FloatLabel label="Invoice start date">
            <CustomDatePicker />
          </FloatLabel>
        </Form.Item>
        <Form.Item>
          <FloatLabel label="Invoice end date">
            <CustomDatePicker />
          </FloatLabel>
        </Form.Item>
      </Form>
    </CustomizedModal>
  );
}
