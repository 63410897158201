import React, { useEffect, useState } from 'react';
import { Button, Form, Table, Modal, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { useLocation } from 'react-router-dom';
import ExpenseModal from './ExpenseModal';
import { CustomizedModal } from '../../components';
import { axios } from '../../utils';
import { useCar } from '../../hooks';

dayjs.extend(utc);

export function Expense({ expenses, carID, mode, setAddExp }) {
  const [form] = Form.useForm();
  const [formExpense] = Form.useForm();
  const location = useLocation();

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [showRecord, setShowRecord] = useState(false);
  const [expense, setExpense] = useState([]);
  const [id, setId] = useState();

  const { refetch } = useCar(carID);
  const showR = () => setShowRecord(true);
  const cancelRecord = () => setShowRecord(false);
  const showModal = () => setIsModalVisible(true);

  const onUpdate = () => {
    const fd = new FormData();
    fd.append('car', carID);
    fd.append('cost', formExpense.getFieldValue('cost'));
    fd.append('description', formExpense.getFieldValue('description'));
    fd.append('expense_type', formExpense.getFieldValue('expense_type'));
    fd.append('date', dayjs.utc(formExpense.getFieldValue('date')).format());
    fd.append(
      'total_excluded_vat',
      formExpense.getFieldValue('total_excluded_vat'),
    );
    fd.append('vat_amount', formExpense.getFieldValue('vat_amount') || 0);
    fd.append(
      'service_providor',
      formExpense.getFieldValue('service_providor') || 0,
    );
    axios
      .put(`cars/newcar-expense/${id}/`, fd)
      .then(() => {
        message.success('Expense record updated');
        refetch();
      })
      .finally(() => cancelRecord());
  };

  const addWithNewCar = () => {
    setExpense([...expense, form.getFieldsValue()]);
    setIsModalVisible(false);
    form.resetFields();
  };

  const removeWithNewCar = () => {
    setExpense(
      expense.filter(
        (item) => item.description !== formExpense.getFieldValue('description'),
      ),
    );
    formExpense.resetFields();
    cancelRecord();
  };

  const removeItem = () => {
    axios
      .delete(`cars/newcar-expense/${id}/`)
      .then(() => {
        message.success('Expense record deleted');
        refetch();
      })
      .finally(() => cancelRecord());
  };

  const onConfirmExit = () => {
    form.resetFields();
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    Modal.confirm({
      title: 'Exit Confirmation',
      content: 'Do you cancel the add car process?',
      onOk: onConfirmExit,
      okText: 'Yes, exit',
      okType: 'danger',
      closable: true,
      maskClosable: true,
    });
  };

  const onFinish = () => {
    const fd = new FormData();
    fd.append('car', carID);
    fd.append('cost', form.getFieldValue('cost'));
    fd.append('description', form.getFieldValue('description'));
    fd.append('expense_type', form.getFieldValue('expense_type'));
    fd.append('date', dayjs.utc(form.getFieldValue('date')).format());
    fd.append(
      'total_excluded_vat',
      form.getFieldValue('total_excluded_vat') || 0,
    );
    fd.append('vat_amount', form.getFieldValue('vat_amount') || 0);
    fd.append('service_providor', form.getFieldValue('service_providor'));
    axios
      .post(`cars/newcar-expense/`, fd)
      .then(() => {
        message.success('New expense record added');
        refetch();
      })
      .finally(() => setIsModalVisible(false));
  };

  useEffect(() => {
    setIsModalVisible(false);
  }, [location]);

  useEffect(() => {
    if (mode === 'update') setExpense(expenses);
  }, [expenses]);

  useEffect(() => {
    if (mode === 'add') setAddExp(expense);
  }, [expense]);

  const columns = [
    {
      title: 'Expence type',
      dataIndex: 'expense_type',
      render: (type) => (
        <>
          {(String(type) === '1' && 'MOT') ||
            (String(type) === '2' && 'Parts') ||
            (String(type) === '3' && 'PCO') ||
            (String(type) === '4' && 'Road TAX') ||
            (String(type) === '5' && 'Bodywork expenses') ||
            (String(type) === '6' && 'Accident expenses') ||
            (String(type) === '10' && 'Service') ||
            (String(type) === '11' && 'Tyer') ||
            (String(type) === '12' && 'Seat Cover') ||
            (String(type) === '13' && 'Trackers and Camera') ||
            (String(type) === '7' && 'Other expenses')}
        </>
      ),
    },
    {
      title: 'Total',
      dataIndex: 'cost',
      render: (cost) => <>£ {cost}</>,
    },
    {
      title: 'NET',
      dataIndex: 'total_excluded_vat',
      render: (net) => <>£ {net}</>,
    },
    {
      title: 'VAT',
      dataIndex: 'vat_amount',
      render: (vat) => <>£ {vat}</>,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (date) => <>{dayjs(date).format('DD/MM/YYYY')}</>,
      sorter: (a, b) => dayjs(a.date).unix() - dayjs(b.date).unix(),
    },
    {
      title: 'Service Providor',
      dataIndex: 'service_providor',
      render: (vat) => (vat === 'undefined' && '-') || vat,
    },
    {
      title: 'Details',
      dataIndex: 'description',
      render: (val) => (val === 'undefined' && '-') || val,
    },
  ];

  return (
    <>
      <Button
        type="dashed"
        block
        className="full-width"
        style={{ marginBottom: 16 }}
        onClick={showModal}
      >
        <PlusOutlined />
        Add new expense
      </Button>
      <Table
        rowClassName={() => 'editable-row'}
        className="full-width none-border"
        bordered
        dataSource={expense}
        columns={columns}
        pagination={false}
        onRow={(record) => ({
          onClick: () => {
            setId(record.id);
            showR();
            formExpense.setFieldsValue(record);
          },
        })}
        footer={(record) => (
          <div style={{ display: 'flex' }}>
            <p style={{ marginRight: 30 }}>
              Total Cost : £ {record.reduce((acc, cur) => acc + cur.cost, 0)}
            </p>
            <p style={{ flexShrink: 2 }}>
              Total VAT : £{' '}
              {record.reduce((acc, cur) => acc + Number(cur.vat_amount), 0)}
            </p>
          </div>
        )}
      />
      <CustomizedModal
        visible={isModalVisible}
        onCancel={handleCancel}
        afterClose={() => form.resetFields()}
        title="Add expense report"
        maskClosable={false}
        width={792}
        centered
        footer={
          <div>
            <Button
              onClick={form.submit}
              className="footer-cta-btn"
              type="primary"
            >
              Add to expenses
            </Button>
          </div>
        }
      >
        <ExpenseModal
          form={form}
          onFinish={mode === 'update' ? onFinish : addWithNewCar}
        />
      </CustomizedModal>
      <CustomizedModal
        visible={showRecord}
        onCancel={cancelRecord}
        title="Edit expense report"
        maskClosable={false}
        width={792}
        centered
        footer={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              style={{ color: 'red', borderColor: 'red' }}
              type="ghost"
              onClick={mode === 'update' ? removeItem : removeWithNewCar}
            >
              Delete this report
            </Button>
            <Button onClick={formExpense.submit} type="primary">
              Save changes
            </Button>
          </div>
        }
      >
        <ExpenseModal form={formExpense} onFinish={onUpdate} />
      </CustomizedModal>
    </>
  );
}
