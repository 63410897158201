import { useState, useEffect } from 'react';
import { Badge, Button, Layout, Menu } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import cn from 'classnames';

import Logo from '../../assets/logo.png';
import items from './items';
import { Icon } from '../icon';
import { UserProfileBtn } from '../user-profile-btn';

import './styles.scss';
import { useNotificationsCount, useUser } from '../../hooks';
import itemsMot from './itemsMot';
import itemsServise from './itemsService';

const { Sider } = Layout;

export default function SideMenu() {
  const history = useHistory();
  const location = useLocation();

  const [isCollapsed, setCollapsed] = useState();
  const [isSupervisor, setIsSupervisor] = useState(false);

  const { data: user } = useUser();

  useEffect(() => {
    if (user?.[0].is_supervisor) {
      setIsSupervisor(true);
    }
  }, [user]);

  const toggleCollapse = () => {
    setCollapsed((prevState) => !prevState);
  };

  let list;
  let notifCountReqURL;
  let notifPageURL;
  switch (localStorage.getItem('type')) {
    case 'mot':
      list = itemsMot;
      notifCountReqURL = '/mot/count-motnotification/';
      notifPageURL = '/mot/notifications';
      break;
    case 'service':
      list = itemsServise;
      notifCountReqURL = '/service/garage/count-notifications/';
      notifPageURL = '/service/notifications';
      break;
    case 'admin':
      list = items;
      notifCountReqURL = '/account/notifications-count/';
      notifPageURL = '/notifications';
      break;
    default:
      break;
  }

  const { data } = useNotificationsCount({
    type: notifCountReqURL,
  });

  let notificationsCount;
  if (typeof data === 'object') {
    notificationsCount = data.Notifications;
  } else {
    notificationsCount = data;
  }
  const userLevel = localStorage.getItem('type');
  return (
    <Sider
      className={cn('sidebar-root', { 'is-collapsed': isCollapsed })}
      breakpoint="md"
      width={288}
      collapsible
      collapsed={isCollapsed}
      onCollapse={setCollapsed}
      trigger={null}
    >
      <Button className="collapse-btn" onClick={toggleCollapse}>
        <Icon
          variant="arrow-left"
          width={24}
          height={24}
          className={cn('collapse-icon', { rotate: isCollapsed })}
        />
      </Button>
      <div className="sidebar-inner with-custom-scrollbar">
        <header className="sidebar-header">
          <div className="sidebar-logo-container">
            <div>
              <img src={Logo} alt="logo" className="sidebar-logo" />
            </div>
            <h1 className="sidebar-heading">
              <span style={{ textTransform: 'capitalize' }}>{userLevel}</span>
              <span>Panel</span>
            </h1>
          </div>
          <div className="divider" />
        </header>
        <Menu
          theme="light"
          mode="inline"
          selectedKeys={location.pathname}
          className="sidebar-menu"
        >
          <Menu.ItemGroup
            key="general"
            title={!isCollapsed ? 'General' : undefined}
          >
            {userLevel === 'admin' && (
              <Menu.Item
                key="/dashboard"
                icon={
                  <Icon
                    variant="dashboard"
                    className="icon-24 icon-fill-inherit"
                  />
                }
                onClick={() => history.push('/dashboard')}
                style={isCollapsed ? { paddingRight: '32px' } : undefined}
              >
                Dashboard
              </Menu.Item>
            )}
            <Menu.Item
              key={notifPageURL}
              icon={
                <Icon
                  variant="notification"
                  className="icon-24 icon-fill-inherit"
                />
              }
              onClick={() => history.push(notifPageURL)}
              style={isCollapsed ? { paddingRight: '32px' } : undefined}
            >
              <Badge
                size="default"
                count={notificationsCount}
                offset={[!isCollapsed ? -24 : 4, !isCollapsed ? 20 : 10]}
                overflowCount={99}
                color="#2c56b6"
                className={cn('notification-count-badge', {
                  relative: isCollapsed,
                })}
              >
                Notifications
              </Badge>
            </Menu.Item>
            {userLevel === 'admin' && (
              <Menu.Item
                key="/task-board"
                icon={
                  <Icon
                    variant="TaskBoard"
                    className="icon-24 icon-fill-inherit"
                  />
                }
                onClick={() => history.push('/task-board')}
                style={isCollapsed ? { paddingRight: '32px' } : undefined}
              >
                Task board
              </Menu.Item>
            )}
          </Menu.ItemGroup>
          {list?.map((item, index) => (
            <Menu.ItemGroup
              key={[index]}
              title={!isCollapsed ? item.title : undefined}
            >
              {item.items.map((itemMenu) => {
                if (itemMenu.pathname === '/reports' && !isSupervisor)
                  return null;
                return (
                  <Menu.Item
                    key={itemMenu.pathname}
                    icon={itemMenu.Icon}
                    onClick={() => history.push(itemMenu.pathname)}
                  >
                    {itemMenu.Title}
                  </Menu.Item>
                );
              })}
            </Menu.ItemGroup>
          ))}
        </Menu>
      </div>
      <div className="user-profile-container">
        <div className="divider full-width without-margin" />
        <UserProfileBtn />
      </div>
    </Sider>
  );
}
