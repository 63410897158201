/* eslint-disable import/no-webpack-loader-syntax */
import {
  Divider,
  Form,
  Input,
  Spin,
  Select,
  Button,
  message,
  DatePicker,
  InputNumber,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import { FloatLabel } from '../floating-label';
import { CustomDatePicker } from '../custom-date-picker';
import { useCarSearch, useComplex } from '../../hooks';
import { axios } from '../../utils';
import { SinglePhotoUploader } from '../single-photo-uploader';
import { Icon } from '../icon';
import { CustomizedModal } from '../modal';
import { Heading } from '../heading';

mapboxgl.workerClass =
  // eslint-disable-next-line import/no-unresolved
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const { Option } = Select;

export default function ItemsModal({
  modeModal,
  form,
  noLinkCar,
  noLinkDriver,
  isGarage,
  dataMot,
  isCarService,
  setLocation,
  location,
  isHistory,
  isMot,
  isPco,
  setVisiblePictureModal,
  refetchDataMot,
}) {
  const [searchInputCar, setSearchInputDriver] = useState();
  const [isVisibleBodyworkModal, setIsVisibleBodyworkModal] = useState(false);
  const [loadingSubmitBodywork, setLoadingSubmitBodywork] = useState(false);
  const [loadingReserveMot, setLoadingReserveMot] = useState(false);
  const [isOpenModalMotReserve, setIsOpenModalMotReserve] = useState(false);
  const [isElectric, setIsElectric] = useState(false);
  const [viewport, setViewport] = useState({
    height: 224,
    latitude: location?.lat,
    longitude: location?.lng,
    zoom: 9,
  });
  useEffect(() => {
    if (dataMot) {
      setIsElectric(dataMot?.is_electric);
    }
  }, [dataMot]);

  const [FormBodywork] = Form.useForm();
  const [reserveForm] = Form.useForm();

  const {
    data: carSearched,
    isLoading: loadingModel,
    isFetching,
  } = useCarSearch(searchInputCar && searchInputCar, isGarage);

  const { data: dataPcoCenters, isLoading: isLoadingPcoCenters } =
    useComplex(isPco);

  useEffect(() => {
    if (dataPcoCenters && isMot && modeModal === 'show') {
      form?.setFieldsValue({
        test_appointment_details: {
          test_center_name: dataPcoCenters?.filter(
            (item) => item.type === 'MOT test',
          )[0].name,
        },
      });
    }
  });

  // console.log(modeModal);
  const disabledDateMot = (current) =>
    current <
      dayjs(dataMot.test_appointment_details.test_date).subtract(2, 'weeks') ||
    current > dayjs(dataMot.test_appointment_details.test_date);

  // eslint-disable-next-line import/no-webpack-loader-syntax
  mapboxgl.accessToken =
    'pk.eyJ1IjoibW9naGFuaTgxIiwiYSI6ImNrdzByY21xYzBpZTEycXBxaDd4aWhlM28ifQ.imTrkjM7tx4ztuEa1Clgbg';

  let title = '';
  if (isCarService) {
    title = 'Service';
  }
  if (isMot) {
    title = 'MOT';
  }
  if (isPco) {
    title = 'PCO';
  }
  const disabledDate = (current) => current && current < dayjs().startOf('day');
  const isRequiredRule = [
    {
      required: true,
      message: 'This field is required',
    },
  ];

  const priceBase = (value) =>
    value && `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  useEffect(() => {
    if (dataMot?.test_appointment_details?.testcenter_location) {
      setLocation({
        lat: +`${dataMot?.test_appointment_details?.testcenter_location}`.split(
          ',',
        )[0],
        lng: +`${dataMot?.test_appointment_details?.testcenter_location}`.split(
          ',',
        )[1],
      });
    }
  }, [dataMot]);

  const handleChangeLocation = ({ lngLat }) => {
    setLocation({ lat: lngLat[1], lng: lngLat[0] });
  };

  const reserveMotHandler = () =>
    reserveForm.validateFields().then((values) => {
      setLoadingReserveMot(true);
      const sendData = {
        reserve_time: dayjs(values.reserve_time).format('YYYY-MM-DD HH:00:00'),
      };
      if (dataMot?.Driver_details?.id) {
        sendData.user = dataMot?.Driver_details?.id;
      } else {
        sendData.car = dataMot?.car_details?.id;
      }
      axios
        .post('/mot/reserve-timepanel/', sendData)
        .then(() => {
          setIsOpenModalMotReserve(false);
          message.success('MOT appointment reserved successfully');
          refetchDataMot();
        })
        .finally(() => setLoadingReserveMot(false));
    });

  const handleSelect = (id) => {
    form.resetFields(['car_details', ['Driver_details', 'phone_number']]);
    if (id.split('#')[3] === 'true') {
      axios
        .get(`/account/activeusers/${id.split('#')[2]}/`)
        .then(({ data }) => {
          if (data?.assigncar?.fueltype === 'fullElectric') setIsElectric(true);
          else setIsElectric(false);
          form.setFieldsValue({
            Driver_details: {
              phone_number: data.phone_number,
              name: `${data.first_name} ${data.last_name}`,
            },
            car_details: {
              model: data.assigncar.model,
              registrationnumber: data.assigncar.registrationnumber,
              is_electric: data?.assigncar?.fueltype,
            },
            ...data,
          });
        });
    } else {
      axios.get(`/cars/car/${id.split('#')[0]}/`).then(({ data }) => {
        if (data?.fueltype === 'fullElectric') setIsElectric(true);
        else setIsElectric(false);
        form.setFieldsValue({
          car_details: {
            model: data.model,
            registrationnumber: data?.registrationnumber,
            is_electric: data?.fueltype,
          },
          ...data,
        });
      });
    }
  };

  const Submitbodywork = () => {
    FormBodywork.validateFields().then(() => {
      setLoadingSubmitBodywork(true);
      const fd = new FormData();
      const time = dayjs(
        FormBodywork.getFieldValue(['test_appointment_details', 'test_time']),
      ).format('HH:mm:00');
      const date = dayjs(
        FormBodywork.getFieldValue(['test_appointment_details', 'test_date']),
      ).format('YYYY-MM-DD');
      fd.append('reserve_time', `${date} ${time}`);
      fd.append('car', dataMot?.car_details?.id);
      fd.append(
        'testcenter_address',
        FormBodywork.getFieldValue([
          'test_appointment_details',
          'testcenter_address',
        ]),
      );
      fd.append(
        'testcenter_name',
        FormBodywork.getFieldValue([
          'test_appointment_details',
          'testcenter_name',
        ]),
      );
      axios
        .post('/pco/bodywork-reserve/', fd)
        .then(() => {
          message.success('Bodywork appointment reserved successfully');
          setIsVisibleBodyworkModal(false);
          refetchDataMot();
        })
        .finally(() => setLoadingSubmitBodywork(false));
    });
  };
  let titleModal;
  switch (dataMot?.state) {
    case 'Pending report confirmation':
      titleModal = `${title} test result`;
      break;
    case 'PendingReport':
      titleModal = `${title} test result`;
      break;
    case 'Confirmed':
      titleModal = `${title} test result`;
      break;
    case 'Appointment Canceled':
      titleModal = `Book a ${title} appointment`;
      break;
    case 'Due Soon':
      titleModal = `Book a ${title} appointment`;
      break;
    default:
      titleModal =
        modeModal === 'show'
          ? `Book a ${title} appointment`
          : `${title} appointment details`;
      break;
  }
  return (
    <>
      <Title level={4}>{titleModal}</Title>
      {modeModal === 'show' || noLinkCar ? (
        <Divider orientation="left">Car details</Divider>
      ) : (
        <Divider orientation="left" className="tetst">
          <span>Car details</span>
          {dataMot?.car_details?.id && (
            <Link to={`/car-bank?car_id=${dataMot?.car_details?.id}`}>
              Open car profile
            </Link>
          )}
        </Divider>
      )}
      <Form.Item
        className="form-item space-bottom"
        name="fullname"
        rules={isRequiredRule}
      >
        <FloatLabel label="Registration number">
          {isGarage || modeModal === 'update' ? (
            <Input disabled />
          ) : (
            <Select
              loading={loadingModel || isFetching}
              notFoundContent={loadingModel ? <Spin size="small" /> : undefined}
              allowClear
              showSearch
              onSelect={handleSelect}
              disabled={modeModal === 'update'}
              searchValue={searchInputCar}
              onSearch={(value) => setSearchInputDriver(value)}
            >
              {carSearched &&
                carSearched?.results?.map((item) => (
                  <Option
                    key={+item.id}
                    value={`${item.id}#${item.registrationnumber}#${item?.assing_to?.id}#${item?.assing_to?.is_active}`}
                  >
                    {item?.registrationnumber} - {item?.make} {item?.model}
                  </Option>
                ))}
            </Select>
          )}
        </FloatLabel>
      </Form.Item>
      <Form.Item name={['car_details', 'model']}>
        <FloatLabel label="Car model">
          <Input disabled />
        </FloatLabel>
      </Form.Item>
      {modeModal === 'show' || noLinkDriver ? (
        <Divider orientation="left">Drivers details</Divider>
      ) : (
        <Divider orientation="left" className="tetst">
          <span>Drivers details</span>
          {dataMot?.Driver_details?.id && (
            <Link to={`/drivers?driver_id=${dataMot?.Driver_details?.id}`}>
              Open drivers profile
            </Link>
          )}
        </Divider>
      )}
      <Form.Item name={['Driver_details', 'name']}>
        <FloatLabel label="Driver name">
          <Input disabled />
        </FloatLabel>
      </Form.Item>
      <Form.Item name={['Driver_details', 'phone_number']}>
        <FloatLabel label="Phone number">
          <Input maxLength={14} disabled />
        </FloatLabel>
      </Form.Item>

      <Divider orientation="left">{title} appointment details</Divider>
      <Form.Item
        name={['test_appointment_details', 'test_date']}
        rules={isRequiredRule}
      >
        <FloatLabel label="Test date">
          <CustomDatePicker
            disabledDate={disabledDate}
            disabled={
              isHistory ||
              isGarage ||
              (dataMot?.state === 'Pending report' && isCarService) ||
              (dataMot?.state === 'Rejected' && isCarService)
            }
          />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        name={['test_appointment_details', 'test_time']}
        rules={isRequiredRule}
        normalize={(value) => (!isPco ? value.split('-')[0] : value)}
      >
        <FloatLabel label="Test time">
          {isPco ? (
            <CustomDatePicker
              disabled={isHistory}
              format="HH:mm"
              picker="time"
            />
          ) : (
            <Select
              disabled={
                isHistory ||
                isGarage ||
                (dataMot?.state === 'Pending report' && isCarService) ||
                (dataMot?.state === 'Rejected' && isCarService)
              }
            >
              <Option value="09">9 - 10</Option>
              <Option value="10">10 - 11</Option>
              <Option value="11">11 - 12</Option>
              <Option value="12">12 - 13</Option>
              <Option value="14">14 - 15</Option>
              <Option value="15">15 - 16</Option>
              <Option value="16">16 - 17</Option>
            </Select>
          )}
        </FloatLabel>
      </Form.Item>

      {!isGarage && isCarService && isElectric && (
        <>
          <Form.Item
            name={['test_appointment_details', 'testcenter_name']}
            rules={isRequiredRule}
          >
            <FloatLabel label="Test center name">
              <Input
                disabled={
                  (!isPco && isMot && isCarService) ||
                  (isMot && !isPco && !isCarService) ||
                  (isCarService &&
                    !isElectric &&
                    !dataMot?.car_details?.is_electric) ||
                  isHistory
                }
              />
            </FloatLabel>
          </Form.Item>
          <Form.Item
            style={{ flexBasis: '100%' }}
            name={['test_appointment_details', 'testcenter_address']}
            rules={isRequiredRule}
          >
            <FloatLabel label="Address">
              <Input
                disabled={
                  (!isPco && isMot && isCarService) ||
                  (isMot && !isPco && !isCarService) ||
                  (isCarService &&
                    !isElectric &&
                    !dataMot?.car_details?.is_electric) ||
                  isHistory
                }
              />
            </FloatLabel>
          </Form.Item>
        </>
      )}
      {isPco && (
        <Form.Item
          className="form-item space-bottom"
          name="test_center"
          rules={isRequiredRule}
          style={{ flexBasis: '100%' }}
        >
          <FloatLabel label="Complex name">
            <Select
              loading={isLoadingPcoCenters}
              notFoundContent={
                isLoadingPcoCenters ? <Spin size="small" /> : undefined
              }
              allowClear
              showSearch
              disabled={isHistory}
            >
              {dataPcoCenters
                ?.filter((item) => item.type === 'PCO test')
                ?.map((item) => (
                  <Option key={+item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </FloatLabel>
        </Form.Item>
      )}
      {!isGarage && isCarService && !isElectric && (
        <Form.Item
          className="form-item space-bottom"
          name="test_center"
          rules={isRequiredRule}
          style={{ flexBasis: '100%' }}
        >
          <FloatLabel label="Complex name">
            <Select
              loading={isLoadingPcoCenters}
              notFoundContent={
                isLoadingPcoCenters ? <Spin size="small" /> : undefined
              }
              allowClear
              showSearch
              disabled={isHistory}
            >
              {dataPcoCenters
                ?.filter((item) => item.type === 'Vehicle service')
                ?.map((item) => (
                  <Option key={+item.id} value={item.id}>
                    {item.name}
                  </Option>
                ))}
            </Select>
          </FloatLabel>
        </Form.Item>
      )}

      {(isGarage &&
        dataMot?.state !== 'Scheduled' &&
        dataMot?.state !== 'Appointment Canceled' &&
        dataMot?.state !== 'Driver was absent' &&
        dataMot?.state !== 'Pending report') ||
      (isHistory && dataMot?.state === 'Confirmed') ||
      dataMot?.state === 'Pending report confirmation' ||
      dataMot?.state === 'Rejected' ||
      dataMot?.state === 'Pending report' ? (
        <>
          {isMot && (
            <>
              <Form.Item name="test_certificate" rules={isRequiredRule}>
                <SinglePhotoUploader
                  accept="application/pdf"
                  title="Click here to upload"
                  titleTextUp="Test certificate"
                  disabled={isHistory}
                />
              </Form.Item>
              <Form.Item name="invoice" rules={isRequiredRule}>
                <SinglePhotoUploader
                  accept="application/pdf"
                  title="Click here to upload"
                  titleTextUp="Test Invoice"
                  disabled={isHistory}
                />
              </Form.Item>
              <Form.Item
                name="total_excluded_vat"
                style={{ marginTop: 25 }}
                rules={isRequiredRule}
              >
                <FloatLabel label="Total excluded VAT">
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    formatter={priceBase}
                    maxLength={9}
                    className="full-width"
                  />
                </FloatLabel>
              </Form.Item>
              <Form.Item
                name="vat_amount"
                style={{ marginTop: 25 }}
                rules={isRequiredRule}
              >
                <FloatLabel label="VAT amount">
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    formatter={priceBase}
                    maxLength={9}
                    className="full-width"
                  />
                </FloatLabel>
              </Form.Item>
            </>
          )}
          {isPco && (
            <>
              <Form.Item name="testcertificate" rules={isRequiredRule}>
                <SinglePhotoUploader
                  accept="application/pdf"
                  title="Click here to upload"
                  titleTextUp="Test certificate"
                  disabled={isHistory}
                />
              </Form.Item>
              <Form.Item name="invoice" rules={isRequiredRule}>
                <SinglePhotoUploader
                  accept="application/pdf"
                  title="Click here to upload"
                  titleTextUp="Test Invoice"
                  disabled={isHistory}
                />
              </Form.Item>
            </>
          )}
          {isCarService && (
            <>
              <Form.Item name="invoice" rules={isRequiredRule}>
                <SinglePhotoUploader
                  accept="application/pdf"
                  title="Click here to upload"
                  titleTextUp="Test Invoice"
                  disabled={isHistory}
                />
              </Form.Item>
              <Form.Item />
              <Form.Item
                name="total_excluded_vat"
                style={{ marginTop: 25 }}
                rules={isRequiredRule}
              >
                <FloatLabel label="Total excluded VAT">
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    formatter={priceBase}
                    maxLength={9}
                    className="full-width"
                  />
                </FloatLabel>
              </Form.Item>
              <Form.Item
                name="vat_amount"
                style={{ marginTop: 25 }}
                rules={isRequiredRule}
              >
                <FloatLabel label="VAT amount">
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    formatter={priceBase}
                    maxLength={9}
                    className="full-width"
                  />
                </FloatLabel>
              </Form.Item>
              <Form.Item name="current_milage" rules={isRequiredRule}>
                <FloatLabel label="Serviced at mileage">
                  <InputNumber
                    onWheel={(e) => e.target.blur()}
                    className="full-width"
                  />
                </FloatLabel>
              </Form.Item>
              <p style={{ marginTop: 15 }}>
                The mileage that the vehicle was serviced at .
              </p>
            </>
          )}
        </>
      ) : null}

      {isCarService && !isHistory && !isGarage && isElectric && (
        <>
          <ReactMapGL
            {...viewport}
            onViewportChange={(nextViewport) => setViewport(nextViewport)}
            mapboxApiAccessToken="pk.eyJ1IjoibW9naGFuaTgxIiwiYSI6ImNrdzByY21xYzBpZTEycXBxaDd4aWhlM28ifQ.imTrkjM7tx4ztuEa1Clgbg"
            mapStyle="mapbox://styles/moghani81/ckx1ide8437z014o3gv51wtvf"
            style={{ flexBasis: '100%' }}
            className="map"
            onClick={handleChangeLocation}
          >
            <Marker
              latitude={location.lat}
              longitude={location.lng}
              offsetLeft={-20}
              offsetTop={-10}
            >
              <Icon variant="marker" width={24} height={24} />
            </Marker>
          </ReactMapGL>
          <Form.Item
            name={['car_details', 'is_electric']}
            style={{ height: 0, flexBasis: 0 }}
          >
            <Input type="hidden" />
          </Form.Item>
        </>
      )}
      {dataMot?.state === 'PendingReport' && (
        <>
          <Form.Item
            name="test_certificate"
            style={{ marginTop: 25 }}
            rules={isRequiredRule}
          >
            <SinglePhotoUploader
              accept="application/pdf"
              title="Click here to upload"
              titleTextUp="Test certificate"
              disabled={isHistory}
            />
          </Form.Item>
          <Form.Item
            name="invoice"
            style={{ marginTop: 25 }}
            rules={isRequiredRule}
          >
            <SinglePhotoUploader
              accept="application/pdf"
              title="Click here to upload"
              titleTextUp="Test Invoice"
              disabled={isHistory}
            />
          </Form.Item>
        </>
      )}

      {isPco &&
        dataMot?.state !== 'PendingReport' &&
        dataMot?.state !== 'Driver was absent' &&
        dataMot?.state !== 'Test not booked yet' &&
        modeModal === 'update' &&
        !isHistory && (
          <>
            <Divider orientation="left">Latest MOT test details</Divider>
            <Form.Item name={['latest_mot_testdetails', 'last_mot']}>
              <FloatLabel label="Latest MOT test date">
                <CustomDatePicker disabledDate={disabledDate} disabled />
              </FloatLabel>
            </Form.Item>
            <Form.Item name="is_mot">
              <FloatLabel label="Car MOT status">
                <Input
                  style={{
                    border:
                      dataMot?.latest_mot_testdetails?.mot_state !==
                        'Scheduled' &&
                      dataMot?.latest_mot_testdetails?.mot_state !==
                        'Confirmed' &&
                      dataMot?.latest_mot_testdetails?.mot_state !==
                        'Pending report' &&
                      dataMot?.latest_mot_testdetails?.mot_state !==
                        'Pending report confirmation ' &&
                      '1.5px solid #da4242',
                  }}
                  type="text"
                  disabled
                />
              </FloatLabel>
            </Form.Item>
            <Form.Item
              name={['latest_mot_testdetails', 'mot_reserve_scheduled_date']}
            >
              <FloatLabel label="Scheduled date">
                <CustomDatePicker disabledDate={disabledDate} disabled />
              </FloatLabel>
            </Form.Item>
            <Form.Item
              name={['latest_mot_testdetails', 'mot_reserve_scheduled_time']}
            >
              <FloatLabel label="Scheduled time">
                <Input disabled />
              </FloatLabel>
            </Form.Item>
            {dataMot?.latest_mot_testdetails?.mot_state !== 'Scheduled' &&
              dataMot?.latest_mot_testdetails?.mot_state !== 'Confirmed' &&
              dataMot?.latest_mot_testdetails?.mot_state !== 'Pending report' &&
              dataMot?.latest_mot_testdetails?.mot_state !==
                'Pending report confirmation' && (
                <Button
                  type="primary"
                  onClick={() => setIsOpenModalMotReserve(true)}
                >
                  Book an MOT test for this driver
                </Button>
              )}
            <CustomizedModal
              visible={isOpenModalMotReserve}
              onCancel={() => setIsOpenModalMotReserve(false)}
              width={712}
              centered
              footer={
                <>
                  <Button
                    type="primary"
                    style={{ padding: '0 59px' }}
                    onClick={reserveMotHandler}
                    loading={loadingReserveMot}
                  >
                    Submit
                  </Button>
                </>
              }
            >
              <Heading
                title="Book a MOT appointment"
                subTitle="Please fill the form below to book an appointment"
                element="h3"
                style={{ marginBottom: '20px' }}
              />
              <Form form={reserveForm}>
                <Form.Item name="reserve_time" rules={isRequiredRule}>
                  <DatePicker
                    format="YYYY/MM/DD HH"
                    style={{ flexBasis: '100%', width: '100%' }}
                    disabledDate={disabledDateMot}
                    showTime={{
                      hideDisabledOptions: true,
                      disabledHours: () => [
                        0, 1, 2, 3, 4, 5, 6, 7, 8, 13, 16, 17, 18, 19, 20, 21,
                        22, 23,
                      ],
                    }}
                  />
                </Form.Item>
              </Form>
            </CustomizedModal>
            <Button
              type="ghost"
              onClick={() => setVisiblePictureModal(true)}
              style={{ minWidth: '100%', margin: '40px 0' }}
            >
              <Divider orientation="left" style={{ marginTop: 0 }}>
                Car pictures
              </Divider>
            </Button>
            {dataMot?.bodywork_appointment_details ? (
              <>
                <Divider orientation="left">
                  Service appointment details
                </Divider>
                <Form.Item
                  name={['bodywork_appointment_details', 'test_date']}
                  rules={isRequiredRule}
                >
                  <FloatLabel label="Test date">
                    <CustomDatePicker disabledDate={disabledDate} disabled />
                  </FloatLabel>
                </Form.Item>
                <Form.Item
                  name={['bodywork_appointment_details', 'test_time']}
                  rules={isRequiredRule}
                  normalize={(value) => value.split('-')[0]}
                >
                  <FloatLabel label="Test time">
                    <Select disabled>
                      <Option value="09">9 - 10</Option>
                      <Option value="10">10 - 11</Option>
                      <Option value="11">11 - 12</Option>
                      <Option value="12">12 - 13</Option>
                      <Option value="14">14 - 15</Option>
                      <Option value="15">15 - 16</Option>
                      <Option value="16">16 - 17</Option>
                    </Select>
                  </FloatLabel>
                </Form.Item>
                <Form.Item
                  name={['bodywork_appointment_details', 'testcenter_name']}
                  rules={isRequiredRule}
                  initialValue="Kwik Fit"
                >
                  <FloatLabel label="Test center name">
                    <Input disabled />
                  </FloatLabel>
                </Form.Item>
                <Form.Item
                  style={{ flexBasis: '100%' }}
                  name={['bodywork_appointment_details', 'testcenter_address']}
                  rules={isRequiredRule}
                  initialValue="Blackheath Rd, London SE10 8EF, United Kingdom"
                >
                  <FloatLabel label="Address">
                    <Input disabled />
                  </FloatLabel>
                </Form.Item>
              </>
            ) : (
              <Button
                type="primary"
                style={{ minWidth: '100%' }}
                onClick={() => setIsVisibleBodyworkModal(true)}
              >
                Submit a bodywork appointment
              </Button>
            )}
          </>
        )}
      {(dataMot?.reject_reason &&
        isGarage &&
        dataMot?.state === 'Accepted by admin') ||
        (dataMot?.state === 'Rejected' && (
          <div className="rejection-reason">
            <Divider orientation="left">Service appointment details</Divider>
            {dataMot?.reject_reason}
          </div>
        ))}
      <CustomizedModal
        visible={isVisibleBodyworkModal}
        onCancel={() => setIsVisibleBodyworkModal(false)}
        width={712}
        centered
        afterClose={() => {
          FormBodywork.resetFields();
        }}
        footer={
          <>
            <Button
              type="primary"
              style={{ padding: '0 59px' }}
              onClick={Submitbodywork}
              loading={loadingSubmitBodywork}
            >
              Submit
            </Button>
          </>
        }
      >
        <Heading
          title="Submit a bodywork appointment"
          subTitle="Please fill the form below to submit the bodywork appointment"
          element="h3"
        />
        <Form
          style={{ marginTop: 24 }}
          className="inline-container"
          form={FormBodywork}
        >
          <Form.Item
            name={['test_appointment_details', 'test_date']}
            rules={isRequiredRule}
          >
            <FloatLabel label="Test date">
              <CustomDatePicker
                disabledDate={disabledDate}
                disabled={isHistory || isGarage}
              />
            </FloatLabel>
          </Form.Item>
          <Form.Item
            name={['test_appointment_details', 'test_time']}
            rules={isRequiredRule}
            normalize={(value) => (!isPco ? value.split('-')[0] : value)}
          >
            <FloatLabel label="Test time">
              {isPco ? (
                <CustomDatePicker
                  disabled={isHistory}
                  format="HH:mm"
                  picker="time"
                  value="gg"
                />
              ) : (
                <Select
                  disabled={
                    isHistory ||
                    isGarage ||
                    (dataMot?.state === 'Pending report' && isCarService) ||
                    (dataMot?.state === 'Rejected' && isCarService)
                  }
                >
                  <Option value="09">9 - 10</Option>
                  <Option value="10">10 - 11</Option>
                  <Option value="11">11 - 12</Option>
                  <Option value="12">12 - 13</Option>
                  <Option value="14">14 - 15</Option>
                  <Option value="15">15 - 16</Option>
                  <Option value="16">16 - 17</Option>
                </Select>
              )}
            </FloatLabel>
          </Form.Item>
          <Form.Item
            name={['test_appointment_details', 'testcenter_name']}
            rules={isRequiredRule}
          >
            <FloatLabel label="Test center name">
              <Input />
            </FloatLabel>
          </Form.Item>
          <Form.Item
            style={{ flexBasis: '100%' }}
            name={['test_appointment_details', 'testcenter_address']}
            rules={isRequiredRule}
          >
            <FloatLabel label="Address">
              <Input />
            </FloatLabel>
          </Form.Item>
        </Form>
      </CustomizedModal>
    </>
  );
}
