import { Divider } from 'antd';

import { PersonalInfoSection } from './PersonalInfo';
import { LicenseInfoSection } from './LicenseInfo';
import { DrivingAndPCOLicenseSection } from './DrivingAndPCOLicense';
import { InsuranceNumberSection } from './InsuranceNumber';
import { Notes } from './Notes';

function SignupDocumentsStep() {
  return (
    <>
      <Divider orientation="left">Driver&apos;s personal information</Divider>
      <PersonalInfoSection />
      <Divider orientation="left">License info</Divider>
      <LicenseInfoSection />
      <Divider orientation="left" style={{ marginTop: '40px' }}>
        Driving and PCO license
      </Divider>
      <DrivingAndPCOLicenseSection />
      <Divider orientation="left">National Insurance number</Divider>
      <InsuranceNumberSection />
      <Divider orientation="left">Notes</Divider>
      <Notes />
    </>
  );
}

export {
  SignupDocumentsStep,
  PersonalInfoSection,
  LicenseInfoSection,
  DrivingAndPCOLicenseSection,
  InsuranceNumberSection,
};
