import { Steps } from 'antd';

import { CheckMarkDot } from '../check-mark-dot';

import './styles.scss';

export const CustomizedStepper = ({
  current,
  steps,
  setCurrent,
  onChange,
  ...restProps
}) => (
  <Steps
    className="styled-steps"
    current={current}
    responsive
    labelPlacement="vertical"
    size="small"
    onChange={(cur) => {
      setCurrent?.(cur);
      onChange?.(cur);
    }}
    {...restProps}
  >
    {steps.map((step) => {
      const { title, ...rest } = step;
      return (
        <Steps.Step
          stepIcon={CheckMarkDot}
          title={title}
          key={title}
          {...rest}
        />
      );
    })}
  </Steps>
);
