import { Button } from 'antd';
import { useHistory } from 'react-router';
import { string, func } from 'prop-types';

import cn from 'classnames';
import { Icon } from '../icon';

import './index.scss';

/**
 * Callback for click on go-back button.
 * @callback goBack
 */

/**
 * @param {goBack=} onClick
 * @param {string=} [className='']
 */
export function GoBackBtn({ onClick, className, ...restProps }) {
  const history = useHistory();

  const handleGoBack = () => {
    if (onClick) onClick();
    else history.goBack();
  };

  return (
    <Button
      onClick={handleGoBack}
      type="text"
      className={cn('go-back-btn', className)}
      icon={
        <Icon variant="arrow-left" width={24} height={24} fill="currentColor" />
      }
      {...restProps}
    >
      Back
    </Button>
  );
}
GoBackBtn.propTypes = {
  className: string,
  onClick: func,
};
GoBackBtn.defaultProps = {
  className: '',
  onClick: undefined,
};
