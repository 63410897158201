import qs from 'query-string';
import axios from '../utils/axios';

export const getPCNs = async ({
  pageParam = 1,
  fetchEndpoint = '/pcn/new-pcn-panel/?',
  queryParams,
}) => {
  let endpoint = fetchEndpoint;
  const queryString = qs.stringify(
    {
      page: pageParam || null,
      ...queryParams,
    },
    { skipNull: true, skipEmptyString: true },
  );

  if (queryString) endpoint += queryString;

  const { data } = await axios.get(endpoint);
  return data;
};
