import {
  Divider,
  Form,
  Input,
  Spin,
  Select,
  Button,
  Table,
  Checkbox,
  Upload,
  Modal,
  message,
  InputNumber,
  Row,
  Col,
} from 'antd';
import Title from 'antd/lib/typography/Title';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { useState } from 'react';
import { RightOutlined } from '@ant-design/icons';
import { useQueryClient } from 'react-query';
import { useDriverMOt } from '../../hooks';
import { allowOnlyNumbers, axios, getBase64 } from '../../utils';
import { FloatLabel } from '../../components/floating-label';
import { CustomDatePicker } from '../../components/custom-date-picker';
import { SinglePhotoUploader } from '../../components/single-photo-uploader';
import { CustomizedModal } from '../../components';

const { Option } = Select;

export default function ItemsModal({
  modeModal,
  form,
  noLinkCar,
  noLinkDriver,
  dataModal,
  titleModal,
  isHistory,
  setValueStatus,
  setIsEnableInstallment,
  isEnableInstallment,
  setIsOtherDriver,
  isOtherDriver,
}) {
  const [searchInputDriver, setSearchInputDriver] = useState('');
  const [isVisibleAccidentPictures, setIsVisibleAccidentPictures] =
    useState(false);
  const [isLoadingGoToSagepay, setIsLoadingGoToSagepay] = useState(false);
  const [disableAccidentPicBtn, setDisableAccidentPicBtn] = useState(true);
  const [isVisablePayWithCash, setVisablePayWithCash] = useState(false);
  const [isVisableRefrenceNumber, setVisableRefrenceNumber] = useState(false);

  const [preview, setPreview] = useState({
    image: null,
    visible: false,
  });
  const queryClient = useQueryClient();

  const {
    data: driverSearch,
    isLoading: loadingModel,
    isFetching,
  } = useDriverMOt(searchInputDriver, undefined);

  const editImages = (data) => {
    const fd = new FormData();
    const images =
      dataModal?.accident_images ||
      form.getFieldValue('accident_images').fileList;

    images.forEach((image, index) => {
      fd.append(
        `image_ids[${index}]`,
        image.response ? image.response.data.id : image.id,
      );
    });

    fd.append(`image_ids[${images.length}]`, data.id);

    axios.put(`accident/panel/report/${dataModal.id}/`, fd);
  };

  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      // eslint-disable-next-line no-param-reassign
      file.preview = await getBase64(file.originFileObj);
    }
    setPreview({
      image: file.url || file.preview,
      visible: true,
    });
  };

  const isRequiredRule = [
    {
      required: true,
      message: 'This field is required',
    },
  ];

  const columns = [
    {
      title: 'Id',
      dataIndex: 'id',
    },
    {
      title: '£ 500.00',
      dataIndex: 'installment_amount',
      render: (val) => `£ ${val}`,
    },
    {
      title: 'Date',
      dataIndex: 'start_date',
      render: (date) => dayjs(date).format('DD/MM/YYYY'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
  ];

  const goToSagepay = () => {
    form.validateFields().then(() => {
      setIsLoadingGoToSagepay(true);
      axios
        .post('/payment/collect-accident-payment', {
          accident: dataModal?.id,
          reference_number: +form.getFieldValue([
            'payment',
            'opayo_payment',
            'reference_number',
          ]),
          amount: +form.getFieldValue('amount'),
          driver_car: dataModal?.driver,
        })
        .then(({ data: { gateway } }) => {
          window.location.href = gateway;
        })
        .finally(() => setIsLoadingGoToSagepay(false));
    });
  };

  const priceBase = (value) =>
    value && `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const handleSelect = (id) => {
    axios.get(`/account/activeusers/${id}/`).then(({ data }) => {
      setDisableAccidentPicBtn(false);
      form.setFieldsValue({
        Driver_details: { phone_number: data.phone_number },
        car_details: {
          model: data.assigncar.model,
          registrationnumber: data.assigncar.registrationnumber,
          is_electric: data?.assigncar?.fueltype,
        },
      });
    });
  };

  const uploadImage = ({ onSuccess, onError, onProgress, file, car }) => {
    const fd = new FormData();
    fd.append('image', file);
    fd.append('car', car);
    return new Promise((resolve, reject) => {
      axios
        .post('/accident/panel/add-image/', fd, {
          onUploadProgress: (e) => {
            const percent = (e.loaded / e.total) * 100;
            onProgress({ ...e, percent });
          },
        })
        .then((data) => {
          onSuccess(data);
          resolve(data);
        })
        .catch((err) => {
          onError(err);
          reject(err);
        });
    });
  };

  const removePicHandler = (image) => {
    const id = image.response ? image.response.data.id : image.id;
    return axios.delete(`/accident/panel/add-image/${id}/`).then(() => {
      if (dataModal) {
        return true;
      }
      return true;
    });
  };

  const payWithCash = () => {
    form.validateFields(['amount']).then(() => {
      axios
        .post('/payment/cash/', {
          amount: form.getFieldValue('amount'),
          invoice: dataModal?.invoice?.id,
          driver_car: dataModal?.driver,
        })
        .then(() => {
          message.success('Payment successful');
          queryClient.invalidateQueries('getAccidentId');
          form.resetFields(['amount']);
          setVisablePayWithCash(false);
        });
    });
  };

  const ColumnsPay = [
    {
      title: 'Date',
      dataIndex: 'date',
      render: (date) => dayjs(date).format('DD/MM/YYYY'),
    },
    {
      title: 'Source',
      dataIndex: 'source',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
  ];

  return (
    <>
      <Title level={4}>{titleModal}</Title>
      {modeModal === 'show' || noLinkDriver ? (
        <Divider orientation="left">Drivers details</Divider>
      ) : (
        <Divider orientation="left" className="tetst">
          <span>Drivers details</span>
          {dataModal?.Driver_details?.id && (
            <Link to={`/drivers?driver_id=${dataModal?.Driver_details?.id}`}>
              Open drivers profile
            </Link>
          )}
        </Divider>
      )}
      <Form.Item
        className="form-item space-bottom"
        name="fullName"
        rules={isRequiredRule}
      >
        <FloatLabel label="Full name">
          {modeModal === 'update' ? (
            <Input disabled />
          ) : (
            <Select
              loading={loadingModel || isFetching}
              notFoundContent={loadingModel ? <Spin size="small" /> : undefined}
              allowClear
              showSearch
              onSelect={handleSelect}
              disabled={modeModal === 'update'}
              searchValue={searchInputDriver}
              onSearch={(value) => setSearchInputDriver(value)}
              onClear={() => setDisableAccidentPicBtn(true)}
            >
              {driverSearch &&
                driverSearch?.results?.map((item) => (
                  <Option
                    key={+item.id}
                    value={`${item.id}#${item.first_name}${item.last_name}#${item?.assigncar?.assing_to?.drivercar_id}#${item?.assigncar?.id}`}
                  >
                    {item?.first_name} {item?.last_name}{' '}
                    {`- ${item?.assigncar?.model} ${item?.assigncar?.registrationnumber}`}
                  </Option>
                ))}
            </Select>
          )}
        </FloatLabel>
      </Form.Item>
      <Form.Item name={['Driver_details', 'phone_number']}>
        <FloatLabel label="Phone number">
          <Input maxLength={14} />
        </FloatLabel>
      </Form.Item>
      {modeModal === 'show' || noLinkCar ? (
        <Divider orientation="left">Car details</Divider>
      ) : (
        <Divider orientation="left" className="tetst">
          <span>Car details</span>
          {dataModal?.car_details?.id && (
            <Link to={`/car-bank?car_id=${dataModal?.car_details?.id}`}>
              Open car profile
            </Link>
          )}
        </Divider>
      )}
      <Form.Item name={['car_details', 'model']}>
        <FloatLabel label="Car model">
          <Input disabled />
        </FloatLabel>
      </Form.Item>
      <Form.Item name={['car_details', 'registrationnumber']}>
        <FloatLabel label="Registration number">
          <Input disabled />
        </FloatLabel>
      </Form.Item>
      <Divider orientation="left">Other drivers details</Divider>
      <Form.Item name="otherdriver_driving_license">
        <SinglePhotoUploader
          disabled={isHistory}
          titleTextUp="Driver's driving license picture"
        />
      </Form.Item>
      <Form.Item />
      <Form.Item rules={isRequiredRule} name="otherdriver_first_name">
        <FloatLabel label="First Name">
          <Input disabled={isHistory} />
        </FloatLabel>
      </Form.Item>
      <Form.Item rules={isRequiredRule} name="otherdriver_last_name">
        <FloatLabel label="Last Name">
          <Input disabled={isHistory} />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        name="otherdriver_phone_number"
        normalize={(value) => value.replace(/-*/g, '')}
        rules={isRequiredRule}
      >
        <FloatLabel label="Phone number">
          <Input maxLength={14} />
        </FloatLabel>
      </Form.Item>
      <Form.Item rules={isRequiredRule} name="otherdriver_registrationnumber">
        <FloatLabel label="Car registration number">
          <Input disabled={isHistory} />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        rules={isRequiredRule}
        name="otherdriver_address"
        style={{ flexBasis: '100%' }}
      >
        <FloatLabel label="Full address">
          <Input disabled={isHistory} />
        </FloatLabel>
      </Form.Item>
      <Divider orientation="left">Information of the accident</Divider>
      <Form.Item name="test_date" rules={isRequiredRule}>
        <FloatLabel label="Accident date">
          <CustomDatePicker
            format="YYYY-MM-DD HH:mm"
            showTime
            disabled={isHistory}
          />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        rules={isRequiredRule}
        name="location"
        style={{ flexBasis: '100%' }}
      >
        <FloatLabel label="Location of the accident">
          <Input disabled={isHistory} />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        rules={isRequiredRule}
        name="description"
        style={{ flexBasis: '100%' }}
      >
        <FloatLabel label="A brief description of how the accident happened">
          <Input disabled={isHistory} />
        </FloatLabel>
      </Form.Item>
      <Button
        onClick={() => setIsVisibleAccidentPictures(true)}
        className="open-expenses"
        style={{ marginTop: 0 }}
        disabled={modeModal !== 'update' && disableAccidentPicBtn}
      >
        <span>Accident scene pictures</span>
        <RightOutlined />
      </Button>

      <Divider orientation="left">Case status</Divider>
      <Form.Item name="culprit_of_accident" rules={isRequiredRule}>
        <FloatLabel label="Culprit of accident">
          <Select
            disabled={isHistory || dataModal?.payment?.type}
            onSelect={(value) => {
              if (modeModal === 'update') setIsOtherDriver(value);
            }}
          >
            <Option value={1}>Our driver</Option>
            <Option value={2}>The other driver</Option>
          </Select>
        </FloatLabel>
      </Form.Item>
      <Form.Item name="status" rules={isRequiredRule}>
        <FloatLabel label="Case status">
          <Select
            disabled={isHistory}
            onSelect={(val) => {
              if (val === 'Pending Review') setValueStatus(1);
              if (val === 'In progress') setValueStatus(2);
              if (val === 'Closed') setValueStatus(3);
            }}
          >
            <Option value={1}>Pending Review</Option>
            <Option value={2}>In progress</Option>
            <Option value={3}>Closed</Option>
          </Select>
        </FloatLabel>
      </Form.Item>
      {modeModal === 'update' &&
        isOtherDriver === 1 &&
        dataModal?.culprit_of_accident !== null &&
        (isOtherDriver === 1 ||
          dataModal?.culprit_of_accident === 'Our driver') && (
          <>
            <Form.Item
              name="installment"
              valuePropName="checked"
              style={{ flexBasis: '100%' }}
            >
              <Checkbox
                checked={isEnableInstallment}
                onChange={(e) => setIsEnableInstallment(e.target.checked)}
                disabled={dataModal?.payment?.type}
              >
                Collect the payment via installment plan
              </Checkbox>
            </Form.Item>
            {!isEnableInstallment ? (
              <>
                <Row style={{ flexBasis: '100%' }} justify="space-between">
                  <Col>
                    <Form.Item
                      rules={isRequiredRule}
                      name={['payment', 'total_to_be_collected']}
                    >
                      <FloatLabel label="Insurance cost">
                        <InputNumber
                          onWheel={(e) => e.target.blur()}
                          formatter={priceBase}
                          className="full-width"
                          maxLength={9}
                          disabled={
                            isHistory || !!dataModal?.payment?.total_collected
                          }
                        />
                      </FloatLabel>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      onClick={() => setVisableRefrenceNumber(true)}
                      loading={isLoadingGoToSagepay}
                      disabled={
                        dataModal?.payment?.total_collected ===
                        dataModal?.payment?.total_to_be_collected
                      }
                    >
                      Collect via Sagepay
                    </Button>
                  </Col>
                  <Col>
                    <Button
                      type="primary"
                      onClick={() => setVisablePayWithCash(true)}
                      loading={isLoadingGoToSagepay}
                      disabled={
                        dataModal?.payment?.total_collected ===
                        dataModal?.payment?.total_to_be_collected
                      }
                    >
                      Collect via Cash
                    </Button>
                  </Col>
                </Row>
                <Table
                  columns={ColumnsPay}
                  dataSource={dataModal?.payment?.payments}
                  pagination={false}
                  style={{ flexBasis: '100%' }}
                  footer={() => (
                    <div className="total-amount">
                      <span>Total amount collected :</span>
                      <span>£{dataModal?.payment?.total_collected}</span>
                    </div>
                  )}
                />
              </>
            ) : (
              <>
                <Form.Item
                  name="totalCollected"
                  rules={isRequiredRule}
                  getValueProps={() => ({
                    value:
                      dataModal.payment.total_to_be_collected -
                        dataModal.payment.total_collected || 500,
                  })}
                >
                  <FloatLabel label="Total to be collected">
                    <InputNumber
                      onWheel={(e) => e.target.blur()}
                      formatter={priceBase}
                      maxLength={9}
                      className="full-width"
                      disabled
                    />
                  </FloatLabel>
                </Form.Item>
                <Form.Item
                  name="lenghtInvoice"
                  rules={[
                    {
                      pattern: /^[2-9]?$|^10$/,
                      message: 'You can pay between 2 and 10 installments',
                    },
                    {
                      required: true,
                      message: 'You must enter the number of installments',
                    },
                  ]}
                >
                  <FloatLabel label="Number of installments">
                    <Input
                      onKeyPress={allowOnlyNumbers}
                      disabled={dataModal?.payment?.type === 'GoCardless'}
                    />
                  </FloatLabel>
                </Form.Item>

                <Form.Item
                  name="lenghtInvoice"
                  getValueProps={() => ({
                    value:
                      (dataModal.payment.total_to_be_collected -
                        dataModal.payment.total_collected || 500) /
                      (form.getFieldValue('lenghtInvoice') || 1),
                  })}
                >
                  <FloatLabel label="Amount of each installment">
                    <Input disabled />
                  </FloatLabel>
                </Form.Item>
                <Form.Item name="first_charge_date" rules={isRequiredRule}>
                  <FloatLabel label="Direct debit start date">
                    <CustomDatePicker />
                  </FloatLabel>
                </Form.Item>
                {dataModal?.payment?.type === 'GoCardless' && (
                  <Table
                    scroll={{
                      x: 750,
                    }}
                    columns={columns}
                    style={{ flexBasis: '100%' }}
                    dataSource={
                      dataModal?.payment?.gocardless_invoice?.payments
                    }
                    pagination={false}
                  />
                )}
              </>
            )}
          </>
        )}

      <CustomizedModal
        visible={isVisibleAccidentPictures}
        onCancel={() => setIsVisibleAccidentPictures(false)}
        width={792}
        centered
        footer={false}
      >
        <div
          className="inline-container"
          style={{ justifyContent: 'space-between' }}
        >
          <Form.Item
            getValueProps={(values) => {
              if (Array.isArray(values)) {
                return {
                  fileList: values?.map(({ image, id }) => ({
                    url: image,
                    id,
                  })),
                };
              }
              return values;
            }}
            valuePropName="fileList"
            name="accident_images"
            className="accident_images"
          >
            <Upload
              accept="image/png, image/jpeg, image/jpg"
              customRequest={(e) => {
                const car =
                  dataModal?.car_details?.id ||
                  form?.getFieldValue('fullName').split('#')[3];
                return uploadImage({ ...e, car }).then(({ data }) => {
                  if (dataModal) {
                    editImages(data);
                  }
                });
              }}
              disabled={isHistory}
              onPreview={handlePreview}
              listType="picture-card"
              onRemove={removePicHandler}
            >
              {dataModal?.accident_images.length >= 10
                ? null
                : 'Click here to upload'}
            </Upload>
          </Form.Item>

          <Modal
            visible={preview.visible}
            title={null}
            footer={null}
            closable={false}
            onCancel={() => setPreview({ visible: false, image: null })}
            className="preview-pic-modal"
          >
            <img style={{ width: '100%' }} src={preview.image} />
          </Modal>
        </div>
      </CustomizedModal>
      <CustomizedModal
        visible={isVisablePayWithCash}
        centered
        width={416}
        footer={
          <Button
            style={{ width: '100%' }}
            type="primary"
            onClick={payWithCash}
          >
            Collect via Cash
          </Button>
        }
        onCancel={() => setVisablePayWithCash(false)}
        afterClose={() => form.resetFields(['refrence_number'])}
      >
        <Form.Item
          name="amount"
          rules={[
            { required: true, message: 'This field is required' },
            () => ({
              validator(_rule, value) {
                if (
                  +value >
                  dataModal?.payment.total_to_be_collected -
                    dataModal?.payment.total_collected
                ) {
                  return Promise.reject(
                    Error(
                      'The payment amount should be less than the invoice amount',
                    ),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <FloatLabel label="Amount">
            <Input type="number" />
          </FloatLabel>
        </Form.Item>
      </CustomizedModal>
      <CustomizedModal
        visible={isVisableRefrenceNumber}
        centered
        width={416}
        footer={
          <Button
            style={{ width: '100%' }}
            type="primary"
            onClick={goToSagepay}
          >
            Collect via sage pay
          </Button>
        }
        onCancel={() => setVisableRefrenceNumber(false)}
        afterClose={() => form.resetFields(['refrence_number', 'amount'])}
      >
        <Form.Item
          name="amount"
          rules={[
            { required: true, message: 'This field is required' },
            () => ({
              validator(_rule, value) {
                if (
                  +value >
                  dataModal?.payment.total_to_be_collected -
                    dataModal?.payment.total_collected
                ) {
                  return Promise.reject(
                    Error('Amount should be less than deposit amount'),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <FloatLabel label="Amount">
            <Input
              disabled={
                dataModal?.payment?.opayo_payment?.confirm_payment_status ===
                'OK'
              }
              type="number"
            />
          </FloatLabel>
        </Form.Item>
        <Form.Item
          name={['payment', 'opayo_payment', 'reference_number']}
          rules={isRequiredRule}
        >
          <FloatLabel label="Payment reference number">
            <Input
              type="number"
              disabled={
                dataModal?.payment?.opayo_payment?.confirm_payment_status ===
                'OK'
              }
            />
          </FloatLabel>
        </Form.Item>
      </CustomizedModal>
    </>
  );
}
