import { Button, Tabs, Modal, Form, message } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import dayjs from 'dayjs';
import { useQueryClient } from 'react-query';
import { ScheduleTable, TableListMOT, CustomizedModal } from '../../components';
import ItemsModal from '../../components/test-center/ItemsModal';
import { axios } from '../../utils';

const { TabPane } = Tabs;

export function Pco() {
  const [form] = Form.useForm();
  const location = useLocation();
  const history = useHistory();
  const queryClient = useQueryClient();

  const [isChangeing, setIsChangeing] = useState();
  const [isVisible, setVisible] = useState(false);
  const [loadingBookApponment, setLoadingBookApponment] = useState(false);

  const category =
    useMemo(() => qs.parse(location.search)?.category, [location.search]) ||
    'upcoming';

  const tabClick = (key) => history.push(`/pco-test?category=${key}`);

  useEffect(() => setVisible(false), [location]);

  const onConfirmExit = () => setVisible(false);

  const onCancel = () => {
    Modal.confirm({
      title: 'Exit Confirmation',
      content:
        'You are leaving this process. All your unsaved data will be lost. Do you wish to proceed?',
      onOk: onConfirmExit,
      okText: 'Leave',
      cancelText: 'Stay',
      okType: 'danger',
      closable: true,
      maskClosable: true,
      closeIcon: true,
    });
  };

  const [locationMap, setLocationMap] = useState({
    lat: 51.5320534,
    lng: -0.2959684,
  });

  const confirmBookAppointment = () => {
    setLoadingBookApponment(true);
    const time = form.getFieldValue(['test_appointment_details', 'test_time']);
    const date = dayjs(
      form.getFieldValue(['test_appointment_details', 'test_date']),
    ).format('YYYY-MM-DD');
    const isElectric = form.getFieldValue('is_electric');
    const fd = new FormData();
    if (form.getFieldValue('fullname').split('#')[3] === 'true') {
      fd.append('user', form.getFieldValue('fullname').split('#')[2]);
    } else {
      fd.append('car', form.getFieldValue('fullname').split('#')[0]);
    }
    fd.append('reserve_time', `${date} ${dayjs(time).format('HH:mm')}:00`);
    fd.append('is_electric', isElectric);
    fd.append('test_center', form.getFieldValue('test_center'));
    axios
      .post('/pco/reserve-time/', fd)
      .then(() => {
        setVisible(false);
        message.success('Appointment scheduled');
        queryClient.invalidateQueries('getMotList');
      })
      .finally(() => setLoadingBookApponment(false));
  };

  return (
    <>
      <Tabs
        tabBarExtraContent={
          <Button onClick={() => setVisible(true)} className="secondary-btn">
            Book a PCO appointment
          </Button>
        }
        activeKey={category}
        onTabClick={tabClick}
        type="card"
        className="tables-tab-bar"
        animated
        destroyInactiveTabPane
      >
        <TabPane tab="Upcoming" key="upcoming">
          <TableListMOT url="/pco/list/" isPco />
        </TabPane>
        <TabPane tab="History" key="history">
          <TableListMOT isHistory url="/pco/history/" isPco />
        </TabPane>
        <TabPane tab="Schedule calendar" key="schedule">
          <ScheduleTable routeOpenMpdal="/pco-test" url="/pco/check-time/" />
        </TabPane>
      </Tabs>
      <CustomizedModal
        visible={isVisible}
        className="book-appointment"
        onCancel={isChangeing ? onCancel : onConfirmExit}
        afterClose={() => {
          setIsChangeing(false);
          form.resetFields();
        }}
        footer={
          <>
            <Button
              type="primary"
              onClick={form.submit}
              className="footer-cta-btn"
              loading={loadingBookApponment}
            >
              Confirm and book appointment
            </Button>
          </>
        }
      >
        <Form
          form={form}
          className="inline-container"
          style={{ justifyContent: 'space-between' }}
          onFinish={confirmBookAppointment}
          onValuesChange={(val) => {
            setIsChangeing(!!val);
          }}
        >
          <ItemsModal
            setLocation={setLocationMap}
            location={locationMap}
            form={form}
            modeModal="show"
            isMap
            isPco
          />
        </Form>
      </CustomizedModal>
    </>
  );
}
