import {
  Button,
  DatePicker,
  Divider,
  Form,
  message,
  Modal,
  Upload,
} from 'antd';

import printJS from 'print-js';
import dayjs from 'dayjs';
import { CloseCircleFilled } from '@ant-design/icons';
import { useQuery } from 'react-query';
import { useState } from 'react';
import { Icon } from '../icon';
import { CheckMarkDot } from '../check-mark-dot';

import { FloatLabel } from '../floating-label';
import { CustomDatePicker } from '../custom-date-picker';
import { axios, getBase64 } from '../../utils';

const { TimePicker } = DatePicker;

const UploadDocRow = ({
  name,
  driverID,
  printEndpoint,
  title,
  onSuccessUpload,
  document,
  onAccept,
  onReject,
  isActiveDriver,
  disabledInActiveUser,
}) => {
  const handleUploadDoc = ({ onSuccess, onError, onProgress, file }) => {
    const fd = new FormData();
    fd.append('user', driverID);
    fd.append(name, file);

    axios
      .put('/account/driverform/', fd, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: (e) => {
          const percent = (e.loaded / e.total) * 100;
          onProgress({ ...e, percent });
        },
      })
      .then(() => {
        onSuccess();
        onSuccessUpload?.();
      })
      .catch((err) => onError({ message: err }));
  };

  const showFile = (file) => {
    const blob = new Blob([file], { type: 'application/pdf' });

    getBase64(blob).then((base64) => {
      printJS({
        // https://github.com/crabbly/Print.js/issues/581
        printable: base64.replace('data:application/pdf;base64,', ''),
        base64: true,
        documentTitle: `${name}-${driverID}.pdf`,
      });
    });
  };

  const printDoc = () => {
    message.loading({
      key: 'downloading-pdf',
      content: 'Generating document...\nPlease wait',
    });
    axios
      .get(`/${printEndpoint}${driverID}/`, {
        responseType: 'arraybuffer',
      })
      .then(({ data }) => showFile(data))
      .finally(() => message.destroy('downloading-pdf'));
  };

  return (
    <div className="upload-doc-row with-y-divider">
      <div
        style={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}
      >
        <a
          href={document?.link}
          target="_blank"
          rel="noopener noreferrer"
          className="view-doc-link-btn"
        >
          <Icon
            variant="document"
            width={36}
            height={36}
            fill="currentColor"
            color="inherit"
          />
        </a>
        <div className="doc-received-status-box">
          <span className="doc-title">{title}</span>
          {!isActiveDriver && (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <span className="heading received-heading">Received:</span>
              <CheckMarkDot
                status={
                  !document || document?.state === 'Rejected'
                    ? 'error'
                    : 'finish'
                }
              />
            </div>
          )}
        </div>
      </div>
      <button type="button" onClick={printDoc} className="print-doc-btn">
        Print this document
      </button>
      <div
        style={{
          flexBasis: '156px',
          maxWidth: '156px',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        {!!document && document.state !== 'Rejected' ? (
          <div className="doc-acceptance-status-box">
            <span className="heading acceptance-heading">Accepted:</span>
            <CheckMarkDot
              className="accept-btn"
              status="finish"
              solid={document?.state !== 'Accept'}
              onClick={onAccept}
            />
            <CheckMarkDot
              htmlFor={`accept_${name}`}
              className="reject-btn"
              status="error"
              solid={document?.state !== 'Rejected'}
              onClick={onReject}
            />
          </div>
        ) : (
          <Upload
            className="upload-doc-btn"
            accept="application/pdf"
            maxCount={1}
            customRequest={handleUploadDoc}
            disabled={!disabledInActiveUser}
          >
            {isActiveDriver ? 'Update' : 'Upload'}
          </Upload>
        )}
      </div>
    </div>
  );
};

export function LegalDocumentsStep({
  driver,
  refetchDriver,
  isActiveDriver,
  form,
}) {
  const [loadingEditHireStartDate, setLoadingEditHireStartDate] =
    useState(false);
  const onAccept = (name) => {
    message.loading({ key: 'accept-doc', content: 'Accepting' });

    axios.get(`/account/acceptlegaldoc/${name}/${driver?.id}/`).then(() => {
      message.success({ key: 'accept-doc', content: 'Accepted' });
      refetchDriver();
    });
  };

  const onReject = (name) => {
    message.loading({ key: 'reject-doc', content: 'Rejecting' });
    axios.get(`/account/rejectlegaldoc/${name}/${driver?.id}/`).then(() => {
      message.success({ key: 'reject-doc', content: 'Rejected' });
      refetchDriver();
    });
  };

  const confirmUpdateDeilver = () => {
    setLoadingEditHireStartDate(true);
    const deliveryDate = form.getFieldsValue().delivery_date;
    const fd = new FormData();
    fd.append('delivery_date', dayjs(deliveryDate).unix());
    axios
      .put(`cars/drivercars/${driver?.driver_car?.id}/`, fd)
      .then(() => {
        message.success({ key: 'update-delivery-date', content: 'Updated' });
      })
      .finally(() => setLoadingEditHireStartDate(false));
  };

  const updateDeliveryDate = () => {
    Modal.confirm({
      closeIcon: true,
      centered: true,
      title:
        'Are you sure you want to change the delivery date and time of the car?',
      content:
        'These changes cause Send a new email to the driver and sign again',
      onOk: confirmUpdateDeilver,
      okText: 'Confirm',
      okType: 'danger',
      closable: true,
      maskClosable: true,
    });
  };

  const { data: archivedAgreement } = useQuery(['archived-agreement'], () =>
    axios
      .get(`/account/archived-agreement/?user=${driver?.id}`)
      .then(({ data }) => data),
  );

  return (
    <>
      <div>
        {[
          {
            name: 'agreement',
            title: 'Hire agreement',
          },
          {
            name: 'termsandconditions',
            title: 'Terms and conditions',
          },
          // {
          //   name: 'fleetdriverproposal',
          //   title: 'Insurance request form',
          // },
        ].map((item) => (
          <UploadDocRow
            key={item.name}
            name={item.name}
            title={item.title}
            driverID={driver?.id}
            printEndpoint={`account/print-legaldoc/${item.name}/`}
            document={driver?.[item.name]}
            onSuccessUpload={refetchDriver}
            onAccept={() => onAccept(item.name)}
            onReject={() => onReject(item.name)}
            isActiveDriver={isActiveDriver}
            disabledInActiveUser={
              driver?.driver_car?.resign_agreemnt ||
              driver?.state?.[4] ||
              driver?.state?.[5]
            }
          />
        ))}
      </div>
      {archivedAgreement?.map((item) => (
        <a
          href={item?.agreement}
          target="_blank"
          key={item.id}
          rel="noreferrer"
          style={{ display: 'block', marginBottom: 12 }}
        >
          id : {item.id} Created at{' '}
          {dayjs(item.created_at).format('YYYY/MM/DD')} - Updated at
          {dayjs(item.updated_at).format('YYYY/MM/DD')}
        </a>
      ))}
      <div className="inline-container">
        <Divider orientation="left">Hire start date</Divider>
        <Form.Item name="delivery_date">
          <FloatLabel label="Date">
            <CustomDatePicker style={{ width: '100%' }} suffixIcon={null} />
          </FloatLabel>
        </Form.Item>
        <Form.Item
          name="delivery_date"
          getValueProps={(value) => ({ value: dayjs(value) })}
        >
          <FloatLabel label="Date">
            <TimePicker
              style={{ width: '100%' }}
              showSecond={false}
              format="HH:mm"
              showNow={false}
              suffixIcon={null}
              clearIcon={
                <CloseCircleFilled
                  color="91a2ab"
                  className="date-input-clear-icon"
                />
              }
            />
          </FloatLabel>
        </Form.Item>
      </div>
      <Button
        type="primary"
        loading={loadingEditHireStartDate}
        onClick={updateDeliveryDate}
        block
      >
        apply changes
      </Button>
    </>
  );
}
