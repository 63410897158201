import { useInfiniteQuery } from 'react-query';
import * as api from '../api';

export const useMileageList = ({ paramers }) =>
  useInfiniteQuery(
    ['getMileageList', paramers],
    ({ pageParam = 1 }) => api.getMileageList({ paramers, pageParam }),
    {
      getNextPageParam: (lastPage) => lastPage?.next ?? false,
    },
  );
