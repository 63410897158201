import React, { useMemo } from 'react';
import { Badge, Tabs } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import './index.scss';
import { useNotificationsCount } from '../../hooks';
import Notif from './Notif';

const { TabPane } = Tabs;

export function Notifications() {
  const history = useHistory();
  const location = useLocation();

  const page =
    useMemo(() => qs.parse(location.search)?.category, [location.search]) ||
    'payment';

  const tabClick = (key) => history.push(`/notifications?category=${key}`);

  const { data: notifications } = useNotificationsCount({
    type:
      localStorage.getItem('type') === 'mot'
        ? '/mot/count-motnotification/'
        : '/account/notifications-count/',
  });

  return (
    <>
      <Tabs
        type="card"
        className="tables-tab-bar"
        activeKey={page}
        onTabClick={tabClick}
        animated
        destroyInactiveTabPane
      >
        <TabPane
          tab={
            <span className="notifications-tab">
              Payment <Badge count={notifications?.payment} title={false} />
            </span>
          }
          key="payment"
        >
          <Notif
            url="/account/payment-notif/"
            unSeenReqURL="/account/unseen-licenses-notification/"
            seenReqURL="/account/seen-licenses-notification/"
            notifCountURL="/account/notifications-count/"
          />
        </TabPane>
        <TabPane
          tab={
            <span className="notifications-tab">
              Car Service <Badge count={notifications?.service} title={false} />
            </span>
          }
          key="service"
        >
          <Notif
            url="/account/service-notif/"
            unSeenReqURL="/account/unseen-licenses-notification/"
            seenReqURL="/account/seen-licenses-notification/"
            notifCountURL="/account/notifications-count/"
          />
        </TabPane>
        <TabPane
          tab={
            <span className="notifications-tab">
              MOT <Badge count={notifications?.mot} title={false} />
            </span>
          }
          key="mot"
        >
          <Notif
            url="/account/mot-notif/"
            unSeenReqURL="/account/unseen-licenses-notification/"
            seenReqURL="/account/seen-licenses-notification/"
            notifCountURL="/account/notifications-count/"
          />
        </TabPane>
        <TabPane
          tab={
            <span className="notifications-tab">
              PCO <Badge count={notifications?.pco} title={false} />
            </span>
          }
          key="pco"
        >
          <Notif
            url="/account/pco-notif/"
            unSeenReqURL="/account/unseen-licenses-notification/"
            seenReqURL="/account/seen-licenses-notification/"
            notifCountURL="/account/notifications-count/"
          />
        </TabPane>
        <TabPane
          tab={
            <span className="notifications-tab">
              Car Return{' '}
              <Badge count={notifications?.returncar} title={false} />
            </span>
          }
          key="return"
        >
          <Notif
            url="/account/returncar-notif/"
            unSeenReqURL="/account/unseen-licenses-notification/"
            seenReqURL="/account/seen-licenses-notification/"
            notifCountURL="/account/notifications-count/"
          />
        </TabPane>
        <TabPane
          tab={
            <span className="notifications-tab">
              Licenses <Badge count={notifications?.Licenses} title={false} />
            </span>
          }
          key="licenses"
        >
          <Notif
            url="/account/licenses-notif/"
            unSeenReqURL="/account/unseen-licenses-notification/"
            seenReqURL="/account/seen-licenses-notification/"
            notifCountURL="/account/notifications-count/"
          />
        </TabPane>
        <TabPane
          tab={
            <span className="notifications-tab">
              Mileage <Badge count={notifications?.mileage} title={false} />
            </span>
          }
          key="mileage"
        >
          <Notif
            url="/account/mileage-notif/"
            unSeenReqURL="/account/unseen-licenses-notification/"
            seenReqURL="/account/seen-licenses-notification/"
            notifCountURL="/account/notifications-count/"
          />
        </TabPane>
      </Tabs>
    </>
  );
}
