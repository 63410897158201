/* eslint-disable react/destructuring-assignment */
import { Button, Form, Table, Modal, message, Spin, Input } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router';
import dayjs from 'dayjs';
import { useCarReturnList, useCarReturn } from '../../hooks/carReturn';
import { InfiniteScrollWrapper } from '../../components/Infinite-scroll-wrapper';
import { CustomizedModal, FloatLabel } from '../../components';
import ItemsModalCarReturn from './ItemsModal';
import { axios } from '../../utils';

export default function TableCarReturn({ isHistory }) {
  const history = useHistory();
  const location = useLocation();
  const [form] = Form.useForm();
  const [formUnAssign] = Form.useForm();

  const [isVisible, setVisible] = useState(false);
  const [isChangeing, setIsChangeing] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState(false);
  const [isVisibleUnAssign, setIsVisibleUnAssign] = useState(false);
  const [isLoadingUn, setIsLoadingUn] = useState(false);

  const id = useMemo(() => qs.parse(location.search).id, [location.search]);

  const category = useMemo(
    () => qs.parse(location.search).category,
    [location.search],
  );

  const queryParams = useMemo(() => {
    const params = qs.parse(location.search);
    delete params?.category;
    delete params?.id;
    return params;
  }, [location.search]);

  const isRequiredRule = [
    {
      required: true,
      message: 'This field is required',
    },
  ];

  const getOrdering = () => {
    if (queryParams.created_at === 'ascend') return 'created_at';
    if (queryParams.created_at === 'descend') return '-created_at';
    if (queryParams.appointment_date === 'ascend') return 'appointment_date';
    if (queryParams.appointment_date === 'descend') return '-appointment_date';
    return undefined;
  };

  const {
    data: dataCarReturn,
    refetch,
    isLoading: carReturnIsLoading,
  } = useCarReturn({ id, category });

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isLoading,
    isFetching,
    refetch: refetchList,
  } = useCarReturnList({ category, ordering: getOrdering() });

  const handelChangeTable = (pagination, filters, sorter) => {
    const { current: page, pageSize: size } = pagination;
    const params = { page, size };
    if (sorter.column) {
      Object.assign(params, {
        [`sort_${sorter.columnKey}`]:
          sorter.order === 'descend' ? 'descend' : 'ascend',
      });
    }
    history.replace({
      search: qs.stringify({
        ...qs.parse(history.location.search),
        appointment_date: params.sort_appointment_date,
        created_at: params.sort_created_at,
      }),
    });
  };

  const onConfirmExit = () => {
    setVisible(false);
  };

  const onCancel = () => {
    Modal.confirm({
      title: 'Exit Confirmation',
      content:
        'You are leaving this process. All your unsaved data will be lost. Do you wish to proceed?',
      onOk: onConfirmExit,
      okText: 'Leave',
      cancelText: 'Stay',
      okType: 'danger',
      closable: true,
      maskClosable: true,
      closeIcon: true,
    });
  };

  const changeCarReturn = () => {
    form.validateFields().then(() => {
      setIsLoadingUpdate(true);
      const fd = new FormData();
      fd.append(
        'appointment_date',
        dayjs(form.getFieldValue('appointment_date')).format(
          'YYYY-MM-DD HH:mm:00',
        ),
      );
      fd.append('reason', form.getFieldValue('reason'));
      fd.append('driver', dataCarReturn?.driver_id);
      fd.append('account_number', form.getFieldValue('account_number'));
      fd.append('sort_code', form.getFieldValue('sort_code'));
      fd.append(
        'name_of_the_account_holder',
        form.getFieldValue('name_of_the_account_holder'),
      );
      fd.append('bank_name', form.getFieldValue('bank_name'));
      if (form.getFieldValue('bank_statement_picture')?.file?.originFileObj) {
        fd.append(
          'bank_statement_picture',
          form.getFieldValue('bank_statement_picture')?.file?.originFileObj,
        );
      }
      axios
        .put(`/returncar/panel/returncar-list/${id}/`, fd)
        .then(() => {
          message.success('Appointment details updated');
          setIsChangeing(false);
        })
        .finally(() => setIsLoadingUpdate(false));
    });
  };

  const confirmCarReturn = () => {
    form.validateFields().then(() => {
      setIsLoadingConfirm(true);
      axios
        .get(`/returncar/panel/appointmentdate-confirmation/${id}/`)
        .then(() => {
          refetchList();
          setVisible(false);
          message.success('Appointment details confirmed');
        })
        .finally(() => setIsLoadingConfirm(false));
    });
  };

  const unAssignCarHandler = () => {
    formUnAssign.validateFields().then(() => {
      setIsLoadingUn(true);
      axios
        .put(
          `/returncar/panel/returncar-list/${id}/`,
          formUnAssign.getFieldsValue(),
        )
        .then(() => {
          setIsVisibleUnAssign(false);
          setVisible(false);
          refetchList();
          message.success('Driver was unassigned successfully');
        })
        .finally(() => setIsLoadingUn(false));
    });
  };

  useEffect(() => {
    if (!id) setVisible(false);
    if (id) setVisible(true);
  }, [location]);

  useEffect(() => {
    if (dataCarReturn) {
      form.setFieldsValue({
        ...dataCarReturn,
        model: `${dataCarReturn.car_details.company} ${dataCarReturn.car_details.model}`,
        fullName: `${dataCarReturn.Driver_details.first_name} ${dataCarReturn.Driver_details.last_name}`,
        appointment_date: dayjs
          .utc(dataCarReturn.appointment_date)
          .format('YYYY/MM/DD HH:mm'),
      });
    }
  }, [dataCarReturn]);

  const getSortOrder = (parameter) => {
    if (queryParams[parameter] === 'descend') return 'descend';
    if (queryParams[parameter] === 'ascend') return 'ascend';
    return null;
  };

  const columns = [
    {
      title: "Driver's name",
      dataIndex: 'first_name',
      key: 'Driver_details',
      render: (_, fullName) =>
        `${fullName.Driver_details?.first_name} ${fullName.Driver_details?.last_name}`,
    },
    {
      title: 'Car',
      dataIndex: 'car_details',
      key: 'car_details',
      render: (car) =>
        car ? (
          <>
            {car.company} {car.model}
            <br />
            {`(${car.registrationnumber})`}
          </>
        ) : (
          '-'
        ),
    },
    {
      title: 'Request date',
      dataIndex: 'created_at',
      render: (value) =>
        value ? <>{dayjs(value).format('DD/MM/YYYY')}</> : '-',
      key: 'created_at',
      sorter: true,
      sortOrder: getSortOrder('created_at'),
    },
    {
      title: 'Appointment date',
      dataIndex: 'appointment_date',
      render: (value) =>
        value ? <>{dayjs(value).format('DD/MM/YYYY')}</> : '-',
      key: 'appointment_date',
      sorter: true,
      sortOrder: getSortOrder('appointment_date'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'state',
    },
  ];

  return (
    <>
      <InfiniteScrollWrapper
        lengthData={data?.results?.[0]?.count}
        hasMore={hasNextPage}
        functionNext={fetchNextPage}
        scrollableTarget="modal-with-infinite-scroll-table"
      >
        <Table
          scroll={{
            x: 1024,
          }}
          loading={isLoading || isFetching}
          className="bordered-table clickable-table-row"
          dataSource={data?.pages.reduce((res, cur) => {
            if (cur?.results) {
              res.push(...cur?.results);
            } else res.push(...cur);
            return res;
          }, [])}
          onChange={handelChangeTable}
          columns={columns}
          pagination={false}
          onRow={(record) => ({
            onClick: () => {
              history.push({
                search: qs.stringify({
                  id: record.id,
                  ...qs.parse(history.location.search),
                }),
              });
            },
          })}
        />
      </InfiniteScrollWrapper>
      <CustomizedModal
        visible={isVisible}
        className="book-appointment"
        onCancel={isChangeing ? onCancel : onConfirmExit}
        afterClose={() => {
          setIsChangeing(false);
          form.resetFields();
          history.push({
            search: qs.exclude(location.search, ['id']),
          });
        }}
        footer={
          <>
            {dataCarReturn?.status === 'Pending appointment confirmation' && (
              <>
                {isChangeing ? (
                  <Button
                    type="primary"
                    onClick={changeCarReturn}
                    className="footer-cta-btn"
                    loading={isLoadingUpdate}
                    disabled={!isChangeing}
                  >
                    Apply Change
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={confirmCarReturn}
                    className="footer-cta-btn"
                    loading={isLoadingConfirm}
                  >
                    Confirm the appointment
                  </Button>
                )}
              </>
            )}
            {dataCarReturn?.status === 'Pending settlement' && (
              <>
                {isChangeing ? (
                  <Button
                    type="primary"
                    onClick={changeCarReturn}
                    className="footer-cta-btn"
                    loading={isLoadingUpdate}
                    disabled={!isChangeing}
                  >
                    Apply Change
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    onClick={() => setIsVisibleUnAssign(true)}
                    className="footer-cta-btn"
                    loading={isLoadingConfirm}
                  >
                    Unassign car
                  </Button>
                )}
              </>
            )}
          </>
        }
      >
        <Spin spinning={carReturnIsLoading}>
          <Form
            form={form}
            className="inline-container"
            style={{ justifyContent: 'space-between' }}
            onValuesChange={(val) => {
              setIsChangeing(!!val);
            }}
          >
            <ItemsModalCarReturn
              titleModal="Car return appointment"
              modeModal="update"
              dataCar={dataCarReturn}
              refetch={refetch}
              carReturnIsLoading={carReturnIsLoading}
              isHistory={isHistory}
            />
          </Form>
        </Spin>
      </CustomizedModal>
      <CustomizedModal
        visible={isVisibleUnAssign}
        afterClose={() => formUnAssign.resetFields()}
        onCancel={() => setIsVisibleUnAssign(false)}
        maskClosable={false}
        width={712}
        centered
        footer={
          <div>
            <Button
              onClick={unAssignCarHandler}
              className="footer-cta-btn"
              type="primary"
              loading={isLoadingUn}
            >
              Confirm
            </Button>
          </div>
        }
      >
        <p>Please fill the form below to confirm the unassign process.</p>
        <Form
          form={formUnAssign}
          name="horizontal_login"
          layout="inline"
          className="reset-error-form"
          style={{ justifyContent: 'space-between' }}
        >
          <Form.Item
            rules={isRequiredRule}
            style={{ marginBottom: '16px' }}
            name="receipt_number"
          >
            <FloatLabel label="Reciept number">
              <Input autoComplete="off" />
            </FloatLabel>
          </Form.Item>
          <Form.Item
            rules={isRequiredRule}
            style={{ marginBottom: '16px' }}
            name="sorting_number"
          >
            <FloatLabel label="Sorting number">
              <Input autoComplete="off" />
            </FloatLabel>
          </Form.Item>
        </Form>
      </CustomizedModal>
    </>
  );
}
