/* eslint-disable camelcase */
import {
  Modal,
  Button,
  Form,
  InputNumber,
  Divider,
  Input,
  message,
  Tabs,
  DatePicker,
  Row,
  Col,
} from 'antd';
import React, { useState, useMemo } from 'react';
import cn from 'classnames';
import qs from 'query-string';
import { useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useQuery, useQueryClient } from 'react-query';
import printJS from 'print-js';
import { FloatLabel } from '../floating-label';
import { axios, getBase64 } from '../../utils';
import { CustomizedModal } from '../modal';
import { MiscellaneousTable } from './Miscellaneous';
import { MiscellaneousModal } from './MiscellaneousModal';

const { TabPane } = Tabs;

const inputFormatterProps = {
  formatter: (value) =>
    value && `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
  parser: (value) => value.replace(/£\s?|(,*)/g, ''),
};

export function DepositAndInvoiceStep({ form, user }) {
  const location = useLocation();
  const queryClient = useQueryClient();
  const [MiscellaneousForm] = Form.useForm();
  const paymentsURL = `/payment/balance-report?driver=${user?.driver_car?.id}&type=Payments`;
  const { data: payments } = useQuery([paymentsURL, user?.id], async () => {
    const { data } = await axios.get(paymentsURL);
    return data;
  });

  const depositURL = `/payment/balance-report?driver=${user?.driver_car?.id}&type=Deposit`;
  const { data: deposit } = useQuery([depositURL, user.id], async () => {
    const { data } = await axios.get(depositURL);
    return data;
  });

  const [isVisableRefrenceNumber, setVisableRefrenceNumber] = useState(false);
  const [isVisablePayWithCash, setVisablePayWithCash] = useState(false);
  const [isVisableSetupHolidays, setVisableSetupHolidays] = useState(false);
  const [isVisableWeeklyCharge, setVisableWeeklyCharge] = useState(false);
  const [sagePayLoading, setSagePayLoading] = useState(false);
  const [goCardLessLoading, setGoCardLessLoading] = useState(false);
  const [isVisableMiscellaneous, setVisableMiscellaneous] = useState(false);
  const [invoice, setInvoice] = useState();
  const [isPayed, setIsPayed] = useState(false);
  const [titleExpenceModal, setTitleExpenceModal] = useState(false);
  const queryParams = useMemo(() => {
    const params = qs.parse(location.search);
    return params;
  }, [location.search]);

  const showFile = (file) => {
    const blob = new Blob([file], { type: 'application/pdf' });

    getBase64(blob).then((base64) => {
      printJS({
        printable: base64.replace('data:application/pdf;base64,', ''),
        base64: true,
        documentTitle: `reciept.pdf`,
      });
    });
  };

  const redirectToPaymentLink = (driverCarId) => {
    axios
      .post('payment/first-deposit', {
        driver_car: driverCarId,
        description: form.getFieldValue('refrence_number'),
        amount: form.getFieldValue(['payment', 'calculated']),
      })
      .then(({ data: { gateway } }) => {
        window.location.href = gateway;
      })
      .finally(() => setSagePayLoading(false));
  };

  const handleFirstPay = () => {
    form.validateFields(['refrence_number']).then(() => {
      const driver_car = form.getFieldValue('assingcar_id');
      if (driver_car) {
        setSagePayLoading(true);
        redirectToPaymentLink(driver_car);
      }
    });
  };

  const handleSetupGoCardLess = () => {
    if (user?.id) {
      setGoCardLessLoading(true);
      axios
        .post('/payment/gocardless-setup', { user: user.id })
        .then(({ data: { gocardless } }) => {
          window.location.href = gocardless;
        });
    }
  };

  const { data: insuranceCharge } = useQuery('insuranceCharge', async () => {
    const { data } = await axios.get('/settings/insurances/');
    return data;
  });
  const printTotalHireInvoice = () => {
    message.loading({
      key: 'downloading-pdf',
      content: 'Generating recipt...\nPlease wait',
    });
    axios
      .get(`/payment/print-receipt/?totalhireinvoice=${user.id}`, {
        responseType: 'arraybuffer',
      })
      .then(({ data }) => showFile(data))
      .finally(() => message.destroy('downloading-pdf'));
  };

  const vehicle_weekly_rent = parseFloat(
    (user?.driver_car?.weekly_rent_amount -
      insuranceCharge?.[0]?.daily_cost * 7) /
      1.2,
  ).toFixed(2);

  const vat = parseFloat(
    user?.driver_car?.weekly_rent_amount -
      insuranceCharge?.[0]?.daily_cost * 7 -
      (user?.driver_car?.weekly_rent_amount -
        insuranceCharge?.[0]?.daily_cost * 7) /
        1.2,
  ).toFixed(2);

  const handlePayCash = () => {
    form.validateFields(['amount']).then(() => {
      const { amount } = form.getFieldsValue();
      axios
        .post('/payment/cash/', { amount, driver_car: user?.driver_car?.id })
        .then(() => {
          queryClient.invalidateQueries('driver');
          queryClient.invalidateQueries(
            `/payment/balance-report?driver=${user?.driver_car?.id}&type=Deposit`,
          );
          queryClient.invalidateQueries(
            `/payment/balance-report?driver=${user?.driver_car?.id}&type=Payments`,
          );
          message.success('Payment successful');
          setVisablePayWithCash(false);
        });
    });
  };

  const handleHolidaySetup = () => {
    form
      .validateFields(['weekly_rent', 'holiday_payment_amount'])
      .then(({ weekly_rent, holiday_payment_amount }) => {
        const sendData = {
          weekly_rent,
          start_date: dayjs(holiday_payment_amount[0]).format('YYYY-MM-DD'),
          end_date: dayjs(holiday_payment_amount[1]).format('YYYY-MM-DD'),
          driver_car: user?.driver_car?.id,
        };
        axios.post('/payment/payment-holiday/', sendData).then(() => {
          queryClient.invalidateQueries('driver');
          message.success('Payment holiday set up successfully');
          setVisableSetupHolidays(false);
        });
      });
  };

  const handlePayWithInstallment = () => {
    form
      .validateFields([
        ['driver_car', 'deposit_amount'],
        ['driver_car', 'weekly_rent_amount'],
        ['driver_car', 'first_deposit'],
        ['driver_car', 'number_of_installments'],
      ])
      .then(() => {
        axios
          .put(`/cars/drivercars/${user?.driver_car?.id}/`, {
            first_deposit: form.getFieldValue(['driver_car', 'first_deposit']),
            number_of_installments: form.getFieldValue([
              'driver_car',
              'number_of_installments',
            ]),
            weekly_rent_amount: form.getFieldValue([
              'driver_car',
              'weekly_rent_amount',
            ]),
            deposit_amount: form.getFieldValue([
              'driver_car',
              'deposit_amount',
            ]),
          })
          .then(() => {
            queryClient.invalidateQueries('driver');
          });
      });
  };

  const handleChangeWeeklyCharge = () => {
    form.validateFields(['weekly_rent']).then(() => {
      const { weekly_rent_amount } = form.getFieldsValue();
      axios
        .put(`/cars/weekly-rent-update/${user?.driver_car?.id}/`, {
          weekly_rent_amount,
        })
        .then(() => {
          message.success('Weekly charge amount amount updated');
          setVisableWeeklyCharge(false);
          queryClient.invalidateQueries('driver');
        });
    });
  };

  return (
    <div className="inline-container">
      <Form.Item name={['driver_car', 'deposit_amount']}>
        <FloatLabel label="Deposit fee">
          <InputNumber
            onWheel={(e) => e.target.blur()}
            min={0}
            className="full-width"
            {...inputFormatterProps}
            disabled={
              user?.first_deposit_balance?.total_collected !== 0 ||
              payments?.total_collected !== 0 ||
              deposit?.total_collected !== 0
            }
          />
        </FloatLabel>
      </Form.Item>
      <Form.Item name={['driver_car', 'weekly_rent_amount']}>
        <FloatLabel label="Weekly rent">
          <InputNumber
            onWheel={(e) => e.target.blur()}
            min={0}
            type="text"
            className="full-width"
            {...inputFormatterProps}
            disabled={
              user?.first_deposit_balance?.total_collected !== 0 ||
              payments?.total_collected !== 0 ||
              deposit?.total_collected !== 0
            }
          />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        name={['driver_car', 'first_deposit']}
        rules={[
          { required: true, message: 'This field is required' },
          ({ getFieldValue }) => ({
            validator(_rule, value) {
              if (
                !value ||
                getFieldValue(['driver_car', 'deposit_amount']) >= value
              ) {
                return Promise.resolve();
              }
              return Promise.reject(
                Error('The first deposit must be less than the deposit fee'),
              );
            },
          }),
        ]}
      >
        <FloatLabel label="First deposit">
          <InputNumber
            onWheel={(e) => e.target.blur()}
            className="full-width"
            {...inputFormatterProps}
            disabled={
              user?.first_deposit_balance?.total_collected !== 0 ||
              payments?.total_collected !== 0 ||
              deposit?.total_collected !== 0
            }
          />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        name={['driver_car', 'number_of_installments']}
        rules={[{ required: true, message: 'This field is required' }]}
        initialValue={0}
      >
        <FloatLabel label="Number of installments">
          <InputNumber
            type="number"
            onWheel={(e) => e.target.blur()}
            min={!user?.driver_car?.successful_payment && 0}
            className="full-width"
            disabled={
              user?.first_deposit_balance?.total_collected !== 0 ||
              payments?.total_collected !== 0 ||
              deposit?.total_collected !== 0
            }
          />
        </FloatLabel>
      </Form.Item>

      {payments?.total_collected === 0 && deposit?.total_collected === 0 && (
        <Button type="primary" onClick={handlePayWithInstallment}>
          Apply billing changes
        </Button>
      )}
      <Row
        justify="space-between"
        style={{ flexBasis: '100%', margin: '32px auto' }}
      >
        <Col span={11}>
          <div className="table_deposit">
            <Divider orientation="left">First Payment breakdown</Divider>
            <hr />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 19 }}>
              <Row justify="space-between">
                <Col>First deposit</Col>
                <Col>£ {user.driver_car?.first_deposit}</Col>
              </Row>
              <Row justify="space-between">
                <Col>First week charge</Col>
                <Col>£ {user?.driver_car?.weekly_rent_amount}</Col>
              </Row>
              <Row
                justify="space-between"
                style={{ borderTop: '1px solid #000', paddingTop: 19 }}
              >
                <Col>
                  <b>Total</b>
                </Col>
                <Col>
                  <b>
                    £{' '}
                    {user?.driver_car?.weekly_rent_amount +
                      user.driver_car?.first_deposit}
                  </b>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
        <Col span={11}>
          <div className="table_deposit">
            <Divider orientation="left">Direct debit breakdown</Divider>
            <hr />
            <div style={{ display: 'flex', flexDirection: 'column', gap: 19 }}>
              <Row justify="space-between">
                <Col>Vehicle weekly rent (Net)</Col>
                <Col>£ {vehicle_weekly_rent}</Col>
              </Row>
              <Row justify="space-between">
                <Col>VAT</Col>
                <Col>£ {vat}</Col>
              </Row>
              <Row justify="space-between">
                <Col>Insurance charge</Col>
                <Col>£ {insuranceCharge?.[0]?.daily_cost * 7}</Col>
              </Row>
              <Row
                justify="space-between"
                style={{ borderTop: '1px solid #000', paddingTop: 19 }}
              >
                <Col>
                  <b>Total</b>
                </Col>
                <Col>
                  <b>£ {user?.driver_car?.weekly_rent_amount}</b>
                </Col>
              </Row>
            </div>
          </div>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Row gutter={30}>
            <Col span={!user?.driver_car?.successful_payment ? 12 : 24}>
              <Button
                type="primary"
                className={cn({
                  'successful-payment-btn':
                    user?.driver_car?.successful_payment,
                })}
                block
                onClick={() => {
                  if (user?.driver_car?.successful_payment) {
                    message.loading({
                      key: 'downloading-pdf',
                      content: 'Generating recipt...\nPlease wait',
                    });
                    axios
                      .get(
                        `/payment/print-receipt/?initialdeposit=${user?.id}`,
                        {
                          responseType: 'arraybuffer',
                        },
                      )
                      .then(({ data }) => {
                        showFile(data);
                      })
                      .finally(() => message.destroy('downloading-pdf'));
                  } else {
                    form.setFieldsValue({
                      full_name: `${user?.first_name} ${user?.last_name}`,
                    });
                    setVisableRefrenceNumber(true);
                  }
                }}
              >
                {user?.driver_car?.successful_payment ? (
                  <>
                    First Deposit successful -{' '}
                    <u style={{ color: 'darkgreen' }}>Download recipt</u>
                  </>
                ) : (
                  'SagePay'
                )}
              </Button>
            </Col>
            {!user?.driver_car?.successful_payment && (
              <Col span={12}>
                <Button
                  block
                  type="primary"
                  onClick={() => {
                    form.setFieldsValue({
                      full_name: `${user?.first_name} ${user?.last_name}`,
                    });
                    setVisablePayWithCash(true);
                  }}
                >
                  Pay with cash
                </Button>
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <Button
        type="primary"
        className={cn({
          'successful-payment-btn':
            user?.driver_car?.successful_gocardless_setup ||
            user?.mandate_status === 'active',
          'gocardless-btn': user?.mandate_status === 'cancelled',
        })}
        onClick={handleSetupGoCardLess}
        loading={goCardLessLoading}
        disabled={
          user?.driver_car?.successful_gocardless_setup || user?.mandate_status
        }
      >
        {`GoCardless setup  ${user?.mandate_status ?? ''}`}
      </Button>
      {!user?.archive && user?.state?.[10] && (
        <>
          <Divider orientation="left">Extra actions</Divider>
          <Form.Item>
            <Button
              type="primary"
              className="full-width"
              onClick={() => setVisableSetupHolidays(true)}
            >
              Setup payment holiday
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              className="full-width"
              onClick={() => setVisableWeeklyCharge(true)}
            >
              Change weekly charge rate
            </Button>
          </Form.Item>
        </>
      )}
      <Divider orientation="left" className="tetst">
        Payments history
        <a onClick={printTotalHireInvoice}>
          <u>Print driver’s total hire invoice</u>
        </a>
      </Divider>
      <Tabs
        animated
        defaultActiveKey="1"
        style={{ flexBasis: '100%', marginTop: 0 }}
        className="tabs-deposit-invoice"
        tabBarExtraContent={
          user?.state?.[10] &&
          !user?.archive && (
            <Button
              type="primary"
              onClick={() => {
                setVisableMiscellaneous(true);
              }}
              style={{ margin: '10px' }}
              size="medium"
            >
              Add a new expense
            </Button>
          )
        }
      >
        <TabPane tab="Hire history" key="1">
          <MiscellaneousTable
            url={`/payment/balance-report?driver=${user?.driver_car?.id}&type=Payments`}
            setVisableMiscellaneous={setVisableMiscellaneous}
            setInvoice={setInvoice}
            form={MiscellaneousForm}
            setIsPayed={setIsPayed}
          />
        </TabPane>
        <TabPane tab="Deposit history" key="2">
          <MiscellaneousTable
            url={`/payment/balance-report?driver=${user?.driver_car?.id}&type=Deposit`}
            setVisableMiscellaneous={setVisableMiscellaneous}
            setInvoice={setInvoice}
            form={MiscellaneousForm}
            setIsPayed={setIsPayed}
          />
        </TabPane>
        <TabPane
          tab="Miscellaneous expenses"
          disabled={!user?.state?.[10]}
          key="3"
        >
          <MiscellaneousTable
            url={`/payment/balance-report?driver=${user?.driver_car?.id}&type=Miscellaneous`}
            setVisableMiscellaneous={setVisableMiscellaneous}
            setInvoice={setInvoice}
            form={MiscellaneousForm}
            setIsPayed={setIsPayed}
          />
        </TabPane>
      </Tabs>
      <CustomizedModal
        visible={isVisablePayWithCash}
        centered
        width={416}
        footer={
          <Button
            style={{ width: '100%' }}
            type="primary"
            onClick={handlePayCash}
            loading={sagePayLoading}
          >
            Collect via Cash
          </Button>
        }
        onCancel={() => setVisablePayWithCash(false)}
        afterClose={() => form.resetFields(['refrence_number'])}
      >
        <Form.Item name="full_name">
          <FloatLabel label="Driver's name">
            <Input disabled />
          </FloatLabel>
        </Form.Item>
        <Form.Item name="payment_title" initialValue="Initial deposit">
          <FloatLabel label="Payment title">
            <Input disabled />
          </FloatLabel>
        </Form.Item>
        <Form.Item
          name="amount"
          rules={[
            { required: true, message: 'This field is required' },
            ({ getFieldValue }) => ({
              validator(_rule, value) {
                if (
                  value >
                  getFieldValue(['payment', 'calculated']) -
                    user?.first_deposit_balance?.total_collected
                ) {
                  return Promise.reject(
                    Error(
                      'The payment amount should be less than the deposit amount',
                    ),
                  );
                }
                return Promise.resolve();
              },
            }),
          ]}
        >
          <FloatLabel label="Payment amount">
            <Input type="number" />
          </FloatLabel>
        </Form.Item>
      </CustomizedModal>
      <CustomizedModal
        visible={isVisableRefrenceNumber}
        centered
        width={416}
        footer={
          <Button
            style={{ width: '100%' }}
            type="primary"
            onClick={handleFirstPay}
            loading={sagePayLoading}
          >
            Collect via sage pay
          </Button>
        }
        onCancel={() => setVisableRefrenceNumber(false)}
        afterClose={() => form.resetFields(['refrence_number'])}
      >
        <Form.Item name="full_name">
          <FloatLabel label="Driver's name">
            <Input disabled />
          </FloatLabel>
        </Form.Item>
        <Form.Item name="payment_title" initialValue="Initial deposit">
          <FloatLabel label="Payment title">
            <Input disabled />
          </FloatLabel>
        </Form.Item>
        <Form.Item name={['payment', 'calculated']}>
          <FloatLabel label="Payment amount">
            <Input />
          </FloatLabel>
        </Form.Item>
        <Form.Item
          name="refrence_number"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <FloatLabel label="Reference Number">
            <Input />
          </FloatLabel>
        </Form.Item>
      </CustomizedModal>
      {queryParams?.msg && (
        <p className="full-width error-text">
          You have checked the &quot;More than one person is required to
          authorize Direct Debits&quot; option, currently this feature is not
          supported. Please try again.
        </p>
      )}
      <Modal
        visible={isVisableSetupHolidays}
        onCancel={() => {
          setVisableSetupHolidays(false);
          form.resetFields(['weekly_rent', 'holiday_payment_amount']);
        }}
        closable={false}
        maskClosable
        footer={null}
        centered
        title="Payment holiday setup"
        className="driver-contact-info-modal"
      >
        <Form.Item
          name="holiday_payment_amount"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <DatePicker.RangePicker />
        </Form.Item>
        <Form.Item
          name="weekly_rent"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <FloatLabel label="Weekly charge rate">
            <InputNumber
              onWheel={(e) => e.target.blur()}
              className="full-width"
              formatter={(value) =>
                value && `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              maxLength={9}
            />
          </FloatLabel>
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={handleHolidaySetup}
            style={{ width: '100%' }}
          >
            Setup the payment holiday
          </Button>
        </Form.Item>
      </Modal>
      <Modal
        visible={isVisableWeeklyCharge}
        onCancel={() => {
          setVisableWeeklyCharge(false);
          form.resetFields(['weekly_rent', 'holiday_payment_amount']);
        }}
        closable={false}
        maskClosable
        footer={null}
        centered
        title="Payment holiday setup"
        className="driver-contact-info-modal"
        width={864}
      >
        <Form.Item
          name="weekly_rent_amount"
          rules={[{ required: true, message: 'This field is required' }]}
        >
          <FloatLabel label="Weekly charge rate">
            <InputNumber
              onWheel={(e) => e.target.blur()}
              className="full-width"
              formatter={(value) =>
                value && `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              maxLength={9}
            />
          </FloatLabel>
        </Form.Item>
        <div className="warning-box">
          <h1>
            <svg
              width="22"
              height="20"
              viewBox="0 0 22 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.7605 13.92L14.3605 2.4C13.5005 0.85 12.3105 0 11.0005 0C9.69047 0 8.50047 0.85 7.64047 2.4L1.24047 13.92C0.430466 15.39 0.340466 16.8 0.990466 17.91C1.64047 19.02 2.92047 19.63 4.60047 19.63H17.4005C19.0805 19.63 20.3605 19.02 21.0105 17.91C21.6605 16.8 21.5705 15.38 20.7605 13.92ZM10.2505 7C10.2505 6.59 10.5905 6.25 11.0005 6.25C11.4105 6.25 11.7505 6.59 11.7505 7V12C11.7505 12.41 11.4105 12.75 11.0005 12.75C10.5905 12.75 10.2505 12.41 10.2505 12V7ZM11.7105 15.71C11.6605 15.75 11.6105 15.79 11.5605 15.83C11.5005 15.87 11.4405 15.9 11.3805 15.92C11.3205 15.95 11.2605 15.97 11.1905 15.98C11.1305 15.99 11.0605 16 11.0005 16C10.9405 16 10.8705 15.99 10.8005 15.98C10.7405 15.97 10.6805 15.95 10.6205 15.92C10.5605 15.9 10.5005 15.87 10.4405 15.83C10.3905 15.79 10.3405 15.75 10.2905 15.71C10.1105 15.52 10.0005 15.26 10.0005 15C10.0005 14.74 10.1105 14.48 10.2905 14.29C10.3405 14.25 10.3905 14.21 10.4405 14.17C10.5005 14.13 10.5605 14.1 10.6205 14.08C10.6805 14.05 10.7405 14.03 10.8005 14.02C10.9305 13.99 11.0705 13.99 11.1905 14.02C11.2605 14.03 11.3205 14.05 11.3805 14.08C11.4405 14.1 11.5005 14.13 11.5605 14.17C11.6105 14.21 11.6605 14.25 11.7105 14.29C11.8905 14.48 12.0005 14.74 12.0005 15C12.0005 15.26 11.8905 15.52 11.7105 15.71Z"
                fill="#DA4242"
              />
            </svg>
            Warning!
          </h1>
          <p>
            Changing the weekly charge rate of the driver will result in
            deprecating the current agreement file, and the client will have to
            sign the hire agreement again.Also, all of the “Pending submission”
            payments will be canceled and replaced with new invoices. Are you
            willing to continue?
          </p>
        </div>
        <Form.Item>
          <Button
            type="primary"
            onClick={handleChangeWeeklyCharge}
            style={{ width: '100%' }}
          >
            Change the weekly charge rate
          </Button>
        </Form.Item>
      </Modal>
      <MiscellaneousModal
        isVisable={isVisableMiscellaneous}
        setVisable={setVisableMiscellaneous}
        invoice={invoice}
        form={MiscellaneousForm}
        driver={user?.driver_car?.id}
        url={`/payment/balance-report?driver=${user?.driver_car?.id}&type=Miscellaneous`}
        isPayed={isPayed}
        title={titleExpenceModal}
        setTitle={setTitleExpenceModal}
      />
    </div>
  );
}
