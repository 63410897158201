import { Table, Avatar } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';

import { useMemo } from 'react';
import { useDrivers } from '../../hooks';
import { getSearchFilterProps, InfiniteScrollWrapper } from '../../components';

export function ArchivedDrivers() {
  const history = useHistory();
  const location = useLocation();

  const queryParams = useMemo(() => {
    const params = qs.parse(location.search);
    delete params?.category;
    delete params?.driver_id;
    return params;
  }, [location.search]);

  const { data, isLoading, isFetching, hasNextPage, fetchNextPage } =
    useDrivers({ category: 'archived', queryParams });

  const handelChangeTable = (pagination, filters) => {
    const { current: page, pageSize: size } = pagination;
    const params = { page, size };

    Object.entries(filters).forEach(([key, value]) => {
      if (value?.length > 0) {
        let paramKey;
        switch (key) {
          case 'phone':
            paramKey = 'phone';
            break;
          case 'first_name':
            paramKey = 'search';
            break;
          case 'postal_number':
            paramKey = 'postal_number';
            break;
          case 'birth_date':
            paramKey = 'birth_date';
            break;
          default:
            paramKey = key;
            break;
        }
        Object.assign(params, { [paramKey]: value[0] });
      }
    });
    history.replace({
      search: qs.stringify({
        ...qs.parse(history.location.search),
        search: params.search,
        phone_number: params.phone,
        postal_number: params.postal_number,
        birth_date: params.birth_date,
      }),
    });
  };

  const columns = [
    {
      title: 'Reference Number',
      dataIndex: 'reference_number',
      key: 'reference_number',
      width: 1,
    },
    {
      title: 'Name',
      dataIndex: 'first_name',
      ...getSearchFilterProps('Name'),
      render: (record, _) => (
        <>
          <Avatar
            size="large"
            shape="square"
            src={_?.profileimage}
            style={{ backgroundColor: '#cee5fb', marginRight: '16px' }}
          />
          <span>
            {_?.first_name} {_?.last_name}
          </span>
        </>
      ),
    },
    {
      title: 'Phone number',
      dataIndex: 'phone_number',
      key: 'phone',
      ...getSearchFilterProps('Phone'),
    },
    {
      title: 'Date of birth',
      dataIndex: 'birth_date',
      key: 'birth_date',
      ...getSearchFilterProps('Date of birth'),
      render: (value) => value || '-',
    },
    {
      title: 'Postcode',
      dataIndex: 'postal_number',
      key: 'postal_number',
      ...getSearchFilterProps('Postcode'),
      render: (value) => value || '-',
    },
  ];

  return (
    <InfiniteScrollWrapper
      lengthData={data?.pages?.[0].count}
      hasMore={hasNextPage}
      functionNext={fetchNextPage}
    >
      <Table
        columns={columns}
        dataSource={data?.pages.reduce((res, cur) => {
          if (cur?.results) {
            res.push(...cur?.results);
          } else res.push(...cur);
          return res;
        }, [])}
        rowKey={(record) => record.id}
        loading={isLoading || isFetching}
        pagination={false}
        onRow={(driver) => ({
          onClick: () => {
            history.push({
              search: qs.stringify({
                ...qs.parse(history.location.search),
                driver_id: driver.id,
              }),
            });
          },
          className: 'clickable-table-row',
        })}
        onChange={handelChangeTable}
      />
    </InfiniteScrollWrapper>
  );
}
