import qs from 'query-string';
import axios from '../utils/axios';

const getMotList = async ({ type, search, pageParam = 1 }) => {
  const params = qs.stringify(
    { ...search, page: pageParam },
    { skipNull: true, skipEmptyString: true },
  );
  const { data } = await axios.get(`${type}?${params}`);
  return data;
};

const getMot = async ({ type, id }) => {
  const { data } = await axios.get(`${type}${id}/`);
  return data;
};

const getCheckTime = async ({ url, time }) => {
  const { data } = await axios.get(`${url}?date=${time}`);
  return data;
};

const getCarSearch = async (CarName) => {
  const { data } = await axios.get(`/cars/car/?search=${CarName}`);
  return data;
};
const getDriverMOt = async (driverName) => {
  const { data } = await axios.get(
    `/account/activeusers/?search=${driverName}`,
  );
  return data;
};
export { getMotList, getCheckTime, getCarSearch, getMot, getDriverMOt };
