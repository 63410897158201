import React, { useState } from 'react';
import dayjs from 'dayjs';
import { Form } from 'antd';

import { SignaturePad } from '../../capture-signature-modal';

function SignaturePlaceholder({ onClick, src, ...rest }) {
  const image =
    !src || typeof src === 'string' ? src : URL.createObjectURL(src);
  return src ? (
    <img
      className="signature-pad-placeholder"
      role="button"
      onClick={onClick}
      src={image}
      {...rest}
    />
  ) : (
    <div
      className="signature-pad-placeholder"
      role="button"
      onClick={onClick}
      {...rest}
    >
      Touch here to sign
    </div>
  );
}

export function SignatureSection({ onSave, name, user }) {
  const [isSignaturePadVisible, setSignaturePadVisible] = useState(false);

  return (
    <div className="section capture-signature-section">
      <div className="flex-col">
        <h4 className="title">Signature of Hirer:</h4>
        <SignaturePad
          isVisible={isSignaturePadVisible}
          close={() => setSignaturePadVisible(false)}
          onFinish={(signatureImg) => onSave(signatureImg, name)}
          isRequired
        />
        <Form.Item
          name={name}
          valuePropName="src"
          rules={[
            {
              required: true,
              message: 'This field is required',
            },
          ]}
        >
          <SignaturePlaceholder onClick={() => setSignaturePadVisible(true)} />
        </Form.Item>
      </div>
      <div className="flex-col">
        <h4 className="title">Client&apos;s name:</h4>
        <span className="value">
          {`${user?.first_name || ''} ${user?.last_name || ''}`.trim() || '-'}
        </span>
        <h4 className="title">Date:</h4>
        <span className="value">{dayjs().format('DD/MM/YYYY')}</span>
      </div>
    </div>
  );
}
