/* eslint-disable camelcase */
import React, { useState } from 'react';
import { Form, Input, Button, message } from 'antd';
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons';
import { Link, useHistory } from 'react-router-dom';
import { AuthLayout } from '../../layout';
import axios from '../../utils/axios';
import { Heading } from '../../components';
import './Login.scss';

export function Login() {
  const [form] = Form.useForm();
  const [isSubmitting, setSubmitting] = useState(false);
  const history = useHistory();

  const handleLogin = () => {
    form.validateFields().then((formData) => {
      setSubmitting(true);
      const device_id = localStorage.getItem('fcm_token');
      axios
        .post(
          '/account/token/',
          device_id ? { ...formData, device_id } : { ...formData },
        )
        // eslint-disable-next-line no-unused-vars
        .then(({ data: { token, ...userData } }) => {
          if (userData.is_mot_admin) {
            localStorage.setItem('access_token', token.access);
            localStorage.setItem('type', 'mot');
            localStorage.setItem('refresh_token', token.refresh);
            history.push('/mot/mot-test');
          }
          if (userData.is_service_admin) {
            localStorage.setItem('access_token', token.access);
            localStorage.setItem('type', 'service');
            localStorage.setItem('refresh_token', token.refresh);
            history.push('/service/service-test');
          }
          if (userData.is_staff) {
            localStorage.setItem('access_token', token.access);
            localStorage.setItem('type', 'admin');
            localStorage.setItem('refresh_token', token.refresh);
            history.push('/dashboard');
          }
        })
        .catch(
          ({ response: { data } }) =>
            data.messages && message.error(data.messages),
        )
        .finally(() => setSubmitting(false));
    });
  };

  return (
    <AuthLayout>
      <Form
        form={form}
        onFinish={handleLogin}
        className="login-form"
        initialValues={history.location.state}
      >
        <Heading
          title="Welcome back"
          subTitle="Please enter your email and password to sign in to your account"
          className="auth-layout-heading"
        />
        <Form.Item
          name="username"
          rules={[{ required: true, message: 'Please enter your username' }]}
        >
          <Input placeholder="Username" />
        </Form.Item>
        <Form.Item
          name="password"
          rules={[{ required: true, message: 'Please enter your password' }]}
        >
          <Input.Password
            placeholder="Password"
            iconRender={(visible) =>
              visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
            }
          />
        </Form.Item>
        <Link to="/auth/forgot-password" className="forgot-pass-link">
          Forgot your password?
        </Link>

        <Form.Item noStyle>
          <Button
            type="primary"
            htmlType="submit"
            block
            className="cta-btn"
            loading={isSubmitting}
          >
            Sign in
          </Button>
        </Form.Item>
      </Form>
    </AuthLayout>
  );
}
