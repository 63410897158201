import qs from 'query-string';
import axios from '../utils/axios';

const getUserStates = async () => {
  const {
    data: { State },
  } = await axios.get('/account/user-state/');
  return State;
};

const getPersonalInfoRejectReasons = async () => {
  const { data } = await axios.get(`/account/reject-reasens/`);
  return data;
};

const getDriverByID = async (id) => {
  const { data } = await axios.get(`/account/users/${id}/`);
  return data;
};

const getDrivers = async ({ fetchEndpoint, pageParam = 1, queryParams }) => {
  let endpoint = fetchEndpoint;
  const queryString = qs.stringify(
    {
      page: pageParam || null,
      ...queryParams,
    },
    { skipNull: true, skipEmptyString: true },
  );

  if (queryString) endpoint += queryString;

  const { data } = await axios.get(endpoint);
  return data;
};

export {
  getUserStates,
  getPersonalInfoRejectReasons,
  getDriverByID,
  getDrivers,
};
