import { Form, Input } from 'antd';
import dayjs from 'dayjs';
import { FloatLabel } from '../floating-label';
import { SinglePhotoUploader } from '../single-photo-uploader';
import { CustomDatePicker } from '../custom-date-picker';

const A25yearsAgo = dayjs().subtract(25, 'years');
const checkDisableDates = (current) => current > A25yearsAgo;

export function PersonalInfoSection() {
  /*
  we set a custom container for picker popup because by default it relevant
  to body and stay still with scroll
  */
  return (
    <div className="inline-container">
      <Form.Item name="profileimage" label="Driver's picture (Optional)">
        <SinglePhotoUploader size="small" />
      </Form.Item>
      <Form.Item name="reference_number">
        <FloatLabel label="Driver reference number">
          <Input disabled />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        name="first_name"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <FloatLabel label="First name">
          <Input />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        name="last_name"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <FloatLabel label="Last Name">
          <Input />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        name="birth_date"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <FloatLabel label="Date of birth">
          <CustomDatePicker
            disabledDate={checkDisableDates}
            defaultPickerValue={dayjs().subtract(25, 'years')}
          />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        name="phone_number"
        rules={[
          {
            required: true,
            message: 'This field is required',
          },
        ]}
      >
        <FloatLabel label="Phone number">
          <Input maxLength={14} />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        name="address"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <FloatLabel label="Full address">
          <Input.TextArea cols="21" rows="4" />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        name="postal_number"
        rules={[{ required: true, message: 'This field is required' }]}
      >
        <FloatLabel label="Postcode">
          <Input />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        name="email"
        rules={[
          { required: true, message: 'This field is required' },
          { type: 'email', message: 'Please enter a valid email address' },
        ]}
      >
        <FloatLabel label="Email address">
          <Input type="email" />
        </FloatLabel>
      </Form.Item>
    </div>
  );
}
