import { Icon } from '../icon';

const itemsMot = [
  {
    title: 'Main menu',
    items: [
      {
        Icon: <Icon variant="list" className="icon-24 icon-fill-inherit" />,
        pathname: '/mot/reports',
        Title: 'Reports',
        TitleHeader: 'sss',
        id: 2,
      },
      {
        Icon: <Icon variant="list" className="icon-24 icon-fill-inherit" />,
        pathname: '/mot/mot-test',
        Title: 'Schedules',
        TitleHeader: 'Drivers list',
        id: 1,
      },
    ],
  },
];
export default itemsMot;
