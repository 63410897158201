/* eslint-disable camelcase */
/* eslint-disable no-unused-vars */
import { Button, Tabs } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { DriverProfileModal } from '../../components';
import { ActiveDrivers } from './Active';
import { ArchivedDrivers } from './Archived';
import { NewRegisteredDrivers } from './NewRegistered';
import { RegisterNewDriverModal } from './RegisterNewDriverModal';

import './styles.scss';

const { TabPane } = Tabs;

export function DriversList() {
  const history = useHistory();
  const location = useLocation();

  const [isModalVisible, setModalVisible] = useState(false);

  const page =
    useMemo(() => qs.parse(location.search)?.category, [location.search]) ||
    'active';

  const tabClick = (key) => {
    history.push(`?category=${key}`);
  };

  useEffect(() => {
    setModalVisible(false);
  }, [location]);

  return (
    <>
      <Tabs
        tabBarExtraContent={
          <Button
            onClick={() => setModalVisible(true)}
            className="secondary-btn"
          >
            Sign up new driver
          </Button>
        }
        activeKey={page}
        onTabClick={tabClick}
        type="card"
        className="tables-tab-bar"
        animated
        destroyInactiveTabPane
      >
        <TabPane tab="Active" key="active">
          <ActiveDrivers />
        </TabPane>
        <TabPane tab="New registered" key="new">
          <NewRegisteredDrivers />
        </TabPane>
        <TabPane tab="Archived" key="archived">
          <ArchivedDrivers />
        </TabPane>
      </Tabs>
      <RegisterNewDriverModal
        isVisible={isModalVisible}
        setVisible={setModalVisible}
      />
      <DriverProfileModal width={900} />
    </>
  );
}
