import { Table, DatePicker, Form } from 'antd';
import { useHistory, useLocation } from 'react-router-dom';
import dayjs from 'dayjs';
import { useEffect, useMemo } from 'react';
import qs from 'query-string';
import { useSummaryReport } from '../../hooks/report';

const { RangePicker } = DatePicker;

export default function SummaryReport() {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const query = useMemo(() => qs.parse(location.search), [location.search]);
  delete query.category;

  const { data, loading, isFetching } = useSummaryReport({
    ...query,
    start_date:
      query?.start_date || dayjs().subtract(1, 'month').format('YYYY-MM-DD'),
    end_date: query?.end_date || dayjs().format('YYYY-MM-DD'),
  });

  const changeDate = (date) => {
    history.push(
      `reports?category=summary&start_date=${dayjs(date?.[0]).format(
        'YYYY-MM-DD',
      )}&end_date=${dayjs(date?.[1]).format('YYYY-MM-DD')}`,
    );
  };

  useEffect(() => {
    if (query.start_date) {
      form.setFieldsValue({
        date: [dayjs(query.start_date), dayjs(query.end_date)],
      });
    } else {
      form.setFieldsValue({
        date: [dayjs(new Date()).subtract(1, 'month'), dayjs(new Date())],
      });
    }
  }, [location]);

  const columns = [
    {
      title: 'Sales Hire',
      dataIndex: 'sales_hire',
      render: (value) => `£ ${value}`,
    },
    {
      title: 'Sales Insurance',
      dataIndex: 'sales_insurance',
      render: (value) => `£ ${value}`,
    },
    {
      title: 'Miscellaneous expenses',
      dataIndex: 'miscellaneous_expenses',
      render: (value) => `£ ${value}`,
    },
    {
      title: 'Total Sales',
      dataIndex: 'total_sales',
      render: (value) => `£ ${value}`,
    },
    {
      title: 'Total Vat',
      dataIndex: 'total_vat',
      render: (value) => `£ ${value}`,
    },
    {
      title: 'Total Deposit Collected',
      dataIndex: 'total_deposit_collected',
      render: (value) => `£ ${value}`,
    },
  ];

  return (
    <>
      <Form form={form}>
        <Form.Item name="date">
          <RangePicker
            style={{ width: 'auto', marginBottom: 30 }}
            onChange={changeDate}
          />
        </Form.Item>
      </Form>
      <Table
        loading={loading || isFetching}
        columns={columns}
        dataSource={[data]}
        pagination={false}
      />
    </>
  );
}
