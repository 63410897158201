/* eslint-disable react/destructuring-assignment */
import { Button, DatePicker, Input, Table } from 'antd';
import dayjs from 'dayjs';
import { RightOutlined, LeftOutlined } from '@ant-design/icons';
import { useEffect, useMemo, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import { useCheckTime } from '../../hooks';

export function ScheduleTable({ url, routeOpenMpdal }) {
  const location = useLocation();
  const history = useHistory();

  const [timestamp, setTimestamp] = useState();
  const [defaultValueWeek, setDefaultValueWeek] = useState(dayjs());

  const time =
    useMemo(() => qs.parse(location.search)?.time, [location.search]) || '';

  const {
    data: dataReserve,
    isLoading,
    isFetching,
  } = useCheckTime({
    url,
    time: timestamp || dayjs().startOf('week').format('YYYY-MM-DD'),
  });
  const reserveData = dataReserve || [];
  const reserveDataForTable =
    reserveData.length > 0 &&
    reserveData[0].schedule.map((item, index) =>
      reserveData
        .map((day) => ({
          [dayjs(day.date).format('dddd').toLowerCase()]: day.schedule[index],
        }))
        .reduce(
          (a, v) => ({ ...a, [Object.keys(v)[0]]: v[Object.keys(v)[0]] }),
          {},
        ),
    );

  useEffect(() => {
    if (time) {
      setTimestamp(dayjs.unix(time).startOf('week').format('YYYY-MM-DD'));
      setDefaultValueWeek(dayjs.unix(+time).startOf('week'));
    }
  }, [location]);

  function onChangeDatePicker(date) {
    setDefaultValueWeek(dayjs(date).startOf('week'));
    setTimestamp(dayjs.unix(time).startOf('week').format('YYYY-MM-DD'));
    history.push({
      search: qs.stringify({
        ...qs.parse(history.location.search),
        time: dayjs(date).startOf('week').unix(),
      }),
    });
  }

  const customWeekStartEndFormat = (value) =>
    `${dayjs(value).startOf('week').format('DD/MM')} - ${dayjs(value)
      .endOf('week')
      .format('DD/MM')}`;

  const changeDatePicker = (count) => {
    setDefaultValueWeek(dayjs(defaultValueWeek).weekday(count).startOf('week'));
    history.push({
      search: qs.stringify({
        ...qs.parse(history.location.search),
        time: dayjs(defaultValueWeek).weekday(count).startOf('week').unix(),
      }),
    });
    setTimestamp(
      dayjs.unix(time).weekday(count).startOf('week').format('YYYY-MM-DD'),
    );
  };

  return (
    <>
      <div style={{ textAlign: 'center' }}>
        <Button.Group>
          <Button
            type="text"
            icon={<LeftOutlined />}
            className="btn-date-range prev-week"
            onClick={() => changeDatePicker(-7)}
          />
          <DatePicker
            className="weekly-picker"
            onChange={onChangeDatePicker}
            picker="week"
            value={defaultValueWeek}
            format={customWeekStartEndFormat}
            suffixIcon={false}
            clearIcon={false}
          />
          <Button
            type="text"
            icon={<RightOutlined />}
            className="btn-date-range next-week"
            onClick={() => changeDatePicker(7)}
          />
        </Button.Group>
      </div>
      <Table
        dataSource={reserveDataForTable}
        bordered
        loading={isLoading || isFetching}
        scroll={{
          x: 1024,
        }}
        pagination={false}
        columns={reserveData.map((item) => ({
          title:
            (dayjs(item.date).format('dddd DD/MM') ===
              dayjs().format('dddd DD/MM') &&
              `${dayjs(item.date).format('dddd DD/MM')} (today)`) ||
            dayjs(item.date).format('dddd DD/MM'),
          dataIndex: dayjs(item.date).format('dddd').toLowerCase(),
          className:
            (dayjs(item.date).format('dddd DD/MM') ===
              dayjs().format('dddd DD/MM') &&
              'today') ||
            (item.is_holidays && 'is_holidays'),
          render: (value) => (
            <>
              <>
                <p className="service-hours">{value?.time}</p>
                {value?.reserve &&
                  value?.reserve.map((i) => (
                    <p
                      key={i}
                      style={{ display: 'flex', alignItems: 'center' }}
                    >
                      <Input
                        style={{ width: 'auto' }}
                        checked
                        type="checkbox"
                      />{' '}
                      &nbsp;&nbsp;
                      <Link to={i?.id && `${routeOpenMpdal}?id=${i?.id}`}>
                        <span className="service-name">
                          {i?.first_name} {i?.last_name}
                        </span>
                      </Link>
                    </p>
                  ))}
              </>
            </>
          ),
        }))}
      />
    </>
  );
}
