import qs from 'query-string';
import { axios } from '../utils';

export const getCarReturnList = async ({
  pageParam = 1,
  category,
  ordering,
}) => {
  const params = qs.stringify({ ordering, page: pageParam, category });
  const { data } = await axios.get(
    `/returncar/panel/returncar-list/?${params}`,
  );
  return data;
};

export const getCarReturn = async ({ id, category }) => {
  const { data } = await axios.get(
    category === 'history'
      ? `/returncar/panel/returncar-list/${id}/?category=${category}`
      : `/returncar/panel/returncar-list/${id}/`,
  );
  return data;
};
