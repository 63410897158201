import { Typography } from 'antd';
import './Header.scss';

const { Title } = Typography;
export default function HeaderPanel({ title, ...restProps }) {
  return (
    <header className="header-panel" {...restProps}>
      <Title level={2} className="page-title">
        {title}
      </Title>
    </header>
  );
}
