import { Switch, Route } from 'react-router-dom';
import { ConfirmPassword, ForgotPassword, Login, NotFound } from '../../pages';

export default function GuestRouter() {
  return (
    <Switch>
      <Route path="/auth/login">
        <Login />
      </Route>
      <Route path="/auth/forgot-password">
        <ForgotPassword />
      </Route>
      <Route path="/auth/confirm-password/:uid/:token">
        <ConfirmPassword />
      </Route>
      <Route>
        <NotFound />
      </Route>
    </Switch>
  );
}
