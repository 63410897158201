/* eslint-disable react/destructuring-assignment */
import { Button, Form, message, Modal, Spin, Table } from 'antd';
import qs from 'query-string';
import dayjs from 'dayjs';
import { useHistory, useLocation } from 'react-router-dom';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import {
  CustomizedModal,
  getSearchFilterProps,
  InfiniteScrollWrapper,
} from '../../components';
import ItemsModal from './ItemsModal';
import { useAccident, useAccidentId } from '../../hooks/accidents';
import { axios } from '../../utils';

export default function TableAccident({ isHistory }) {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const queryClient = useQueryClient();

  const [isVisible, setVisible] = useState(false);
  const [isChangeing, setIsChangeing] = useState();
  const [isLoadingAddReport, setIsLoadingAddReport] = useState(false);
  const [isLoadingConfirm, setIsLoadingConfirm] = useState(false);
  const [isOtherDriver, setIsOtherDriver] = useState(2);
  const [isEnableInstallment, setIsEnableInstallment] = useState(false);

  const id = useMemo(() => qs.parse(location.search).id, [location.search]);

  const paramsQuery = useMemo(
    () => qs.parse(qs.exclude(history.location.search, ['id'])),
    [location.search],
  );

  const category = useMemo(
    () => qs.parse(location.search).category,
    [location.search] || 'new',
  );

  const { data: dataAccidentId, isLoading: accidentIsLoading } = useAccidentId({
    id,
    category,
  });

  const onConfirmExit = () => {
    setVisible(false);
  };
  const onCancel = () => {
    Modal.confirm({
      closeIcon: true,
      centered: true,
      title: 'Exit Confirmation',
      content:
        'You are leaving this process. All your unsaved data will be lost. Do you wish to proceed?',
      onOk: onConfirmExit,
      okText: 'Leave',
      cancelText: 'Stay',
      okType: 'danger',
      closable: true,
      maskClosable: true,
      icon: false,
    });
  };

  const queryParams = useMemo(() => {
    const params = qs.parse(location.search);
    delete params?.category;
    delete params?.id;
    return params;
  }, [location.search]);

  const { data, hasNextPage, fetchNextPage, isLoading, isFetching } =
    useAccident(paramsQuery);

  const getSortOrder = (parameter) => {
    if (queryParams[parameter] === 'descend') return 'descend';
    if (queryParams[parameter] === 'ascend') return 'ascend';
    return null;
  };

  const updateAccidentReport = () => {
    const arrayNameFild = Object.keys(form.getFieldsValue());
    const filtered = arrayNameFild.filter(
      (value) =>
        value !== 'lenghtInvoice' &&
        value !== 'reference_number' &&
        value !== 'amount',
    );
    form.validateFields(filtered).then(() => {
      setIsLoadingAddReport(true);
      const fd = new FormData();
      fd.append(
        'otherdriver_first_name',
        form.getFieldValue('otherdriver_first_name'),
      );
      fd.append(
        'otherdriver_last_name',
        form.getFieldValue('otherdriver_last_name'),
      );
      fd.append(
        'otherdriver_phone_number',
        form.getFieldValue('otherdriver_phone_number'),
      );
      fd.append(
        'amount',
        form.getFieldValue(['payment', 'total_to_be_collected']),
      );
      fd.append(
        'otherdriver_registrationnumber',
        form.getFieldValue('otherdriver_registrationnumber'),
      );
      fd.append(
        'otherdriver_address',
        form.getFieldValue('otherdriver_address'),
      );
      if (
        form.getFieldValue('otherdriver_driving_license')?.file?.originFileObj
      ) {
        fd.append(
          'otherdriver_driving_license',
          form.getFieldValue('otherdriver_driving_license')?.file
            ?.originFileObj,
        );
      }
      fd.append(
        'date',
        dayjs(form.getFieldValue('test_date')).format('YYYY-MM-DD HH:mm:00'),
      );
      fd.append('location', form.getFieldValue('location'));
      fd.append('description', form.getFieldValue('description'));
      if (
        form.getFieldValue('culprit_of_accident') === 'Our driver' ||
        form.getFieldValue('culprit_of_accident') === 1
      ) {
        fd.append('culprit_of_accident', 1);
      } else if (
        form.getFieldValue('culprit_of_accident') === 'Other driver' ||
        form.getFieldValue('culprit_of_accident') === 2
      ) {
        fd.append('culprit_of_accident', 2);
      }
      if (
        form.getFieldValue('status') === 'Pending Review' ||
        form.getFieldValue('status') === 1
      ) {
        fd.append('status', 1);
      } else if (
        form.getFieldValue('status') === 'In progress' ||
        form.getFieldValue('status') === 2
      ) {
        fd.append('status', 2);
      } else if (
        form.getFieldValue('status') === 'Closed' ||
        form.getFieldValue('status') === 3
      ) {
        fd.append('status', 3);
      }
      axios
        .put(`accident/panel/report/${dataAccidentId.id}/`, fd)
        .then(() => {
          message.success('Report details updated');
          queryClient.invalidateQueries('getAccident');
          setVisible(false);
        })
        .finally(() => setIsLoadingAddReport(false));
    });
  };

  const confirmInvoice = () => {
    form.validateFields(['lenghtInvoice', 'first_charge_date']).then(() => {
      setIsLoadingConfirm(true);
      axios
        .post('/payment/collect-accident-payment', {
          accident: dataAccidentId?.id,
          number_of_installments: form.getFieldValue('lenghtInvoice'),
          amount: form.getFieldValue('totalCollected'),
          first_charge_date: dayjs(
            form.getFieldValue('first_charge_date'),
          ).format('YYYY-MM-DD'),
        })
        .then(() => queryClient.invalidateQueries('getAccidentId'))
        .finally(() => setIsLoadingConfirm(false));
    });
  };

  const handelChangeTable = (pagination, filters, sorter) => {
    const { current: page, pageSize: size } = pagination;
    const params = { page, size };
    if (sorter.column) {
      Object.assign(params, {
        [`sort_${sorter.columnKey}`]:
          sorter.order === 'descend' ? 'descend' : 'ascend',
      });
    }
    Object.entries(filters).forEach(([keyColumn, value]) => {
      if (value?.length > 0) {
        let paramKey;
        switch (keyColumn) {
          case 'Driver_details':
            paramKey = 'name';
            break;
          case 'car_details':
            paramKey = 'car';
            break;
          default:
            break;
        }
        Object.assign(params, { [paramKey]: value[0] });
      }
    });
    history.replace({
      search: qs.stringify({
        ...qs.parse(history.location.search),
        name: params.name,
        car: params.car,
        ordering: params.sort_created_at,
      }),
    });
  };

  useEffect(() => {
    if (!id) setVisible(false);
    if (id) setVisible(true);
  }, [location]);

  useEffect(() => {
    if (dataAccidentId?.payment?.type === 'GoCardless') {
      form.setFieldsValue({ installment: true });
      setIsEnableInstallment(true);
    } else {
      form.setFieldsValue({ installment: false });
      setIsEnableInstallment(false);
    }
    if (dataAccidentId) {
      if (dataAccidentId.culprit_of_accident === 'Other driver') {
        setIsOtherDriver(2);
      } else {
        setIsOtherDriver(1);
      }
      form.setFieldsValue({
        ...dataAccidentId,
        model: `${dataAccidentId.car_details.company} ${dataAccidentId.car_details.model}`,
        fullName: `${dataAccidentId.Driver_details.first_name} ${dataAccidentId.Driver_details.last_name}`,
        otherdriverFullName: `${dataAccidentId?.otherdriver_first_name} ${dataAccidentId?.otherdriver_last_name}`,
        test_date: dayjs.utc(dataAccidentId.date).format('YYYY/MM/DD HH:mm'),
        lenghtInvoice:
          dataAccidentId?.payment?.gocardless_invoice?.payments?.length,
      });
    }
  }, [dataAccidentId]);

  const columns = [
    {
      title: "Driver's name",
      dataIndex: 'first_name',
      key: 'Driver_details',
      ...getSearchFilterProps("Driver's name"),
      render: (_, fullName) =>
        `${fullName.Driver_details?.first_name} ${fullName.Driver_details?.last_name}`,
    },
    {
      title: 'Car',
      dataIndex: 'car_details',
      ...getSearchFilterProps('Car name'),
      key: 'car_details',
      render: (car) =>
        car ? (
          <>
            {car.company} {car.model}
            <br />
            {`(${car.registrationnumber})`}
          </>
        ) : (
          '-'
        ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      render: (value) =>
        value ? <>{dayjs(value).format('DD/MM/YYYY')}</> : '-',
      key: 'created_at',
      sorter: true,
      sortOrder: getSortOrder('ordering'),
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'state',
    },
  ];

  return (
    <>
      <InfiniteScrollWrapper
        lengthData={data?.results?.[0]?.count}
        hasMore={hasNextPage}
        functionNext={fetchNextPage}
        scrollableTarget="modal-with-infinite-scroll-table"
      >
        <Table
          scroll={{
            x: 1024,
          }}
          loading={isLoading || isFetching}
          className="bordered-table clickable-table-row"
          dataSource={data?.pages.reduce((res, cur) => {
            if (cur?.results) {
              res.push(...cur?.results);
            } else res.push(...cur);
            return res;
          }, [])}
          onChange={handelChangeTable}
          columns={columns}
          pagination={false}
          onRow={(record) => ({
            onClick: () => {
              history.push({
                search: qs.stringify({
                  id: record.id,
                  ...qs.parse(history.location.search),
                }),
              });
            },
          })}
        />
      </InfiniteScrollWrapper>
      <CustomizedModal
        visible={isVisible}
        className="book-appointment"
        onCancel={isChangeing ? onCancel : onConfirmExit}
        afterClose={() => {
          history.push({
            search: qs.exclude(location.search, ['id']),
          });
          setIsChangeing(false);
          setIsEnableInstallment(false);
          form.resetFields();
        }}
        footer={
          <>
            {!dataAccidentId?.payment?.type &&
              isEnableInstallment &&
              !isHistory && (
                <Button
                  loading={isLoadingConfirm}
                  onClick={confirmInvoice}
                  type="primary"
                  disabled={
                    dataAccidentId?.culprit_of_accident === 'Other driver'
                  }
                >
                  Confirm and generate invoice
                </Button>
              )}
            {!isHistory && (
              <Button
                loading={isLoadingAddReport}
                onClick={updateAccidentReport}
                type="primary"
                disabled={!isChangeing}
              >
                update
              </Button>
            )}
          </>
        }
      >
        <Spin spinning={accidentIsLoading}>
          <Form
            form={form}
            className="inline-container"
            style={{ justifyContent: 'space-between' }}
            onValuesChange={(val) => {
              setIsChangeing(!!val);
            }}
          >
            <ItemsModal
              modeModal="update"
              isHistory={isHistory}
              dataModal={dataAccidentId}
              form={form}
              isOtherDriver={isOtherDriver}
              setIsOtherDriver={setIsOtherDriver}
              isEnableInstallment={isEnableInstallment}
              setIsEnableInstallment={setIsEnableInstallment}
              titleModal="show accident details"
            />
          </Form>
        </Spin>
      </CustomizedModal>
    </>
  );
}
