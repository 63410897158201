import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Layout } from 'antd';

import SideMenu from '../../components/sidebar';
import HeaderComponent from '../../components/header';
import './styles.scss';

import sidebarItems from '../../components/sidebar/items';
import motSidebarItems from '../../components/sidebar/itemsMot';
import itemsService from '../../components/sidebar/itemsService';

export function UserLayout({ children }) {
  const { pathname } = useLocation();
  const [curTitle, setCurTitle] = useState('');
  useEffect(() => {
    let list;
    switch (localStorage.getItem('type')) {
      case 'mot':
        list = motSidebarItems;
        break;
      case 'service':
        list = itemsService;
        break;
      case 'admin':
        list = sidebarItems;
        break;
      default:
        break;
    }
    // Reads Title and subTitle From JSON File in Different routes
    list.some((itemGroup) => {
      const newTitle = itemGroup.items.find(
        (item) => item.pathname === pathname,
      )?.Title;
      if (newTitle) {
        setCurTitle(newTitle);
      }
      switch (pathname) {
        case '/notifications':
          setCurTitle('Notifications');
          break;
        case '/mot/notifications':
          setCurTitle('Notifications');
          break;
        case '/service/notifications':
          setCurTitle('Notifications');
          break;
        case '/dashboard':
          setCurTitle('Dashboard');
          break;
        case '/task-board':
          setCurTitle('Task Board');
          break;
        default:
          break;
      }
      return newTitle !== undefined;
    });
  }, [pathname]);

  return (
    <Layout style={{ minHeight: '100%' }}>
      <SideMenu />
      <Layout className="main-container" style={{ minHeight: '100%' }}>
        <Layout.Content className="page-content">
          <HeaderComponent title={curTitle} />
          {children}
        </Layout.Content>
      </Layout>
    </Layout>
  );
}
