import { Tabs } from 'antd';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import qs from 'query-string';
import VehiclesFinance from './VehiclesFinance';
import SummaryReport from './SummaryReport';
import SalesBreakdown from './SalesBreakdown';
import ReceiptBreakdown from './ReceiptBreakdown';
import VehicleProfit from './vehicle-profit';

const { TabPane } = Tabs;

export function ReportsAdmin() {
  const history = useHistory();
  const location = useLocation();

  const page =
    useMemo(() => qs.parse(location.search)?.category, [location.search]) ||
    'summary';

  const tabClick = (key) => {
    history.push(`reports?category=${key}`);
  };

  return (
    <>
      <Tabs
        type="card"
        className="tables-tab-bar"
        activeKey={page}
        onTabClick={tabClick}
        animated
      >
        <TabPane tab="Summary report" key="summary">
          <SummaryReport />
        </TabPane>
        <TabPane tab="Sales breakdown" key="sales-breakdown">
          <SalesBreakdown />
        </TabPane>
        <TabPane tab="Receipt breakdown" key="receipt-breakdown">
          <ReceiptBreakdown />
        </TabPane>
        <TabPane tab="Vehicles finance report" key="vehicles-finance">
          <VehiclesFinance />
        </TabPane>
        <TabPane tab="Vehicle profit" key="vehicle-profit">
          <VehicleProfit />
        </TabPane>
      </Tabs>
    </>
  );
}
