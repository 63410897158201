/* eslint-disable camelcase */
import { Button, Form, Divider, Input, Table, Spin, message } from 'antd';
import React, { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import printJS from 'print-js';
import qs from 'query-string';
import { useHistory, useLocation } from 'react-router-dom';
import { useInvoice } from '../../hooks/payments';
import { axios, confirmExit, getBase64 } from '../../utils';
import { CustomizedModal, FloatLabel } from '../../components';
import './styles.scss';

export function ViewInvoiceModal({
  isVisible,
  setVisible,
  invoiceType = 'pending',
  setRefetch,
}) {
  const [form] = Form.useForm();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const location = useLocation();
  const history = useHistory();

  const queryParams =
    useMemo(() => qs.parse(location.search), [location.search]) || {};

  const { payment_id: paymentID } = queryParams;
  const { data: invoice, isFetching: isFetchingPayment } =
    useInvoice(paymentID);

  const onConfirmClose = () => {
    setVisible(false);
    form.resetFields();
    history.push({
      search: qs.exclude(location.search, ['payment_id']),
    });
  };
  const payNow = (gocardless_payment) => {
    setIsSubmitting(true);
    axios
      .post('/payment/pay-now', { gocardless_payment })
      .then(({ data: { gateway } }) => {
        setIsSubmitting(false);
        window.location.href = gateway;
      })
      .catch(() => setIsSubmitting(false));
  };

  const showFile = (file) => {
    const blob = new Blob([file], { type: 'application/pdf' });

    getBase64(blob).then((base64) => {
      printJS({
        printable: base64.replace('data:application/pdf;base64,', ''),
        base64: true,
        documentTitle: `reciept.pdf`,
      });
    });
  };

  const printDoc = async (id) => {
    message.loading({
      key: 'downloading-pdf',
      content: 'Generating document...\nPlease wait',
    });
    axios
      .get(`/payment/print-receipt?source=Direct Debit&id=${id}`, {
        responseType: 'arraybuffer',
      })
      .then(({ data }) => showFile(data))
      .finally(() => message.destroy('downloading-pdf'));
  };

  const columns = useMemo(() => {
    const temp = [
      {
        title: 'Payment date',
        render: (_, record) => dayjs(record.payment_date).format('DD/MM/YYYY'),
      },
      {
        title: 'Amount',
        render: (_, record) => `£ ${record.total_cost}`,
      },
    ];
    if (invoiceType !== 'pending')
      temp.push(
        {
          title: 'Status',
          render: (_, record) => (
            <span
              style={{ color: record.status === 'Payment failed' && '#da4242' }}
            >
              {record.status}
            </span>
          ),
        },
        {
          width: 54,
          render: (_, record) => {
            if (record.status === 'Payment failed')
              return (
                <Button
                  type="primary"
                  onClick={() => payNow(record.id)}
                  className="footer-cta-btn pay-now-btn"
                  loading={isSubmitting}
                >
                  Pay now
                </Button>
              );
            return null;
          },
        },
        {
          width: 34,
          render: (_, record) => (
            <Button
              type="link"
              className="reciept"
              onClick={() => printDoc(record.id)}
            >
              Reciept
            </Button>
          ),
        },
      );
    return temp;
  }, []);

  const finish = (status) => {
    if (status === 'Confirmed') setIsSubmitting(true);
    axios
      .patch(`/payment/invoices/${paymentID}`, { status })
      .then(() => {
        setIsSubmitting(false);
        setVisible(false);
        form.resetFields();
        setRefetch(true);
        history.push({
          search: qs.exclude(location.search, ['payment_id']),
        });
      })
      .catch(() => setIsSubmitting(false));
    //
  };

  React.useEffect(() => {
    if (paymentID && typeof paymentID === 'string' && paymentID !== undefined)
      setVisible(true);
  }, [paymentID]);

  return (
    <CustomizedModal
      visible={isVisible}
      onCancel={onConfirmClose}
      footer={
        invoiceType === 'pending' ? (
          <>
            <Button
              type="default"
              onClick={() =>
                confirmExit(
                  () => finish('Declined'),
                  true,
                  'Decline Confirmation',
                  'Are you sure you want to decline the invoice?',
                  'Yes, decline',
                )
              }
              className="footer-cta-btn half-width"
              size="large"
              disabled={isSubmitting}
            >
              Decline
            </Button>
            <Button
              type="primary"
              onClick={() => finish('Confirmed')}
              className="footer-cta-btn"
              size="large"
              loading={isSubmitting}
            >
              Confirm and generate invoice
            </Button>
          </>
        ) : null
      }
    >
      <Spin spinning={isFetchingPayment}>
        <header className="invoice-modal-header">
          <h2 className="heading">Invoice details</h2>
        </header>
        <div className="steps-content">
          <div className="inline-container">
            <Divider orientation="left">
              Driver&apos;s info
              <Button
                type="link"
                className="divider-link"
                onClick={() =>
                  history.replace(
                    `/drivers?driver_id=${invoice?.driver_car.driver.id}`,
                  )
                }
              >
                Open driver&apos;s profile
              </Button>
            </Divider>
            <FloatLabel
              label="Name"
              forceFocused
              value={`${invoice?.driver_car.driver.first_name} ${invoice?.driver_car.driver.last_name}`}
            >
              <Input readOnly disabled />
            </FloatLabel>
            <FloatLabel
              label="Phone number"
              forceFocused
              value={invoice?.driver_car.driver.phone_number}
            >
              <Input readOnly disabled />
            </FloatLabel>
            <Divider orientation="left">
              Car details
              <Button
                type="link"
                className="divider-link"
                onClick={() =>
                  history.replace(
                    `/car-bank?car_id=${invoice?.driver_car.car.id}`,
                  )
                }
              >
                Open car profile
              </Button>
            </Divider>
            <FloatLabel
              label="Car model"
              forceFocused
              value={`${invoice?.driver_car.car.make} ${invoice?.driver_car.car.model}`}
            >
              <Input readOnly disabled />
            </FloatLabel>
            <FloatLabel
              label="Registration number"
              forceFocused
              value={invoice?.driver_car.car.registrationnumber}
            >
              <Input readOnly disabled />
            </FloatLabel>
          </div>
          <Divider orientation="left">Payments list</Divider>
          <Table
            columns={columns}
            dataSource={invoice?.payments}
            rowKey="id"
            className="bordered-table full-width"
            rowClassName={(record) =>
              record.status === 'Payment failed' &&
              invoiceType !== 'pending' &&
              'failed-row'
            }
            pagination={false}
          />
        </div>
      </Spin>
    </CustomizedModal>
  );
}
