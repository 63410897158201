import { Avatar } from 'antd';
import { getSearchFilterProps } from '../../components';

export const columns = [
  {
    title: 'Reference Number',
    dataIndex: 'reference_number',
    key: 'reference_number',
    ...getSearchFilterProps('Reference Number'),
  },
  {
    title: 'Name',
    dataIndex: 'first_name',
    key: 'first_name',
    ...getSearchFilterProps('Active Driver'),
    render: (_, record) => (
      <>
        <Avatar
          size="large"
          shape="square"
          src={record?.profileimage}
          style={{ backgroundColor: '#cee5fb', marginRight: '16px' }}
        />
        <span>
          {record?.first_name}&nbsp;{record?.last_name}
        </span>
      </>
    ),
  },
  {
    title: 'Vehicle',
    dataIndex: 'vehicle_make',
    key: 'vehicle',
    ...getSearchFilterProps('Vehicle'),
    render: (car, record) =>
      car ? (
        <>
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {car} {record.vehicle_model}
          <br />
          {/* eslint-disable-next-line react/destructuring-assignment */}
          {`(${record.vehicle_registration_number})`}
        </>
      ) : (
        '-'
      ),
  },
  {
    title: 'Date of birth',
    dataIndex: 'birth_date',
    key: 'birth_date',
    ...getSearchFilterProps('Date of birth'),
    render: (value) => value || '-',
  },
  {
    title: 'Phone number',
    dataIndex: 'phone_number',
    key: 'phone',
    ...getSearchFilterProps('phone number'),
    render: (value) => value || '-',
  },
  {
    title: 'Postcode',
    dataIndex: 'postal_number',
    key: 'postal_number',
    ...getSearchFilterProps('Postcode'),
    render: (value) => value || '-',
  },
];
