import React, { useState } from 'react';
import printJS from 'print-js';
import cn from 'classnames';
import { message, Upload } from 'antd';

import { useQueryClient } from 'react-query';
import { Icon } from '../icon';
import { axios, getBase64 } from '../../utils';

import './styles.scss';

export const DocRow = ({
  title,
  name,
  driverID,
  value,
  uploadable = true,
  printable = true,
  viewable = true,
  suffix,
  requestUrl,
  printEndpoint,
}) => {
  const queryClient = useQueryClient();

  const showFile = (file) => {
    const blob = new Blob([file], { type: 'application/pdf' });

    getBase64(blob).then((base64) => {
      printJS({
        // https://github.com/crabbly/Print.js/issues/581
        printable: base64.replace('data:application/pdf;base64,', ''),
        base64: true,
        documentTitle: `${name}-${driverID}.pdf`,
      });
    });
  };

  const printDoc = async () => {
    message.loading({
      key: 'downloading-pdf',
      content: 'Generating document...\nPlease wait',
    });
    axios
      .get(`/${printEndpoint}${driverID}`, {
        responseType: 'arraybuffer',
      })
      .then(({ data }) => showFile(data))
      .finally(() => message.destroy('downloading-pdf'));
  };

  const [fileList, setFileList] = useState([]);

  const handleUploadDoc = ({ onSuccess, onError, onProgress, file }) => {
    if (requestUrl) {
      const fd = new FormData();
      fd.append('user', driverID);
      fd.append(name, file);

      axios
        .put(requestUrl, fd, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
          onUploadProgress: (e) => {
            const percent = (e.loaded / e.total) * 100;
            onProgress({ ...e, percent });
          },
        })
        .then(() => {
          onSuccess();
          queryClient.invalidateQueries('driver');
          setFileList([]);
        })
        .catch((err) => onError({ message: err }));
    }
  };

  return (
    <div className="upload-doc-row">
      <div
        style={{ display: 'flex', alignItems: 'center', marginRight: 'auto' }}
      >
        {viewable ? (
          <a
            href={value}
            target="_blank"
            rel="noopener noreferrer"
            className={cn('view-doc-link-btn', { disabled: !value })}
          >
            <Icon
              variant="document"
              width={36}
              height={36}
              fill="currentColor"
              color="inherit"
            />
          </a>
        ) : (
          <span className="view-doc-link-btn disabled">
            <Icon
              variant="document"
              width={36}
              height={36}
              fill="currentColor"
              color="inherit"
            />
          </span>
        )}
        <div className="doc-received-status-box">
          <span className="doc-title">{title}</span>
        </div>
      </div>
      {printable && value && (
        <button
          type="button"
          onClick={printDoc}
          className="print-doc-btn"
          style={{ marginRight: '24px' }}
        >
          Print this document
        </button>
      )}
      {uploadable && (
        <Upload
          className="upload-doc-btn"
          accept="application/pdf"
          maxCount={1}
          customRequest={handleUploadDoc}
          fileList={fileList}
          showUploadList={{
            showRemoveIcon: false,
          }}
          onChange={({ fileList: fl }) => setFileList(fl)}
        >
          {!value ? 'Upload' : 'Update'}
        </Upload>
      )}
      {suffix}
    </div>
  );
};
