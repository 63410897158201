import qs from 'query-string';
import axios from '../utils/axios';

const getPaymentInvoiceByID = async (id) => {
  const { data } = await axios.get(`/payment/invoices/${id}/`);
  return data;
};

const getPayments = async ({
  fetchEndpoint,
  pageParam = 1,
  queryParams,
  category,
}) => {
  let endpoint = fetchEndpoint;
  const queryString = qs.stringify(
    category === 'outstanding'
      ? {
          page: pageParam,
          ...queryParams,
        }
      : {
          page_number: pageParam,
          ...queryParams,
        },
    { skipNull: true, skipEmptyString: true },
  );

  if (queryString) endpoint += queryString;

  const { data } = await axios.get(endpoint);
  return data;
};

export { getPaymentInvoiceByID, getPayments };
