import { useInfiniteQuery } from 'react-query';
import qs from 'query-string';
import * as api from '../api';

export const usePCNs = ({ category, queryParams }) => {
  let fetchEndpoint;
  switch (category) {
    case 'history':
      fetchEndpoint = `/pcn/history-pcn-panel/?`;
      break;
    case 'all':
    default:
      fetchEndpoint = `/pcn/new-pcn-panel/?`;
      break;
  }
  return useInfiniteQuery(
    ['PCNs', qs.stringify({ category, ...queryParams })],
    ({ pageParam }) =>
      api.getPCNs({ fetchEndpoint, pageParam, ...queryParams }),
    {
      getNextPageParam: (lastPage) => lastPage?.next ?? false,
    },
  );
};
