import { useState, useEffect } from 'react';
import throttle from 'lodash.throttle';

export const useWindowWidth = () => {
  const [brkPnt, setBrkPnt] = useState(() => window.innerWidth);

  useEffect(() => {
    const calcInnerWidth = throttle(() => {
      setBrkPnt(window.innerWidth);
    }, 200);
    window.addEventListener('resize', calcInnerWidth);
    return () => window.removeEventListener('resize', calcInnerWidth);
  }, []);

  return brkPnt;
};
