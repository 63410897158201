import {
  Typography,
  Form,
  Input,
  Divider,
  Select,
  Spin,
  Button,
  Table,
  InputNumber,
  message,
  Row,
  Col,
} from 'antd';
import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { Link, useHistory } from 'react-router-dom';
import Cleave from 'cleave.js/react';
import { CustomDatePicker } from '../../components/custom-date-picker';
import { FloatLabel } from '../../components/floating-label';
import { SinglePhotoUploader } from '../../components/single-photo-uploader';
import { useSearchCarRegestratinNumber } from '../../hooks';
import { CustomizedModal } from '../../components';
import { allowOnlyNumbers, axios } from '../../utils';

const { Title } = Typography;
const Option = Select;
dayjs.extend(utc);

export default function ItemsModalCarReturn({
  titleModal,
  modeModal,
  isHistory,
  dataCar,
  refetch,
  form,
}) {
  const [formEx] = Form.useForm();
  const history = useHistory();

  const [searchInputDriver, setSearchInputDriver] = useState('');
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingAddExp, setLoadingAddExp] = useState(false);
  const [isEditExp, setIsEditExp] = useState(false);

  const isRequiredRule = [
    {
      required: true,
      message: 'This field is required',
    },
  ];

  const {
    data: searchedCar,
    isFetching,
    isLoading,
  } = useSearchCarRegestratinNumber(searchInputDriver);

  const searchWhitRegistrationNumber = (data) => {
    axios
      .get(`/cars/car/${data.split('#')[0]}`)
      .then(({ data: dataCarSelected }) => {
        form.setFieldsValue({
          ...dataCarSelected,
          fullName: `${dataCarSelected?.assing_to?.name} ${dataCarSelected?.assing_to?.last_name}`,
          Driver_details: {
            phone_number: dataCarSelected?.assing_to?.phone_number,
          },
        });
      });
  };

  const addEx = () => {
    formEx.validateFields().then(() => {
      setLoadingAddExp(true);
      const fd = new FormData();
      fd.append('car', dataCar.car_details.id);
      fd.append('driver', dataCar.driver_id);
      fd.append('cost', formEx.getFieldValue('cost'));
      fd.append('description', formEx.getFieldValue('description'));
      fd.append('expense_type', formEx.getFieldValue('expense_type'));
      fd.append('date', dayjs.utc(formEx.getFieldValue('date')).format());
      axios
        .post('/cars/newcar-expense/', fd)
        .then(() => {
          setIsModalVisible(false);
          refetch();
        })
        .finally(() => setLoadingAddExp(false));
    });
  };
  const EditEx = () => {
    formEx.validateFields().then(({ id }) => {
      setLoadingAddExp(true);
      const fd = new FormData();
      fd.append('car', dataCar.car_details.id);
      fd.append('driver', dataCar.driver_id);
      fd.append('cost', formEx.getFieldValue('cost'));
      fd.append('description', formEx.getFieldValue('details'));
      fd.append('expense_type', formEx.getFieldValue('expense_type'));
      fd.append(
        'date',
        dayjs(
          dayjs(formEx.getFieldValue('date')).format('YYYY-MM-DD'),
        ).format(),
      );
      axios
        .patch(`/cars/newcar-expense/${id}/`, fd)
        .then(() => {
          setIsModalVisible(false);
          refetch();
        })
        .finally(() => setLoadingAddExp(false));
    });
  };
  const deleteEx = () => {
    axios
      .delete(`/cars/newcar-expense/${formEx.getFieldValue('id')}/`)
      .then(() => {
        setIsModalVisible(false);
        message.success('Expense deleted');
        refetch();
      });
  };

  const columns = [
    {
      title: 'Expence type',
      dataIndex: 'expense_type',
    },
    {
      title: 'Cost',
      dataIndex: 'cost',
      render: (cost) => <>£ {cost}</>,
    },
    {
      title: 'Date',
      dataIndex: 'date',
    },
    {
      title: 'Details',
      dataIndex: 'details',
      render: (val) => (val === 'undefined' && '-') || val,
    },
  ];

  return (
    <>
      <Title level={4}>{titleModal}</Title>
      {modeModal === 'add' ? (
        <Divider orientation="left">Car details</Divider>
      ) : (
        <Divider orientation="left" className="tetst">
          <span>Car details</span>
          <Link to={`/car-bank?car_id=${dataCar?.car_details?.id}`}>
            Open car profile
          </Link>
        </Divider>
      )}
      <Form.Item
        className="form-item space-bottom"
        name={['car_details', 'registrationnumber']}
        rules={isRequiredRule}
      >
        <FloatLabel label="Registration number">
          {modeModal === 'update' ? (
            <Input disabled />
          ) : (
            <Select
              loading={isLoading || isFetching}
              notFoundContent={isLoading ? <Spin size="small" /> : undefined}
              allowClear
              showSearch
              onSelect={searchWhitRegistrationNumber}
              searchValue={searchInputDriver}
              onSearch={(value) => setSearchInputDriver(value)}
            >
              {searchedCar &&
                searchedCar?.results?.map((item) => (
                  <Option
                    key={+item.id}
                    value={`${item?.id}#${item?.registrationnumber}#${item?.assing_to?.drivercar_id}`}
                    disabled={!item?.assing_to}
                  >
                    {item?.assing_to ? (
                      `${item?.assing_to?.name} ${item?.assing_to?.last_name} - ${item?.registrationnumber} ${item?.model} ${item?.make} `
                    ) : (
                      <span style={{ color: '#da4242' }}>
                        {item?.registrationnumber} {item?.model} {item?.make}
                      </span>
                    )}
                  </Option>
                ))}
            </Select>
          )}
        </FloatLabel>
      </Form.Item>
      <Form.Item name="model">
        <FloatLabel label="Car model">
          <Input disabled />
        </FloatLabel>
      </Form.Item>
      {modeModal === 'add' ? (
        <Divider orientation="left">Drivers details</Divider>
      ) : (
        <Divider orientation="left" className="tetst">
          <span>Drivers details</span>
          <Link to={`/drivers?driver_id=${dataCar?.Driver_details?.id}`}>
            Open drivers profile
          </Link>
        </Divider>
      )}
      <Form.Item name="fullName">
        <FloatLabel label="Name">
          <Input disabled />
        </FloatLabel>
      </Form.Item>
      <Form.Item name={['Driver_details', 'phone_number']}>
        <FloatLabel label="Phone number">
          <Input disabled />
        </FloatLabel>
      </Form.Item>
      <Divider orientation="left">Car return appointment</Divider>
      <Form.Item
        rules={isRequiredRule}
        name="reason"
        style={{ flexBasis: '100%' }}
      >
        <FloatLabel label="Deduction reason">
          <Input.TextArea
            disabled={modeModal === 'show' || isHistory}
            rows={3}
            maxLength={150}
          />
        </FloatLabel>
      </Form.Item>
      <Form.Item name="appointment_date" rules={isRequiredRule}>
        <FloatLabel label="Appointment date">
          <CustomDatePicker disabled={isHistory} />
        </FloatLabel>
      </Form.Item>
      <Form.Item name="appointment_date" rules={isRequiredRule}>
        <FloatLabel label="Appointment Time">
          <CustomDatePicker disabled={isHistory} format="HH:mm" picker="time" />
        </FloatLabel>
      </Form.Item>
      {modeModal === 'update' &&
        dataCar?.status !== 'Pending appointment confirmation' && (
          <>
            <Divider orientation="left">Additional expences</Divider>
            {!isHistory && (
              <Button
                type="dashed"
                block
                className="full-width"
                style={{ marginBottom: 16 }}
                onClick={() => setIsModalVisible(true)}
              >
                <PlusOutlined />
                Add new expense
              </Button>
            )}
            <Table
              rowClassName={() => 'editable-row'}
              className="full-width none-border"
              bordered
              dataSource={dataCar?.additional_expense}
              columns={columns}
              pagination={false}
              onRow={(record) => ({
                onClick: () => {
                  setIsModalVisible(true);
                  setIsEditExp(true);

                  let expenseType;
                  switch (record.expense_type) {
                    case 'Body Work Expense / Vehicle damage':
                      expenseType = 5;
                      break;
                    case 'Fuel shortage':
                      expenseType = 8;
                      break;
                    case 'Dirty vehicle':
                      expenseType = 9;
                      break;
                    default:
                      expenseType = 5;
                      break;
                  }
                  formEx.setFieldsValue({
                    ...record,
                    date: dayjs(record.date),
                    expense_type: expenseType,
                  });
                },
              })}
            />
          </>
        )}
      {dataCar && (
        <Row style={{ flexBasis: '100%', marginTop: 18 }}>
          <Col span={24}>
            <div className="table_deposit">
              <Divider orientation="left">Amount to be returned</Divider>
              <hr />
              <div
                style={{ display: 'flex', flexDirection: 'column', gap: 19 }}
              >
                <Row justify="space-between">
                  <Col>Outstanding balance up to today</Col>
                  <Col>£ {dataCar?.balance?.daily_outstanding}</Col>
                </Row>
                <Row justify="space-between">
                  <Col>Total miscellaneous amount</Col>
                  <Col>£ {dataCar?.balance?.miscellaneous}</Col>
                </Row>
                <Row justify="space-between">
                  <Col>Deposit balance</Col>
                  <Col>£ {dataCar?.balance?.deposit}</Col>
                </Row>
                <Row
                  justify="space-between"
                  style={{ borderTop: '1px solid #000', paddingTop: 19 }}
                >
                  <Col>
                    <b>Total</b>
                  </Col>
                  <Col>
                    <b>
                      £{' '}
                      {dataCar?.balance?.daily_outstanding +
                        dataCar?.balance?.miscellaneous -
                        dataCar?.balance?.deposit}
                    </b>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </Row>
      )}
      {modeModal !== 'add' &&
        dataCar?.status !== 'Pending appointment confirmation' && (
          <>
            <Divider orientation="left">Drivers deposit balance</Divider>
            <Form.Item
              name={['deposit', 'deposit_that_had_to_be_collected']}
              rules={isRequiredRule}
            >
              <FloatLabel label="Total deposit to be collected">
                <Input disabled />
              </FloatLabel>
            </Form.Item>
            <Form.Item
              name={['deposit', 'collected_deposit']}
              rules={isRequiredRule}
            >
              <FloatLabel label="Total deposit collected">
                <Input disabled />
              </FloatLabel>
            </Form.Item>
            <Form.Item
              name={['deposit', 'sum_of_expenses']}
              rules={isRequiredRule}
            >
              <FloatLabel label="Total car expenses">
                <Input disabled />
              </FloatLabel>
            </Form.Item>
            <Form.Item
              name={['deposit', 'total_balance']}
              rules={isRequiredRule}
            >
              <FloatLabel label="Deposit balance">
                <Input disabled />
              </FloatLabel>
            </Form.Item>
          </>
        )}
      <Divider orientation="left">Drivers bank account information</Divider>
      <Form.Item
        name="account_number"
        rules={[
          {
            type: 'string',
            min: 8,
            max: 8,
            message: 'Account number must be 8 characters long',
          },
          ...isRequiredRule,
        ]}
      >
        <FloatLabel label="Account number">
          <Input type="number" disabled={isHistory} />
        </FloatLabel>
      </Form.Item>
      <Form.Item
        name="sort_code"
        normalize={(value) => value.replace(/-*/g, '')}
        rules={isRequiredRule}
      >
        <FloatLabel label="Sort Code">
          <Cleave
            onKeyPress={allowOnlyNumbers}
            className="ant-input ant-input-lg"
            options={{ blocks: [2, 2, 2], delimiter: '-' }}
            disabled={isHistory}
          />
        </FloatLabel>
      </Form.Item>
      <Form.Item name="name_of_the_account_holder" rules={isRequiredRule}>
        <FloatLabel label="Name of the account holder">
          <Input disabled={isHistory} />
        </FloatLabel>
      </Form.Item>
      <Form.Item name="bank_name" rules={isRequiredRule}>
        <FloatLabel label="Bank name">
          <Input disabled={isHistory} />
        </FloatLabel>
      </Form.Item>
      {modeModal === 'update' && (
        <Button
          type="primary"
          onClick={() =>
            history.push(
              `/accidents?name=${`${dataCar?.Driver_details.first_name} ${dataCar?.Driver_details.last_name}`}`,
            )
          }
          style={{ flexBasis: '100%', marginBottom: 16 }}
        >
          check accidents
        </Button>
      )}
      <Form.Item name="bank_statement_picture">
        <SinglePhotoUploader
          title="Click here to upload"
          titleTextUp="Bank statement picture"
          disabled={isHistory}
        />
      </Form.Item>
      <CustomizedModal
        visible={isModalVisible}
        afterClose={() => {
          formEx.resetFields();
          setIsEditExp(false);
        }}
        onCancel={() => setIsModalVisible(false)}
        title="Add expense report"
        maskClosable={false}
        width={792}
        centered
        footer={
          <div>
            {isEditExp ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'row-reverse',
                }}
              >
                <Button
                  onClick={EditEx}
                  className="footer-cta-btn"
                  type="primary"
                  loading={loadingAddExp}
                >
                  update
                </Button>
                <Button
                  onClick={deleteEx}
                  className="btn-denger"
                  style={{ width: '48%' }}
                >
                  delete
                </Button>
              </div>
            ) : (
              <Button
                onClick={addEx}
                className="footer-cta-btn"
                type="primary"
                loading={loadingAddExp}
              >
                Add to expenses
              </Button>
            )}
          </div>
        }
      >
        <Form
          form={formEx}
          name="horizontal_login"
          layout="inline"
          className="reset-error-form"
          style={{ justifyContent: 'space-between' }}
        >
          <Form.Item name="id" />
          <Form.Item
            className="full-width"
            name="expense_type"
            rules={isRequiredRule}
            style={{ marginBottom: '16px' }}
          >
            <FloatLabel label="Expense type">
              <Select>
                <Option value={5}>Body Work Expense / Vehicle damage</Option>
                <Option value={8}>Fuel shortage</Option>
                <Option value={9}>Dirty vehicle</Option>
              </Select>
            </FloatLabel>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: '16px' }}
            className="form-item"
            name="cost"
            rules={isRequiredRule}
          >
            <FloatLabel label="Cost">
              <InputNumber
                onWheel={(e) => e.target.blur()}
                formatter={(value) =>
                  value && `£ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                }
                className="full-width"
              />
            </FloatLabel>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: '16px' }}
            className="form-item"
            name="date"
            rules={isRequiredRule}
          >
            <FloatLabel label="Date">
              <CustomDatePicker className="full-width" />
            </FloatLabel>
          </Form.Item>
          <Form.Item
            style={{ marginBottom: '16px' }}
            className="full-width"
            name={isEditExp ? 'details' : 'description'}
          >
            <FloatLabel label="Description">
              <Input autoComplete="off" />
            </FloatLabel>
          </Form.Item>
        </Form>
      </CustomizedModal>
    </>
  );
}
