/* eslint-disable no-unused-vars */
/* eslint-disable no-console */
import { message } from 'antd';
import axios from 'axios';

const logError = (err) => {
  if (err.response) {
    const { status, statusText, data } = err.response;
    const { method, url, baseURL } = err.response.config;

    let error = '';
    error += `\nError ${status}: ${statusText}`;
    error += `\nmethod: ${method}`;
    error += `\nbase url: ${baseURL}\nendpoint: ${url}`;
    error += `${!data.message ? `\ndata:\n${data}` : ''}`;
    console.error(error);
  } else {
    console.error(err);
  }
};

const API_URL = process.env.REACT_APP_API_URL;

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

// request handler
instance.interceptors.request.use(
  (config) => {
    const temp = config;
    const access = localStorage.getItem('access_token');
    if (access) {
      temp.headers.Authorization = `Bearer ${access}`;
    }
    return temp;
  },
  (error) => Promise.reject(error),
);

// response handler
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (process.env.NODE_ENV === 'development') logError(error);
    const originalRequest = error.config;
    const errCode = error?.response?.status || undefined;
    const errMessage = error?.response?.data?.message || undefined;
    if (errMessage && errCode !== 401) message.error(errMessage);

    if (!error) {
      message.error('Unexpected error');
    } else if (typeof error.response === 'undefined') {
      message.error({ content: 'Network Error', key: 'NETWORK_ERROR' });
    } else if (
      error.code === 'ECONNABORTED' ||
      (error.response && error.response.status === 408)
    ) {
      message.error('The server is taking to long to respond');
    } else if (errCode === 401) {
      const terminateSession = () => {
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        localStorage.removeItem('type');
        if (
          error?.response?.data?.messages[0]?.message ===
          'Token is invalid or expired'
        ) {
          message.error({
            key: 'session-has-expired',
            content: "Your session has expired, you'll be logged out",
          });
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        }
      };

      if (!originalRequest.retry) {
        originalRequest.retry = true;
        const refresh = localStorage.getItem('refresh_token');

        if (refresh) {
          return axios
            .post(`${API_URL}/account/token/refresh/`, { refresh })
            .then(({ data }) => {
              localStorage.setItem('access_token', data.access || '');
              localStorage.setItem('refresh_token', data.refresh || '');
              instance.defaults.headers.common.Authorization = `Bearer ${
                data.access || ''
              }`;
              return instance(originalRequest);
            })
            .catch(() => {
              terminateSession();
              return Promise.reject(error);
            });
        }
        terminateSession();
        return Promise.reject(error);
      }
      terminateSession();
      return Promise.reject(error);
    } else if (errCode === 429) {
      message.error('Too many request');
    } else if (errCode >= 500 && errCode < 600) {
      message.error('Internal Server Error');
    } else if (errCode === 400) {
      const errData = error.response.data;

      const displayError = (data) => {
        if (typeof data === 'string') {
          message.error(data);
        } else if (Array.isArray(data)) {
          data?.map(displayError);
        } else if (typeof data === 'object' && !!data) {
          Object.entries(data).map(([keyError, value]) => {
            if (typeof value === 'string') message.error(value);
            else if (
              Array.isArray(value) &&
              value.every((el) => typeof el === 'string')
            )
              value.map((el) =>
                message.error(
                  `${
                    keyError !== 'non_field_errors' ? `${keyError} :` : ''
                  }${el}`,
                ),
              );
            else displayError(value);
            return Promise.reject(error);
          });
        }
        return Promise.reject(error);
      };

      displayError(errData);
    }

    return Promise.reject(error);
  },
);

export default instance;
