import qs from 'query-string';
import { axios } from '../utils';

export const getMileageList = async ({ paramers, pageParam }) => {
  const queryString = qs.stringify(
    { ...paramers, page_size: 10, page_number: pageParam },
    { skipNull: true, skipEmptyString: true },
  );
  const { data } = await axios.get(`/mileage/drivers/?${queryString}`);
  return data;
};
